import { get, post, put, deleteP, getExcelFile } from 'api/requests';
import { message, loading } from '../commons/actions';
import handleErrorMessage from 'utils/handleErrorMessage';
import { COUNTRY_CODE } from 'utils/constants';
import { formatPrice } from 'utils';
import {
  LIST_PICASH_LOADING,
  LIST_PICASH,
  GET_BANK_ACCOUNTS,
  BANK_ACCOUNTS_LOADING,
  BANK_LIST,
  ACCOUNT_TYPE_LIST,
  DOCUMENT_TYPE_LIST,
  GET_BANK_ACCOUNTS_AVAILABLE,
  RECHARGE_METHODS_LIST,
  PSE_PARAMS,
  CREDIT_CARDS_LOADING,
  GET_CREDIT_CARDS,
  PSE_PARAMS_LOADING,
  LIST_BILLS,
  PAYMENT_DATA,
  LIST_BILL_LOADING,
  SHOW_BILL,
  BILL_LOADING,
  SELECTED_PAYMENT_METHOD
} from './constants';
import {
  urlPicash,
  urlPicashExcel,
  urlBankAccounts,
  urlBankList,
  urlAccountTypeList,
  urlDocumentTypeList,
  urlCreateBankAccount,
  urlBankAccountsAvailable,
  urlRechargeMethods,
  urlPseParams,
  urlRecharge,
  urlCreditCards,
  urlValidateCreditCards,
  urlBills,
  urlPaymentData
} from 'api';

export const getListPicash = async (dispatch, params, configuration) => {
  dispatch({
    type: LIST_PICASH_LOADING,
    loadingPicash: true,
  });
  const id = configuration.account_id;
  await get(`${urlPicash}/${id}`, params)
    .then(({ data }) => {
      dispatch({
        type: LIST_PICASH,
        listPicash: data,
      });
      dispatch({
        type: LIST_PICASH_LOADING,
        loadingPicash: false,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: LIST_PICASH_LOADING,
        loadingBookings: false,
      });
    });
};

export const downloadExcelPicash = async (dispatch, id, params) => {
  loading(dispatch, true);
  await getExcelFile(`${urlPicashExcel}/${id}/transactions`, params)
    .then(() => {
      loading(dispatch, false);
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      loading(dispatch, false);
    });
};

export const getBankAccounts = async (dispatch, params) => {
  dispatch({
    type: BANK_ACCOUNTS_LOADING,
    bankAccountsLoading: true,
  });
  await get(urlBankAccounts, params)
    .then(({ data }) => {
      dispatch({
        type: GET_BANK_ACCOUNTS,
        bankAccounts: data,
      });
      dispatch({
        type: BANK_ACCOUNTS_LOADING,
        bankAccountsLoading: false,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: BANK_ACCOUNTS_LOADING,
        bankAccountsLoading: false,
      });
    });
};

export const getBankList = async (dispatch, params) => {
  await get(urlBankList, params)
    .then(({ data }) => {
      dispatch({
        type: BANK_LIST,
        bankList: data,
      });
    })
    .catch(() => {});
};

export const getAccountTypeList = async (dispatch, params) => {
  await get(urlAccountTypeList, params)
    .then(({ data }) => {
      dispatch({
        type: ACCOUNT_TYPE_LIST,
        accountTypeList: data,
      });
    })
    .catch(() => {});
};

export const getDocumentTypeList = async (dispatch, params) => {
  await get(urlDocumentTypeList, params)
    .then(({ data }) => {
      dispatch({
        type: DOCUMENT_TYPE_LIST,
        documentTypeList: data,
      });
    })
    .catch(() => {});
};

export const createBankAccount = async (dispatch, bank_account) => {
  loading(dispatch, true);
  let status = false;
  await post(urlCreateBankAccount, { ...bank_account })
    .then(() => {
      loading(dispatch, false);
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      loading(dispatch, false);
      status = false;
    });
  return { status };
};

export const editBankAccount = async (dispatch, id, bank_account) => {
  loading(dispatch, true);
  let status = false;
  await put(`${urlBankAccounts}/${id}`, { ...bank_account })
    .then(() => {
      loading(dispatch, false);
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      loading(dispatch, false);
      status = false;
    });
  return { status };
};

export const removeBankAccount = async (dispatch, id) => {
  let status = false;
  await deleteP(`${urlBankAccounts}/${id}`, {})
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
};

export const getBankAccountsAvailable = async (dispatch) => {
  await get(urlBankAccountsAvailable, {})
    .then(({ data }) => {
      dispatch({
        type: GET_BANK_ACCOUNTS_AVAILABLE,
        bankAccountsAvailable: data,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
    });
};

export const withdraw = async (dispatch, id, value, lang) => {
  let status = false;
  const amount = {
    sub_units: formatPrice({ sub_units: value, iso: COUNTRY_CODE[lang][4] }),
    iso: COUNTRY_CODE[lang][4],
  };
  await post(`${urlBankAccounts}/${id}/request_account_balance_withdraw`, {
    amount,
  })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
};

export const getRechargeMethods = async (dispatch, params) => {
  await get(urlRechargeMethods, params)
    .then(({ data }) => {
      dispatch({
        type: RECHARGE_METHODS_LIST,
        rechargeMethods: data,
      });
    })
    .catch(() => {});
};

export const getPseParams = async (dispatch) => {
  dispatch({
    type: PSE_PARAMS_LOADING,
    pseParamsLoading: true,
  });
 
  await get(urlPseParams, {})
    .then(({ data }) => {
      dispatch({
        type: PSE_PARAMS,
        pseParams: data,
      });

      dispatch({
        type: PSE_PARAMS_LOADING,
        pseParamsLoading: false,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: PSE_PARAMS_LOADING,
        pseParamsLoading: false,
      });
    });
};

export const newRechargePicash = async (dispatch, values, lang) => {
  let status = false;
  let redirectPage = null;
  const amount = {
    sub_units: formatPrice({
      sub_units: values.amount,
      iso: COUNTRY_CODE[lang][4],
    }),
    currency: COUNTRY_CODE[lang][4],
  };

  const transaction = {
    ...values,
    code: values.code,
    amount: amount,
    favorite_place_id: null,
    web: true,
    pibox_web: true,
  };
  
  await post(urlRecharge, { transaction })
    .then(({ data }) => {
      status = true;
      redirectPage = data?.raw_response?.data?.urlbanco ? data.raw_response.data.urlbanco : data.raw_response.answer.paymentURL ;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
      redirectPage = null;
    });
  return { status, redirectPage };
};

export const newRechargeWithCreditCard = async (dispatch, values, lang) => {
  let status = false;
  const amount = {
    sub_units: formatPrice({
      sub_units: values.amount,
      iso: COUNTRY_CODE[lang][4],
    }),
    currency: COUNTRY_CODE[lang][4],
  };
  const transaction = {
    ...values,
    // code: values.code,
    amount: amount,
    // favorite_place_id: null,
    // web: true,
    // pibox_web: true,
  };

  await post(urlRecharge, { transaction })
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
};

export const getCreditCards = async (dispatch, params) => {
  dispatch({
    type: CREDIT_CARDS_LOADING,
    creditCardsLoading: true,
  });
  await get(urlCreditCards, params)
    .then(({ data }) => {
      dispatch({
        type: GET_CREDIT_CARDS,
        creditCards: data,
      });
      dispatch({
        type: CREDIT_CARDS_LOADING,
        creditCardsLoading: false,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: CREDIT_CARDS_LOADING,
        creditCardsLoading: false,
      });
    });
};

export const addCreditCard = async (dispatch, card_info) => {
  loading(dispatch, true);
  let response = { status: false, cardId: null };
  const { validation_amount, ...card } = card_info;
  await post(urlCreditCards, { ...card })
    .then(({ data }) => {
      const { _id } = data;
      response.status = true;
      response.cardId = _id;
      loading(dispatch, false);
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      loading(dispatch, false);
    });
  return response;
};

export const removeCreditCard = async (dispatch, id) => {
  let status = false;
  await deleteP(`${urlCreditCards}/${id}`, {})
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
};

export const validateCreditCard = async (dispatch, card) => {
  loading(dispatch, true);
  let status = false;

  await post(urlValidateCreditCards, { card })
    .then(() => {
      loading(dispatch, false);
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      loading(dispatch, false);
      status = false;
    });
  return { status };
};

export const getBills = async (dispatch, params) => {
  dispatch({
    type: LIST_BILL_LOADING,
    loadingBills: true,
  });
  await get(urlBills, params)
    .then(({ data }) => {
      dispatch({
        type: LIST_BILLS,
        listBills: data,
      });
      dispatch({
        type: LIST_BILL_LOADING,
        loadingBills: false,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: LIST_BILL_LOADING,
        loadingBills: false,
      });
    });
};

export const getPaymentData = async (dispatch, params) => {
  await get(urlPaymentData, params)
    .then(({ data }) => {
      dispatch({
        type: PAYMENT_DATA,
        paymentData: data,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
    });
};

export const getShowBill = async (dispatch, id) => {
  dispatch({
    type: BILL_LOADING,
    loadingBill: true,
  });
  await get(`${urlBills}/${id}`, {})
    .then(({ data }) => {
      dispatch({
        type: SHOW_BILL,
        showBill: data,
      });
      dispatch({
        type: BILL_LOADING,
        loadingBill: false,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: BILL_LOADING,
        loadingBill: false,
      });
    });
};

export const selectedPaymentMethod = (dispatch, paymentId) => {
  dispatch({
    type: SELECTED_PAYMENT_METHOD,
    paymentId
  });
}

export const sendToPay = async (dispatch, id, electronic_bill_payment) => {
  loading(dispatch, true);
  let status = false;
  let urlPay = '';
  await post(`${urlBills}/${id}/payment_link`, { ...electronic_bill_payment })
    .then(({data}) => {
      loading(dispatch, false);
      status = true;
      urlPay = data?.url
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      loading(dispatch, false);
      status = false;
      urlPay = '';
    });
  return { status, urlPay };
};