import { LIST_GROUPS, LIST_GROUPS_LOADING, GROUP_USER_LIST, GROUP_USER_LIST_LOADING } from './constants';

const groupsReducer = (state, action) => {
  switch (action.type) {
    case LIST_GROUPS_LOADING: {
      return {
        ...state,
        loadingGroups: action.loadingGroups
      }
    }
    case LIST_GROUPS:
      return {
        ...state,
        listGroups: action.listGroups
      }
    case GROUP_USER_LIST:
      return {
        ...state,
        groupUserList: action.groupUserList
      }
    case GROUP_USER_LIST_LOADING:
      return {
        ...state,
        loadingGroupUserList: action.loadingGroupUserList
      }
    default:
      return state
  }
}

export default groupsReducer;
