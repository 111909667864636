import React, { useContext, useEffect, useState } from 'react';
import { Button, Accordion, Typography, AccordionDetails, AccordionSummary, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { I18nContext } from 'translations';
import { removeDuplicatesIds } from 'utils';
import DestinationFields from '../FormFields/DestinationFields';
import CustomerFields from '../FormFields/CustomerFields';
import PackageFields from '../FormFields/PackageFields';
import styles from '../../NewStyle';

const Stops = ({
  classes, expanded, handleChange, origin, index, address, fields, moveStops, removeStops, register,
  handleAddress, showPackage, item, city, changeCoord, configuration, errors, loadingAproxAddress, 
  setCustomer, customer, counterDelivery, Controller, control, isChecked, checkStops, cities, company, isRent
}) => {
  const [checked, setChecked] = useState(false);
  const { translate } = useContext(I18nContext);

  const onChangeOrder = async (e) => {
    e.stopPropagation();
    setChecked(e.target.checked);
    e.target.checked ? isChecked.push({ id: e.target.name, value: e.target.checked }) : isChecked.splice(isChecked.findIndex(x => x.id === e.target.name), 1);
    if (isChecked.length > 2) {
      alert(translate('app.express.maximumStopsToChangeOrder'));
      setChecked(!e.target.checked);
      isChecked.pop();
    }
  }

  useEffect(() => {
    isChecked.length === 0 && setChecked(false)
  }, [checkStops]) // eslint-disable-line

  return (
    <Accordion
      expanded={expanded === `stops${index}`}
      name={item.id}
      onChange={handleChange(`stops${index}`)}
      className={classes.accordionContainer}
    >
      <AccordionSummary
        expandIcon={expanded !== `stops${index}` && <EditIcon color="primary" fontSize="small" />}
        disabled={(expanded === 'close' ? false : true && expanded !== 'paymentRent') || (origin && !origin.lat)}
      >
        {checkStops && (
          <Checkbox
            style={{ padding: '0 13px 0 0' }}
            size="medium"
            icon={<RadioButtonUncheckedIcon fontSize="small" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
            checked={checked}
            name={index.toString()}
            onClick={onChangeOrder}
          />
        )}
        <Typography className="maxWidth93">
          <span className="tag-rounded" style={{ marginRight: 10 }}>
            {index+2}
          </span> 
          {address[index] && address[index].lat ? address[index].name : translate('app.express.addDestination')}
        </Typography>
        {expanded === 'close' && index < fields.length-1 && (
          <SwapVertIcon
            fontSize="default"
            className={classes.iconSwap}
            onClick={async (e) => await moveStops(e, index, index+1)}
            onFocus={(e) => e.stopPropagation()}
            color="primary"
          />
        )}
      </AccordionSummary>
      {expanded === `stops${index}` && fields.length > 1 && (
        <DeleteForeverIcon
          fontSize="small"
          color="secondary"
          onClick={async () => await removeStops(index, item.id)}
          className={classes.iconDelete}
        />
      )}
      <AccordionDetails className={classes.flexColumn}>
        <DestinationFields
          index={index}
          address={address}
          handleAddress={handleAddress}
          register={register}
          id={item.id}
          city={{
            lon: city ? city.location.lon : configuration.cities_for_delivery.length > 0 ? configuration.cities_for_delivery[0].location.lon : '-74.134',
            lat: city ? city.location.lat : configuration.cities_for_delivery.length > 0 ? configuration.cities_for_delivery[0].location.lat : '4.674',
            name: city ? city.name : configuration.cities_for_delivery.length > 0 ? configuration.cities_for_delivery[0].name : translate('app.express.nameCouldNotBeFound'),
            id: city ? city._id : configuration.cities_for_delivery.length > 0 ? configuration.cities_for_delivery[0]._id : 0
          }}
          type={1}
          changeCoord={changeCoord}
          loadingAproxAddress={loadingAproxAddress}
          item={item}
          cities={cities?.length > 0 ? removeDuplicatesIds(cities) : cities}
          isRent={isRent}
        />
        {!isRent &&
          <>
            <div style={{ margin: '10px 0' }}>
              <Button
                className="text-left line-height-xs"
                color="primary"
                type="button"
                startIcon={customer ? <RemoveIcon fontSize="small" /> : <AddIcon fontSize="small" />}
                onClick={async e => {
                  e.stopPropagation();
                  setCustomer(!customer);
                  const section = document.querySelector(`#customer${index}`);
                  section && section.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }}
              >
                {translate('app.express.addDataOfThePersonWhoReceives')}
              </Button>
            </div>
            <div id={`customer${index}`} style={{ display: customer ? 'block' : 'none' }}>
              <CustomerFields
                index={index}
                customer={customer}
                register={register}
                errors={errors}
                item={item}
              />
              <br />
            </div>
          
            <div style={{ display: showPackage ? 'block' : 'none' }} >
              <PackageFields
                index={index}
                register={register}
                errors={errors}
                counterDelivery={counterDelivery}
                item={item}
                Controller={Controller}
                control={control}
                company={company}
              />
            </div>
          </>
        }
      </AccordionDetails>
    </Accordion>
  );
}

function areEqual(prevProps, nextProps) {
  return prevProps.origin === nextProps.origin
    && prevProps.item === nextProps.item
    && prevProps.address === nextProps.address
    && prevProps.expanded === nextProps.expanded
    && prevProps.customer === nextProps.customer
    && prevProps.showPackage === nextProps.showPackage
    && prevProps.loadingAproxAddress === nextProps.loadingAproxAddress
    && prevProps.checkStops === nextProps.checkStops
}

export default withStyles(styles)(React.memo(Stops, areEqual));
