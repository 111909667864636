import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Button, TextField, InputAdornment, FormControl,
  FormControlLabel, Checkbox, FormHelperText, Select, MenuItem, InputLabel
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { I18nContext } from 'translations';
import { COUNTRIES, INDUSTRY_TYPE_CD, TERMS_CORPORATE_URL } from 'utils/constants';
import schema from './schema';
import stylesFormCompanyData from './stylesFormCompanyData';

function FormCompanyData({ classes, submit }) {
  const { translate } = useContext(I18nContext);
  const { handleSubmit, control, errors, register } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classes.form}>
      <FormControl fullWidth required className="text-left">
        <InputLabel htmlFor="type_cd">{translate('country')} </InputLabel>
        <Controller
          control={control}
          name="company.geo_fence_id"
          defaultValue={COUNTRIES[0]._id}
          label={translate('country')}
          className={classes.marginBottomField}
          as={
            <Select>
              {COUNTRIES.map(country => (
                <MenuItem value={country._id} key={country._id}>
                  <img src={`/images/flags/${country._id}.svg`} alt={country.name} className={classes.flags} />
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          }
        />
      </FormControl>

      <TextField
        name="company.name"
        error={errors.company && !!errors.company.name}
        label={translate('companyName')}
        helperText={errors.company && errors.company.name && translate(errors.company.name.message)}
        type="text"
        inputRef={register}
        fullWidth
        className={classes.marginBottomField}
        required
        InputProps={{
          endAdornment: <InputAdornment position="end"><LocationCityIcon className={classes.icon} /></InputAdornment>,
        }}
      />

      <TextField
        name="company.tax_id"
        error={errors.company && !!errors.company.tax_id}
        label={translate('uniqueIdCompany')}
        helperText={errors.company && errors.company.tax_id && translate(errors.company.tax_id.message)}
        type="text"
        inputRef={register}
        fullWidth
        className={classes.marginBottomField}
        required
        InputProps={{
          endAdornment: <InputAdornment position="end"><RecentActorsIcon className={classes.icon} /></InputAdornment>,
        }}
      />

      <FormControl fullWidth required className="text-left">
        <InputLabel htmlFor="type_cd">{translate('companyType')} </InputLabel>
        <Controller
          control={control}
          name="company.industry_type_cd"
          defaultValue={1}
          label={translate('companyType')}
          className={classes.marginBottomField}
          as={
            <Select>
              {INDUSTRY_TYPE_CD.map(industry => (
                <MenuItem value={industry._id} key={industry._id.toString()}>
                  {translate(industry.name)}
                </MenuItem>
              ))}
            </Select>
          }
        />
      </FormControl>

      <FormControl error={!!errors.terms} required className={classes.marginBottomField}>
        <FormControlLabel
          control={<Checkbox name="passenger.has_accepted_tc" inputRef={register} />}
          label={
            <Link to={TERMS_CORPORATE_URL} target="_blank" color="primary">
              {translate('acceptTerms')} *
            </Link>
          }
        />
        <FormHelperText>{errors?.company?.has_accepted_tc && translate(errors?.company?.has_accepted_tc.message)}</FormHelperText>
      </FormControl>

      <Button
        type="submit"
        color="primary"
        variant="contained"
        size="large"
        className={classes.button}
      >
        {translate('signup')}
      </Button>
    </form>
  );
}

FormCompanyData.propTypes = {
  classes: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired
};

export default withStyles(stylesFormCompanyData)(FormCompanyData);