import { string, object } from 'yup';

const schema = object().shape({
  'cc-num': string().required('requiredField'),
  'cc-exp': string().required('requiredField'),
  'cc-csc': string().required('requiredField'),
  'cc-name': string().required('requiredField'),
  fiscal_number: string().required('requiredField'),
});

export default schema;
