import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  btn: {
    background: 'rgb(227 221 221)',
    color: '#663786',
    boxShadow: 'none',
    borderRadius: '20px',
    margin: '0 9px 5px 0',
    fontWeight: 'bold',
    padding: '4px 12px',
    '& img': {
      width: '25px',
      marginRight: '10px'
    },
    '& svg': {
      marginRight: '10px'
    }
  }
}));

export default styles;