import React, { useState, useContext, useEffect } from 'react';
import { Grid, TextField, Typography, Button } from '@material-ui/core';
import { I18nContext } from 'translations';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AutocompleteInput from 'components/CustomInputs/AutocompleteInput';
import ModalMap from 'components/Modals/ModalMap';

const DestinationFields = ({
  index,
  address,
  handleAddress,
  register,
  id,
  city,
  type,
  changeCoord,
  loadingAproxAddress,
  item,
  cities,
  isRent
}) => {
  const { translate } = useContext(I18nContext);
  const [open, setOpen] = useState(false);
  const handleModalMap = () => setOpen(!open);
  const [cityStop, setCityStop] = useState(item?.city_id && cities?.length > 0 ? cities?.filter(id => id._id === item?.city_id)?.[0] : null);

  useEffect(() => {
    item && item.location && handleAddress({ name: item.address, lat: item.location.lat, lon: item.location.lon }, index, id);
  }, []); // eslint-disable-line

  useEffect(() => {
    item && item.lat && handleAddress({ name: item.address, lat: item.lat, lon: item.lon }, index, id);
  }, []); // eslint-disable-line

  return (
    <Grid item container spacing={2}>
      <Grid item md={12} sm={6} xs={12}>
        {cities && (
          <Autocomplete
            id="cities"
            options={cities}
            style={{ marginBottom: 15 }}
            autoHighlight
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
            value={cityStop ? cityStop : cities[0]}
            onChange={(event, newValue) => {
              event.stopPropagation();
              setCityStop(newValue);
            }}
          />
        )}
        <AutocompleteInput
          title={translate('app.express.address')}
          name={`stops[${index}].address`}
          address={address ? address[index] : ''}
          pos={{ lat: cityStop ? cityStop.location.lat : city.lat, lon: cityStop ? cityStop.location.lon : city.lon }}
          cityId={cityStop ? cityStop._id : city.id}
          focus
          handleChange={(info) => handleAddress(info, index, id)}
        />
        <Typography
          color="textSecondary"
          variant="caption"
          className="line-height-xs"
        >
          {translate('app.express.selectAddressFromTheListOfSuggestions')}
          <Button type="button" size="small" onClick={handleModalMap} color="primary" style={{ fontSize: '0.75rem', padding: '0px 5px' }}>
            {translate('app.express.help')}
          </Button>
        </Typography>
      </Grid>
      {!isRent &&
        <Grid item md={12} sm={6} xs={12}>
          <TextField
            type="text"
            name={`stops[${index}].secondary_address`}
            defaultValue={item.secondary_address}
            variant="outlined"
            label={translate('app.express.supplementaryAddress')}
            fullWidth
            inputRef={register()}
            size="small"
          />
        </Grid>
      }
      {open && (
        <ModalMap
          open={open}
          handleModalMap={handleModalMap}
          city={cityStop ? { id: cityStop._id, name: cityStop.name, lat: cityStop.location.lat, lon: cityStop.location.lon } : city}
          changeAddress={changeCoord}
          address={address && address[index]}
          index={index}
          type={type}
          id={id}
          loadingAproxAddress={loadingAproxAddress}
        />
      )}
    </Grid>
  )
}

function areEqual(prevProps, nextProps) {
  return prevProps.item === nextProps.item
    && prevProps.loadingAproxAddress === nextProps.loadingAproxAddress
    && prevProps.address === nextProps.address;
}

export default React.memo(DestinationFields, areEqual);