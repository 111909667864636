import React, { useContext, useEffect, useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Snackbar,
  Button,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { I18nContext } from 'translations';
import { message as closeMessage } from 'context/commons/actions';
import { getCostCenter } from 'context/express/actions';
import { useStateValue } from 'context/store';
import { createPromoCode, updatePromoCode } from 'context/promocode/actions';
import { toIsoString, modalMessage, flatFormat } from 'utils';
import { INITIAL_PAGE } from 'utils/constants';
import styles from 'components/Tables/styleTableFilters';
import Loading from 'components/Loading';
import { schema, schemaEdit } from './schema';

const defaultValues = {
  ini_date: null,
  end_date: null,
  multiplier: '',
  amount: '',
  cost_center_id: ''
};

const FormPromoCode = ({ classes, codeInfo, setOpen, getListPromoCode, isEdit, setCodeInfo, setIsEdit, removePromoCode }) => {
  const { langCode, translate } = useContext(I18nContext);
  const [costCenter, setCostCenter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [
    {
      checkin: { company },
      commons: { message },
    }, dispatch
  ] = useStateValue();

  const { handleSubmit, control, errors, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(isEdit ? schemaEdit : schema)
  });

  const listCostCenter = useCallback(async () => {
    const callback = company && (await getCostCenter(company._id));
    callback && callback.costCenter && setCostCenter(callback.costCenter);
  }, [company]);

  useEffect(() => {
    listCostCenter();
  }, [listCostCenter]);

  useEffect(() => {
    if (isEdit && codeInfo && codeInfo.length > 0) {
      const dataEdit = {
        ...codeInfo[0],
        amount: flatFormat({ sub_units: codeInfo[0].amount.sub_units, iso: codeInfo[0].amount.currency }),
        cost_center_id: codeInfo[0].cost_center?.id ? codeInfo[0].cost_center.id : ''
      }
      reset(dataEdit)
    } else {
      reset(defaultValues)
    }
  }, [codeInfo, isEdit]) // eslint-disable-line

  const messageOk = () => {
    setLoading(false);
    modalMessage(translate('congratulations'), translate('promoCode.form.messageOk'), 'success', translate('continue')).then(async() => {
      await getListPromoCode(INITIAL_PAGE);
      reset(defaultValues);
      setCodeInfo([])
      setIsEdit(false)
    })
  }

  const onSubmit = async (values) => {
    const params = {
      ...values,
      ini_date: toIsoString(values.ini_date),
      end_date: toIsoString(values.end_date)
    };
    Object.keys(params).forEach((key) => {
      if (params[key] === '' || params[key] == null) {
        delete params[key];
      }
    });
    if (!isEdit) {
      setLoading(true);
      const callback = await createPromoCode(dispatch, params, company?._id, langCode);
      callback.status ? messageOk() : setLoading(false);
    } else {
      setLoading(true);
      const callback = await updatePromoCode(dispatch, company?._id, codeInfo[0]._id, params, langCode);
      callback.status ? messageOk() : setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      noValidate
      className="relative"
    >
      {loading && <Loading />}
      <Grid container spacing={1}>
        {!isEdit &&
          <Grid item md={3} sm={4} xs={6}>
            <Controller
              control={control}
              name="multiplier"
              label={translate('promoCode.form.codesToGenerate')}
              defaultValue=""
              as={
                <TextField
                  type="text"
                  variant="outlined"  
                  fullWidth
                  size="small"
                  className={classes.filter}
                  error={!!errors.multiplier}
                  helperText={errors.multiplier && translate(errors.multiplier.message)}
                />
              }
            />
          </Grid>
        }
        <Grid item md={3} sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Controller
              name="ini_date"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <KeyboardDateTimePicker
                  className={classes.filter}
                  size="small"
                  inputVariant="outlined"
                  label={translate('promoCode.form.validSince')}
                  format="dd/MM/yyyy"
                  cancelLabel={translate('cancel')}
                  okLabel={translate('accept')}
                  minDate={new Date()}
                  invalidDateMessage={translate('invalidFormat')}
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!errors.ini_date}
                  helperText={errors.ini_date && translate(errors.ini_date.message)}
                />
              )}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={1} className="text-center hidden-sm" style={{ flexBasis: 0 }}>
          <ArrowForwardIcon fontSize="default" />
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Controller
              name="end_date"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <KeyboardDateTimePicker
                  className={classes.filter}
                  size="small"
                  inputVariant="outlined"
                  label={translate('promoCode.form.validUntil')}
                  format="dd/MM/yyyy"
                  cancelLabel={translate('cancel')}
                  okLabel={translate('accept')}
                  minDate={new Date()}
                  invalidDateMessage={translate('invalidFormat')}
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!errors.end_date}
                  helperText={errors.end_date && translate(errors.end_date.message)}
                />
              )}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
         <Controller
            control={control}
            name="amount"
            label={translate('promoCode.form.balanceByCode')}
            defaultValue=""
            as={
              <TextField
                type="text"
                variant="outlined"  
                fullWidth
                size="small"
                className={classes.filter}
                error={!!errors.amount}
                helperText={errors.amount && translate(errors.amount.message)}
              />
            }
          />
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.filter}
            fullWidth
          >
            <InputLabel>
              {translate('promoCode.form.costCenter')}
            </InputLabel>
            <Controller
              control={control}
              name="cost_center_id"
              label={translate('promoCode.form.costCenter')}
              variant="outlined"
              defaultValue=""
              as={
                <Select>
                  <MenuItem value="">
                    {translate('app.bookings.filter.choose')}
                  </MenuItem>
                  {costCenter.map((center) => (
                    <MenuItem key={center._id} value={center._id}>
                      {center.name}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item md={6}  xs={12} className="text-center-sm">
          <Button
            variant="contained"
            color="primary"
            size="small"
            type="submit"
            style={{ width: 120, marginRight: 10 }}
            className="active-primary-btn"
          >
            {isEdit ? translate('promoCode.form.edit') : translate('promoCode.form.generate')}
          </Button>
        </Grid>
        <Grid item md={6}  xs={12} className={codeInfo?.length > 1 ? 'active-table-code text-center-sm' : 'disabled-table-code text-center-sm'}>
          <span style={{ marginRight: 10 }} className="hidden-sm">{translate('promoCode.form.blockActions')}</span>
          <Button
            variant="contained"
            type="button"
            size="small"
            disableElevation
            style={{ marginRight: 10 }}
            startIcon={<ArrowForwardIcon />}
            className="active-primary-btn"
            onClick={() => {
              setOpen(true)
              reset(defaultValues)
            }}
          >
            {translate('promoCode.form.assign')}
          </Button>
          <Button
            variant="contained"
            type="button"
            size="small"
            disableElevation
            startIcon={<DeleteOutlineOutlinedIcon />}
            className="active-red-btn"
            onClick={() => {
              removePromoCode(codeInfo)
              reset(defaultValues)
            }}
          >
            {translate('promoCode.form.remove')}
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </form>
  );
};

export default withStyles(styles)(FormPromoCode);