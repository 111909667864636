import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { I18nContext } from 'translations';

const withHelmet = ({
  title,
  description,
  linkCanonical,
  image,
  noIndex,
  favicon
}) => WrappedComponent => props => {
  const { translate } = useContext(I18nContext);
  return (
    <>
      <Helmet>
        <title>{translate(title)}</title>
        <meta property="og:title" content={translate(title)} />
        <meta property="twitter:title" content={translate(title)} />
        <meta name="description" content={translate(description)} />
        <meta property="og:description" content={translate(description)} />
        <meta property="twitter:description" content={translate(description)} />
        <meta property="og:image" content={image || 'https://pibox.app/images/logo192.png'} />
        <meta name="twitter:image" content={image || 'https://pibox.app/images/logo192.png'} />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        {noIndex && (
          <meta name="robots" content="noindex" />
        )}
        {linkCanonical && (
          <link rel="canonical" href={linkCanonical}/>
        )}
      </Helmet>
      <WrappedComponent {...props} />
    </>
  );
};

export default withHelmet;
