import React, { useContext } from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  Typography,
  Avatar,
  Button,
  Grid,
  withStyles
} from '@material-ui/core';
import { I18nContext } from 'translations';
import { format } from 'date-fns';
import styles from '../style';

const RequestDriverTable = ({
  classes,
  data,
  bookingReservationId,
  selectedPrincipalBooking
}) => {
  const { translate } = useContext(I18nContext);

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>{translate('app.bookingReserved.table.driver')}</TableCell>
              <TableCell>{translate('app.bookingReserved.table.phone')}</TableCell>
              <TableCell>{translate('app.bookingReserved.table.createDate')}</TableCell>
              <TableCell>{translate('app.bookingReserved.table.scheduled')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data || []).map(booking => (
              <TableRow hover key={booking._id}>
                <TableCell>
                  {booking.driver._id?? '-'}
                </TableCell>
                <TableCell>
                  <Grid container alignItems="center" spacing={1} style={{ minWidth: 205 }}>
                    <Grid item sm={3} xs={2}>
                      <Avatar
                        alt="driver"
                        src={booking.driver && booking.driver.photo_url ? booking.driver.photo_url : ''}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        component="span"
                      >
                        {booking.driver ? booking.driver.name : ' - '}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    component="span"
                  >
                    {booking.driver ? booking.driver.phone : ' - '}
                  </Typography>
                </TableCell>
                <TableCell style={{ minWidth: 170 }}>
                  <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    component="span"
                  >
                    {format(new Date(booking.created_at), 'dd/MM/yyyy, hh:mm a')}
                  </Typography>
                </TableCell>
                <TableCell style={{ minWidth: 170 }}>
                  <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    component="span"
                  >
                    {format(new Date(booking.scheduled_at), 'dd/MM/yyyy, hh:mm a')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color={bookingReservationId ? "secondary" : "primary"}
                    onClick={() => bookingReservationId ? selectedPrincipalBooking(null) : selectedPrincipalBooking(booking._id)}
                  >
                    {bookingReservationId ? translate('app.bookingReserved.table.remove') : translate('app.bookingReserved.table.select')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default withStyles(styles)(RequestDriverTable);

