import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  language: {
    position: 'absolute',
    right: 7,
    top: 11,
    '& .flag-select__options': {
      right: 0
    }
  },
  logo: {
    position: 'absolute',
    left: 12,
    top: 13,
    height: '45px'
  },
  title: {
    margin: '10px 0',
    display: 'inline-block',
    textAlign: 'center'
  }
}));

export default styles;