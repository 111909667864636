import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, Container, Grid, Typography, Snackbar, Hidden, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { signup, createSession } from 'context/users/actions';
import { I18nContext } from 'translations';
import LayoutOuter from 'components/Layouts/LayoutOuter';
import Loading from 'components/Loading';
import Menu from 'components/Landing/Layouts/Menu';
import FacebookComponent from 'components/Social/FacebookComponent';
import SignupForm from 'components/Forms/Signup/SignupForm';
import LinkAccount from '../components/LinkAccount';
import withHelmet from 'utils/withHelmet';
import { RENT } from 'utils/constants';
import styles from './style';

const isRent = process.env.REACT_APP_API_URL_BASE_IS_RENT === RENT;

function Signup({ classes, history }) {
  const [dataFacebook, setDataFacebook] = useState(null);
  const { translate } = useContext(I18nContext);
  const [{ commons: { loading, message}, session: { session } }, dispatch] = useStateValue();
  const search = history.location.search.split('?')[1];
  const query = new URLSearchParams(history.location.search);

  const shopifyData = query.get('shp_uuid') && `/app/integrations/shopify/activation?${search}`;
  const linkShopify = query.get('shp_uuid') && `/login?${search}`;

  const onSubmit = async values => await signup(dispatch, dataFacebook ? { ...values, oauth: dataFacebook.oauth } : { ...values }, 0, history, search ? shopifyData : '');

  const responseFacebook = (response) => {
    const oauth = {
      provider: 'facebook',
      uid: response.userID ? response.userID : response.uid,
      token: response.accessToken ? response.accessToken : response.token
    }
    response && response.accessToken && createSession(dispatch, { oauth: oauth }, history);

    setDataFacebook({
      passenger: {
        name: response.name,
        email: response.email
      },
      oauth
    });
  }

  useEffect(() => {
    session && responseFacebook(session);
  }, [session]); // eslint-disable-line

  return (
    <LayoutOuter bg="#f8f8f8">
      <Menu />
      <Container maxWidth="md" style={{ marginTop: 70 }} className="container-width-lg">
        <Paper elevation={4} variant="outlined" className={classes.wrapper}>
          {loading && <Loading />}
          <Grid container alignItems="center" justify="center" spacing={2}>
            <Grid item sm={5} md={6} xl={7} className="text-center">
              <img
                src={isRent ? "/images/rent/driver-rent.jpg" : "/images/users/pibox-boxes.png"}
                alt="signup"
                className={classes.principalImg}
              />
              <Hidden xsDown>
                <LinkAccount
                  text="doYouAlreadyHaveAnAccount"
                  link={linkShopify ? linkShopify : "/login"}
                  textLink="loginHere"
                />
              </Hidden>
            </Grid>
            <Grid item sm={7} md={6} xl={5} className="text-center">
              <Typography variant="h4" color="textPrimary">
                {translate('signup')}
              </Typography>
              <br />
              <SignupForm
                submit={onSubmit}
                passenger={dataFacebook && dataFacebook.passenger}
              />
              <br />
              <Divider color="lightgray" />
              <br />
              <FacebookComponent
                responseFacebook={responseFacebook}
                text={translate('orSignUpWith')}
              />
            </Grid>
            <Hidden smUp>
              <Grid item xs={12}>
                <LinkAccount
                  text="doYouAlreadyHaveAnAccount"
                  link={linkShopify ? linkShopify : "/login"}
                  textLink="loginHere"
                />
              </Grid>
            </Hidden>
          </Grid>
        </Paper>
      </Container>
      <Snackbar
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </LayoutOuter>
  );
}

Signup.propTypes = {
  classes: PropTypes.object.isRequired
};


export default withStyles(styles)(
  withHelmet({
    title: isRent ? 'Picap Rent' : 'Envíos y Mensajería Urbana | Pibox',
    description: isRent ? 'Picap Rent' : 'Realiza envíos de paquetes y carga a todo el país',
    image: isRent ? '/faviconPicap.png' : 'https://pibox.app/images/landing/cargo.png',
    noIndex: true,
    favicon: isRent ? '/faviconPicap.png' : '/favicon.ico'
  })(Signup)
);
