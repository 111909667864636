import {
  LIST_BOOKINGS_LOADING,
  LIST_PACKAGES_LOADING,
  OPTIMIZE_STOPS,
  CREATE_BOOKING,
  LIST_BOOKINGS,
  LIST_PACKAGES,
  LIST_BY_GROUPS,
  BULK_LOAD_FAILED,
  RELATED_CITIES,
  QUOTE,
  BULK_LOAD,
  LIST_REPORTS,
  BULK_SCHEDULED_FAILED,
  LOADING_PROMO_CODE_REDEEM,
  PROMO_CODE_REDEEM,
  BULK_SCHEDULED_SUCCESS
} from './constants';
import { flatFormat } from 'utils';

const expressReducer = (state, action) => {
  switch (action.type) {
    case CREATE_BOOKING:
      return {
        ...state,
        booking: action.booking
      }
    case QUOTE:
      return {
        ...state,
        quote: action.quote,
        loadingQuote: action.loadingQuote,
        totalWeight: action.quote?.estimated_total_weight && action.quote.estimated_total_weight
      }
    case BULK_LOAD:
      return {
        ...state,
        bulkLoad: action.bulkLoad && action.bulkLoad.stops.map(stop => {
          const info = {
            ...stop,
            packages: [{
              ...stop.packages[0],
              declared_value: flatFormat({ sub_units: stop.packages[0].declared_value.sub_units, iso: stop.packages[0].declared_value.iso }),
              collected_value: stop.packages[0].collected_value ? flatFormat({ sub_units: stop.packages[0].collected_value.sub_units, iso: stop.packages[0].collected_value.iso }) : 0
            }]
          }
          return { ...info }
        }),
        loadingBulkLoad: action.loadingBulkLoad
      }
    case BULK_LOAD_FAILED:
      return {
        ...state,
        bulkLoadFailedStops: action.bulkLoadFailedStops && action.bulkLoadFailedStops.map(stop => {
          const info = {
            ...stop,
            packages: stop.packages ? [{
              ...stop.packages[0],
              declared_value: stop.packages[0].declared_value ? flatFormat({ sub_units: stop.packages[0].declared_value.sub_units, iso: stop.packages[0].declared_value.iso }) : null,
              collected_value: stop.packages[0].collected_value ? flatFormat({ sub_units: stop.packages[0].collected_value.sub_units, iso: stop.packages[0].collected_value.iso }) : null
            }] : []
          }
          return { ...info }
        }),
      }
    case OPTIMIZE_STOPS:
      return {
        ...state,
        optimize: action.optimize && action.optimize.booking && action.optimize.booking.stops.map(stop => {
          const info = {
            ...stop,
            packages: [{
              ...stop.packages[0],
              declared_value: flatFormat({ sub_units: stop.packages[0].declared_value.sub_units, iso: stop.packages[0].declared_value.currency }),
              collected_value: stop.packages[0].collected_value ? flatFormat({ sub_units: stop.packages[0].collected_value.sub_units, iso: stop.packages[0].collected_value.currency }) : null
            }]
          }
          return { ...info }
        }),
      }
    case LIST_BOOKINGS:
      return {
        ...state,
        list: action.list
      }
    case LIST_BOOKINGS_LOADING: 
      return {
        ...state,
        loadingBookings: action.loadingBookings
      }
    case LIST_PACKAGES: 
      return {
        ...state,
        listPackages: action.listPackages
      }
    case LIST_PACKAGES_LOADING: 
      return {
        ...state,
        loadingPackages: action.loadingPackages
      }
    case LIST_BY_GROUPS:
      const info = action.listByGroups ? action.listByGroups.length > 0 && action.listByGroups.map(group => {
        return {
          id: group._id,
          title: group.name
        }
      }) : []
      return {
        ...state,
        listByGroups: info?.length > 0 ? [{ id: 'admin', title: 'app.roles.administrative' }].concat(info) : []
      }
    case RELATED_CITIES:
      let cities = action.relatedCities && [action.relatedCities[0]];
      action.relatedCities && action.relatedCities[0]?.related_cities && Object.entries(action.relatedCities[0].related_cities).forEach(([key]) => {
        return cities.push(action.relatedCities[0].related_cities[key])
      })
      return {
        ...state,
        relatedCities: cities.length > 1 ? cities : null
      }
    case LIST_REPORTS:
      return {
        ...state,
        reports: action.reports
      }
    case LOADING_PROMO_CODE_REDEEM: 
      return {
        ...state,
        loadingPromoCode: action.loadingPromoCode
      }
    case PROMO_CODE_REDEEM:
      return {
        ...state,
        promoCode: action.promoCode
      }
    case BULK_SCHEDULED_FAILED:
      return {
        ...state,
        failedScheduled: action.failedScheduled
      }
    case BULK_SCHEDULED_SUCCESS:
      return {
        ...state,
        successScheduled: action.successScheduled
      }
    default:
      return state
  }
}

export default expressReducer;
