import { get } from 'api/requests';
import { message } from '../../commons/actions';
import handleErrorMessage from 'utils/handleErrorMessage';
import { urlBookings } from 'api';
import { LIST_SERVICES } from './constants';

export const listActiveServicesOperations = async (dispatch, params) => {
  /*dispatch({
    type: LIST_BOOKINGS_LOADING,
    loadingBookings: true
  });*/
  await get(urlBookings, params)
    .then(({ data }) => {
      dispatch({
        type: LIST_SERVICES,
        list: data
      });
      /*dispatch({
        type: LIST_BOOKINGS_LOADING,
        loadingBookings: false
      });*/
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      /*dispatch({
        type: LIST_BOOKINGS_LOADING,
        loadingBookings: false
      });*/
    });
}