import WooCommerceSale from 'models/sales/WooCommerceSale';
import MercadoFlexSale from 'models/sales/MercadoFlexSale';
import ShopifySale from 'models/sales/ShopifySale';
import VtexSale from 'models/sales/VtexSale';

const SalesAdapter = (sale) => {
  const SaleClasses = {
    mercadoflex: new MercadoFlexSale(sale),
    shopify: new ShopifySale(sale),
    woocommerce: new WooCommerceSale(sale),
    vtex: new VtexSale(sale)
  };
  return SaleClasses[sale.order.integrator];
};

export default SalesAdapter;
