import React, { useContext, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { I18nContext } from 'translations';
import RefreshIcon from '@material-ui/icons/Refresh';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import CloseIcon from '@material-ui/icons/Close';
import ModalDownloadExcel from 'components/Modals/ModalDownloadExcel';

const OptionButtons = ({
  reset, setCleanStops, setExpanded, expanded, setCheckStops, isChecked, moveStops, checkStops, fields,
  handleSubmit, submit, exportData
}) => {
  const { translate } = useContext(I18nContext);
  const [openDownload, setOpenDownload] = useState(false);
  return (
    <Grid container justify="center" alignItems="center" style={{ minHeight: '60px' }} className="text-center">
      <Grid item xs={4}>
        <Button
          type="button"
          color="primary"
          size="small"
          startIcon={<RefreshIcon />}
          disabled={expanded === 'close' ? false : true}
          onClick={() => {
            reset({ stops: [] });
            setExpanded('origin');
            setCleanStops(true);
            isChecked = [];
            setCheckStops(false);
          }}
        >
          {translate('app.express.clearRoute')}
        </Button>
      </Grid>
      <Grid item xs={4}>
        {checkStops ? (
          <Button
            type="button"
            color="secondary"
            size="small"
            disabled={expanded === 'close' ? false : true}
            startIcon={<CloseIcon />}
            onClick={e => {
              e.preventDefault();
              isChecked = [];
              setCheckStops(false);
           }}
         >
           {translate('cancel')}
         </Button>
        ) : (
          <Button
            type="button"
            color="primary"
            size="small"
            startIcon={<HistoryOutlinedIcon />}
            disabled={((expanded === 'close' ? false : true) || (fields.length <= 1 ? true : false))}
            onClick={async e => {
              e.preventDefault();
              isChecked = [];
              setCheckStops(false);
              setCleanStops(true);
              await handleSubmit((values) => submit(values, 2))()
            }}
          >
            {translate('app.express.optimizeRoute')}
          </Button>
        )}
      </Grid>
      <Grid item xs={4}>
        {checkStops ? (
          <Button
            type="button"
            color="secondary"
            size="small"
            disabled={expanded === 'close' ? false : true}
            startIcon={<SwapVertIcon />}
            onClick={(e) => {
              if (isChecked.length === 2) {
                moveStops(e, isChecked[0].id, isChecked[1].id);
                isChecked = [];
                setCheckStops(false);
              } else {
                alert(translate('app.express.selectAMinimumStops'));
              }
            }}
          >
            {translate('app.express.confirm')}
          </Button>
        ) : (
          <Button
            type="button"
            color="primary"
            disabled={((expanded === 'close' ? false : true) || (fields.length <= 1 ? true : false))}
            startIcon={<SwapVertIcon />}
            onClick={() => setCheckStops(true)}
          >
            {translate('app.express.rearrange')}
          </Button>
        )}
        <Button
          type="button"
          color="primary"
          variant="contained"
          endIcon={<AutorenewIcon />}
          disabled={expanded === 'close' ? false : true}
          style={{ position: 'fixed', right: 20, bottom: 20, zIndex: 999 }}
          onClick={async () => {
            await handleSubmit((values) => submit(values, 3))();
            setOpenDownload(true)
          }}
        >
          {translate('app.express.generateExcel')}
        </Button>
        {openDownload && (
          <ModalDownloadExcel
            openDownload={openDownload}
            setOpenDownload={setOpenDownload}
            exportData={exportData}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default OptionButtons;