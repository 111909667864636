import { useState, useCallback, useEffect } from 'react';
import { useStateValue } from 'context/store';
import {  PER_PAGE } from 'utils/constants';
import { piboxAdminRolesCompany } from 'utils';
import { getListByGroups } from 'context/express/actions';

export default function useGetDataExpress(isRent) {
	const [per_page] = useState(PER_PAGE);
  const [filters, setFilters] = useState({
    payments_collected_by_company: '',
    service_type: isRent ? 'rent' : ''
  });
  
  const [user_id, setUser_id] = useState(null);
  const [inputSearchSelected, setInputSearchSelected] = useState(null);
  const [{
    checkin: { company, checkin },
    bookings: { listByGroups },
    commons: { message } },
    dispatch
  ] = useStateValue();

  const onFilters = params => setFilters(params);

  const myServices = async () => {
    checkin && 
      setUser_id(checkin.profile._id);
      setInputSearchSelected(null);
  }

  const roles = useCallback(() => {
    let rol = [];
    if (checkin && checkin.profile && checkin.profile.roles) {
      rol = piboxAdminRolesCompany(checkin.profile.roles);
    }
    return rol;
  },[checkin])

  const getListGroups = useCallback(async () => {
    if (company) {
		  const { _id } = company
      await getListByGroups(dispatch, _id);
    }
  },[dispatch, company])

	useEffect(() => {
		roles().length > 0 && getListGroups();
	}, [getListGroups, roles])

  return {
		company,
		dispatch,
		message,
		roles,
		checkin,
		per_page,
		user_id,
		setUser_id,
		filters,
		onFilters,
		setFilters,
		listByGroups,
		inputSearchSelected,
		setInputSearchSelected,
		myServices,
		getListGroups
	}
}
