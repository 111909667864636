import React, { useContext } from 'react';
import { Container, Paper, Grid, Typography, Snackbar } from '@material-ui/core';
import { useStateValue } from 'context/store';
import { integrationLogin, closeMessage } from 'context/integrations/actions';
import { I18nContext } from 'translations';
import FormLogin from 'components/Forms/Login';
import Loading from 'components/Loading';

export default function LoginIntegration({ location }) {
  const { translate } = useContext(I18nContext);
  const [{ integrations: {loading, message} }, dispatch] = useStateValue();

  const onSubmit = async values => {
    const query = new URLSearchParams(location.search);
    const app_id = query.get('app_id');
    const secret_token = query.get('secret_token');
    await integrationLogin(dispatch, { session: values, app_id, secret_token });
  }

  return (
    <Container maxWidth="md" style={{ marginTop: 70 }} className="container-width-lg">
      {loading && <Loading />}
      <Paper elevation={4} variant="outlined">

        <Grid container alignItems="center" justify="center" spacing={2}>
          <Grid item sm={5} md={6} className="text-center">
            <img
              src="/images/users/truck-box.png"
              alt="recover-password"
              style={{ maxWidth: '100%' }}
            />
          </Grid>

          <Grid item sm={7} md={6}>
            <Typography variant="h4" color="textPrimary">
              {translate('login')}
            </Typography>
            <br /><br />
            <FormLogin
              submit={onSubmit}
              showOptions={false}
            />
          </Grid>
        </Grid>
      </Paper>
      <Snackbar
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </Container>
  )
}