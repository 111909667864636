import React, { useContext, lazy, Suspense } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Container, LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { I18nContext } from 'translations';
import withHelmet from 'utils/withHelmet';
const LayoutLanding = lazy(() => import('components/Landing/Layouts'));
const TermsEs = lazy(() => import('./Texts/TermsEs'));
const TermsPt = lazy(() => import('./Texts/TermsPt'));

const styles = createStyles(() => ({
  terms: {
    marginTop: '68px',
    padding: '40px 10px',
    textAlign: 'justify'
  }
}));

const Terms = ({ classes }) => {
  const { langCode } = useContext(I18nContext);
  
  const text = () => {
    switch (langCode) {
      case 'es':
        return <TermsEs />
      case 'pt':
        return <TermsPt />
      default:
        return <TermsEs />
    }
  }

  return (
    <Suspense fallback={<LinearProgress color="primary" />}>
      <LayoutLanding>
        <div className={classes.terms}>
          <Container maxWidth="md">
            {text()}
          </Container>
        </div>
      </LayoutLanding>
    </Suspense>
  )
}

Terms.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(withHelmet({
  title: 'Términos y Condiciones | Pibox',
  description: 'Términos y Condiciones sobre la línea de negocio de Pibox.',
  linkCanonical: 'https://pibox.app/terms',
  image: 'https://pibox.app/images/landing/cargo.png',
  noIndex: true
})(Terms));