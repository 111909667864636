import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  containerMap: {
    position: 'relative',
    boxShadow: '0px 1px 4px rgba(0,0,0,0.5)',
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      minHeight: '40vh'
    }
  },
  paddingInfo: {
    padding: '5px 15px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  marginBottomInfo: {
    marginBottom: 8
  },
  line: {
    margin: '7px 0'
  },
  orderXs: {
    [theme.breakpoints.down('sm')]: {
      order: 2
    }
  },
  containerRight: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 1px 3px rgba(0,0,0,0.5)',
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      minHeight: '42vh',
      textAlign: 'center'
    }
  },
  scroll: {
    [theme.breakpoints.up('md')]: {
      height: '100vh',
      overflowY: 'auto',
      overflowX: 'hidden'
    }
  },
  imgAddress: {
    width: '17px',
    marginRight: '10px',
    verticalAlign: 'middle'
  },
  infoAddress: {
    maxWidth: '80%',
    display: 'inline-block',
    verticalAlign: 'middle'
  }
}));

export default styles;