import React, { useState, useContext, useEffect, useCallback } from 'react';
import { I18nContext } from 'translations';
import AddIcon from '@material-ui/icons/Add';
import { useStateValue } from 'context/store';
import { cancelPromoCode, listPromoCode } from 'context/promocode/actions';
import TitleWithDowload from 'components/Titles/TitleWithDowload';
import ActivePromoCodesTable from 'components/Tables/PromoCodes/ActivePromoCodes';
import Loading from 'components/Loading';
import { INITIAL_PAGE, PER_PAGE } from 'utils/constants';
import { modalMessage, modalMessageHtml } from 'utils';
import FormPromoCode from './Form'


const GeneratePromoCode = () => {
  const { translate } = useContext(I18nContext);
  const [codeInfo, setCodeInfo] = useState([]);
  const [per_page] = useState(PER_PAGE);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [
    {
      checkin: { company },
      promoCodes: { list, loadingPromoCode }
    }, dispatch
  ] = useStateValue();

  const getListPromoCode = useCallback(
    async (page) => {
      listPromoCode(dispatch, company._id, {
        page,
        per_page,
        status_cd: 0
      });
    },
    [dispatch, per_page, company]
  );

  useEffect(() => {
    company && getListPromoCode(INITIAL_PAGE)
  },[]) // eslint-disable-line

  const messageOk = () => {
    modalMessage(
      translate('congratulations'),
      translate('promoCode.actions.cancelConfirmText'),
      'success',
      translate('accept'),
      true
    ).then(() => {
      getListPromoCode(INITIAL_PAGE)
      setCodeInfo([])
      setOpen(false)
    });
  };

  const messageNonCancellable = (nonCancellable) => {
    let promocodeContent = nonCancellable.map((item) => { 
      return `<li class='showCode'>
        Codigo: <b class='showCode-code'>${item.code} </b>
      </li>`;
    }).join('');

    modalMessageHtml('', `<h4 class='showCode-unasignables'>
      ${translate('promoCode.actions.notCancelText')}</h4>
      <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;">
        <span class="swal2-x-mark">
        <span class="swal2-x-mark-line-left"></span>
        <span class="swal2-x-mark-line-right"></span>
        </span>
      </div><br />
      ${translate('promoCode.actions.tryAgain')} <br /><br />
      <div class='text-left container-unasignables'>${promocodeContent}</div>`,
      'error', translate('close'), false, '', false, 750)
    .then(() => {
      setOpen(false)
      setCodeInfo([])
    })
  }

  const removePromoCode = (code) => {
    const promos = code.map(pcode => {
      return {
        id: pcode._id,
        code: pcode.code
      }
    })
    modalMessage(
      '',
      translate('promoCode.actions.cancelAskConfirm'),
      'warning',
      'Continue',
      true,
      true,
      translate('cancel')
    ).then(async (response) => {
      if (response.isConfirmed) {
        const callback = await cancelPromoCode(dispatch, company._id, { promo_codes: promos });
        callback?.nonCancellable?.length > 0 ? messageNonCancellable(callback?.nonCancellable) : callback.status && messageOk();
      }
    });
  }

  return (
    <div className="padding-page-full-height">

      {loadingPromoCode && <Loading />}

      <TitleWithDowload
        title={translate('promoCode.generateTripCodes')}
        icon={
          <AddIcon className="align-center" style={{ marginRight: 5 }} />
        }
      />

      <div style={{ margin: '10px 0 20px' }}>
        <FormPromoCode
          codeInfo={codeInfo?.length > 0 ? codeInfo : null}
          setOpen={setOpen}
          getListPromoCode={getListPromoCode}
          isEdit={isEdit}
          setCodeInfo={setCodeInfo}
          setIsEdit={setIsEdit}
          removePromoCode={removePromoCode}
        />
      </div>

      {list && (  
        <ActivePromoCodesTable
          data={list}
          codeInfo={codeInfo}
          setCodeInfo={setCodeInfo}
          open={open}
          setOpen={setOpen}
          getListPromoCode={getListPromoCode}
          recordsPerPage={per_page}
          setIsEdit={setIsEdit}
          removePromoCode={removePromoCode}
        />
      )}

    </div>
  );
};

export default GeneratePromoCode;
