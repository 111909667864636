import React, { useContext, lazy, Suspense } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Container, LinearProgress } from '@material-ui/core';
import { I18nContext } from 'translations';
const LayoutLanding = lazy(() => import('components/Landing/Layouts'));
const PrivacyInformationEs = lazy(() => import('./Texts/PrivacyInformationEs'));
const PrivacyInformationPt = lazy(() => import('./Texts/PrivacyInformationPt'));

const styles = createStyles(() => ({
  terms: {
    marginTop: '68px',
    padding: '40px 10px',
    textAlign: 'justify'
  }
}));

const PrivacyInformation = ({ classes }) => {
  const { langCode } = useContext(I18nContext);
  
  const text = () => {
    switch (langCode) {
      case 'es':
        return <PrivacyInformationEs />
      case 'pt':
        return <PrivacyInformationPt />
      default:
        return <PrivacyInformationEs />
    }
  }

  return (
    <Suspense fallback={<LinearProgress color="primary" />}>
      <LayoutLanding>
        <div className={classes.terms}>
          <Container maxWidth="md">
            {text()}
          </Container>
        </div>
      </LayoutLanding>
    </Suspense>
  )
}

export default withStyles(styles)(PrivacyInformation);