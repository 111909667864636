import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField, InputAdornment } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import EmailIcon from '@material-ui/icons/Email';
import { I18nContext } from 'translations';
import styles from './style';
import schema from './schema';

function Form({ submit, classes, resetForm }) {
  const { translate } = useContext(I18nContext);
  const { handleSubmit, errors, reset, control } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset();
  }, [resetForm, reset]);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classes.form}>
      <Controller
        control={control}
        name="email"
        defaultValue=""
        render={({ onChange, value }) => (
          <TextField
            autoFocus
            onChange={onChange}
            value={value}
            error={!!errors.email}
            label={translate('email')}
            helperText={errors.email && translate(errors.email.message)}
            type="email"
            fullWidth
            className={classes.marginBottomField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EmailIcon className={classes.icon} />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <Button
        type="submit"
        color="primary"
        variant="contained"
        size="large"
        className={classes.button}
      >
        {translate('send')}
      </Button>
    </form>
  );
}

Form.propTypes = {
  submit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  resetForm: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Form);
