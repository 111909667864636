import React, { useContext, useEffect, useCallback, useState } from 'react';
import {
  Grid,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Snackbar
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { message as closeMessage } from 'context/commons/actions';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { getListFavoriteAddresses } from 'context/settings/favoriteAddresses/actions';
import FavoriteAddressesTable from 'components/Tables/Settings/FavoriteAddresses';
import styles from './FavoriteAddressesStyles';
import Loading from 'components/Loading';
import { INITIAL_PAGE, PER_PAGE } from 'utils/constants';

function ModalListFavoriteAddresses({
  open,
  setOpen,
  cityId,
  getSelectedAddress,
  classes
}) {
  const { translate } = useContext(I18nContext);
  const [{ commons: { message }, addresses: { listAddresses, loadingAddresses } }, dispatch] = useStateValue();
  
  const [perPage] = useState(PER_PAGE);

  const fetchAddresses = useCallback(async (page) => {
    getListFavoriteAddresses(dispatch, { cityId, page, perPage });
  }, [dispatch, cityId, perPage]);

  useEffect(() => {
    fetchAddresses(INITIAL_PAGE);
  }, [fetchAddresses]);

  const sedesAddresses = listAddresses?.data?.filter(item => item.type_cd === 10) || [];
  const favoriteAddresses = listAddresses?.data?.filter(item => item.type_cd !== 10) || [];

  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className="text-center" disableTypography>
        <Typography variant="h5" color="primary">
          {translate('app.settings.addresses.titleFavoriteAddresses')}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.content}>
        {loadingAddresses && <Loading />}
        <Grid container direction='column'>
          <Grid item md xs={12}>
            {favoriteAddresses.length > 0 ? (
              <FavoriteAddressesTable
                data={{
                  ...listAddresses,
                  data: favoriteAddresses
                }}
                chosenAddress={getSelectedAddress}
                showSelectOption={true}
                getAddresses={fetchAddresses}
                recordsPerPage={perPage}
              />
            ) : (
              <Typography variant="h5" color="textSecondary">
                {translate('app.settings.addresses.NoFavoriteAddressesMessage')}
                <Link to="/app/settings">{translate('app.settings.addresses.goToSettings')}</Link> - 
                {translate('app.settings.addresses.NoFavoriteAddressesMessage2')}
              </Typography>
            )}
          </Grid>
          <Grid item md xs={12}>
            {sedesAddresses?.length > 0 && (
              <>
                <Typography variant="h5" color="primary" style={{ marginBottom: 5 }}>
                  {translate('app.settings.groups')}
                </Typography>
                <FavoriteAddressesTable
                  data={{
                    ...listAddresses,
                    data: sedesAddresses
                  }}
                  chosenAddress={getSelectedAddress}
                  showSelectOption={true}
                  getAddresses={fetchAddresses}
                  recordsPerPage={perPage}
                />
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
        >
          {translate('close')}
        </Button>
      </DialogActions>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </Dialog>
  );
}

export default withStyles(styles)(ModalListFavoriteAddresses);
