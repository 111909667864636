import { createStyles } from '@material-ui/core/styles';

const styles = createStyles(theme => ({
  modalPibox: {
    '& .MuiPaper-rounded': {
      borderRadius: 10
    },
    '& .MuiDialogTitle-root': {
      backgroundColor: 'rgb(70, 32, 143)',
      color: '#fff',
      padding: '11px 24px',
      '& h6': {
        fontSize: '1rem'
      }
    },
    '& .MuiDialogContent-root': {
      padding: '8px',
      backgroundColor: 'rgb(247,247,247)',
      '& .bg-white': {
        backgroundColor: '#fff',
        borderRadius: '6px',
        padding: '18px',
        '& h5': {
          fontSize: '1.2rem'
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: '8px'
        }
      }
    }
  }
}));

export default styles;