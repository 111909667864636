import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  card: {
    '& > .rccs': {
      width: '30rem',
      '& > .rccs__card': {
        height: '20rem',
        width: '30rem',
      },
    },
  },
  table: {
    border: '1px solid #e0e0e0',
    '& thead': {
      backgroundColor: '#f9fafb',
      '& tr th': {
        fontFamily: 'amaranthbold',
        lineHeight: 1.3,
        padding: 7,
      },
    },
    '& tbody tr': {
      '& td': {
        fontSize: '0.8em',
        padding: '6px',
        color: '#000',
        '& button': {
          fontSize: '1em',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& thead': {
        display: 'none',
      },
      '& tbody tr': {
        '& td': {
          width: '100%',
          textAlign: 'center',
          borderBottom: 0,
          padding: '2px 5px',
          display: 'inline-block',
        },
        '& td:first-child': {
          paddingTop: '10px',
        },
        '& td:last-child': {
          paddingBottom: '10px',
          borderBottom: '1px solid #ccc',
        },
      },
    },
    '& .MuiTableRow-root.Mui-selected': {
      backgroundColor: '#F5EFF9',
    },
  },
  date: {
    fontSize: '0.94em',
    [theme.breakpoints.up('md')]: {
      width: '120px',
      display: 'inline-block',
    },
  },
  address: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '230px',
      minWidth: '150px',
    },
  },
  status: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '190px',
      minWidth: '130px',
    },
  },
  headquartersCell: {
    width: '33%',
  },
  choseOption: {
    width: '18%',
  },
}));

export default styles;
