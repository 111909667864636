import React, { useContext } from 'react'
import { Typography } from '@material-ui/core'
import { Pie } from 'react-chartjs-2';
import { I18nContext } from 'translations';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export default function PieGraph ({ title, info }) {
  const { translate } = useContext(I18nContext);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
      },
      datalabels: {
        formatter: (val) => `${val}%`,
        color: 'white',
        font: {
          size: 18
        },
        padding: 6
      },
      tooltip: {
        callbacks: {
          label: (ttItem) => `${ttItem.label}: ${ttItem.parsed}%`
        }
      }
    }
  };

  const data = {
    labels: [`${translate('analytics.collections.pendingCollections')}`, `${translate('analytics.collections.collected')}`],
    datasets: [
      {
        data: [((info?.company_collect_pending * 100)/info?.require_counter_delivery).toFixed(2), ((info?.company_collect_done * 100)/info?.require_counter_delivery).toFixed(2)],
        backgroundColor: [
          '#ee44eb',
          '#9380f4'
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Typography variant="body1">
        <b>{title}</b>
      </Typography>
      <br />
      <div style={{ height:'calc(100vh - 450px)' }}>
        <Pie data={data} options={options} />
      </div>
    </>
  )
}