import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer
} from '@material-ui/core';
import { I18nContext } from 'translations';
import { format } from 'date-fns';
import { IDS_SERVICES_TYPE_PIBOX } from 'utils/constants';
import styles from '../style';

const FailedScheduledTable = ({
  classes,
  data
}) => {
  const { translate } = useContext(I18nContext);

  const handleDate = (date) => {
    try {
      return format(new Date(date), 'dd/MM/yyyy, hh:mm a')
    }
    catch {
      return date
    }
  }

  const handleServiceType = (serviceType) => {
    try {
      return translate(IDS_SERVICES_TYPE_PIBOX[serviceType])
    }
    catch {
      return serviceType
    }
  }

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell>{translate('app.bulkLoad.failedStops.table.address')}</TableCell>
              <TableCell>{translate('hoursDedicated.title')}</TableCell>
              <TableCell>{translate('app.express.vehicleType')}</TableCell>
              <TableCell>{translate('hoursDedicated.scheduleDate')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data || []).map((stop, index) => (
              <TableRow hover key={index}>
                <TableCell className={classes.address}>
                  {stop?.address || <span className="error">{translate('app.operations.optimize.errorAddress')}</span>}
                </TableCell>
                <TableCell>
                  {stop?.hours_dedicated}
                </TableCell>
                <TableCell>
                  {handleServiceType(stop?.requested_service_type_id) ? handleServiceType(stop.requested_service_type_id) : stop?.requested_service_type_id}
                </TableCell>
                <TableCell>
                  {stop?.scheduled_at ? handleDate(stop.scheduled_at) : stop?.scheduled_at}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default withStyles(styles)(FailedScheduledTable);

