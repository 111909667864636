import React, { useContext } from 'react';
import { Button, Grid, Paper, Container, FormControlLabel, FormControl, Snackbar,
  FormLabel, RadioGroup, Radio
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useStateValue } from 'context/store';
import { closeMessage } from 'context/integrations/actions';
import Rating from '@material-ui/lab/Rating';
import { yupResolver } from '@hookform/resolvers';
import { sendSurvey  } from 'context/surveys/actions';
import { I18nContext } from 'translations';
import Loading from 'components/Loading';
import { modalMessage } from 'utils';
import schema from './schema';

export default function HeinekenForm({ location }) {
  const { translate } = useContext(I18nContext);
  const [{ commons: {message, loading } }, dispatch] = useStateValue();
  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema)
  });
  const query = new URLSearchParams(location.search);
  const id = query.get('booking_id');
  const token = query.get('token');

  const messageOk = () => {
    modalMessage('', translate('survey.form.messageSuccessful'), 'success', translate('accept')).then((result) => {
      window.location.href = `https://pibox.app/app`;
    });
  }

  const submit = async (values) => {
    const form = {
      ...values,
      pickup_on_time: JSON.parse(values.pickup_on_time),
      beer_was_cold: JSON.parse(values.beer_was_cold),
      correct_packaging: JSON.parse(values.correct_packaging),
    }
    const callback = await sendSurvey(dispatch, id, form, token);
    callback && callback.status && messageOk();
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      style={{
        minHeight: '100vh',
        justifyContent: 'center',
        backgroundColor: 'lightgrey'
      }}
    >
      <Container maxWidth="sm">
        <Paper variant="outlined">
          {loading && <Loading />}

          <form
            style={{ padding: '40px 30px' }}
            onSubmit={handleSubmit(submit)}
            noValidate
          >
            <FormControl
              component="fieldset"
              style={{ width: '100%', marginBottom: 25 }}
              error={!!errors.experience_rating}
            >
              <FormLabel component="legend">
                {translate('survey.form.question1')} *
              </FormLabel>

              <Controller
                name="experience_rating"
                control={control}
                rules={{ required: true }}
                defaultValue={0}
                render={(props) => {
                  return <Rating
                    style={{ padding: '10px 0 5px' }}
                    name="experience_rating"
                    value={parseInt(props.value)}
                    onChange={props.onChange}
                  />
                }}
              />
              <small className="red">
                {errors.experience_rating && translate(errors.experience_rating.message)}
              </small>
            </FormControl>

            <FormControl
              component="fieldset"
              style={{ width: '100%', marginBottom: 25 }}
              error={!!errors.pickup_on_time}
            >
              <FormLabel component="legend">
                {translate('survey.form.question2')} *
              </FormLabel>
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue=""
                name="pickup_on_time"
                style={{ flexDirection: 'row', paddingTop: '7px' }}
                as={
                  <RadioGroup >
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" />}
                      label={translate('survey.form.yes')}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label={translate('survey.form.no')}
                    />
                  </RadioGroup>
                }
              />
              <small className="red">
                {errors.pickup_on_time && translate(errors.pickup_on_time.message)}
              </small>
            </FormControl>

            <FormControl
              component="fieldset"
              style={{ width: '100%', marginBottom: 25 }}
              error={!!errors.beer_was_cold}
            >
              <FormLabel component="legend">
                {translate('survey.form.question3')}
              </FormLabel>
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue=""
                name="beer_was_cold"
                style={{ flexDirection: 'row', paddingTop: '7px' }}
                as={
                  <RadioGroup>
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" />}
                      label={translate('survey.form.yes')}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label={translate('survey.form.no')}
                    />
                  </RadioGroup>
                }
              />
              <small className="red">
                {errors.beer_was_cold && translate(errors.beer_was_cold.message)}
              </small>
            </FormControl>

            <FormControl
              component="fieldset"
              style={{ width: '100%', marginBottom: 25 }}
              error={!!errors.correct_packaging}
            >
              <FormLabel component="legend">
                {translate('survey.form.question4')}
              </FormLabel>
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue=""
                name="correct_packaging"
                style={{ flexDirection: 'row', paddingTop: '7px' }}
                as={
                  <RadioGroup>
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" />}
                      label={translate('survey.form.yes')}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label={translate('survey.form.no')}
                    />
                  </RadioGroup>
                }
              />
              <small className="red">
                {errors.correct_packaging && translate(errors.correct_packaging.message)}
              </small>
            </FormControl>
            <div className="text-center">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                size="large"
              > 
                {translate('send')}
              </Button>
            </div>
          </form>
        </Paper>
        <Snackbar
          open={!!message}
          autoHideDuration={6000}
          onClose={() => closeMessage(dispatch, null)}
          message={translate(message)}
        />
      </Container>
    </Grid>
  );
}

