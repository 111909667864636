import React, { useContext } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { I18nContext } from 'translations';

export default function AutocompleteFromList({
	data,
	label,
	setValue
}) {
	const { translate } = useContext(I18nContext);
	return (
		<Autocomplete
			noOptionsText={translate('noMatches')}
			size="small" 
			options={data}
			getOptionLabel={(option) => translate(option.title)}
			onChange={(event, newValue) => setValue(newValue ? newValue.id : '')}
			renderInput={(params) => <TextField variant="outlined" {...params} label={translate(label)} />}
		/>
	)
}