import React, { useEffect, useState, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import polyline from '@mapbox/polyline';

// mapboxgl.accessToken = process.env.REACT_APP_API_URL_BASE_MAPS_API_TOKEN;
const STYLE_URL = process.env.REACT_APP_API_URL_BASE_MAPS_API_STYLES_URL;

function MarkerMap({ city, origin, stops, remove, points, returnToOrigin, cleanStops, setAddress, setCleanStops, quotation }) {

  const [map, setMap] = useState(null);
  const mapContainerRef = useRef(null);
  let arrayCoordinates = [];

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: STYLE_URL,
      zoom: 11,
      center: [city.lon, city.lat]
    });
    map.on('style.load', function() {
      setMap(map);
    })

    
  }, [city.lat]); // eslint-disable-line

  const coordinates = () => {
  	points.map(point => point.lat && arrayCoordinates.push([ point.lon && point.lon, point.lat && point.lat ]));
		return arrayCoordinates;
  }

  const zoomIn = () => {
    const bounds = coordinates(points).reduce((bounds, coord) => {
      return bounds.extend(coord);
    }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

    map.fitBounds(bounds, {
      padding: 80,
      maxZoom: 15,
      duration: 2000
    }); 
  }

  const removeMarker = idr => {
    if (map.getLayer(`stop${idr}`)) {
      map.removeLayer(`stop${idr}`);
      map.removeLayer(`stop-${idr}`);
    }
    if (map.getSource(`stop${idr}`)) {
      map.removeSource(`stop${idr}`);
    }
  }

  const removePolyline = () => {
    if (map.getLayer('route-line')) {
      map.removeLayer('route-line');
    }
    if (map.getSource('route-line')) {
      map.removeSource('route-line');
    }
  }

  const newPoint = (id, secondId, geojson) => {
    zoomIn();
    map.addSource(id, {
      'type': 'geojson',
      'data': geojson
    });
    map.addLayer({
      'id': id,
      'type': 'circle',
      'source': id,
      'paint': {
        'circle-radius': 13,
        'circle-color': '#9A68C2',
        'circle-stroke-width': 2.5,
        'circle-stroke-color': '#9A68C2',
        'circle-stroke-opacity': 0.7
      },
    });
    map.addLayer({
      'id': secondId,
      'source': id,
      'type': 'symbol',
      'layout': {
        // "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
        'text-field': ['get', 'name'],
        'text-size': 12,
        'text-offset': [0, -0.7],
        'text-anchor': 'top',
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
        'icon-rotation-alignment': 'map',
        'icon-ignore-placement': true
      },
      'paint': {
        "text-color": "#ffffff"
      }
    });
  }

  useEffect(() => {
    if (map && origin && origin.lat) {
      const geojson = {
          type: 'Feature',
          properties: {
            name: `${returnToOrigin ? stops.length + 2 : '1'}`,
          },
          geometry: {
            type: 'Point',
            coordinates: [origin.lon ? origin.lon : city.lon, origin.lat ? origin.lat : city.lat]
          }
      };
      
      if (map.getSource('origin')) {
        zoomIn();
        map.getSource('origin').setData(geojson);
      } else {
        newPoint('origin', 'origin-1', geojson);
      }
    }
  }, [map, origin, returnToOrigin, stops]) // eslint-disable-line
  

  useEffect(() => {
    if (map && origin && stops && stops.length > 0) {
      remove && removeMarker(remove);

      stops.map((stop, index) => {
        const geojson = {
            type: 'Feature',
            properties: {
              name: index + 2,
            },
            geometry: {
              type: 'Point',
              coordinates: [stop.lon && stop.lon, stop.lat && stop.lat]
            }
        }
        
        if (map.getSource(`stop${stop.id}`)) {
          zoomIn();
          removePolyline();
          return map.getSource(`stop${stop.id}`).setData(geojson);
        } else {
          removeMarker(stop.id);
          removePolyline();
          return stop.lat && newPoint(`stop${stop.id}`, `stop-${stop.id}`, geojson);
        }
      });
    }
  }, [map, stops]) // eslint-disable-line


  useEffect(() => {
    if (map && quotation) {
      const polylineCoordinates = quotation && polyline
      .decode(quotation.encoded_polyline)
      .map((coord) => coord.reverse());
      removePolyline();

      map.addSource('route-line', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: polylineCoordinates,
          },
        },
      });
    
      map.addLayer({
        id: 'route-line',
        type: 'line',
        source: 'route-line',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#9A68C2',
          'line-width': 2,
          'line-opacity': 0.7,
          'line-offset': 1
        },
      });

      /* eslint-disable */
      stops && stops.length > 0 && stops.forEach((stop, index) => {
        if (map.getSource(`stop${stop.id}`)) {
          removeMarker(stop.id);
          const geojson = {
              type: 'Feature',
              properties: {
                name: index + 2,
              },
              geometry: {
                type: 'Point',
                coordinates: [stop.lon && stop.lon, stop.lat && stop.lat]
              }
          }
          stop.lat && newPoint(`stop${stop.id}`, `stop-${stop.id}`, geojson);
        }
      });
      /* eslint-enable */
    }
  }, [quotation, map]); // eslint-disable-line


  useEffect(() => {
    if (map && cleanStops) {
      stops.map((stop) => {
        if (map.getSource(`stop${stop.id}`)) {
          return removeMarker(stop.id);
        }
        return null;
      });
      setAddress([]);
      setCleanStops(false);
      removePolyline();
    }
  }, [map, cleanStops]) // eslint-disable-line

  return (
    <div
      style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, width: '100%' }}
      ref={mapContainerRef}
    />
  );
}

export default MarkerMap;
