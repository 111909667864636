import { urlBooking, urlBookings } from 'api';
import { post, patch } from 'api/requests';
import { message } from '../commons/actions';
import handleErrorMessage from 'utils/handleErrorMessage';

export const confirmWaitDriver = async (dispatch, id, values) => {
  let status = false;
  await post(`${urlBookings}/${id}/wait_driver_delayed`, values)
    .then((json) => {
      return json.data;
    })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
};

export const confirmCancelBooking = async (dispatch, id) => {
  let status = false;
  await patch(`${urlBooking}/${id}/cancel`)
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
};
