import React, { useState, useEffect, useCallback, useContext } from 'react';
import { MenuItem, CircularProgress, TextField } from '@material-ui/core'; 
import Autosuggest from 'react-autosuggest';
import { Controller } from 'react-hook-form';
import { urlSuggestionAddress } from 'api';
import { get } from 'api/requests';
import { debounce } from 'lodash';
import { I18nContext } from 'translations';

const getSuggestions = async (value, cityId) => {
  const req = get(urlSuggestionAddress, {
    address: value,
    city_id: cityId
  });
  const data = await req;
  const sugg = data.data.map(da => ({ name: da.suggestion, lat: da.loc.lat, lon: da.loc.lon }))
  return sugg
}

const renderSuggestion = suggestion => (
  <MenuItem component="div">
    <span>{suggestion.name}</span>
  </MenuItem>
);

function renderInputComponent(inputProps) {
  const {
    classes, inputRef = () => { }, ref, value, ...other
  } = inputProps;
  return (
    <TextField
      variant="outlined"
      fullWidth
      size="small"
      InputProps={{
        value,
        inputRef: node => {
          ref(node);
          inputRef(node);
        }
      }}
      {...other}
    />
  );
}

function AutoCompleteField({
  name, control, defaultValue, cityId, title, address, clearErrors, required
}) {
  const [valueInput, setValueInput] = useState("");
  const [suggestion, setSuggestion] = useState([]);
  const [loading, setLoading] = useState(false);
  const { translate } = useContext(I18nContext);

  const loadSuggestions = (value) => {
    setLoading(true);
    setTimeout(async () => {
      const sugg = await getSuggestions(value, cityId);
      if (value.length > 3) {
        setSuggestion(sugg);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }, 100);
  }

  const debounceLoadData = useCallback(debounce(loadSuggestions, 1000), [cityId]);
 
  useEffect(() => {
    setValueInput('');
    control.setValue(name, '');
    control.setValue('lat', '');
    control.setValue('lon', '');
  }, [cityId, name]) // eslint-disable-line

  useEffect(() => {
    if (address) {
      setValueInput(address.name);
      control.setValue(name, address.name);
      control.setValue('lat', address.lat);
      control.setValue('lon', address.lon);
      clearErrors([name])
    }
  }, [address]) // eslint-disable-line


  const onChange = (event, { newValue }) => {
    setValueInput(newValue);
    if (newValue !== control.getValues(name)) {
      control.setValue(name, "");
      control.setValue('lat', '');
      control.setValue('lon', '');
    }
    return newValue;
  };

  const onSuggestionsFetchRequested = ({ value }) => debounceLoadData(value);

  const onSuggestionsClearRequested = () => {
    setSuggestion([]);
  };

  const getSuggestionValue = suggestion => {
    if (suggestion.name) {
      control.setValue(name, suggestion.name);
      control.setValue('lat', suggestion.lat);
      control.setValue('lon', suggestion.lon);
      clearErrors([name])
      return suggestion.name
    }
  };

  const inputProps = {
    placeholder: title,
    value: valueInput,
    label: title,
    onChange
  };

  const classes = {
    container: {
      position: 'relative',
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 3,
      left: 0,
      right: 0,
      maxHeight: 250,
      overflow: 'auto',
      backgroundColor: 'white'
    },
    suggestionsList: {
      paddingLeft: 0,
    },
    suggestion: {
      display: 'block',
      padding: 5,
      color: '#464242',
      fontSize: '0.97rem'
    }
  };

  return (
    <div className="relative">
      {loading && <CircularProgress color="primary" size={20} style={{ position: 'absolute', right: 20, top: 10 }} />}
      <Controller
        as={
          <Autosuggest
            suggestions={suggestion}
            theme={classes}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            renderInputComponent={renderInputComponent}
          />
        }
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={{ required: required ? translate('selectAnOptionFromTheList') : false}}
      />
      <Controller
        control={control}
        name="lat"
        defaultValue=""
        as={<input type="hidden" />}
      />
      <Controller
        control={control}
        name="lon"
        defaultValue=""
        as={<input type="hidden" />}
      />
    </div>
  );
}

function areEqual(prevProps, nextProps) {
  return prevProps.address === nextProps.address
    && prevProps.cityId === nextProps.cityId
}

export default React.memo(AutoCompleteField, areEqual);