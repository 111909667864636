import React, { useEffect, useState, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  Checkbox
} from '@material-ui/core';
import { I18nContext } from 'translations';
import EmptyData from '../EmptyData';
import styles from '../style';
import { formatCost, balanceStatuses } from 'utils';

const BalancesTable = ({
  classes,
  data,
  recordsPerPage,
  setPassengerInfo,
  getListBalances,
  history
}) => {
  const [currentPage, setCurrentPage] = useState(data.page);
  const { translate } = useContext(I18nContext);
  const [isCheckAll, setIsCheckAll] = useState(false);

  useEffect(() => {
    setCurrentPage(data.page);
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    getListBalances(newPage);
  };

  if (!data || (data && data.data && data.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />;
  }

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    const info = data?.data.map(all => {
      return {
        ...all,
        checked: true
      }
    })
    setPassengerInfo(info)
    if (isCheckAll) {
      setPassengerInfo([]);
    }
  };

  return (
    <>
      <TableContainer>
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              {!history &&
                <>
                  <TableCell>
                    <Checkbox
                      onChange={handleSelectAll}
                      checked={isCheckAll}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell style={{ minWidth: '235px' }}>
                    {'Actions'}
                  </TableCell>
                </>
              }
              <TableCell>{translate('balances.table.assigned_passenger')}</TableCell>
              <TableCell>{translate('balances.table.creator_passenger')}</TableCell>
              <TableCell>{translate('balances.form.assign.validSince')}</TableCell>
              <TableCell>{translate('balances.form.assign.validUntil')}</TableCell>
              <TableCell>{translate('balances.table.total_amount')}</TableCell>
              <TableCell>{translate('balances.table.total_consumed_amount')}</TableCell>
              <TableCell>{translate('balances.table.total_available_amount')}</TableCell>
              <TableCell>{translate('balances.table.service_type')}</TableCell>
              <TableCell>{translate('balances.table.status')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data.data || []).map((balance) => (
              <TableRow
                hover
                key={balance._id}
                className={'active-table-code'}
              >
                <TableCell>
                  {balance?.assigned_passenger?.name ?? '-'}
                  <br/>
                  { balance?.assigned_passenger?.email ?? '-'}
                </TableCell>
                <TableCell>
                  {balance?.creator_passenger?.name ?? '-'} 
                  <br/> 
                  {balance?.creator_passenger?.email ?? '-'}
                </TableCell>
                <TableCell>
                  {balance?.ini_date ?? '-'}
                </TableCell>
                <TableCell>
                  {balance?.end_date ?? '-'}
                </TableCell>
                <TableCell>
                  {formatCost(balance?.total_amount)}
                </TableCell>
                <TableCell>
                  {formatCost(balance?.total_consumed_amount)}
                </TableCell>
                <TableCell>
                  {formatCost(balance?.total_available_amount)}
                </TableCell>
                <TableCell>
                  {balance?.service_type ?? '-'}
                </TableCell>
                <TableCell>
                  { <span className={balanceStatuses(balance?.status?.cd)}>{balance?.status?.description ?? '-' }</span> }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.total ? data.total : data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
    </>
  );
};

export default withStyles(styles)(BalancesTable);
