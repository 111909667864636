import React from 'react';
import Integration from 'models/integrations/Integration';
import ShopifyActivation from 'components/Integrations/Shopify/ShopifyActivation';
import ShopifyDisableStore from 'components/Integrations/Shopify/ShopifyDisableStore';
import ShopifyStoreConfiguration from 'components/Integrations/Shopify/ShopifyStoreConfiguration';

class ShopifyIntegration extends Integration {
  constructor(integration) {// eslint-disable-line
    super(integration);
  }
  getActivateIntegration() {
    return <ShopifyActivation url={this.auth_url} />;
  }
  getDisableIntegration(account, getIntegrations) {
    return (
      <ShopifyDisableStore
        account={account}
        getIntegrations={getIntegrations}
      />
    );
  }
  getEditStore(id) {
    return (
      // this.user_provider_integrations.length > 1 && (
        <ShopifyStoreConfiguration
          store={this.user_provider_integrations[id]}
        />
      // )
    );
  }
}

export default ShopifyIntegration;
