import React from 'react';
import { Button } from '@material-ui/core';

const ButtonUrl = ({ url, label }) => (
  <Button color="primary" size="small" href={url} target="_blank">
    {label}
  </Button>
);

export default ButtonUrl;
