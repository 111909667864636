import React from 'react';
import Integration from 'models/integrations/Integration';
import MercadoFlexActivation from 'components/Integrations/MercadoFlex/MercadoFlexActivation';
import MercadoFlexStoreConfiguration from 'components/Integrations/MercadoFlex/MercadoFlexStoreConfiguration';
import MercadoFlexDisable from 'components/Integrations/MercadoFlex/MercadoFlexDisable';
class MercadoFlexIntegration extends Integration {
  constructor(integration) {// eslint-disable-line
    super(integration);
  }
  getActivateIntegration() {
    return <MercadoFlexActivation url={this.auth_url} />;
  }
  getDisableIntegration(account, getIntegrations) {
    return (
      <MercadoFlexDisable
        account={account}
        getIntegrations={getIntegrations}
      />
    );
  }
  getEditStore(store) {
    return <MercadoFlexStoreConfiguration store={this.user_provider_integrations[store]} />;
  }
}

export default MercadoFlexIntegration;
