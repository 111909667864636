import React, { lazy, Suspense} from 'react';
import { LinearProgress } from '@material-ui/core';
import withHelmet from 'utils/withHelmet';
import BannerVtex from 'components/Landing/Banners/BannerVtex';
import UseVtex from 'components/Landing/Vtex/UseVtex';
import LinkAccountsVtex from 'components/Landing/Vtex/LinkAccountsVtex';
const LayoutLanding = lazy(() => import('components/Landing/Layouts'));
const Contact = lazy(() => import('components/Landing/Layouts/Contact'));

const LandingVtex = () => (
  <Suspense fallback={<LinearProgress color="primary" />}>
    <LayoutLanding>
      <BannerVtex />
      <UseVtex />
      <LinkAccountsVtex />
      <Contact />
    </LayoutLanding>
  </Suspense>
)

export default withHelmet({
  title: 'Vtex E-commerce | Pibox',
  description: '',
  linkCanonical: 'https://pibox.app/vtex'
})(LandingVtex)
