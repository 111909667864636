import React, { useContext } from 'react';
import { I18nContext } from 'translations';
import ButtonUrl from 'components/Buttons/ButtonUrl';

const ShopifyActivation = ({ url }) => {
  const { translate } = useContext(I18nContext);
  return (
    <ButtonUrl url={url} label={translate('app.integrations.table.activate')} />
  );
};

export default ShopifyActivation;
