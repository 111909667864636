import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Typography, Container, Button, Snackbar, Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { getListFavoriteAddresses, removeFavoriteAddress } from 'context/settings/favoriteAddresses/actions';
import ModalFavoriteAddresses from 'components/Modals/Settings/FavoriteAddresses';
import FavoriteAddressesTable from 'components/Tables/Settings/FavoriteAddresses';
import Loading from 'components/Loading';
import { modalMessage } from 'utils';
import { INITIAL_PAGE, PER_PAGE } from 'utils/constants';

export default function FavoriteAddresses ({ company }) {
  const { translate } = useContext(I18nContext);
  const [{ commons: { message }, addresses: { listAddresses, loadingAddresses } }, dispatch] = useStateValue();
	const [open, setOpen] = useState(false);
  const [per_page] = useState(PER_PAGE);
  const [addressEdit, setAddressEdit] = useState(null);
  const [checked, setChecked] = useState(false);

  const getAddresses = useCallback(async (page) => {
    getListFavoriteAddresses(dispatch, { page, per_page });
  }, [dispatch, per_page]);

  const editAddress = (address) => {
    setOpen(true);
    setAddressEdit(address);
  }

  const messageOk = () => {
    modalMessage(translate('congratulations'), translate('app.settings.addresses.addressDeletedSuccessfully'), 'success', translate('accept'), true).then(() => {
      setOpen(false);
      getAddresses();
    })
  }

  const removeAddress = async (address) => {
    modalMessage('', translate('app.settings.addresses.messageConfirmRemove'), 'warning', 'Continue', true, true, translate('cancel')).then(async (response) => {
      if (response.isConfirmed) {
        const { _id } = address
        const callback = await removeFavoriteAddress(dispatch, _id)
        callback.status && messageOk();
      }
    });
  }

  useEffect(() => {
    getAddresses(INITIAL_PAGE);
  }, [getAddresses])

  const limitOrigin = (e) => {
    setChecked(e.target.checked)
    
  }

	return (
		<>
			<Typography variant="h5" color="primary">
        {translate('app.settings.addresses.myFavoriteAddresses')}
      </Typography>
			<div className="text-center">
        <ModalFavoriteAddresses
          open={open}
          setOpen={setOpen}
          getAddresses={getAddresses}
          addressEdit={addressEdit}
          setAddressEdit={setAddressEdit}
				/>
				<Container maxWidth="lg" className="padding0-xs">
          <Grid container>
            <Grid item md={6} className="text-left">
              {company?._id && 
                <FormControlLabel
                  style={{ paddingTop: 8 }}
                  label="Limitar punto de origen a estas direcciones?"
                  control={<Checkbox checked={checked} onChange={e => limitOrigin(e)} />}
                />
              }
            </Grid>
            <Grid item md={6} className="text-right">
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => setOpen(true)}
              >
                {translate('app.settings.addresses.addAddress')}
              </Button>
            </Grid>
          </Grid>
          <br />
          {loadingAddresses && <Loading />}
          {listAddresses && (
            <FavoriteAddressesTable
              editAddress={editAddress}
              data={listAddresses}
              removeAddress={removeAddress}
              getAddresses={getAddresses}
              recordsPerPage={per_page}
            />
          )}
				</Container>
        <Snackbar
          style={{ zIndex: 99999 }}
          open={!!message}
          autoHideDuration={6000}
          onClose={() => closeMessage(dispatch, null)}
          message={translate(message)}
        />
			</div>
		</>
	)
}