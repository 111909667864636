import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer
} from '@material-ui/core';
import { formatCost } from 'utils';
import { I18nContext } from 'translations';
import styles from '../style';

function OptimizedRoutesTable({
  classes,
  route,
  tasks,
  handleChangeSelectedStop,
  selectedStop
}) {
  const { translate } = useContext(I18nContext);

  const handleClickRow = (e, task) => {
    if (!route) return;
    handleChangeSelectedStop(e, task, route);
  };

  return (
    <TableContainer>
      <Table className={classes.table} size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>{translate('app.operations.optimize.optimizedRoutes.table.destiny')}</TableCell>
            <TableCell>{translate('app.operations.optimize.optimizedRoutes.table.NPackages')}</TableCell>
            <TableCell>{translate('app.operations.optimize.optimizedRoutes.table.declaredValue')}</TableCell>
            <TableCell>{translate('app.operations.optimize.optimizedRoutes.table.client')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks.length > 0
          && tasks.map((task, index) => (
            <TableRow
              id={task.stop_index}
              key={index.toString()}
              selected={selectedStop && task.stop_index === selectedStop.id}
              onClick={(e) => handleClickRow(e, task)}
              style={{ cursor: route ? 'pointer' : 'inherit' }}
            >
              <TableCell>
                <b>{task.address}</b>
              </TableCell>
              <TableCell>
                {task.packages ? task.packages[0].reference : ' - '}
              </TableCell>
              <TableCell>
                {task.packages ? formatCost(task.packages[0].declared_value) : ' - '}
              </TableCell>
              <TableCell>
                {task.customer ?
                  <>
                    {task.customer.name && task.customer.name}
                    {task.customer.phone && <><span> - </span><a href={`tel:${task.customer.country_code}${task.customer.phone}`} style={{ color: '#7825bd' }}>{task.customer.country_code} {task.customer.phone}</a></>}
                  </>
                : ' - '}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default withStyles(styles)(OptimizedRoutesTable);
