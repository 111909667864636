import { string, object, boolean } from 'yup';

const schema = object().shape({
  company: object().shape({
    name: string().required('requiredField'),
    tax_id: string().required('requiredField'),
    has_accepted_tc: boolean().oneOf([true], 'acceptTermsError'),
  })
});

export default schema;