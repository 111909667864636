import React from 'react';
import { Button, Drawer } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const Sidebar = ({ side='right', children, open, setOpen, width='250' }) => (
  <Drawer
    anchor={side}
    open={open}
    onClose={() => setOpen(false)}
  >
    <Button
      onClick={() => setOpen(false)}
      color="primary"
      style={{ justifyContent: "flex-end" }}
    >
      <CloseIcon />
    </Button>

    <div style={{ width, padding: 15 }}>
      {children}
    </div>
  </Drawer>
)

export default Sidebar;