import React, { useContext, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  Link,
  Button,
  CircularProgress
} from '@material-ui/core';
import { format } from 'date-fns';
import { I18nContext } from 'translations';
import { OPTIMIZATION_STOPS_FILE_STATUSES } from 'utils/constants';
import { optimizationStopsFileStatusesClass } from 'utils';
import { retryOptimizeRoutes } from 'context/operations/optimizeRoutes/actions';
import EmptyData from '../../EmptyData';
import styles from '../../style';

const RoutesFileListTable = ({
  classes,
  data,
  recordsPerPage,
  getRouteFileList
}) => {
  const [currentPage, setCurrentPage] = useState(data.page);
  const { translate } = useContext(I18nContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCurrentPage(data.page)
  }, [data])

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage)
    getRouteFileList(newPage);
  }

  if (!data || (data && data.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />
  }

  const retryOk = () => {
    setLoading(false);
    getRouteFileList(currentPage);
  }

  const retryOptimize = async (id) => {
    setLoading(true);
    const callback = await retryOptimizeRoutes(id);
    callback.status ? retryOk() : setLoading(false);
  }

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell>{translate('app.operations.optimize.table.file.name')}</TableCell>
              <TableCell>{translate('app.operations.optimize.table.file.creationDate')}</TableCell>
              <TableCell>{translate('app.operations.optimize.table.file.files')}</TableCell>
              <TableCell>{translate('app.operations.optimize.table.file.costCenter')}</TableCell>
              <TableCell>{translate('app.operations.optimize.table.file.status')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data.data || []).map(file => (
              <TableRow hover key={file._id}>
                <TableCell>
                  {file.filename}
                </TableCell>
                <TableCell>
                  <span className={classes.date}>
                    {format(new Date(file.created_at), 'dd/MM/yyyy, hh:mm a')}
                  </span>
                </TableCell>
                <TableCell>
                  <Link
                    href={file.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="primary"
                    underline="hover"
                    download
                  >
                    <b>{translate('app.operations.optimize.table.file.downloadFile')}</b>
                  </Link>
                </TableCell>
                <TableCell>
                  {file.cost_center?.name ?? ' - '}
                </TableCell>
                <TableCell>
                  <b className={optimizationStopsFileStatusesClass(file.status_cd)}>
                    {translate(OPTIMIZATION_STOPS_FILE_STATUSES[file.status_cd])}
                  </b>
                </TableCell>
                <TableCell>
                  {file.retryable ?
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => retryOptimize(file._id)}
                      disabled={loading}
                      startIcon={loading && <CircularProgress size="1.5rem" color="inherit" />}
                    >
                      {translate('retry')}
                    </Button>
                  : 
                    <Button
                      variant="contained"
                      href={`/app/operations/optimized-routes/${file._id}`}
                      size="small"
                      color="primary"
                      disabled={file.status_cd !== 1}
                    >
                      {translate('app.operations.optimize.table.file.see')}
                    </Button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
    </>
  );
}

function areEqual(prevProps, nextProps) {
  return prevProps.data === nextProps.data;
}

export default withStyles(styles)(React.memo(RoutesFileListTable, areEqual));