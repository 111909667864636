import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TimerIcon from '@material-ui/icons/Timer';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import OptimizedRoutesTable from 'components/Tables/Routes/OptimizedRoutes';
import {
  Box,
  Grid,
  Tooltip,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel
} from '@material-ui/core';
import { I18nContext } from 'translations';
import { PaginationAccordion } from 'components/Paginations/PaginationsAccordion';
import styles from './style';

function ListRoutes({
  classes,
  data,
  page,
  perPage,
  selectedStop,
  setRouteData,
  handleChangePage,
  handleChangeSelectedStop,
  handleChangeSelectedRoutes
}) {
  const { translate } = useContext(I18nContext);
  const [routeList, setRouteList] = useState([]);
  const [expandedRoute, setExpandedRoute] = useState(data && data.data ? data.data[0].route_index : null);

  const scrollToTask = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  };

  useEffect(() => {
    if (data.data.length) {
      if (!selectedStop) {
        setExpandedRoute(data.data[0].route_index);
      } else {
        let index = data.data.findIndex((route) => route.route_index === selectedStop.route);
        if (index < 0) index = 0;
        const id = data.data[index].route_index;
        setExpandedRoute(id);
        setRouteList([{ id: parseInt(id), index }]);
        scrollToTask(selectedStop.id);
      }
    }
  }, [selectedStop, data]);

  useEffect(() => {
    handleChangeSelectedRoutes && handleChangeSelectedRoutes(routeList);
  }, [routeList, handleChangeSelectedRoutes]);

  const handleCheck = (e, index, route) => {
    e.stopPropagation();
    const id = e.target.value;
    if (e.target.checked) {
      setRouteList(routeList.concat({ id: parseInt(id), index }));
      setRouteData(prev => prev.concat(route))
    } else {
      setRouteList(routeList.filter((item) => parseInt(item.id) !== parseInt(id)));
      setRouteData(prev => prev.filter(r => r.route_index !== parseInt(id)))
    }
  };

  const handleAccordion = (routeId) => (event, isExpanded) => {
    setExpandedRoute(isExpanded ? routeId : null);
  };

  return(
    <>
      {(data.data || []).map((route, index) => (
        <Accordion
          key={route.route_index}
          className={classes.containerAccordion}
          expanded={route.route_index === expandedRoute}
          onChange={handleAccordion(route.route_index)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.titleAccordion}
          >
            <FormControlLabel
              aria-label="Acknowledge"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              className={classes.fullWidth}
              control={
                <Checkbox
                  checked={routeList.some((item) => item.id === route.route_index)}
                  onChange={(e) => handleCheck(e, index, route)}
                  value={route.route_index}
                />
              }
              label={
                <Grid container spacing={1} alignItems="center">
                  <Grid item md={5} lg={5} xs={12}>
                    <b>
                      {`${translate('app.operations.optimize.optimizedRouteText')} # ${index+1}`}
                    </b>
                  </Grid>
                  <Grid item md={7} lg={7} xs={12}>
                    <Box display="flex" ml={-0.3}>
                      <Tooltip
                        title={translate('app.operations.optimize.estimatedTime')}
                        placement="top"
                        arrow
                        enterTouchDelay={1}
                      >
                        <span className={classes.routeTime}>
                          <TimerIcon />
                          &nbsp;
                          {route.estimated_transport_time}
                        </span>
                      </Tooltip>&nbsp;&nbsp;
                      <Tooltip
                        title={translate('app.operations.optimize.distance')}
                        placement="top"
                        arrow
                        enterTouchDelay={1}
                      >
                        <span className={classes.routeTime}>
                          <SettingsEthernetIcon />
                          &nbsp;
                          {route.estimated_distance}
                        </span>
                      </Tooltip>&nbsp;&nbsp;
                      <Tooltip
                        title={translate('app.operations.optimize.weight')}
                        placement="top"
                        arrow
                        enterTouchDelay={1}
                      >
                        <span className={classes.routeTime}>
                          <FitnessCenterIcon style={{ fontSize: '1.3rem' }} />
                          &nbsp;
                          {route.estimated_total_weight} Gr
                        </span>
                      </Tooltip>
                      <Tooltip
                        title={translate('app.operations.optimize.routeColor')}
                        placement="top"
                        arrow
                        enterTouchDelay={1}
                      >
                        <InvertColorsIcon style={{ color: `#${route.color_hex_code}`, position: 'absolute', right: 52 }} />
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              }
            />
          </AccordionSummary>
          <AccordionDetails className={classes.accDetails}>
            <OptimizedRoutesTable
              route={route}
              tasks={route.stops || []}
              selectedStop={selectedStop}
              handleChangeSelectedStop={handleChangeSelectedStop}
            />
          </AccordionDetails>
        </Accordion>
      ))}
      {page > 0 && (
        <div className="text-right">
          <PaginationAccordion
            handleChangePage={handleChangePage}
            page={page}
            perPage={perPage}
            totalPages={parseInt(data.pages, 2)}
            totalP={data.pages}
            total={data.total}
            type="inc"
          />
        </div>
      )}
    </>
  )
}

export default withStyles(styles)(ListRoutes);