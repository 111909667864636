import React, { useContext, useState } from 'react';
import { Button } from '@material-ui/core';
import { I18nContext } from 'translations';
import ModalWooCommerceForm from './ModalWooCommerce';

const WooCommerceActivation = ({getIntegrations}) => {
  const { translate } = useContext(I18nContext);

  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        size="small"
        color="primary"
        style={{ fontSize: '0.93rem' }}
        onClick={() => setOpen(true)}
      >
        {translate('app.integrations.table.activate')}
      </Button>
      <ModalWooCommerceForm openWooCommerce={open} setOpenWooCommerce={setOpen} getIntegrations={getIntegrations}/>
    </>
  );
};

export default WooCommerceActivation;
