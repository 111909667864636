import React, { useCallback, useEffect, useState, useContext } from 'react';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { LinearProgress } from '@material-ui/core';
import Title from 'components/Titles';
import { useStateValue } from 'context/store';
import { listRouteFileListOperations } from 'context/operations/optimizeRoutes/actions';
import RoutesFileListTable from 'components/Tables/Operations/OptimizeRoutes/RoutesFileListTable';
import { INITIAL_PAGE, PER_PAGE } from 'utils/constants';
import { I18nContext } from 'translations';

export default function RoutesFileList() {
  const [{ optimizeRoutes: { loadingListRouteFile, listRouteFile }}, dispatch] = useStateValue();
  const [per_page] = useState(PER_PAGE);
  const [tempPage, setTempPage] = useState(1);
  const { translate } = useContext(I18nContext);

  const getRouteFileList = useCallback(async (page) => {
    setTempPage(page);
    listRouteFileListOperations(dispatch, { page, per_page })
  }, [dispatch, per_page]);

  useEffect(() => {
    getRouteFileList(INITIAL_PAGE);
  }, [getRouteFileList]);

  useEffect(() => {
    if (tempPage === 1) {
      const interval = setInterval(() => {
        getRouteFileList(INITIAL_PAGE);
      }, 20000);
      return () => clearInterval(interval);
    }
  }, [getRouteFileList, tempPage]); // eslint-disable-line

  return (
    <div className="padding-page-full-height">
      <Title
        title={translate('app.operations.optimize.fileList')}
        icon={<ListAltIcon className="align-center" style={{ marginRight: 5 }} />}
      />
      <div className="marginFiltersTable">
        {loadingListRouteFile ? <LinearProgress color="primary" /> : <hr style={{ border: '2px solid transparent', margin: 0 }} />}
        {listRouteFile &&
          <RoutesFileListTable
            data={listRouteFile}
            getRouteFileList={getRouteFileList}
            recordsPerPage={per_page}
          />
        }
      </div>
    </div>
  )
}