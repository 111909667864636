import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  logo: {
    width: '85px',
    marginRight: theme.spacing(10),
    position: 'relative',
    top: 7
  },
  logoRent: {
    width: '70px',
    marginRight: theme.spacing(10),
    position: 'relative',
    top: 7
  },
  menu: {
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
    '& a': {
      padding: '0 12px',
      fontSize: '0.92rem'
    },
    '& .active' : {
      borderBottom: '2px solid',
      borderRadius: 0
    }
  },
  menuRight: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(3)
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    '& .btn-rounded': {
      marginLeft: 10
    },
    '& .flag-select': {
      paddingBottom: 0,
      cursor: 'pointer',
      '& :focus': {
        outline: 'none'
      },
      '& button': {
        cursor: 'pointer'
      }
    }
  },
  menuBar: {
    boxShadow: 'none',
    borderBottom: '1px solid #eceaea'
  },
  toolbar: {
    padding: '2px 0',
  },
  itemsMenu: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& a': {
      fontSize: '0.92rem',
      padding: '0 12px'
    },
    '& .active' : {
      borderBottom: '2px solid',
      borderRadius: 0
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      '& a' : {
        display: 'block',
        padding: '10px'
      }
    }
  },
  menuResponsive: {
    position: 'absolute',
    top: 60,
    right: 0,
    zIndex: 1,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    minWidth: 250,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: '0 0px 6px rgba(109,49,126, 0.35)',
    padding: '10px 5px 20px'
  }
}));

export default styles;