import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { I18nContext } from 'translations';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
  Checkbox,
  Link,
  Tooltip,
} from '@material-ui/core';
import { format } from 'date-fns';
import { bookingStatuses } from 'utils';
import { BOOKING_STATUSES } from 'utils/constants';
import EmptyData from '../EmptyData';
import styles from '../style';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SalesAdapter from 'adapters/SalesAdapter';

const SalesTable = ({
  classes,
  handleChangeCheck,
  data,
  recordsPerPage,
  getSales,
}) => {
  const { translate } = useContext(I18nContext);
  const [state, setState] = useState({});
  const [currentPage, setCurrentPage] = useState(data.page);
  const [citySelected, setCitySelected] = useState(null);

  const prevState = useRef();

  useEffect(() => {
    const currentSelected =
      Object.values(state)?.filter((e) => e === true) || false;

    if (currentSelected) {
      if (currentSelected < 2 && currentSelected < prevState) {
        setCitySelected(null);
      } else {
        setCitySelected(state?.sale?.order?.city_id);
      }
      prevState.current =
        Object.values(state)?.filter((e) => e === true) || false;
    }
  }, [state]);

  const handleChange = useCallback(
    (event, sale) => {
      setState({ ...state, [event.target.name]: event.target.checked, sale });
      handleChangeCheck(
        { ...state, [event.target.name]: event.target.checked },
        { ...sale, checked: event.target.checked }
      );
    },
    [state, handleChangeCheck]
  );

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    getSales(newPage);
  };

  useEffect(() => {
    setCurrentPage(data.page);
  }, [data]);

  if (!data || (data && data.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />;
  }

  return (
    <>
      <TableContainer>
        <Table className={classes.table} size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{translate('app.sales.table.status')}</TableCell>
              <TableCell>
                {translate('app.sales.table.trackingNumber')}
              </TableCell>
              <TableCell>{translate('app.sales.table.product')}</TableCell>
              <TableCell>{translate('app.sales.table.date')}</TableCell>
              <TableCell>{translate('app.sales.table.integration')}</TableCell>
              <TableCell>
                {translate('app.sales.table.deliveryStatus')}
              </TableCell>
              <TableCell>{translate('app.sales.table.shipmentId')}</TableCell>
              <TableCell>
                {translate('app.sales.table.deliveryAddress')}
              </TableCell>
              <TableCell>
                {translate('app.sales.table.deliveryNumber')}
              </TableCell>
              <TableCell>
                {translate('app.sales.table.orderType')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data.data || []).map((saleobject, index) => {
              const sale = SalesAdapter(saleobject);
              return (
                <TableRow
                  hover
                  key={`${sale.id}${index}`}
                  style={{
                    opacity:
                      citySelected &&
                      citySelected !== sale?.order?.city_id &&
                      '0.7',
                  }}
                >
                  <TableCell>
                    {sale.isReadyToDispatch() ? (
                      <Tooltip
                        title={
                          sale.booking_id
                            ? translate('app.sales.noCreateService.reason')
                            : translate('app.sales.noCreateService.reason2')
                        }
                      >
                        <InfoOutlinedIcon fontSize="small" />
                      </Tooltip>
                    ) : (
                      <Checkbox
                        checked={state[index] ? state[index] : false}
                        onChange={(e) => handleChange(e, sale)}
                        name={index.toString()}
                        color="primary"
                        size="small"
                        style={{ padding: 2 }}
                        disabled={
                          sale.destination?.lat === null ||
                          sale.destination?.address === null ||
                          ![null, undefined].includes(sale?.booking_id) ||
                          (citySelected &&
                            citySelected !== sale?.order?.city_id)
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell className={classes.status}>
                    <span
                      className={sale.getSaleClass()}
                      style={{ padding: '3px 7px' }}
                    >
                      {translate(sale.getSaleStatus())}
                    </span>
                  </TableCell>
                  <TableCell>{sale.order_id || ' - '}</TableCell>

                  <TableCell className={classes.address}>
                    {sale.product_name || '-'}
                  </TableCell>
                  <TableCell>
                    {sale.date_of_purchase ? (
                      <span className={classes.date}>
                        {format(
                          new Date(sale.date_of_purchase),
                          'dd/MM/yyyy, hh:mm a'
                        )}
                      </span>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell>
                    {sale.order.photo_url ? (
                      <img
                        src={sale.order.photo_url}
                        style={{ width: '85px' }}
                        alt={sale.integrator}
                      />
                    ) : sale.integrator ? (
                      <p>{sale.integrator}</p>
                    ) : (
                      ' - '
                    )}
                  </TableCell>
                  <TableCell>
                    {sale.status_cd !== null ? (
                      <span
                        className={bookingStatuses(sale.status_cd)}
                        style={{ padding: '3px 7px' }}
                      >
                        {translate(BOOKING_STATUSES[sale.status_cd])}
                      </span>
                    ) : (
                      ' - '
                    )}
                  </TableCell>
                  <TableCell>
                    <span style={{ fontSize: '0.94em' }}>
                      {sale.booking_id || '-'}
                    </span>
                  </TableCell>
                  <TableCell className={classes.address}>
                    {sale.booking_id ? (
                      <Link
                        href={`/bookings/${sale.booking_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        color="primary"
                        underline="hover"
                      >
                        {sale.destination.address || ' - '}
                      </Link>
                    ) : (
                      <p style={{ margin: 0 }}>
                        {sale.destination.address || ' - '}
                      </p>
                    )}
                  </TableCell>
                  <TableCell className={classes.address}>
                    <p style={{ margin: 0 }}>
                      {sale.external_id || ' - '}
                    </p>
                  </TableCell>
                  <TableCell className={classes.address}>
                    <p style={{ margin: 0 }}>
                      {sale.order.order_type || ' - '}
                    </p>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.total ? data.total : data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        backIconButtonProps={{
          style: { display: 'none' },
        }}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
    </>
  );
};

function areEqual(prevProps, nextProps) {
  return prevProps.data === nextProps.data;
}

export default withStyles(styles)(React.memo(SalesTable, areEqual));
