import { GET_TRACKING, TRACKING_RATING } from './constants';

const trackingReducer = (state, action) => {
  switch (action.type) {
    case GET_TRACKING:
      if (state.events.length === 0) {
        state.events = [...state.events, {
          _id: '900',
          created_at: action.tracking.created_at,
          status_cd: 900
        }]
      }
      if (action.tracking.accepted_by_driver_at && !state.events.some(x => x._id === '901' )) {
        state.events = [...state.events, {
          _id: '901',
          created_at: action.tracking.accepted_by_driver_at,
          status_cd: 901
        }]
      }
      if (action.tracking.stop.started_at && !state.events.some(x => x._id === '902' )) {
        state.events = [...state.events, {
          _id: '902',
          created_at: action.tracking.stop.started_at,
          status_cd: 902
        }]
      }
      if (action.tracking.events.length > 0) {
        const events = action.tracking.events.filter(item1 => !state.events.some(item2 => (item2._id === item1._id)));
        events.length > 0 && (state.events = [...state.events, ...events]);
      }
      return {
        ...state,
        tracking: action.tracking,
        events: state.events.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
      }
    case TRACKING_RATING:
      return {
        ...state,
        rating: action.rate
      }
    default:
      return state
  }
}

export default trackingReducer;
