import React, { useContext, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
  Button,
  Avatar
} from '@material-ui/core';
import { useStateValue } from 'context/store';
import { I18nContext } from 'translations';
import { ROLES_COMPANY, SERVICE_TYPE_USER_TEXT } from 'utils/constants';
import EmptyData from '../../EmptyData';
import styles from '../../style';

const UsersTable = ({
  classes,
  data,
  editUser,
  removeUser,
  getUsers,
  recordsPerPage,
  usersPerGroup,
  btnChoose,
  selectUser,
  toggleActiveUser
}) => {
  const [currentPage, setCurrentPage] = useState(data.page);
  const [{ checkin: { checkin } }] = useStateValue();
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    setCurrentPage(data.page)
  }, [data])

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage)
    getUsers(newPage);
  }

  if (!data || (data && data.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />
  }

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{translate('app.settings.users.table.user')}</TableCell>
              <TableCell>{translate('app.settings.users.table.role')}</TableCell>
              <TableCell>{translate('app.settings.users.table.services')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.data.map(user => (
              <TableRow hover key={user._id} style={{ opacity: user.active_passenger_in_company ? '1' : '0.75'}}>
                <TableCell style={{ width: 75 }}>
                  {user.photo_url
                    ?
                      <Avatar
                        alt={user.name}
                        src={user.photo_url}
                      />
                    : <Avatar className="background-primary">
                      <small>
                        {user.name.split(' ').map(x => x.charAt(0)).join('').substr(0, 2).toUpperCase()}
                      </small>
                    </Avatar>
                  }
                </TableCell>
                <TableCell>
                  {user.name} <br />
                  <span className="text-grey">{user.email}</span>
                </TableCell>
                <TableCell>
                  {ROLES_COMPANY[user.role_id] ? translate(ROLES_COMPANY[user.role_id]) : ' - '}
                </TableCell>
                <TableCell className="divider-service-type">
                  {user.requestable_service_groups && user.requestable_service_groups.map((type) => (
                    type.is_enabled &&
                      <span>
                        {translate(SERVICE_TYPE_USER_TEXT[type.requestable_service_type_group_cd])}
                      </span>
                  ))}
                </TableCell>
                {!usersPerGroup &&
                  <TableCell>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      style={{ marginRight: 10 }}
                      onClick={() => editUser(user)}
                    >
                      {translate('app.settings.users.table.edit')}
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      size="small"
                      style={{ marginRight: 10 }}
                      onClick={() => removeUser(user)}
                      disabled={checkin && user._id === checkin.profile._id}
                    >
                      {translate('app.settings.users.table.remove')}
                    </Button>
                    {user.active_passenger_in_company ? (
                      <Button
                        color="secondary"
                        variant="contained"
                        size="small"
                        style={{ width: '75px' }}
                        className="btn-yellow"
                        onClick={() => toggleActiveUser(user, 0)}
                      >
                        {translate('app.settings.users.inactivate')}
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        style={{ width: '75px' }}
                        className="btn-yellow"
                        onClick={() => toggleActiveUser(user, 1)}
                      >
                        {translate('app.settings.users.activate')}
                      </Button>
                    )}
                  </TableCell>
                }
                {btnChoose && (
                  <TableCell>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      style={{ marginRight: 10 }}
                      onClick={() => selectUser(user)}
                    >
                      {translate('choose')}
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
    </>
  );
}

function areEqual(prevProps, nextProps) {
  return prevProps.data === nextProps.data;
}

export default withStyles(styles)(React.memo(UsersTable, areEqual));

