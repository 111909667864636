import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { format } from 'date-fns';
import { SENDER_TYPE } from 'utils/constants';

const styles = () => ({
  text: {
    backgroundColor: '#fff',
    padding: '7px 15px',
    borderRadius: '4px',
    display: 'inline-block',
    fontSize: '13px',
    textAlign: 'justify',
    fontFamily: 'montserratmedium',
  },
  receiverMessage: {
    textAlign: 'left',
    marginBottom: '7px',
    '& div': {
      color: '#000'
    }
  },
  senderMessage: {
    textAlign: 'right',
    marginBottom: '7px',
    '& div': {
      color: '#ca58f4',
    }
  },
  picapMessage: {
    textAlign: 'right',
    marginBottom: '7px',
    '& div': {
      color: '#6f2f82',
    }
  },
  time: {
    margin: '0 0 5px',
    textAlign: 'left',
    fontSize: '11px',
    color: '#000',
    fontFamily: 'montserratmedium'
  },
  messageContainer: {
    padding: '2px;'
  }
});

const BookingChatMessage = ({
  classes,
  message
}) => {
  const messageClassName = () => {
    let returnedClass = classes.picapMessage;
    if (message.sender === SENDER_TYPE.PASSENGER) {
      returnedClass = classes.senderMessage;
    } else if (message.sender === SENDER_TYPE.DRIVER) {
      returnedClass = classes.receiverMessage;
    }
    return returnedClass;
  };

  const formattedTime = () => {
    const { createdAt } = message;
    if (createdAt) {
      return format(new Date(createdAt), 'dd/MM/yyyy, hh:mm a')
    }
    return '';
  };

  return (
    <Grid item xs={12} className={classes.messageContainer}>
      <div className={messageClassName()}>
        <div className={classes.text}>
          <p className={classes.time}>
            { formattedTime() }
          </p>
          { message.message }
        </div>
      </div>
    </Grid>
  );
};

export default withStyles(styles)(BookingChatMessage);