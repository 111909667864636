import {
  GET_SESSION,
  GET_SESSION_CHECK,
  DELETE_SESSION,
  SESSION_LOADING,
  SESSION_FETCHING,
} from './constants';

const whatsapp = (state, action) => {
  switch (action.type) {
    case GET_SESSION: {
      return {
        ...state,
        session: action.session,
      };
    }
    case GET_SESSION_CHECK: {
      return {
        ...state,
        session: action.session,
      };
    }
    case DELETE_SESSION: {
      return {
        ...state,
        session: null,
      };
    }
    case SESSION_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }
    case SESSION_FETCHING: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    default:
      return state;
  }
};

export default whatsapp;
