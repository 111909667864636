import { LOADING_UPDATE_PASSWORD, LOADING_PROFILE } from './constants';
import { message } from '../../commons/actions';
import { urlUpdatePasswordUser, urlSignupUser, urlValidatePhone } from 'api';
import { patch, put, post } from 'api/requests';
import handleErrorMessage from 'utils/handleErrorMessage';

export const updatePasswordUser = async (dispatch, password) => {
  dispatch({
    type: LOADING_UPDATE_PASSWORD,
    loadingPassword: true
  });
  await patch(urlUpdatePasswordUser, { password })
    .then(({ data }) => {
      dispatch({
        type: LOADING_UPDATE_PASSWORD,
        loadingPassword: false
      });
      message(dispatch, 'passwordUpdateCorrectly');
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: LOADING_UPDATE_PASSWORD,
        loadingPassword: false
      });
    });
}


export const updateProfileUser = async (dispatch, id, user) => {
  dispatch({
    type: LOADING_PROFILE,
    loadingProfile: true
  });
  await put(`${urlSignupUser}/${id}`, { ...user })
    .then(() => {
      dispatch({
        type: LOADING_PROFILE,
        loadingProfile: false
      });
      message(dispatch, 'app.profileDataUpdatedCorrectly');
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: LOADING_PROFILE,
        loadingProfile: false
      });
    });
}

export const cleanUser = dispatch => message(dispatch, null);

export const validatePhone = async (dispatch, id) => {
  let status = false;
  await post(`${urlValidatePhone}/${id}/validate_phone`, {})
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
    });
  return { status }
}

export const confirmValidatePhone = async (dispatch, id, params) => {
  let status = false;
  await post(`${urlValidatePhone}/${id}/confirm_phone`, { ...params })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
    });
  return { status }
}

