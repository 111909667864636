import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  container: {
    borderRadius: '1rem',
    boxShadow: `0px 2px 1px -1px rgba(0, 0, 0, 0.2),
       0px 1px 1px rgba(0, 0, 0, 0.14), 
       0px 1px 3px rgba(0, 0, 0, 0.12)`,
  },
  table: {
    border: 'none',
    borderRadius: '1rem',
    '& thead': {
      borderRadius: '1rem 1rem 0 0',

      '& tr th': {
        backgroundColor: '#D7CFFB',
        fontFamily: 'amaranthbold',
        lineHeight: 1.3,
        padding: 7,
        textAlign: 'center',
      },
      '& tr th:first-of-type': {
        borderRadius: '1rem 0 0 0',
      },
      '& tr th:last-of-type': {
        borderRadius: '0 1rem 0 0',
      },
    },
    '& tbody tr': {
      '& td': {
        border: 'none',
        fontSize: '0.8em',
        padding: '6px',
        color: '#000',
        '& button': {
          fontSize: '1em',
        },
      },
    },
    '& tbody tr:last-of-type': {
      borderRadius: '0 0  1rem 1rem',
      'td:last-of-type': {
        borderRadius: '0 0  1rem 0',
      },
      'td:nth-last-child(n-3)': {
        borderRadius: '0 0 0 1rem',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& thead': {
        display: 'none',
      },
      '& tbody tr': {
        '& td': {
          width: '100%',
          textAlign: 'center',
          borderBottom: 0,
          padding: '2px 5px',
          display: 'inline-block',
        },
        '& td:first-child': {
          paddingTop: '10px',
        },
        '& td:last-child': {
          paddingBottom: '10px',
          borderBottom: '1px solid #ccc',
        },
      },
    },
    '& .MuiTableRow-root.Mui-selected': {
      backgroundColor: '#F5EFF9',
    },
  },
}));

export default styles;
