import device_metadata from 'utils/device';
import { post, patch, get, deleteP, deleteD } from 'api/requests';
import {
  urlIntegrations,
  urlIntegrationLogin,
  urlAuthorizeShopify,
  urlActivationShopify,
  urlDeleteStoreShopify,
  urlDisableIntegration,
  urlActivationMercadoflex,
  urlGetShopifyStores,
  urlActivationWoocomerce,
  urlDeleteWoocomerceStore,
  urlGetWoocommerceStores,
  urlGetMercadoflexStores,
  urlActivationVtex,
} from 'api';
import {
  LOADING,
  MESSAGE,
  LOADING_ACTIVATION,
  LIST_INTEGRATIONS,
  LIST_WOOCOMMERCE_STORES,
  LIST_MERCADOFLEX_STORES
} from './constants';
import handleErrorMessage from 'utils/handleErrorMessage';
import { message } from '../commons/actions';

export const integrationLogin = async (dispatch, values) => {
  dispatch({ type: LOADING, loading: true });

  await post(urlIntegrationLogin, { device: { ...device_metadata }, ...values })
    .then(({ data }) => {
      dispatch({
        type: LOADING,
        loading: false,
      });
      if (data.auth_redirect_url) {
        const { auth_redirect_url, session_token } = data;
        window.open(
          `${auth_redirect_url}?session_token=${session_token}`,
          '_self'
        );
      }
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: LOADING, loading: false });
      dispatch({ type: MESSAGE, message: mssg });
    });
};

export const closeMessage = (dispatch) => {
  dispatch({
    type: MESSAGE,
    message: null,
  });
};

export const activationMercadoflex = async (dispatch, values, history) => {
  dispatch({ type: LOADING_ACTIVATION, loading: true });
  await patch(urlActivationMercadoflex, { ...values })
    .then(() => {
      dispatch({
        type: LOADING_ACTIVATION,
        loading: false,
      });
      history.push('/app/bookings/sales');
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: LOADING_ACTIVATION, loading: false });
      dispatch({ type: MESSAGE, message: mssg });
    });
};

export const cleanIntegrations = (dispatch) => {
  dispatch({
    type: LIST_INTEGRATIONS,
    list: null,
  });
  closeMessage(dispatch);
};

export const listIntegrations = async (dispatch, params) => {
  let status = false;
  await get(urlIntegrations, params)
    .then(({ data }) => {
      dispatch({
        type: LIST_INTEGRATIONS,
        list: data,
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      status = false;
    });
  return { status };
};

export const disableAccount = async (dispatch, id) => {
  let status = false;
  await patch(`${urlDisableIntegration}/${id}/deactivate`)
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      status = false;
    });
  return { status };
};

export const activationShopify = async (dispatch, values, url) => {
  let status = false;
  let currentUrl = `${url}/shopify/activation`;
  await post(urlActivationShopify, { ...values, callback_url: currentUrl })
    .then(({ data }) => {
      status = true;
      const { url } = data;
      url
        ? window.open(url, '_target')
        : window.open('/app/bookings/sales', '_self');
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      status = false;
    });

  return { status };
};

export const disabledStoreShopify = async (dispatch, storeName, id) => {
  let status = false;
  await deleteP(`${urlDeleteStoreShopify}/${id}`)
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      status = false;
    });
  return { status };
};

export const authorizeShopify = async (dispatch, params) => {
  let status = false;
  await get(`${urlAuthorizeShopify}?${params.search}`)
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      status = false;
    });
  return { status };
};

export const confirmShopify = async (dispatch, params, history) => {
  let status = false;
  dispatch({ type: LOADING, loading: true });
  await get(`${urlGetShopifyStores}?${params}`)
    .then(() => {
      status = true;
      dispatch({ type: LOADING, loading: false });
      history.push('/app/integrations/shopify/configuration');
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      dispatch({ type: LOADING, loading: false });
      status = false;
    });
  return { status };
};

export const configurationShopify = async (dispatch, store_name, values) => {
  let status = false;
  await patch(`${urlGetShopifyStores}/${store_name}`, { ...values })
    .then((data) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
};

export const activationWoocommerce = async (dispatch, values) => {
  let status = false;
  await post(urlActivationWoocomerce, { ...values })
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      status = false;
    });

  return { status };
};

export const disabledStoreWoocommerce = async (dispatch, storeName, _id) => {
  let status = false;
  await deleteD(`${urlDeleteWoocomerceStore}/${_id}`, {
    store_name: storeName,
  })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      status = false;
    });
  return { status };
};

export const listStoresWoocommerce = async (dispatch) => {
  await get(urlGetWoocommerceStores, {})
    .then(({ data }) => {
      dispatch({
        type: LIST_WOOCOMMERCE_STORES,
        listWooCommerceStores: data.stores || [],
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
    });
};

export const configurationWoocommerce = async (
  dispatch,
  store_name,
  values
) => {
  let status = false;
  await patch(`${urlGetWoocommerceStores}/${store_name}`, { ...values })
    .then((data) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
};

//Woocommerce

export const activationMercadoFlex = async (dispatch, values) => {
  let status = false;
  await post(urlActivationMercadoflex, { ...values })
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      status = false;
    });

  return { status };
};


export const listStoresMercadoFlex = async (dispatch) => {
  await get(urlGetMercadoflexStores, {})
    .then(({ data }) => {
      dispatch({
        type: LIST_MERCADOFLEX_STORES,
        listMercadoFlexStores: data.stores || [],
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
    });
};

export const configurationMercadoFlex = async (
  dispatch,
  store_name,
  values
) => {
  let status = false;
  await patch(`${urlGetMercadoflexStores}/${store_name}`, { ...values })
    .then((data) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
};

export const activationVtex = async (dispatch, values) => {
  let status = false;
  await post(urlActivationVtex, { ...values })
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      status = false;
    });

  return { status };
};


export const disabledStoreVtex = async (dispatch, storeName, _id) => {
  let status = false;
  await deleteD(`${urlActivationVtex}/${_id}`, {
    store_name: storeName,
  })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      status = false;
    });
  return { status };
};