import React, { useEffect, useRef, useState, useContext } from 'react';
import { Grid, Button, RootRef, TextField, Avatar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Person, Send, Close } from '@material-ui/icons';
import { I18nContext } from 'translations';
import { FirebaseChat } from 'utils/firebaseChat';
import BookingChatMessage from './BookingChatMessage';
import styles from './style';

function BookingChat({
  bookingId,
  driver,
  classes,
  openViewChat,
  disabledChat,
  sendAsShopper,
}) {
  const messagesChat = useRef();
  const [messages, setMessages] = useState([]);
  const [chatMessage, setChatMessage] = useState('');
  const [chat] = useState(new FirebaseChat());
  const { translate } = useContext(I18nContext);

  const getMessages = () => {
    chat.listenForMessages(bookingId, (newMessage) => setMessages(prevState => [...prevState, newMessage]));
    messagesChat.current.scrollTop = messagesChat.current.scrollHeight;
  }

  useEffect(() => {
    getMessages();
  }, []) // eslint-disable-line

  useEffect(() => {
    messagesChat.current.scrollTop = messagesChat.current.scrollHeight;
  }, [messages])

  const handleSubmit = e => {
    e.preventDefault();
    if (chatMessage && chatMessage.trim() !== '') {
      if (sendAsShopper) {
        chat.sendMessage(chatMessage.trim(), chat.AS_SHOPPER, bookingId);
      } else {
        chat.sendMessage(chatMessage.trim(), chat.AS_PICAP, bookingId);
      }
      setChatMessage('');
    } else {
      setChatMessage('');
    }
  }

  const handleInputChange = e => setChatMessage(e.target.value);

  return (
    <div className={classes.boxChat}>
      <div className={classes.header}>
        <Grid container spacing={2} alignItems="center">
          <>
            <Grid item sm={3} xs={4} className={classes.textCenter}>
              {driver
                ? <Avatar
                    alt="Driver"
                    src={driver.photo_url}
                    className={classes.avatar}
                  />
                : <Avatar className={classes.avatar}>
                    <Person />
                  </Avatar>
              }
            </Grid>
            <Grid item sm={9} xs={8}>
              <Typography variant="subtitle2" color="textPrimary">
                {driver && driver.name}
              </Typography>
              <Button
                color="primary"
                className={classes.btnClose}
                onClick={openViewChat}
              >
                <Close />
              </Button>
            </Grid>
          </>
        </Grid>
      </div>
      <RootRef rootRef={messagesChat}>
        <Grid container className={classes.containerMessages}>
          {messages.map((message, index) => (
            <BookingChatMessage message={message} key={index.valueOf()} />
          ))}
        </Grid>
      </RootRef>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid container style={{ padding: '1px 10px 10px 10px' }}>
          <Grid item xs={10}>
            <TextField
              value={chatMessage}
              className={classes.inputSubmit}
              placeholder={translate('app.booking.chat.writeAMessage')}
              onChange={handleInputChange}
              disabled={!disabledChat}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              color="primary"
              type="submit"
              className={classes.btnSubmit}
              disabled={!disabledChat}
            >
              <Send />
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default withStyles(styles)(BookingChat);