import React, { useContext } from 'react';
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import { I18nContext } from 'translations';

export default function ModalPackages({
  handleClose,
  open
}) {
  const { translate } = useContext(I18nContext);

  return (
    <Dialog onClose={handleClose} maxWidth="sm" fullWidth open={open}>
      <DialogTitle disableTypography className="text-center">
        <Typography color="primary" variant="h5">
          {translate('app.express.packageMeasurement')}
        </Typography>
      </DialogTitle>

      <DialogContent className="text-center">
        <Grid container alignItems="center">
          <Grid item md={6}>
            <img
              src="/images/users/pibox-boxes.png"
              alt="packages"
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item md={6}>
            <List>
              <ListItem style={{ padding: '0 16px' }}>
                <ListItemText
                  primary={translate('size.extraSmall')}
                  secondary="(Max 10cm)"
                />
              </ListItem>
              <ListItem style={{ padding: '0 16px' }}>
                <ListItemText
                  primary={translate('size.small')}
                  secondary="(Max 20cm)"
                />
              </ListItem>
              <ListItem style={{ padding: '0 16px' }}>
                <ListItemText
                  primary={translate('size.medium')}
                  secondary="(Max 30cm)"
                />
              </ListItem>
              <ListItem style={{ padding: '0 16px' }}>
                <ListItemText
                  primary={translate('size.large')}
                  secondary="(Max 50cm)"
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {translate('close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}