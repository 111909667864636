import React, { useContext, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { startOfDay, endOfDay, formatISO } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import { useForm, Controller } from 'react-hook-form';
import { I18nContext } from 'translations';
import {
  BOOKING_STATUSES,
  FILTER_SERVICE_TYPES,
  PAYMENT_METHODS,
} from 'utils/constants';
import CachedIcon from '@material-ui/icons/Cached';
import styles from '../../styleTableFilters';
import { useCallback } from 'react';
import { getCostCenter } from 'context/express/actions';
import { useStateValue } from 'context/store';
import { piboxAdminRolesCompany } from 'utils';

const BookingTableFilters = ({ classes, onFilters, setPassenger, isRent }) => {
  const { translate } = useContext(I18nContext);
  const [costCenter, setCostCenter] = useState([]);

  const defaultValues = {
    ini_date: null,
    end_date: null,
    status_cd: '',
    payment_method_cd: '',
    use_wallet_balance: '',
    cost_center: '',
    service_type: isRent ? 'rent' : ''
  };

  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const [
    {
      checkin: { company, checkin },
    },
  ] = useStateValue();

  const listCostCenter = useCallback(async () => {
    const callback = company && (await getCostCenter(company._id));
    callback && callback.costCenter && setCostCenter(callback.costCenter);
  }, [company]);

  useEffect(() => {
    listCostCenter();
  }, [listCostCenter]);

  const roles = () => {
    let rol = [];
    if (checkin && checkin.profile.roles) {
      rol = piboxAdminRolesCompany(checkin.profile.roles);
    }
    return rol;
  };

  const onSubmit = (values) => {
    const params = {
      ...values,
      ini_date: values.ini_date && formatISO(startOfDay(values.ini_date)),
      end_date: values.end_date && formatISO(endOfDay(values.end_date)),
    };
    Object.keys(params).forEach((key) => {
      if (params[key] === '' || params[key] == null) {
        delete params[key];
      }
    });
    onFilters(params);
  };

  const clearFilters = () => {
    reset(defaultValues);
    onFilters('');
    setPassenger(null);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      noValidate
      className="relative"
    >
      <Grid container spacing={1}>
        <Grid item md={3} sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Controller
              name="ini_date"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <KeyboardDatePicker
                  className={classes.filter}
                  size="small"
                  inputVariant="outlined"
                  label={translate('app.bookings.filter.dateFrom')}
                  format="dd/MM/yyyy"
                  cancelLabel={translate('cancel')}
                  okLabel={translate('accept')}
                  maxDate={new Date()}
                  invalidDateMessage={translate('invalidFormat')}
                  fullWidth
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Controller
              name="end_date"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <KeyboardDatePicker
                  className={classes.filter}
                  size="small"
                  inputVariant="outlined"
                  label={translate('app.bookings.filter.dateTo')}
                  format="dd/MM/yyyy"
                  cancelLabel={translate('cancel')}
                  okLabel={translate('accept')}
                  maxDate={new Date()}
                  invalidDateMessage={translate('invalidFormat')}
                  fullWidth
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.filter}
            fullWidth
          >
            <InputLabel>{translate('app.bookings.filter.state')}</InputLabel>
            <Controller
              control={control}
              name="status_cd"
              label={translate('app.bookings.filter.state')}
              variant="outlined"
              defaultValue=""
              as={
                <Select>
                  <MenuItem value="">
                    {translate('app.bookings.filter.choose')}
                  </MenuItem>
                  {Object.keys(BOOKING_STATUSES).map((key) => (
                    <MenuItem key={key} value={key}>
                      {translate(BOOKING_STATUSES[key])}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.filter}
            fullWidth
          >
            <InputLabel>
              {translate('app.bookings.filter.paymentMethod')}
            </InputLabel>
            <Controller
              control={control}
              name="payment_method_cd"
              label={translate('app.bookings.filter.paymentMethod')}
              variant="outlined"
              defaultValue=""
              as={
                <Select>
                  <MenuItem value="">
                    {translate('app.bookings.filter.choose')}
                  </MenuItem>
                  {Object.keys(PAYMENT_METHODS).map((key) => (
                    <MenuItem key={key} value={key}>
                      {translate(PAYMENT_METHODS[key])}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Grid>

        <Grid item md={3} sm={4} xs={6}>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.filter}
            fullWidth
          >
            <InputLabel>{translate('app.bookings.filter.wallet')}</InputLabel>
            <Controller
              control={control}
              name="use_wallet_balance"
              label={translate('app.bookings.filter.wallet')}
              variant="outlined"
              defaultValue=""
              as={
                <Select>
                  <MenuItem value="">
                    {translate('app.bookings.filter.choose')}
                  </MenuItem>
                  <MenuItem value={true}>{translate('yes')}</MenuItem>
                  <MenuItem value={false}>{translate('no')}</MenuItem>
                </Select>
              }
            />
          </FormControl>
        </Grid>
        {company && roles().length > 0 && (
          <>
            <Grid item md={3} sm={4} xs={6}>
              <FormControl
                variant="outlined"
                size="small"
                className={classes.filter}
                fullWidth
              >
                <InputLabel>
                  {translate('app.bookings.filter.cost_center')}
                </InputLabel>
                <Controller
                  control={control}
                  name="cost_center"
                  label={translate('app.bookings.filter.cost_center')}
                  variant="outlined"
                  defaultValue=""
                  as={
                    <Select>
                      <MenuItem value="">
                        {translate('app.bookings.filter.choose')}
                      </MenuItem>
                      {costCenter.map((center) => (
                        <MenuItem key={center._id} value={center._id}>
                          {center.name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item md={3} sm={4} xs={6}>
              <FormControl
                variant="outlined"
                size="small"
                className={classes.filter}
                fullWidth
              >
                <InputLabel>
                  {translate('app.bookings.filter.service_type')}
                </InputLabel>
                <Controller
                  control={control}
                  name="service_type"
                  label={translate('app.bookings.filter.service_type')}
                  variant="outlined"
                  defaultValue=""
                  as={
                    <Select>
                      <MenuItem value="">
                        {translate('app.bookings.filter.choose')}
                      </MenuItem>
                      {Object.entries(FILTER_SERVICE_TYPES).map((service) => {
                        return (
                          <MenuItem key={service[0]} value={service[0]}>
                            {translate(service[1])}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                />
              </FormControl>
            </Grid>
          </>
        )}
        <Grid item md={6} xs={12} className="text-center-sm">
          <Button
            variant="contained"
            color="primary"
            size="small"
            type="submit"
            style={{ width: 120, marginRight: 10 }}
          >
            {translate('app.bookings.filter.search')}
          </Button>
          <Button
            color="primary"
            size="small"
            type="button"
            style={{ width: 100 }}
            onClick={clearFilters}
          >
            {translate('clear')}
          </Button>
        </Grid>
      </Grid>
      <Button
        color="primary"
        size="small"
        type="submit"
        style={{ position: 'absolute', right: 0 }}
      >
        <CachedIcon fontSize="small" />
      </Button>
    </form>
  );
};

export default withStyles(styles)(BookingTableFilters);