import React, { useContext, useState } from 'react';
import { Grid, TextField, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import { I18nContext } from 'translations';
import ModalListFavoriteAddresses from 'components/Modals/Settings/FavoriteAddresses/ListFavoriteAddress';
import ModalUpload from '../../../Modals/ModalUpload'; 

const HeaderForm = ({ cities, resetForm, setCity, city, address, expanded, setFavoriteAddress, isRent }) => {
  const { translate } = useContext(I18nContext);
  const [open, setOpen] = useState(false);
  const [openAddresses, setOpenAddresses] = useState(false);
  const handleModalUpload = () => setOpen(!open);
  const handleModalFavoriteAddresses = () => setOpenAddresses(!open);

  const getSelectedAddress = (addressInfo) => {
    const { address, geojson } = addressInfo;
    setFavoriteAddress({
      name: address,
      lat: geojson.coordinates[1],
      lon: geojson.coordinates[0]
    })
    setOpenAddresses(false);
  }

  return (
    <div style={{ padding: '8px 20px', borderBottom: '2px solid lightgray' }}>
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          {!isRent &&
            <IconButton
              aria-label={translate('app.express.bulkLoad')}
              color="primary"
              size="small"
              onClick={handleModalUpload}
              style={{ margin: '0 15px' }}
              disabled={!(address.length <= 1 && expanded === 'stops0') || (address[0] && address[0].lat ? true : false)}
            >
              <CloudUploadOutlinedIcon style={{ fontSize: 34 }}/>
            </IconButton>
          }
          <IconButton
            aria-label={translate('app.express.bulkLoad')}
            color="primary"
            size="small"
            style={{ margin: '0 15px' }}
            onClick={handleModalFavoriteAddresses}
            disabled={expanded === 'close'}
          >
            <FavoriteBorderOutlinedIcon style={{ fontSize: 34 }} />
          </IconButton>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Autocomplete
            id="cities"
            options={cities}
            autoHighlight
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
            value={city ? city : cities[0]}
            onChange={(event, newValue) => {
              event.stopPropagation();
              resetForm();
              setCity(newValue);
            }}
          />
        </Grid>
      </Grid>
      <ModalUpload
        open={open}
        handleModalUpload={handleModalUpload}
        city={city ? city : cities[0]}
      />
      <ModalListFavoriteAddresses
        open={openAddresses}
        setOpen={setOpenAddresses}
        getSelectedAddress={getSelectedAddress}
        cityId={city ? city._id : cities[0]._id}
      />
    </div>
  )
}

function areEqual(prevProps, nextProps) {
  return prevProps.address[0] === nextProps.address[0]
  && prevProps.expanded === nextProps.expanded
  && prevProps.city === nextProps.city
  && prevProps.cities === nextProps.cities
}

export default React.memo(HeaderForm, areEqual);