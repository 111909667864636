import { useState } from 'react';
import { useStateValue } from 'context/store';
import { getAproxAddress } from 'context/express/actions';

export default function useAddressInput() {
  const [{ checkin: { configuration } }, dispatch] = useStateValue();
  const cities = configuration && configuration.cities_for_delivery;
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [open, setOpen] = useState(false);
  const [addressPoint, setAddressPoint] = useState(null);

  const handleModalMap = () => setOpen(!open);

  const changeCoord = async coord => {
    setLoadingAddress(true);
    const callback = await getAproxAddress(dispatch, coord);
    if (callback.aproxAddress) {
      setLoadingAddress(false);
      setAddressPoint({ name: callback.aproxAddress, lat: coord.lat, lon: coord.lng })
    } else {
      setLoadingAddress(false);
      setAddressPoint(null);
    }
  }

  return { loadingAddress, handleModalMap, changeCoord, addressPoint, open, cities }
}
