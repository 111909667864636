import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { PAYMENT_METHODS } from 'utils/constants';
import { booleanToString } from 'utils';
import { I18nContext } from 'translations';
import TextBooking from 'components/Booking/TextBooking';

export default function DetailsPayment ({ paymentMethod, wallet, isRent, promoCode }) {
  const { translate } = useContext(I18nContext);
  return (
    <Grid container justify="center" alignItems="center" className="booking-data">
      <Grid item sm={6} xs={12}>
        <TextBooking
          label={translate('app.booking.paymentMethod')}
          text={paymentMethod ? translate(PAYMENT_METHODS[paymentMethod]) : '-'}
          space
        />
      </Grid>
      <Grid item sm={5} xs={10}>
        <TextBooking
          label={translate('app.booking.useWallet')}
          text={wallet ? translate(booleanToString(wallet)) : 'No'}
          space
        />
      </Grid>
      <Grid item sm={1} xs={2}>
        <img
          src="/images/booking/picash.svg"
          alt="picash"
          style={{ height: 22 }}
        />
      </Grid>
      {isRent && promoCode &&
        <Grid item xs={12}>
          <TextBooking
            label={`${translate('promoCode.form.tripCode')}: `}
            text={promoCode}
          />
        </Grid>
      }
    </Grid>
  )
}