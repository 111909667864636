import React, { useContext, useState } from 'react';
import { Button, TextField, Grid, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { I18nContext } from 'translations';
import schema from './schema';

export default function FormConfigurationMercadoFlex({
  listMercadoFlexStores,
  submit,
  storeName,
}) {
  const { translate } = useContext(I18nContext);
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const findStore = () =>
    listMercadoFlexStores?.find(
      (listShopifyStore) =>
        listShopifyStore.user_external_identificator === storeName
    );

  const [selectedStore] = useState(findStore());
  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      {listMercadoFlexStores?.length > 0 && selectedStore ? (
        <>
          <Grid
            container
            style={{ marginBottom: 10 }}
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} style={{ marginBottom: 5 }}>
              <TextField
                type="text"
                name="main_address"
                variant="outlined"
                label={translate(
                  'app.mercadoFlex.settingmercadoFlex.principal_address'
                )}
                inputRef={register}
                size="small"
                fullWidth
                defaultValue={selectedStore.main_address}
                error={!!errors.main_address}
                helperText={
                  errors.main_address && translate(errors.main_address.message)
                }
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                type="text"
                name="main_city"
                variant="outlined"
                label={translate(
                  'app.mercadoFlex.settingmercadoFlex.principal_city'
                )}
                inputRef={register}
                size="small"
                fullWidth
                defaultValue={selectedStore.main_city}
                error={!!errors.main_city}
                helperText={
                  errors.main_city && translate(errors.main_city.message)
                }
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
          {translate('app.integration.settingStore.form.doNotHaveStores')}
        </Typography>
      )}
      <div className="text-center">
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={listMercadoFlexStores?.length <= 0}
        >
          {translate('save')}
        </Button>
      </div>
    </form>
  );
}
