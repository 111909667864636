import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer
} from '@material-ui/core';
import { I18nContext } from 'translations';
import { SIZE_CD } from 'utils/constants';
import { booleanToString } from 'utils';
import styles from '../../style';

const FailedStopsTable = ({
  classes,
  data,
  isOperations
}) => {
  const { translate } = useContext(I18nContext);

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {isOperations && <TableCell>{translate('app.bulkLoad.failedStops.table.row')}</TableCell>}
              <TableCell>{translate('app.bulkLoad.failedStops.table.address')}</TableCell>
              <TableCell>{translate('app.bulkLoad.failedStops.table.supplementaryAddress')}</TableCell>
              <TableCell>{translate('app.bulkLoad.failedStops.table.orderNumber')}</TableCell>
              <TableCell>{translate('app.bulkLoad.failedStops.table.indications')}</TableCell>
              <TableCell>{translate('app.bulkLoad.failedStops.table.size')}</TableCell>
              <TableCell>{translate('app.bulkLoad.failedStops.table.declaredValue')}</TableCell>
              <TableCell>{translate('app.bulkLoad.failedStops.table.counterDelivery')}</TableCell>
              <TableCell>{translate('app.bulkLoad.failedStops.table.collectedValue')}</TableCell>
              <TableCell>{translate('app.bulkLoad.failedStops.table.customer')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data || []).map((stop, index) => (
              <TableRow hover key={index}>
                {isOperations && 
                  <TableCell className={classes.address}>
                    {stop.row}
                  </TableCell>
                }
                <TableCell className={classes.address}>
                  {(stop.address && stop?.lat) ? stop.address : <span className="error">{translate('app.operations.optimize.errorAddress')}</span>}
                </TableCell>
                <TableCell>
                  {stop.secondary_address}
                </TableCell>
                <TableCell>
                  {stop.packages && stop.packages[0] && stop.packages[0].reference ? stop.packages[0].reference : <span className="error">{translate('app.operations.optimize.errorRequiredField')}</span>}
                </TableCell>
                <TableCell>
                  {stop.packages && stop.packages[0] && stop.packages[0].indications ? stop.packages[0].indications : <span className="error">{translate('app.operations.optimize.errorRequiredField')}</span>}
                </TableCell>
                <TableCell>
                  {stop.packages && stop.packages.length > 0 && (stop.packages[0].size_cd || stop.packages[0].size_cd === 0) ? translate(SIZE_CD[stop.packages[0].size_cd]) : <span className="error">{translate('app.operations.optimize.errorRequiredField')}</span>}
                </TableCell>
                <TableCell>
                  {stop.packages && stop.packages[0] && stop.packages[0].declared_value
                    ? stop.packages[0].declared_value
                    : <span className="error">{translate('app.operations.optimize.errorRequiredField')}</span>
                  }
                </TableCell>
                <TableCell>
                  {stop.packages && stop.packages[0] && stop.packages[0].counter_delivery !== null
                    ? translate(booleanToString(stop.packages[0].counter_delivery))
                    : <span className="error" style={{ margin: '4px 0' }}>{translate('app.operations.optimize.errorRequiredField')}</span>}
                </TableCell>
                <TableCell>
                  {stop.packages && stop.packages[0] && stop.packages[0].counter_delivery === true && (
                    stop.packages[0].collected_value === null || stop.packages[0].collected_value === 0
                      ? <span className="error" style={{ margin: '4px 0' }}>
                          {translate('app.operations.optimize.errorRequiredField')}
                        </span>
                      : stop.packages[0].collected_value
                  )}
                </TableCell>
                <TableCell>
                  {stop.customer && stop.customer.name ? stop.customer.name : '-'}
                  {stop.customer && stop.customer.phone ? ` - ${stop.customer.country_code} ${stop.customer.phone}` : ''}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function areEqual(prevProps, nextProps) {
  return prevProps.data === nextProps.data;
}

export default withStyles(styles)(React.memo(FailedStopsTable, areEqual));

