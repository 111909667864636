import React, { useContext, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Grid
} from '@material-ui/core';
import { I18nContext } from 'translations';
import { useForm } from 'react-hook-form';
import { confirmValidatePhone } from 'context/settings/profile/actions';
import { useStateValue } from 'context/store';
import { modalMessage } from 'utils';

const ModalValidatePhone = ({
  open,
  handleClose,
  userId,
  setHasPhoneValidated
}) => {
  const { translate } = useContext(I18nContext);
  const [, dispatch] = useStateValue();
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register,  errors } = useForm();

  const messageOk = () => {
    setLoading(false)
    setHasPhoneValidated(true);
    modalMessage(
      translate('congratulations'),
      translate('validatePhone.validateNumberConfirmMssg'),
      'success',
      translate('accept'),
      true
    ).then(() => {
      handleClose()
    })
  }

  const submit = async (values) => {
    setLoading(true)
    const callback = await confirmValidatePhone(dispatch, userId, values)
    callback.status ? messageOk() : setLoading(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'sm'}
      aria-labelledby="form-collection-value-dialog-title"
    >
      <DialogTitle id="form-collection-value-dialog-title">
        {translate('validatePhone.enterVerificationCode')}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(submit)} noValidate style={{ paddingTop: '35px' }}>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                name="sms_code"
                label={translate('validatePhone.verificationCode')}
                type="text"
                fullWidth
                required
                focused
                variant="outlined"
                inputProps={{ maxLength: "7" }}
                inputRef={register({
                  required: translate('requiredField'),
                  pattern: {
                    value: /^[0-9]+$/,
                    message: translate('onlyNumbers'),
                  }
                })}
                error={!!errors.sms_code}
                helperText={errors.sms_code && translate(errors.sms_code.message)}
              />
            </Grid>
            <Grid item xs={12} className="text-center">
              <Button
                variant="contained"
                type="submit"
                color="primary"
                size="large"
                disabled={loading}
                startIcon={loading && <CircularProgress size={16} color="inherit" />}
              >
                {translate('send')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="secondary">
          {translate('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalValidatePhone;
