import { LIST_BALANCES, LIST_BALANCES_LOADING, LIST_TRANSACTIONS_BALANCES, LIST_TRANSACTIONS_BALANCES_LOADING } from './constants';

const balancesReducer = (state, action) => {
  switch (action.type) {
    case LIST_BALANCES:
      return {
        ...state,
        list: action.list
      }
    case LIST_BALANCES_LOADING:
      return {
        ...state,
        loadingBalances: action.loadingBalances
      }
    case LIST_TRANSACTIONS_BALANCES:
      return {
        ...state,
        listTransactions: action.listTransactions
      }
    case LIST_TRANSACTIONS_BALANCES_LOADING:
      return {
        ...state,
        loadingTransactions: action.loadingTransactions
      }
    default:
      return state
  }
}

export default balancesReducer;