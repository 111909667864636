import React, { useContext, useState } from 'react';
import { CircularProgress, Grid, Link, Typography } from '@material-ui/core';
import ModalUploadFile from 'components/Modals/ModalUploadFile';
import LinkIcon from '@material-ui/icons/Link';
import ButtonFile from './ButtonFile';
import {
  getCompanyRegister,
  updateDocuments,
} from 'context/settings/companyRegister/actions';
import { useStateValue } from 'context/store';
import { I18nContext } from 'translations';
import { getFileNameFromAWSUrlKey, modalMessage } from 'utils';
import { uploadFile } from 'utils/aws';

const ButtonUploadFileModal = ({
  buttonText,
  size = 'small',
  onChange,
  buttonColor,
  value,
  titleText,
  error,
  errorText,
  disabled,
  type_cd,
  helperText,
  customValidationForHandleUpload
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { translate } = useContext(I18nContext);

  const [
    {
      checkin: { checkin },
    },
    dispatch,
  ] = useStateValue();

  const updateFile = async (file) => {
    return await updateDocuments(dispatch, checkin?.company._id, file);
  };

  const messageOk = () => {
    modalMessage(
      false,
      translate('app.companyRegister.updateFileSuccess'),
      'info',
      translate('close'),
      true
    ).then(() => {
      getCompanyRegister(dispatch, checkin?.company._id);
    });
  };

  const uploadFileAws = async (file) => {
    return await uploadFile(file, Date.parse(new Date()));
  };

  const handleFileUpdate = async (file) => {
    setLoading(true);
    const AWSfile = await uploadFileAws(file[0]);
    const companyFiles = {};
    companyFiles[getFileNameFromAWSUrlKey(AWSfile.key)] = AWSfile.Location;
    const status = await updateFile(companyFiles);
    setLoading(false);
    status && messageOk();
  };

  const handleFile = async (file) => {
    if (file.length > 0) {
      const url = URL.createObjectURL(file[0]);
      setOpen(false);
      customValidationForHandleUpload
        ? handleFileUpdate(file)
        : onChange(file[0], url);
    }
  };

  return (
    <>
      <ButtonFile
        fullWidth
        disabled={disabled}
        color={buttonColor}
        size={size}
        error={error}
        onClick={() => setOpen(true)}
        icon={loading && <CircularProgress size={18} />}
      >
        {buttonText}
      </ButtonFile>
      <Grid container alignItems="center">
        {value && (
          <Link
            href={value}
            target="_blank"
            rel="noreferrer"
            sx={{ display: 'grid', placeItems: 'center' }}
          >
            <LinkIcon />
          </Link>
        )}
        {helperText ? (
          <Typography variant="caption" gutterBottom>
            {helperText}
          </Typography>
        ) : (
          error && (
            <Typography variant="body2" gutterBottom color="error">
              {errorText}
            </Typography>
          )
        )}
      </Grid>
      {open && (
        <ModalUploadFile
          open={open}
          onChange={handleFile}
          handleClose={() => setOpen(false)}
          titleText={titleText}
          buttonText={buttonText}
          dialogTitle={titleText}
          type_cd={type_cd}
        />
      )}
    </>
  );
};

export default ButtonUploadFileModal;
