import React, { useState, useContext } from 'react';
import { I18nContext } from 'translations';
import { Button } from '@material-ui/core';
import MercadoFlexStoreConfigurationModal from './MercadoFlexStoreConfigurationModal';

const MercadoFlexStoreConfiguration = ({ store }) => {
  const { translate } = useContext(I18nContext);
  const [open, setOpen] = useState(false);
  return (
    <>
      <MercadoFlexStoreConfigurationModal
        open={open}
        setOpen={() => setOpen(false)}
        store={store}
      />
      <Button
        color="secondary"
        size="small"
        onClick={() => setOpen(true)}
      >
        {translate('app.integrations.table.config')}
      </Button>
    </>
  );
};

export default MercadoFlexStoreConfiguration;
