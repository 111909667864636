import React, { useContext } from 'react';
import { Grid, Typography, Container, List, ListItem, ListItemText } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { I18nContext } from 'translations';

const styles = createStyles(() => ({
  cargo: {
    padding: '50px 20px',
    backgroundColor: '#fff',
    minHeight: '290px',
    display: 'flex',
    alignItems: 'center'
  },
  video: {
    maxWidth: '100%'
  }
}));

const LinkAccountsVtex = ({ classes }) => {
  const { translate } = useContext(I18nContext);

  return (
    <section className={classes.cargo}>
      <Container maxWidth="md" className="container-width-lg">
        <Typography variant="h4" color="textPrimary" className="text-center">
          {translate('landing.vtex.link.title')}
        </Typography><br />
        <Typography variant="subtitle1">
          {translate('landing.vtex.link.subtitle')}:
        </Typography>
        <Typography variant="body1">
          {translate('landing.vtex.link.description')}
        </Typography>
        <br />
        <Grid container justify="center" alignItems="center" spacing={4}>
          <Grid item sm={6} xs={12}>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                  <span><b>1. {translate('landing.vtex.link.step1')}:</b> {translate('landing.vtex.link.step1Sub')}.</span>
                }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`2. ${translate('landing.vtex.link.step2')}.`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`3. ${translate('landing.vtex.link.step3')}.`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`4. ${translate('landing.vtex.link.step4')}.`}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item sm={6} xs={12}>
            <img src="/images/landing/vtex/active-vtex.png" alt="Vtex-pibox" style={{ width: '100%' }} />
          </Grid>
        </Grid>
        <br />
        <Typography variant="h5">
          {translate('landing.vtex.link.ready.title1')}
        </Typography><br />
        <Typography variant="body1">
          - {translate('landing.vtex.link.ready.item1')}. <br />
          - {translate('landing.vtex.link.ready.item2')}
        </Typography><br />
      </Container>
    </section>
  )
}

export default withStyles(styles)(LinkAccountsVtex);