import React, { useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

function City ({ cities, setCity, resetForm, city, setExpanded }) {

  useEffect(() => {
    setCity(cities[0])
  }, []) // eslint-disable-line
  
  return (
    <Autocomplete
      id="cities"
      options={cities.length > 0
        ? cities
        : [{ id: 'none', name: 'No hay ciudades' }]}
      autoHighlight
      getOptionLabel={(option) => option.name}
      renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
      value={city ? city : cities[0]}
      onChange={(event, newValue) => {
        event.stopPropagation();
        setExpanded('resetCity');
        setCity(newValue);
        resetForm();
      }}
      className="marginBottom10"
    />
  )
}

function areEqual(prevProps, nextProps) {
  return  prevProps.city === nextProps.city
  && prevProps.cities === nextProps.cities
}

export default React.memo(City, areEqual);