import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { I18nContext } from 'translations';
import { INITIAL_PAGE } from 'utils/constants';

export default function PrepackagesTableGroupsFilters({
	user_id,
	setUser_id,
	myPrepackages,
  getPrepackages
}) {
	const { translate } = useContext(I18nContext);
	
	return (
		<div style={{ margin: '7px 0 -18px' }}>
			<Button
				color={user_id ? 'default' : 'primary'}
				size="medium"
				style={{
					borderBottom: '2px solid',
					borderRadius: 0,
					marginRight: 15,
					borderColor: user_id ? 'gray' : '#7825bd'
				}}
				onClick={() => {
          setUser_id(null);
          getPrepackages(INITIAL_PAGE, null);
        }}
			>
				{translate('app.bookings.filter.allTheServices')}
			</Button>
			<Button
				color={user_id ? 'primary' : 'default'}
				size="medium"
				style={{ 
					borderBottom: '2px solid',
					borderRadius: 0,
					marginRight: 15,
					borderColor: user_id ? '#7825bd' : 'gray'
				}}
				onClick={myPrepackages}
			>
				{translate('app.bookings.filter.myServices')}
			</Button>
		</div>
	)
}