import React, { useContext, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useStateValue } from 'context/store';
import { I18nContext } from 'translations';

const CityAutoComplete = ({ onChange, value }) => {
  const { translate } = useContext(I18nContext);
  const [
    {
      checkin: { configuration },
      commons: { loading },
    },
  ] = useStateValue();

  useEffect(() => {
    if (configuration?.cities_for_delivery) {
      const cites = configuration?.cities_for_delivery;
      cites.push({
        _id: 'noService',
        name: translate('app.sales.filter.noService'),
        country_id: 'noService',
      });
    }
  }, [configuration?.cities_for_delivery]); // eslint-disable-line

  return (
    <Autocomplete
      fullWidth
      size="small"
      id="cities"
      loading={loading}
      options={configuration?.cities_for_delivery}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={translate('app.sales.filter.city')}
        />
      )}
      value={value}
      onChange={(event, newValue) => {
        event.stopPropagation();
        onChange(newValue);
      }}
    />
  );
};

export default CityAutoComplete;
