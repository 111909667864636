import React, { useContext, useEffect, useCallback, useState, useRef } from 'react';
import {
  Grid,
  Button,
  Paper,
  Dialog,
  Snackbar,
  TextField,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
  CircularProgress
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import { yupResolver } from '@hookform/resolvers';
import { I18nContext } from 'translations';
import { uploadFile } from 'utils/aws';
import { useStateValue } from 'context/store';
import { showPqr, answerPqr } from 'context/claims/actions';
import { message as closeMessage } from 'context/commons/actions';
import UploadNormalFile from 'components/CustomInputs/UploadNormalFile';
import SendIcon from '@material-ui/icons/Send';
import styles from '../../Tables/styleTableFilters';
import schema from './schemaClaimsTracking';

const defaultValues = {
  mssg: '',
  document: null
}

function ModalClaimsTracking ({
  open,
  role,
  openClaim,
  id
}) {
  const { translate } = useContext(I18nContext);
  const [loading, setLoading] = useState(false);
  const [loadingPqr, setLoadingPqr] = useState(false);
  const [messageImage, setMessageImage] = useState(false);
  const divRef = useRef(null);
  const [
    {
      commons: { message },
      claims: { pqr }
    },
    dispatch,
  ] = useStateValue();

  const { handleSubmit, control, errors, reset, register, watch } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema)
  });

  const loadedPqr = () => {
    setLoadingPqr(false)
    const scrollHeight = divRef.current.scrollHeight;
    const height = divRef.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    divRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  }

  const getPqr = useCallback(
    async () => {
      setLoadingPqr(true);
      const callback = await showPqr(dispatch, id);
      callback.status ? loadedPqr() : setLoadingPqr(false)
    },
    [dispatch, id]
  );

  useEffect(() => {
    getPqr()
  }, [getPqr])

  const mssgSend = async () => {
    setLoading(false);
    await showPqr(dispatch, id)
    reset(defaultValues);
    loadedPqr();
  }

  const uploadFileAws = async (file) => {
    const date = Date.parse(new Date());
    if (file?.size <= 5000000) {
      return await uploadFile(file, `pqr/${date}`);
    } else  {
      return true
    }
  };

  const submit = async values => {
    setLoading(true)
    const responseAttach = values?.document && values.document[0] ? await uploadFileAws(values.document[0]) : null
    const data = {
      ...values,
      document: responseAttach?.Location
    }
    if (responseAttach === true) {
      setMessageImage('claims.errorSizeFile')
      setLoading(false)
    } else {
      setMessageImage(null)
      const callback = await answerPqr(dispatch, id, { pqr: { ...data } })
      callback?.status ? mssgSend() : setLoading(false);
      reset(defaultValues)
    }
  }

  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
      style={{ padding: '10px 40px 20px' }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">
          <b>{translate('claims.claim')}</b>
          <b>{translate('claims.ticketId')}: {pqr?.id}</b>
        </Typography>
        <IconButton aria-label="close"  onClick={openClaim} style={{ position: 'absolute', right: 0, top: 0 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {loadingPqr ? <div className="text-center"><CircularProgress color="primary" /></div> :
        <Grid container>
          <Grid item xs={12}>
            <Paper
              variant="outlined"
              ref={divRef}
              style={{
                padding: '17px 17px 25px', borderRadius: 10, marginBottom: 15, maxHeight: 'calc(100vh - 420px)', minHeight: '150px', overflowY: 'auto'
              }}
            >
              {pqr?.messages?.length > 0 ? pqr.messages.map((mssg, index) => (
                <div key={`mssg-${index}`}>
                  <Typography color="primary" variant="subtitle2">
                    {mssg?.passenger_name}
                  </Typography>
                  <Typography style={{ paddingLeft: '10px' }}>
                    {mssg?.mssg && mssg?.mssg}
                    {mssg?.document && <Button color="primary" href={mssg?.document} target="_blank" style={{ display: 'block', width: '100%' }}>Ver</Button>}
                  </Typography>
                </div>
              ))
              : translate('claims.noMessages')}
            </Paper>
            {((pqr?.status_cd !== 4) && (role?.length > 0)) &&
              <Paper variant="outlined" style={{ padding: '17px 17px 25px', borderRadius: 10, marginBottom: 15 }}>
                <Typography style={{ marginBottom: 15, textTransform: 'uppercase' }}>{translate('claims.answerTo')} {translate('claims.claimsCoordinator')}</Typography>

                <form onSubmit={handleSubmit(submit)} noValidate>
                  <Grid container>
                    <Grid item xs={12} style={{ marginBottom: 10 }}>
                      <Controller
                        name="mssg"
                        control={control}
                        variant="outlined"
                        render={({ onChange, value }) => (
                          <TextField
                            size="small"
                            variant="outlined"
                            label={translate('claims.form.writeAResponse')}
                            fullWidth
                            value={value}
                            onChange={onChange}
                            multiline
                            rows={3}
                            error={!!errors.mssg}
                            helperText={errors.mssg && translate(errors.mssg.message)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <UploadNormalFile
                        text={translate('claims.form.attachFile')}
                        register={register}
                        fileName="document"
                        nameFile={watch('document')}
                        accept="image/*,.pdf"
                        helpText={translate('claims.formatFile')}
                      />
                    </Grid>
                    <Grid item md={7} xs={12} className="text-right" >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ borderRadius: '17px' }}
                        disabled={loading}
                        endIcon={loading ? <CircularProgress color="inherit" size="1rem" /> : <SendIcon color="inherit" size="1rem" />}
                      >
                        {translate('send')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            }
          </Grid>
        </Grid>
        }
      </DialogContent>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!messageImage}
        autoHideDuration={6000}
        onClose={() => setMessageImage(null)}
        message={translate(messageImage)}
      />
    </Dialog>
  )
}

export default withStyles(styles)(ModalClaimsTracking);
