import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  Link
} from '@material-ui/core';
import { format } from 'date-fns';
import { I18nContext } from 'translations';
import { formatCost } from 'utils';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import EmptyData from '../../EmptyData';
import styles from '../../style';

const PicashTable = ({
  classes,
  data,
  recordsPerPage,
  getPicash
}) => {
  const [currentPage, setCurrentPage] = useState(data.page);
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    setCurrentPage(data.page)
  }, [data])

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage)
    getPicash(newPage);
  }

  if (!data || (data && data.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />
  }

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell>{translate('app.payments.table.description')}</TableCell>
              <TableCell>{translate('app.payments.table.date')}</TableCell>
              <TableCell>{translate('app.payments.table.balance')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data.data || []).map(picash => (
              <TableRow hover key={picash._id}>
                <TableCell>
                  {picash.booking_id
                    ? <Link
                      href={`/bookings/${picash.booking_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      color="primary"
                      underline="hover"
                    >
                      <b>{picash.description}</b>
                      <span style={{ color: 'rgba(120,37, 189, 0.9)' }}> -  {picash.booking_id}</span>
                    </Link>
                    : <b style={{ color: '#7825bd' }}>{picash.description}</b>
                  }
                </TableCell>
                <TableCell>
                  <span className={classes.date}>
                    {format(new Date(picash.created_at), 'dd/MM/yyyy, hh:mm a')}
                  </span>
                </TableCell>
                <TableCell>
                  {picash.amount && picash.amount.sub_units > 0
                    ? <b className="text-green"><ArrowUpwardIcon className="icon-up-down" /> {formatCost(picash.amount)}</b>
                    : <b className="text-red"><ArrowDownwardIcon className="icon-up-down" />{formatCost(picash.amount)}</b>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
    </>
  );
}

function areEqual(prevProps, nextProps) {
  return prevProps.data === nextProps.data;
}

export default withStyles(styles)(React.memo(PicashTable, areEqual));

