import ShopifyIntegration from 'models/integrations/ShopifyIntegration';
import MercadoFlexIntegration from 'models/integrations/MercadoFlex';
import WooCommerceIntegration from 'models/integrations/WooCommerceIntegration';
import Integration from 'models/integrations/Integration';
import VtexIntegration from 'models/integrations/VtexIntegration';

const IntegrationsAdapter = (integration) => {
  const IntegrationsClasses = {
    mercadoflex: new MercadoFlexIntegration(integration),
    shopify: new ShopifyIntegration(integration),
    woocommerce: new WooCommerceIntegration(integration),
    vtex: new VtexIntegration(integration),
  };
  return (
    IntegrationsClasses[integration.integrator_id] ||
    new Integration(integration)
  );
};

export default IntegrationsAdapter;
