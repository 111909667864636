import { string, object } from 'yup';

const schema = object().shape({
  bank_id: string().required('requiredField'),
  bank_account_type_id: string().required('requiredField'),
  bank_fiscal_number_type_id: string().required('requiredField'),
  number: string().required('requiredField').matches(/^[0-9]+$/, 'onlyNumbers'),
  fiscal_number: string().required('requiredField').matches(/^[0-9]+$/, 'onlyNumbers'),
  name: string().required('requiredField'),
  last_name: string().required('requiredField')
});

export default schema;