import React, { useContext, useEffect, useCallback, useState } from 'react';
import {
  Grid,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  List,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useStateValue } from 'context/store';
import { getGroupUserList } from 'context/settings/groups/actions';
import { I18nContext } from 'translations';
import { INITIAL_PAGE } from 'utils/constants';
import UsersTable from 'components/Tables/Settings/Users';
import styles from '../Groups/GroupsStyle';
import Loading from 'components/Loading';

function ModalUserList ({
  open,
  setOpen,
  classes,
  idGroup,
  idCompany,
  setIdGroup
}) {
  const { translate } = useContext(I18nContext);
  const [per_page] = useState(6);
  const [{ groups: { groupUserList, loadingGroupUserList }}, dispatch] = useStateValue();

  const getUserList = useCallback(async (page) => {
    idGroup && getGroupUserList(dispatch, { area_id: idGroup, page, per_page }, idCompany)
  }, [dispatch, per_page, idGroup, idCompany]);

  useEffect(() => {
    idCompany && getUserList(INITIAL_PAGE);
  }, [getUserList, idCompany]);

  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="text-center" disableTypography>
        <Typography variant="h5" color="primary">
          {translate('app.settings.groups.groupUsers')}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <Grid container spacing={2} style={{ marginBottom: '5px' }}>
          <Grid item md={12} xs={12}>
            {loadingGroupUserList ? <Loading /> :
              <List className={classes.listContent}>
                {groupUserList &&
                  <UsersTable
                    data={groupUserList}
                    getUsers={getUserList}
                    recordsPerPage={per_page}
                    usersPerGroup={true}
                  />
                }
              </List>
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setIdGroup(null);
          setOpen(false);
        }}>
          {translate('close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(ModalUserList);