import React, { useContext } from 'react';
import { Grid, Typography, Container, List, ListItem, ListItemText } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { I18nContext } from 'translations';

const styles = createStyles(() => ({
  cargo: {
    padding: '50px 20px',
    backgroundColor: '#f5f5f5',
    minHeight: '290px',
    display: 'flex',
    alignItems: 'center'
  },
  video: {
    maxWidth: '100%'
  }
}));

const UseVtex = ({ classes }) => {
  const { translate } = useContext(I18nContext);

  return (
    <section className={classes.cargo}>
      <Container maxWidth="md" className="container-width-lg">
        <Grid container justify="center" alignItems="center" spacing={4}>
          <Grid item sm={12}>
            <Typography variant="h4" color="textPrimary">
              {translate('landing.vtex.howToUseVtex')}
            </Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                  <span>{`1. ${translate('landing.vtex.step1')}`}</span>
                }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`2. ${translate('landing.vtex.step2')}`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`3. ${translate('landing.vtex.step3')}`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`4. ${translate('landing.vtex.step4')}`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`5. ${translate('landing.vtex.step5')}`}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item sm={6} xs={12}>
            <img src="/images/integrations/Step2.png" alt="vtex-pibox" style={{ width: '100%' }} />
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

export default withStyles(styles)(UseVtex);