import { object, number, string } from 'yup';

const schema = object().shape({
  order_synchronization_days: number()
    .integer('app.integration.settingStore.form.mustBeAnInteger')
    .typeError('app.integration.settingStore.form.mustBeNumber')
    .min(1, 'app.integration.settingStore.form.cannotBeLessThanOne')
    .max(60, 'app.integration.settingStore.form.cannotBeGreaterThanSixty'),
  store_name: string()
    .matches(/^\S*$/, 'canNotHaveSpacesMessage')
    .required('requiredField')
    // .url('invalidUrl'),
});

export default schema;
