import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Tabs, Tab, Paper, Box, Snackbar } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { listActiveServicesOperations } from 'context/operations/services/actions';
import Title from 'components/Titles';
import ServicesOperationsTable from 'components/Tables/Operations/Services';
import ServicesTableFilters from 'components/Tables/Operations/Services/Filters/ServicesTableFilters';
import { INITIAL_PAGE, PER_PAGE } from 'utils/constants';

export default function ServicesOperations() {
  const { translate } = useContext(I18nContext);
  const [value, setValue] = useState(0);
  const [filters, setFilters] = useState(null);
  const [per_page] = useState(PER_PAGE); 
  const [{
    //servicesOperations: { list },
    commons: { message } },
    dispatch
  ] = useStateValue();

  const onFilters = params => setFilters(params);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getServices = useCallback(async (page) => {
    listActiveServicesOperations(dispatch, { page, per_page, ...filters })
  }, [filters, dispatch, per_page]);

  useEffect(() => {
    getServices(INITIAL_PAGE);
  }, [filters, getServices])

  return(
    <div className="padding-page-full-height">
      <Title
        title={translate('app.operations.services')}
        icon={<ListAltIcon className="align-center" style={{ marginRight: 5 }} />}
      />
      <>
        <Paper style={{ marginTop: 10 }}>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
          >
            <Tab label={translate('app.operations.services.active')} />
            <Tab label={translate('app.operations.services.record')} />
          </Tabs>
        </Paper>
        <TabPanel value={value} index={0}>
          <div className="marginFiltersTable" style={{ marginTop: 0 }}>
            <ServicesTableFilters
              onFilters={onFilters}
            />
          </div>
          <ServicesOperationsTable 
            data={[]}
          />
        </TabPanel>
      </>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </div>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1} mt={2}>
          {children}
        </Box>
      )}
    </div>
  );
}