import { deliverySaleWoocommerceStatusClass } from 'utils';
import { DELIVERY_SALE_WOOCOMMERCE_STATUS } from 'utils/constants';
import Sale from './Sale';

class WooCommerceSale extends Sale {
  constructor(sale) {// eslint-disable-line
    super(sale);
  }

  getSaleClass() {
    return deliverySaleWoocommerceStatusClass(this.fulfillment_status);
  }

  getSaleStatus() {
    return DELIVERY_SALE_WOOCOMMERCE_STATUS[this.fulfillment_status];
  }
  isReadyToDispatch() {
    return (
      this.destination?.lat === null ||
      this.destination?.address === null ||
      this.destination?.lon === null ||
      this.order?.city_id === null || 
      this.fulfillment_status === null ||
      ![null, undefined].includes(this?.booking_id)
    );
  }
}

export default WooCommerceSale;
