import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  borderLeft: {
    [theme.breakpoints.up('sm')]: {
      borderLeft: '1px solid lightgray'
    }
  },
  links: {
    textDecoration: 'underline',
    color: theme.palette.primary.main
  },
  avatar: {
    width: '55px',
    height: '55px',
    margin: 'auto'
  },
  avatarLarge: {
    width: '80px',
    height: '80px',
    margin: 'auto'
  }
}));

export default styles;