import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { I18nContext } from 'translations';

export default function PromoCodesTableGroupsFilters({
  onFilters,
  filters
}) {
  const { translate } = useContext(I18nContext);

  return (
    <div style={{ margin: '7px 0 -18px' }}>
      <Button
        color={filters === null || filters === undefined ? 'primary' : 'default'}
        size="medium"
        style={{
          borderBottom: '2px solid',
          borderRadius: 0,
          marginRight: 15,
          borderColor: filters === null || filters === undefined ? '#7825bd' : 'gray',
        }}
        onClick={() => onFilters()}
      >
        {translate('promoCode.filter.codesCreated')}
      </Button>
      <Button
        color={filters?.status_cd === 1 ? 'primary' : 'default'}
        size="medium"
        style={{
          borderBottom: '2px solid',
          borderRadius: 0,
          marginRight: 15,
          borderColor: filters?.status_cd === 1 ? '#7825bd' : 'gray',
        }}
        onClick={() => onFilters({
          ...filters,
          status_cd: 1
        })}
      >
        {translate('promoCode.filter.assigned')}
      </Button>
      <Button
        color={filters?.status_cd === 0 ? 'primary' : 'default'}
        size="medium"
        style={{
          borderBottom: '2px solid',
          borderRadius: 0,
          marginRight: 15,
          borderColor: filters?.status_cd === 0 ? '#7825bd' : 'gray',
        }}
        onClick={() => onFilters({
          ...filters,
          status_cd: 0
        })}
      >
        {translate('promoCode.filter.byAssigning')}
      </Button>
    </div>
  );
}
