import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  Snackbar,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { addCreditCard, validateCreditCard } from 'context/payments/actions';
import { formatPrice, modalMessage } from 'utils';
import { I18nContext } from 'translations';
import Cards from 'react-credit-cards';
import schema from './schema';
import SwipeableViews from 'react-swipeable-views/lib/SwipeableViews';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { COUNTRY_CODE, CREDIT_CARD_CENTURY } from 'utils/constants';
import ValidationModal from './ValidationModal';

export default function ModalCreditCard({
  open,
  setOpen,
  getCreditCards,
  creditCardSelected,
}) {
  const { langCode, translate } = useContext(I18nContext);
  const [focused, setFocused] = useState('number');
  const [openValidationModal, setOpenValidationModal] = useState(false);
  const [cardId, setCardId] = useState(null);
  const { handleSubmit, errors, control, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      'cc-num': '',
      'cc-name': '',
      'cc-exp': '',
      'cc-csc': '',
      fiscal_number: '',
    },
  });

  const [index, setIndex] = useState(0);

  const [
    {
      commons: { loading, message },
    },
    dispatch,
  ] = useStateValue();

  const watchCardNumber = watch('cc-num', '');
  const watchCardName = watch('cc-name', '');
  const watchCardExpiration = watch('cc-exp', '');
  const watchCvc = watch('cc-csc', '');
  const watchFiscalNumber = watch('fiscal_number', '');

  const messageOk = () => {
    modalMessage(
      translate('congratulations'),
      translate('app.payments.creditCard.creditCardAddedSuccessfully'),
      'success',
      translate('accept'),
      true
    ).then(() => {
      getCreditCards();
      setOpen(false);
    });
  };

  const submit = async (values) => {
    const expirySplited = values['cc-exp'].split('/');

    const payload = {
      number: values['cc-num'],
      name: values['cc-name'],
      exp_month: expirySplited[0],
      exp_year: `${CREDIT_CARD_CENTURY}${expirySplited[1]}`,
      cvc: values['cc-csc'],
      fiscal_number: values.fiscal_number,
    };

    const callback = await addCreditCard(
      dispatch,
      {
        card: { ...payload },
      },
      langCode
    );
    if (callback.status) {
      setCardId(callback.cardId);
      setOpenValidationModal(true);
    }
  };

  const submitValidation = async (validation_amount) => {
    const amount = {
      sub_units: formatPrice({
        sub_units: validation_amount,
        iso: COUNTRY_CODE[langCode][4],
      }),
      currency: COUNTRY_CODE[langCode][4],
    };

    const card = {
      id: cardId,
      amount,
    };

    const callback = await validateCreditCard(dispatch, card);
    callback.status && messageOk();
  };

  const evalIfNumber = (value) => {
    const regex = /^[0-9\b]+$/;
    if (value === '' || regex.test(value)) {
      return value;
    }
  };

  function clearNumber(value = '') {
    return value.replace(/\D+/g, '');
  }

  function formatExpirationDate(value) {
    const clearValue = clearNumber(value);
    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }
    return clearValue;
  }

  const handleInputFocus = (name) => setFocused(name);

  const handleNumericChange = (e, onChange) => {
    const number = evalIfNumber(e.target.value);
    (number || number === '') && onChange(number);
  };

  const PAGINATION_DIFF = 1;

  useEffect(() => {
    if (watchCardNumber?.split('').length === 16) {
      setIndex(1);
    }
  }, [watchCardNumber]);

  useEffect(() => {
    if (watchCardExpiration?.split('').length === 5) {
      setIndex(2);
    }
  }, [watchCardExpiration]);

  useEffect(() => {
    if (watchCvc?.split('').length === 3) {
      setIndex(3);
    }
  }, [watchCvc]);

  const focusInput = (id) =>
    setTimeout(document.getElementById(id)?.focus(), 1000);

  useEffect(() => {
    index === 0 && focusInput('cc-num');
    index === 1 && focusInput('cc-exp');
    index === 2 && focusInput('cc-csc');
    index === 3 && focusInput('cc-name');
    index === 4 && focusInput('fiscal_number');
  }, [index]);

  return (
    <>
      {openValidationModal ? (
        <ValidationModal
          open={openValidationModal}
          handleSumbit={submitValidation}
          handleClose={() => setOpenValidationModal(false)}
          isLoading={loading}
        />
      ) : (
        <Dialog
          open={open}
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle
            className="text-center"
            disableTypography
            style={{ paddingTop: '1rem' }}
          >
            <Typography variant="h5" color="primary">
              {translate('app.payments.creditCard.addCreditCard')}
            </Typography>
          </DialogTitle>

          <DialogContent className="text-center">
            <Cards
              name={watchCardName}
              number={watchCardNumber}
              cvc={watchCvc}
              expiry={watchCardExpiration}
              placeholders={{
                name: '',
                number: '',
                cvc: '',
                expiry: '',
              }}
              focused={focused}
              locale={{
                valid: 'Expira',
              }}
            />
            <form
              onSubmit={handleSubmit(submit)}
              noValidate
              style={{ margin: '1rem 0' }}
            >
              <Grid
                container
                justify={index > 0 ? 'space-between' : 'flex-end'}
              >
                {index > 0 && (
                  <Grid item xs={6}>
                    <IconButton
                      onClick={() => setIndex(index - PAGINATION_DIFF)}
                      disabled={loading}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </Grid>
                )}
                {index < 4 && (
                  <Grid item xs={6}>
                    <IconButton
                      onClick={() => setIndex(index + PAGINATION_DIFF)}
                      disabled={loading}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>

              <SwipeableViews index={index}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ padding: '2rem 1rem' }}
                >
                  <Controller
                    control={control}
                    name="cc-num"
                    defaultValue={
                      creditCardSelected ? creditCardSelected.number : ''
                    }
                    render={({ onChange, value }) => (
                      <TextField
                        name="cc-num"
                        id="cc-num"
                        autoComplete="cc-num"
                        inputProps={{
                          maxLength: 16,
                          onFocus: () => handleInputFocus('number'),
                        }}
                        onFocus={handleInputFocus}
                        onChange={(e) => handleNumericChange(e, onChange)}
                        value={value}
                        label={translate(
                          'app.payments.creditCard.accountNumber'
                        )}
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        error={!!errors['cc-num']}
                        helperText={
                          errors['cc-num'] &&
                          translate(errors['cc-num'].message)
                        }
                        type="text"
                      />
                    )}
                    cc-num
                  />
                </Grid>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ padding: '2rem 1rem' }}
                >
                  <Controller
                    control={control}
                    name="cc-exp"
                    defaultValue={
                      creditCardSelected ? creditCardSelected.expiry : ''
                    }
                    render={({ onChange, value }) => (
                      <TextField
                        name="cc-exp"
                        id="cc-exp"
                        autoComplete="cc-exp"
                        onChange={(e) => {
                          const number = formatExpirationDate(e.target.value);
                          (number || number === '') && onChange(number);
                        }}
                        value={value}
                        label={translate('app.payments.creditCard.expiry')}
                        inputProps={{
                          maxLength: 5,
                          onFocus: () => handleInputFocus('expiry'),
                        }}
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        error={!!errors['cc-exp']}
                        helperText={
                          errors['cc-exp'] &&
                          translate(errors['cc-exp'].message)
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ padding: '2rem 1rem' }}
                >
                  <Controller
                    control={control}
                    name="cc-csc"
                    defaultValue={
                      creditCardSelected ? creditCardSelected['cc-csc'] : ''
                    }
                    render={({ onChange, value }) => (
                      <TextField
                        name="cc-csc"
                        id="cc-csc"
                        onChange={(e) => handleNumericChange(e, onChange)}
                        inputProps={{
                          onFocus: () => handleInputFocus('cvc'),
                          maxLength: 3,
                        }}
                        value={value}
                        label={translate('app.payments.creditCard.cvc')}
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        error={!!errors['cc-csc']}
                        helperText={
                          errors['cc-csc'] &&
                          translate(errors['cc-csc'].message)
                        }
                        type="text"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ padding: '2rem 1rem' }}
                >
                  <Controller
                    name="cc-name"
                    sx={{ width: '100%' }}
                    control={control}
                    defaultValue={
                      creditCardSelected ? creditCardSelected.name : ''
                    }
                    render={({ onChange, value }) => (
                      <TextField
                        name="cc-name"
                        id="cc-name"
                        autoComplete="cc-name"
                        onChange={(e) =>
                          e.target.value.split('').length < 27 && onChange(e)
                        }
                        inputProps={{
                          onFocus: () => handleInputFocus('name'),
                          pattern: '[a-z][A-Z]{0,27}',
                        }}
                        value={value}
                        label={translate('app.payments.bankAccounts.name')}
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        error={!!errors['cc-name']}
                        helperText={
                          errors['cc-name'] &&
                          translate(errors['cc-name'].message)
                        }
                        type="text"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ padding: '2rem 1rem' }}
                >
                  <Controller
                    sx={{ width: '100%' }}
                    control={control}
                    name="fiscal_number"
                    defaultValue={''}
                    render={({ onChange, value }) => (
                      <TextField
                        name="fiscal_number"
                        id="fiscal_number"
                        onChange={(e) => handleNumericChange(e, onChange)}
                        value={value}
                        label={translate(
                          'app.payments.bankAccounts.fiscalNumber'
                        )}
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        error={!!errors.fiscal_number}
                        helperText={
                          errors.fiscal_number &&
                          translate(errors.fiscal_number.message)
                        }
                        type="text"
                      />
                    )}
                  />
                </Grid>
              </SwipeableViews>

              <Grid container justify="flex-end">
                {index === 4 && (
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading || !watchFiscalNumber}
                    color="primary"
                    startIcon={loading && <CircularProgress size={18} />}
                  >
                    {translate('send')}
                  </Button>
                )}
                <Button onClick={() => setOpen(false)} disabled={loading}>
                  {translate('close')}
                </Button>
              </Grid>
            </form>
          </DialogContent>
          <Snackbar
            style={{ zIndex: 99999 }}
            open={!!message}
            autoHideDuration={6000}
            onClose={() => closeMessage(dispatch, null)}
            message={translate(message)}
          />
        </Dialog>
      )}
    </>
  );
}
