import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Container, Typography, Button, Snackbar } from '@material-ui/core';
import { I18nContext } from 'translations';
import { INITIAL_PAGE, PER_PAGE } from 'utils/constants';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { getListGroups } from 'context/settings/groups/actions';
import Loading from 'components/Loading';
import GroupsTable from 'components/Tables/Settings/Groups';
import ModalGroups from 'components/Modals/Settings/Groups';
import ModalUserList from 'components/Modals/Settings/UserList';

export default function Groups() {
  const [open, setOpen] = useState(false);
  const [group, setGroup] = useState(null);
  const [idGroup, setIdGroup] = useState(null);
  const [per_page] = useState(PER_PAGE);
  const [openUsers, setOpenUsers] = useState(false);
  const { translate } = useContext(I18nContext);
  const [{ checkin: { company }, commons: { message }, groups: { listGroups, loadingGroups } }, dispatch] = useStateValue();

  const editGroup = (group) => {
    setOpen(true);
    setGroup(group);
  }

  const userList = (id) => {
    setOpenUsers(true);
    setIdGroup(id);
  }

  const getGroups = useCallback(async (page) => {
    const { _id } = company;
    getListGroups(dispatch, _id, { page, per_page })
  }, [dispatch, per_page, company]);

  useEffect(() => {
    company && getGroups(INITIAL_PAGE);
  }, [getGroups, company]);

  return (
    <>
      <Typography variant="h5" color="primary">
        {translate('app.settings.groups')}
      </Typography>
      <div className="text-center">
        <ModalGroups
          open={open}
          setOpen={setOpen}
          idCompany={company && company._id}
          getGroups={getGroups}
          group={group}
          setGroup={setGroup}
        />
        <Container maxWidth="lg" className="padding0-xs">
          <div className="text-right">
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={() => setOpen(true)}
            >
              {translate('app.settings.groups.createGroup')}
            </Button>
          </div>
          <br />
          {loadingGroups && <Loading />}
          {listGroups && (
            <GroupsTable
              editGroup={editGroup}
              data={listGroups}
              getGroups={getGroups}
              recordsPerPage={per_page}
              userList={userList}
            />
          )}
          <ModalUserList
            open={openUsers}
            setOpen={setOpenUsers}
            idCompany={company ? company._id : null}
            setIdGroup={setIdGroup}
            idGroup={idGroup}
          />
        </Container>
        <Snackbar
          style={{ zIndex: 99999 }}
          open={!!message}
          autoHideDuration={6000}
          onClose={() => closeMessage(dispatch, null)}
          message={translate(message)}
        />
      </div>
    </>
  )
}