import React, { useReducer, createContext } from 'react';

import EN from './en.json';
import ES from './es.json';
import PT from './pt.json';
import ES_MX from './es_mx.json';

const trasnlations = {
  en: EN,
  es: ES,
  pt: PT,
  es_mx: ES_MX,
  es_pe: ES,
  es_gt: ES,
  es_pyg: ES
};

const getTranslate = langCode => key => trasnlations[langCode][key] || key;

const langSave = localStorage.getItem('lang');

const initialState = {
  langCode: langSave || 'es',
  translate: langSave ? getTranslate(langSave) : getTranslate('es'),
};

export const I18nContext = createContext(initialState);

export const I18nContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_LANGUAGE':
        return {
          langCode: action.payload,
          translate: getTranslate(action.payload)
        };
      default:
        return { ...initialState };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <I18nContext.Provider value={{ ...state, dispatch }}>
      {children}
    </I18nContext.Provider>
  );
};