import React, { useContext, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination
} from '@material-ui/core';
import { I18nContext } from 'translations';
import { pqrStatusClass } from 'utils';
import { PQR_STATUSES } from 'utils/constants';
import { format } from 'date-fns';
import EmptyData from '../EmptyData';
import styles from '../style';

const ClaimsTable = ({
  data,
  classes,
  openClaim,
  getClaims,
  recordsPerPage
}) => {
  const [currentPage, setCurrentPage] = useState(data.page);
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    setCurrentPage(data.page);
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    getClaims(newPage);
  };

  if (!data || (data?.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />;
  }

  return (
    <>
      <TableContainer>
        <Table className={classes.tableStyle} size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{translate('claims.table.state')}</TableCell>
              <TableCell>{translate('claims.table.date')}</TableCell>
              <TableCell>{translate('claims.table.id')}</TableCell>
              <TableCell>{translate('claims.table.subject')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data?.data || []).map(claim => (
              <TableRow
                hover
                key={claim?.id}
              >
                <TableCell>
                  <span className={pqrStatusClass(claim?.status_cd)}>
                    {translate(PQR_STATUSES[claim?.status_cd])}
                  </span>
                </TableCell>
                <TableCell>
                  {format(new Date(claim?.created_at), 'dd/MM/yyyy, hh:mm a')}
                </TableCell>
                <TableCell>
                  <Button
                    color="primary"
                    onClick={() => openClaim(claim?.id)}
                  >
                    {claim?.id}
                  </Button>
                </TableCell>
                <TableCell>
                  {claim?.type_of_request}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.total ? data.total : data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
    </>
  );
};

function areEqual(prevProps, nextProps) {
  return prevProps.data === nextProps.data;
}

export default withStyles(styles)(React.memo(ClaimsTable, areEqual));
