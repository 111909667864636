import React, { useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useForm, Controller } from 'react-hook-form';
import { HOURS_DEDICATED } from 'utils/constants';
import { editHoursDedicated } from 'context/express/actions';
import { useStateValue } from 'context/store';
import { modalMessage } from 'utils';
import { INITIAL_PAGE } from 'utils/constants';

export default function ModalHoursDedicated({ handleModal, open, bookingSelected, getBookings }) {
  const { translate } = useContext(I18nContext)
  const [loading, setLoading] = useState(false)
  const [, dispatch] = useStateValue();

  const { handleSubmit, control } = useForm();

  const messageOk = (mssg) => {
    modalMessage('', mssg, 'success', translate('accept'), true).then(() => {
      setLoading(false);
      handleModal();
      getBookings(INITIAL_PAGE);
    })
  }

  const submit = async values => {
    setLoading(true)
    const callback = await editHoursDedicated(bookingSelected.bookingId, values, dispatch)
    callback.status ? messageOk(callback?.mssgOk) : setLoading(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleModal}
      maxWidth="xs"
      fullWidth
    >
      <Typography className="text-center" variant="h5" color="primary" style={{ padding: 20 }}>
        {translate('hoursDedicated.title')}
      </Typography>
      <DialogContent>
        <form onSubmit={handleSubmit(submit)} noValidate>
          <FormControl fullWidth className="text-left" variant="outlined" size="small" style={{ marginBottom: '17px' }}>
            <InputLabel>{translate('hoursDedicated.title')}</InputLabel>
            <Controller
              control={control}       
              name="hours_dedicated"
              defaultValue={bookingSelected?.hours ? bookingSelected.hours : 0}
              label={translate('hoursDedicated.title')}
              as={
                <Select>
                  {HOURS_DEDICATED.map(hours => (
                    <MenuItem key={hours} value={hours}>
                      {hours}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
          <div className="text-center">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={loading ? <CircularProgress size={18} /> : null}
              disabled={loading}
            >
              {translate('save')}
            </Button>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleModal}
        >
          {translate('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}