import React, { useContext, useEffect } from 'react';
import { Typography, FormControlLabel, Checkbox, FormControl } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import LoopIcon from '@material-ui/icons/Loop';
import { I18nContext } from 'translations';

const ReturnToOriginField = ({ register, numberMap, returnToOrigin, setReturnToOrigin }) => {
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    setReturnToOrigin(returnToOrigin)
  }, [returnToOrigin, setReturnToOrigin])

  return (
    <FormControl required style={{ flexDirection: 'row', alignItems: 'center' }}>
      {numberMap && (
        <Typography component="span">
          <span className="tag-rounded" style={{ marginRight: 10 }}>{numberMap}</span>
        </Typography>
      )}
      <FormControlLabel
        control={
          <Checkbox
            icon={<RadioButtonUncheckedIcon fontSize="small" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
            name="return_to_origin"
            inputRef={register}
            color="secondary"
          />
        }
        label={
          <Typography variant="subtitle2" color="textPrimary">
            <LoopIcon
              fontSize="small"
              style={{ verticalAlign: 'middle', marginRight: 5, fontSize: '1.3rem' }}
            />
            <span>
              {translate('app.express.shouldDriverReturnToTheOriginalRoute')}
            </span>
          </Typography>
        }
      />
    </FormControl>
  )
}

export default React.memo(ReturnToOriginField);