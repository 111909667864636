import {
  LIST_BOOKING,
  LIST_EVENTS,
  GET_AMOUNT_LEFT_TO_COLLECT_BY_COMPANY,
} from './constants';

const bookingReducer = (state, action) => {
  switch (action.type) {
    case LIST_BOOKING:
      return {
        ...state,
        list: action.list,
      };
    case LIST_EVENTS:
      return {
        ...state,
        events: action.events,
      };
    case GET_AMOUNT_LEFT_TO_COLLECT_BY_COMPANY:
      return {
        ...state,
        amountLeftToCollectByCompany: action.amountLeftToCollectByCompany,
      };
    default:
      return state;
  }
};

export default bookingReducer;
