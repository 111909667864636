import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { startOfDay, formatISO } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import { useForm, Controller } from 'react-hook-form';
import { I18nContext } from 'translations';
import { PREPACKAGES_STATUSES } from 'utils/constants';
import styles from '../../styleTableFilters';

const defaultValues = {
  ini_date: null,
  reference: '',
  status_cd: ''
};

const PrepackagesTableFilters = ({ classes, onFilters, filters }) => {
  const { translate } = useContext(I18nContext);
  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const onSubmit = (values) => {
    const params = {
      ...values,
      ini_date: values.ini_date && formatISO(startOfDay(values.ini_date))
    };
    Object.keys(params).forEach((key) => {
      if (params[key] === '' || params[key] == null) {
        delete params[key];
      }
    });
    onFilters(params);
  };

  const clearFilters = () => {
    reset(defaultValues);
    onFilters("");
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate className="relative">
      <Grid container spacing={2} alignItems="center">
        <Grid item md={1} sm={2} xs={12}>
          <Typography variant="subtitle1" style={{ color: 'black' }}>{translate('prepackages.filters.filterBy')}</Typography>
        </Grid>
        <Grid item md={2} sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Controller
              name="ini_date"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <KeyboardDatePicker
                  className={classes.filterStyle}
                  inputVariant="outlined"
                  label={translate('prepackages.filters.date')}
                  format="dd/MM/yyyy"
                  cancelLabel={translate('cancel')}
                  okLabel={translate('accept')}
                  maxDate={new Date()}
                  invalidDateMessage={translate('invalidFormat')}
                  fullWidth
                  value={value}
                  onChange={onChange}
                  size="small"
                />
              )}
            />
          </MuiPickersUtilsProvider> 
        </Grid>
        <Grid item md={2} sm={4} xs={6}>
          <Controller
            name="reference"
            control={control}
            variant="outlined"
            defaultValue=""
            render={({ onChange, value }) => (
              <TextField
                className={classes.filterStyle}
                variant="outlined"
                label={translate('prepackages.filters.orderNumber')}
                fullWidth
                value={value}
                onChange={onChange}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item md={2} sm={4} xs={6}>
          <FormControl
            variant="outlined"
            className={classes.filterStyle}
            fullWidth
            size="small"
          >
            <InputLabel>{translate('prepackages.filters.status')}</InputLabel>
            <Controller
              control={control}
              name="status_cd"
              label={translate('prepackages.filters.status')}
              variant="outlined"
              as={
                <Select>
                  <MenuItem value="">{translate('app.bookings.filter.choose')}</MenuItem>
                  {Object.keys(PREPACKAGES_STATUSES).map(key => (
                    <MenuItem key={key} value={key}>{translate(PREPACKAGES_STATUSES[key])}</MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Grid>

        <Grid item md={4} xs={12} className="text-center-sm">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ width: 120, marginRight: 10 }}
            className="btn-rounded-style"
          >
            {translate('app.bookings.filter.search')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            type="button"
            style={{ width: 100 }}
            onClick={clearFilters}
            className="btn-rounded-style"
          >
            {translate('clear')}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default withStyles(styles)(PrepackagesTableFilters);