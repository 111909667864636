import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/client';
import { I18nContextProvider } from 'translations';
import { theme } from 'utils/theme';
import { StoreProvider } from 'context/store';
import { indexReducer } from 'context/reducers';
import initialState from 'context/initialState';
import { client } from 'graphql/base';
import Routes from 'routes';

function App() {

  return (
    <MuiThemeProvider theme={theme}>
      <StoreProvider reducer={indexReducer} initialState={initialState}>
        <ApolloProvider client={client}>
          <I18nContextProvider>
            <Routes />
          </I18nContextProvider>
        </ApolloProvider>
      </StoreProvider>
    </MuiThemeProvider>
  );
}

export default App;
