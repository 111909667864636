import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Button, Menu } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { I18nContext } from 'translations';
import styles from './style';
import { RENT } from 'utils/constants';
import Language from 'components/Language';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const ItemsMenu = ({ classes, history }) => {
  const isRent = process.env.REACT_APP_API_URL_BASE_IS_RENT === RENT;
  const { translate } = useContext(I18nContext);
  const [pathname, setPathname] = useState(history.location);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElPortal, setAnchorElPortal] = useState(null);
  const [anchorElInt, setAnchorElInt] = useState(null);

  useEffect(() => {
    setPathname(history.location);
  }, [history.location]);

  const handleClick = event => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleClickPortal = event => {
    if (anchorElPortal !== event.currentTarget) {
      setAnchorElPortal(event.currentTarget);
    }
  }

  const handleClosePortal = () => {
    setAnchorElPortal(null);
  }

  const handleClickInt = event => {
    if (anchorEl !== event.currentTarget) {
      setAnchorElInt(event.currentTarget);
    }
  }

  const handleCloseInt = () => {
    setAnchorElInt(null);
  }

  const shopifyData = history.location.search.split('?')[1];

  return (
    <div className={classes.itemsMenu}>
      <div className={classes.menu}>
        {!isRent && 
          <>
            {/*<Button to="/about" color="primary" component={Link} className={pathname.pathname === "/about" ? "active" : ""}>
              {translate('landing.menu.about')}
            </Button>*/}
            <Button
              color="primary"
              aria-owns={anchorEl ? "simple-menu" : undefined}
              aria-haspopup="true"
              onMouseOver={handleClick}
            >
              {translate('landing.menu.services')}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{ onMouseLeave: handleClose }}
              style={{ top: 37, cursor: 'pointer' }}
            >
              <Button href='https://landing.pibox.app/servicios-en-colombia' target='_blank' fullWidth style={{ justifyContent: 'left' }}>
                {translate('landing.menu.services.col')}
              </Button>
              <Button href='https://landing.pibox.app/servicios-en-mexico' target='_blank' fullWidth style={{ justifyContent: 'left' }}>
                {translate('landing.menu.services.mx')}
              </Button>
            </Menu>
            <Button to="/tracking-code" color="primary" component={Link} className={pathname.pathname === "/tracking-code" && pathname.hash === "" ? "active" : ""}>
              {translate('landing.menu.tracking')}
            </Button>
            <Button
              color="primary"
              aria-owns={anchorElInt ? "simple-menu" : undefined}
              aria-haspopup="true"
              onMouseOver={handleClickInt}
              className={(pathname.pathname === "/mercadoflex" || pathname.pathname === "/shopify" || pathname.pathname === "/woocommerce" || pathname.pathname === "/vtex")  ? "active" : ""}
            >
              {translate('landing.menu.integrations')}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorElInt}
              open={Boolean(anchorElInt)}
              onClose={handleCloseInt}
              MenuListProps={{ onMouseLeave: handleCloseInt }}
              style={{ top: 37, cursor: 'pointer' }}
            >
              <Button to="/mercadoflex" color="primary" fullWidth component={Link} className={pathname.pathname === "/mercadoflex" ? "active" : ""} style={{ justifyContent: 'left' }}>
                {translate('landing.menu.mercadoflex')}
              </Button>
              <Button to="/shopify" color="primary" fullWidth component={Link} className={pathname.pathname === "/shopify" ? "active" : ""} style={{ justifyContent: 'left' }}>
                {translate('landing.menu.shopify')}
              </Button>
              <Button to="/woocommerce" color="primary" fullWidth component={Link} className={pathname.pathname === "/woocommerce" ? "active" : ""} style={{ justifyContent: 'left' }}>
                {translate('landing.menu.woocommerce')}
              </Button>
              <Button to="/vtex" color="primary" fullWidth component={Link} className={pathname.pathname === "/vtex" ? "active" : ""} style={{ justifyContent: 'left' }}>
                {translate('landing.menu.vtex')}
              </Button>
            </Menu>
            <Button style={{ padding: 0 }}>
              <span className="hs-cta-wrapper" id="hs-cta-wrapper-7c2e32ac-74c1-49f0-95cb-bbd09dbd14ca">
                <span className="hs-cta-node hs-cta-7c2e32ac-74c1-49f0-95cb-bbd09dbd14ca" id="hs-cta-7c2e32ac-74c1-49f0-95cb-bbd09dbd14ca">
                  <div id="hs-cta-ie-element"></div>
                  <a href="https://cta-redirect.hubspot.com/cta/redirect/20326442/7c2e32ac-74c1-49f0-95cb-bbd09dbd14ca" target="_blank" rel="noopener noreferrer">
                    <img className="hs-cta-img" id="hs-cta-img-7c2e32ac-74c1-49f0-95cb-bbd09dbd14ca" style={{ borderWidth: '0px', width: '115px', verticalAlign: 'middle' }} src="https://no-cache.hubspot.com/cta/default/20326442/7c2e32ac-74c1-49f0-95cb-bbd09dbd14ca.png"  alt="¡Quiero asesoría!"/>
                  </a>
                </span>
              </span>
            </Button>
            <Button href="https://www.envios.pibox.app/blog" color="primary" target="_blank">
              {translate('landing.menu.blog')}
            </Button>
          </>
        }
      </div>
      <div className={classes.menuRight}>
        {isRent ? (
          <>
            <Button href="/login" color="primary" className={pathname.pathname === "/login" && pathname.hash === "" ? "active" : ""}>
              {translate('landing.menu.logIn')}
            </Button>
            <Button href="/presignup" variant="contained" color="primary" className="btn-rounded">
              {translate('landing.menu.signUp')}
            </Button>
            <Language colorIcon="#7825bd" /> 
          </> 
        ) : (
          <>
            <Button
              color="primary"
              aria-owns={anchorElPortal ? "simple-menu" : undefined}
              aria-haspopup="true"
              onMouseOver={handleClickPortal}
            >
                {translate('landing.menu.customerPortal')}
              <KeyboardArrowDownIcon />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorElPortal}
              open={Boolean(anchorElPortal)}
              onClose={handleClosePortal}
              MenuListProps={{ onMouseLeave: handleClosePortal }}
              style={{ top: 37, cursor: 'pointer' }}
            >
              <Button href={shopifyData ? `/login?${shopifyData}`: "/login"} color="primary" className={pathname.pathname === "/login" && pathname.hash === "" ? "active" : ""} style={{ margin: '10px' }}>
                {translate('landing.menu.logIn')}
              </Button>
              <br />
              <Button href={isRent ? "/signup" : "/presignup"} variant="contained" color="primary" className="btn-rounded" style={{ margin: '0 10px 10px 10px' }}>
                {translate('landing.menu.signUp')}
              </Button>
            </Menu>
          </>
        )}
      </div>
    </div>
  )
}

ItemsMenu.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withRouter(ItemsMenu));