import {
  GET_COMPANY_REGISTER_LOADING,
  POST_COMPANY_REGISTER_LOADING,
  SET_COMPANY_REGISTER,
  MESSAGE,
  GET_DOCUMENTS_HISTORY,
  GET_DOCUMENTS_HISTORY_TYPE,
  TOGGLE_DOCUMENTS_HISTORY_TYPE_LOADING,
  SET_TAX_LIABILITY_OPTIONS,
  SET_MAX_DECLARED_VALUE_OPTIONS,
} from './constants';

const companyRegisterReducer = (state, action) => {
  switch (action.type) {
    case GET_COMPANY_REGISTER_LOADING: {
      return {
        ...state,
        getLoading: action.getLoading,
      };
    }
    case POST_COMPANY_REGISTER_LOADING: {
      return {
        ...state,
        postLoading: action.postLoading,
      };
    }
    case SET_COMPANY_REGISTER: {
      return {
        ...state,
        data: action.data,
      };
    }
    case MESSAGE:
      return {
        ...state,
        message: action.message,
      };
    case GET_DOCUMENTS_HISTORY: {
      return {
        ...state,
        documents: action.documents,
      };
    }
    case GET_DOCUMENTS_HISTORY_TYPE: {
      return {
        ...state,
        documents_by_type: action.documents,
      };
    }
    case TOGGLE_DOCUMENTS_HISTORY_TYPE_LOADING: {
      return {
        ...state,
        historyDocumentsLoading: action.isLoading,
      };
    }
    case SET_TAX_LIABILITY_OPTIONS: {
      return {
        ...state,
        taxLiabilityOptions: action.taxLiabilityOptions,
      };
    }
    case SET_MAX_DECLARED_VALUE_OPTIONS: {
      return {
        ...state,
        maxDeclaredValueOptions: action.maxDeclaredValueOptions,
      };
    }
    default:
      return state;
  }
};

export default companyRegisterReducer;
