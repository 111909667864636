import { LOADING_PROFILE, LOADING_UPDATE_PASSWORD } from './constants';

const profileReducer = (state, action) => {
  switch (action.type) {
    case LOADING_UPDATE_PASSWORD: {
      return {
        ...state,
        loadingPassword: action.loadingPassword
      }
    }
    case LOADING_PROFILE: {
      return {
        ...state,
        loadingProfile: action.loadingProfile
      }
    }
    default:
      return state
  }
}

export default profileReducer;
