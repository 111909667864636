import { get, post, patch, getExcelFile } from 'api/requests';
import { message, loading } from '../commons/actions';
import handleErrorMessage from 'utils/handleErrorMessage';
import { COUNTRY_CODE, SIZE_CD } from 'utils/constants';
import { formatPrice, toIsoString } from 'utils';
import { SERVICE_TYPE_ID, RENT_SERVICE_TYPE_ID, PIBOX_CAR_SERVICE_TYPE_ID } from 'utils/constants';
import {
  urlBookingsPackages,
  urlCreateBooking,
  urlExcelBookings,
  urlExcelPackages,
  urlOptimizeStops,
  urlGroupsCompanies,
  urlBookingReservations,
  urlRelatedCities,
  urlGetAddress,
  urlBulkLoad,
  urlBookings,
  urlQuote,
  urlExportBookings,
  urlExportPackages,
  urlCompanyPromoCode,
  urlPromoCode,
  urlBulkScheduled
} from 'api';
import {
  LIST_BOOKINGS_LOADING,
  LIST_PACKAGES_LOADING,
  CREATE_BOOKING,
  OPTIMIZE_STOPS,
  LIST_BOOKINGS,
  LIST_PACKAGES,
  RELATED_CITIES,
  BULK_LOAD_FAILED,
  LIST_BY_GROUPS,
  BULK_LOAD,
  QUOTE,
  LIST_REPORTS,
  PROMO_CODE_REDEEM,
  BULK_SCHEDULED_FAILED,
  LOADING_PROMO_CODE_REDEEM,
  BULK_SCHEDULED_SUCCESS
} from './constants';

export const getAproxAddress = async (dispatch, coord) => {
  let aproxAddress = null;
  await get(urlGetAddress, { lat: coord.lat, lon: coord.lng })
    .then((json) => {
      return json.data;
    })
    .then((data) => {
      aproxAddress = data.aprox_address;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
    });
  return { aproxAddress };
};

export const dataStops = async (values, translate) => {
  let data = values.values.stops.filter((x) => x);
  let stops = data.map((val, i) => {
    const info = {
      secondary_address: val.secondary_address,
      indications: val.packages[0].indications,
      size_cd: `${val.packages[0].size_cd} (${translate(
        SIZE_CD[val.packages[0].size_cd]
      )})`,
      declared_value: val.packages[0].declared_value,
      reference: val.packages[0].reference,
      counter_delivery: val.packages[0].counter_delivery
        ? val.packages[0].counter_delivery
        : false,
      name: val.customer.name,
      phone: val.customer.phone,
      email: val.customer.email,
    };
    return { address: values.address[i].name, ...info };
  });

  const dataStops = stops.map((d) => {
    return [
      { value: d.address },
      { value: d.secondary_address },
      { value: d.indications },
      { value: d.size_cd },
      { value: d.declared_value },
      { value: d.reference },
      { value: d.counter_delivery ? 'SI' : 'NO' },
      { value: d.name },
      { value: d.phone },
      { value: d.email },
    ];
  });

  const excelStops = [
    {
      columns: [
        { value: 'Direccion', widthPx: 170 },
        { value: 'Direccion complementaria', widthPx: 170 },
        { value: 'Indicaciones', widthPx: 170 },
        { value: 'Tamaño', widthPx: 120 },
        { value: 'Valor declarado', widthPx: 120 },
        { value: 'Numero de pedido', widthPx: 120 },
        { value: 'Pago contraentrega', widthPx: 120 },
        { value: 'Nombre', widthPx: 120 },
        { value: 'Telefono', widthPx: 120 },
        { value: 'Correo electronico', widthPx: 170 },
      ],
      data: dataStops,
    },
  ];
  return excelStops;
};

export const excelFailedStops = async (stops, translate) => {
  const dataStops = stops.failedStops.map((d) => {
    return [
      { value: d.address },
      { value: d.secondary_address ? d.secondary_address : '' },
      { value: d.packages[0].indications ? d.packages[0].indications : '' },
      {
        value: `${d.packages[0].size_cd} (${translate(
          SIZE_CD[d.packages[0].size_cd]
        )})`,
      },
      {
        value: d.packages[0].declared_value
          ? d.packages[0].declared_value.toString()
          : '',
      },
      { value: d.packages[0].reference ? d.packages[0].reference : '' },
      { value: d.packages[0].counter_delivery ? 'SI' : 'NO' },
      { value: d.customer.name ? d.customer.name : '' },
      { value: d.customer.phone ? d.customer.phone : '' },
      { value: d.customer.email ? d.customer.email : '' },
    ];
  });

  const excelStops = [
    {
      columns: [
        { value: 'Direccion', widthPx: 170 },
        { value: 'Direccion complementaria', widthPx: 170 },
        { value: 'Indicaciones', widthPx: 170 },
        { value: 'Tamaño', widthPx: 120 },
        { value: 'Valor declarado', widthPx: 120 },
        { value: 'Numero de pedido', widthPx: 120 },
        { value: 'Pago contraentrega', widthPx: 120 },
        { value: 'Nombre', widthPx: 120 },
        { value: 'Telefono', widthPx: 120 },
        { value: 'Correo electronico', widthPx: 170 },
      ],
      data: dataStops,
    },
  ];

  return excelStops;
};

export const createBooking = async (
  dispatch,
  values,
  type,
  lang,
  saleList,
  bookingReservationId,
  routeOptimizeOps
) => {
  let data = values.values.stops.filter((x) => x);
  let stops = data.map((val, i) => {
    const externalId = routeOptimizeOps?.length > 0 ? routeOptimizeOps[0].external_id : saleList?.length > 0 ? saleList[i].external_id : null;
    const info = {
      ...val,
      customer: {
        ...val.customer,
        phone: val.customer.phone.replace(/\s/g, ''),
      },
      packages: [
        {
          ...val.packages[0],
          counter_delivery: val.packages[0].counter_delivery
            ? val.packages[0].counter_delivery
            : false,
          size_cd: parseInt(val.packages[0].size_cd),
          delivery_sale_id: val.packages[0].delivery_sale_id
            ? val.packages[0].delivery_sale_id
            : null,
          declared_value: {
            sub_units: formatPrice({
              sub_units: val.packages[0].declared_value,
              iso: COUNTRY_CODE[lang][4],
            }),
            currency: COUNTRY_CODE[lang][4],
          },
          collected_value:
            val.packages[0].collected_value &&
            val.packages[0].collected_value > 0
              ? {
                  sub_units: formatPrice({
                    sub_units: val.packages[0].collected_value,
                    iso: COUNTRY_CODE[lang][4],
                  }),
                  currency: COUNTRY_CODE[lang][4],
                }
              : null,
          weight: parseInt(val.packages[0].weight),
          external_id: externalId || null,
        },
      ],
    };
    return {
      ...info,
      address: values.address[i].name,
      lat: values.address[i].lat,
      lon: values.address[i].lon,
    };
  });

  const externalId = stops?.length > 0 ? stops[0].packages[0].external_id : saleList?.length > 0 ? saleList[0].external_id : null;

  const uuid = crypto.randomUUID();

  const booking = {
    ...values.values,
    booking_reservation_id: bookingReservationId,
    scheduled_at: values.values.scheduled_at
      ? toIsoString(values.values.scheduled_at)
      : null,
    requires_a_driver_with_base_money: values.moneyBalance
      ? values.moneyBalance
      : false,
    money_to_pay_for_package: values.moneyBalance && values.values.money_to_pay_for_package
      ? {
          sub_units: formatPrice({
            sub_units: values.values.money_to_pay_for_package,
            iso: COUNTRY_CODE[lang][4],
          }),
          currency: COUNTRY_CODE[lang][4],
        }
      : null,
    address: values.origin.name,
    lat: values.origin.lat,
    lon: values.origin.lon,
    requested_service_type_id: values?.values?.vehicle
      ? values.values.vehicle : values.values.vehicle_types === PIBOX_CAR_SERVICE_TYPE_ID ?
      values.values.vehicle_types
      : SERVICE_TYPE_ID,
    hours_dedicated: values.values.scheduled_at
      ? values.values.hours_dedicated
        ? values.values.hours_dedicated !== -1
          ? values.values.hours_dedicated
          : null
        : null
      : null,
    stops,
    integration_relation_order_uuid: externalId ? uuid : null,
    multiplier: values.values.multiplier
      ? parseInt(values.values.multiplier)
      : 1,
    payment_method_cd: parseInt(values?.values.payment_method)
  };

  if (type === 0) {
    loading(dispatch, true);
    await post(urlCreateBooking, { booking })
      .then(({ data }) => {
        dispatch({
          type: CREATE_BOOKING,
          booking: data,
        });
        loading(dispatch, false);
      })
      .catch((error) => {
        const mssg = handleErrorMessage(error, dispatch);
        loading(dispatch, false);
        message(dispatch, mssg);
      });
  } else if (type === 1) {
    dispatch({ type: QUOTE, quote: null, loadingQuote: true });
    await post(urlQuote, { booking })
      .then(({ data }) => {
        dispatch({
          type: QUOTE,
          quote: data,
          loadingQuote: false,
        });
      })
      .catch((error) => {
        const mssg = handleErrorMessage(error, dispatch);
        message(dispatch, mssg);
        dispatch({ type: QUOTE, quote: null, loadingQuote: false });
      });
  } else if (type === 2) {
    loading(dispatch, true);
    await post(urlOptimizeStops, { booking })
      .then(({ data }) => {
        dispatch({
          type: OPTIMIZE_STOPS,
          optimize: data,
        });
        loading(dispatch, false);
      })
      .catch((error) => {
        const mssg = handleErrorMessage(error, dispatch);
        message(dispatch, mssg);
        loading(dispatch, false);
        dispatch({
          type: OPTIMIZE_STOPS,
          optimize: { booking },
        });
      });
  }
};

export const createBookingRent = async (
  dispatch,
  values,
  type
) => {
  const booking = {
    address: values.origin.name,
    lat: values.origin.lat,
    lon: values.origin.lon,
    secondary_address: values.values.secondary_address,
    end_lat: values.address[0]?.lat,
		end_lon: values.address[0]?.lon,
		end_address: values.address[0]?.name,
    cost_center_id: values.values.cost_center_id,
    payment_method: values.values.payment_method,
    use_wallet_balance: values.values.use_wallet_balance,
    booking_for_cd: 1,
    requested_service_type_id: RENT_SERVICE_TYPE_ID,
    promo_code_id: values.promoCode ? values.promoCode.id : ''
  };

  if (type === 0) {
    loading(dispatch, true);
    await post(urlCreateBooking, { booking })
      .then(({ data }) => {
        dispatch({
          type: CREATE_BOOKING,
          booking: data,
        });
        loading(dispatch, false);
      })
      .catch((error) => {
        const mssg = handleErrorMessage(error, dispatch);
        loading(dispatch, false);
        message(dispatch, mssg);
      });
  } else if (type === 1) {
    dispatch({ type: QUOTE, quote: null, loadingQuote: true });
    await post(urlQuote, { booking })
      .then(({ data }) => {
        dispatch({
          type: QUOTE,
          quote: data,
          loadingQuote: false,
        });
      })
      .catch((error) => {
        const mssg = handleErrorMessage(error, dispatch);
        message(dispatch, mssg);
        dispatch({ type: QUOTE, quote: null, loadingQuote: false });
      });
  }
};

export const uploadBulkLoad = async (dispatch, values) => {
  dispatch({ type: BULK_LOAD, bulkLoad: null, loadingBulkLoad: true });
  let status = false;
  let statusFailedStop = false;
  await post(urlBulkLoad, { ...values })
    .then(({ data }) => {
      dispatch({
        type: BULK_LOAD,
        bulkLoad: data,
        loadingBulkLoad: false,
      });
      if (data.failed_stops && data.failed_stops.length > 0) {
        dispatch({
          type: BULK_LOAD_FAILED,
          bulkLoadFailedStops: data.failed_stops,
        });
        statusFailedStop = true;
      }
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({ type: BULK_LOAD, bulkLoad: null, loadingBulkLoad: false });
      status = false;
      statusFailedStop = false;
    });
  return { status, statusFailedStop };
};

export const listBookings = async (dispatch, params) => {
  dispatch({
    type: LIST_BOOKINGS_LOADING,
    loadingBookings: true,
  });
  await get(urlBookings, params)
    .then(({ data }) => {
      dispatch({
        type: LIST_BOOKINGS,
        list: data,
      });
      dispatch({
        type: LIST_BOOKINGS_LOADING,
        loadingBookings: false,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: LIST_BOOKINGS_LOADING,
        loadingBookings: false,
      });
    });
};

export const downloadExcelBooking = async (dispatch, params, type) => {
  loading(dispatch, true);
  await getExcelFile(type === 1 ? urlExcelPackages : urlExcelBookings, params)
    .then(() => {
      loading(dispatch, false);
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      loading(dispatch, false);
    });
};

export const listBookingsPackages = async (dispatch, params) => {
  dispatch({
    type: LIST_PACKAGES_LOADING,
    loadingPackages: true,
  });
  await get(urlBookingsPackages, params)
    .then(({ data }) => {
      dispatch({
        type: LIST_PACKAGES,
        listPackages: data,
      });
      dispatch({
        type: LIST_PACKAGES_LOADING,
        loadingPackages: false,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: LIST_PACKAGES_LOADING,
        loadingPackages: false,
      });
    });
};

export const getListByGroups = async (dispatch, id) => {
  await get(`${urlGroupsCompanies}/${id}/areas?all=true`, {})
    .then(({ data }) => {
      dispatch({
        type: LIST_BY_GROUPS,
        listByGroups: data,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
    });
};

export const cleanOptimizeStops = (dispatch) => {
  dispatch({
    type: OPTIMIZE_STOPS,
    optimize: null,
  });
};

export const cleanBulkLoad = (dispatch) => {
  dispatch({
    type: BULK_LOAD,
    bulkLoad: null,
  });
  dispatch({
    type: BULK_LOAD_FAILED,
    bulkLoadFailedStops: null,
  });
};

export const cleanBulkLoadFailedStops = (dispatch) => {
  dispatch({
    type: BULK_LOAD_FAILED,
    bulkLoadFailedStops: null,
  });
};

export const cleanBookings = (dispatch) => {
  dispatch({
    type: LIST_BOOKINGS,
    list: null,
  });
};

export const cleanQuote = (dispatch) => {
  dispatch({
    type: QUOTE,
    quote: null,
    loadingQuote: false,
  });
}

export const cleanPackages = (dispatch) => {
  dispatch({
    type: LIST_PACKAGES,
    listPackages: null,
  });
};

export const getRelatedCities = async (dispatch, lang, originCity) => {
  await post(urlRelatedCities, { country_id: COUNTRY_CODE[lang][0] })
    .then((json) => {
      return json.data;
    })
    .then((data) => {
      dispatch({
        type: RELATED_CITIES,
        relatedCities:
          data?.cities_for_delivery?.filter(
            (id) => id._id === originCity._id
          ) || null,
      });
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getCostCenter = async (id) => {
  let costCenter = [];
  await get(`${urlGroupsCompanies}/${id}/cost_centers`)
    .then(({ data }) => {
      costCenter.push(...data);
    })
    .catch((error) => {
      console.error(error);
    });
  return { costCenter };
};

export const listBookingReservation = async (dispatch) => {
  let reservations = [];
  await get(urlBookingReservations, {})
    .then(({ data }) => {
      reservations.push(...data);
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
    });
  return { reservations };
};

export const exportBookings = async (dispatch, params, type) => {
  let status = false;
  await post(type === 1 ? urlExportPackages : urlExportBookings, { ...params })
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
};

export const listReports = async (dispatch, params, urlExport) => {
  await get(urlExport, params)
    .then(({ data }) => {
      dispatch({ type: LIST_REPORTS, reports: data });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
    });
};

export const redeemPromoCode = async (dispatch, companyId, values) => {
  dispatch({
    type: LOADING_PROMO_CODE_REDEEM,
    loadingPromoCode: true,
  });
  await patch(companyId ? `${urlCompanyPromoCode}/${companyId}/corporative_promo_codes/redeem` : `${urlPromoCode}`, { code: values })
    .then((json) => {
      return json.data;
    })
    .then((data) => {
      dispatch({
        type: PROMO_CODE_REDEEM,
        promoCode: data,
      });
      dispatch({
        type: LOADING_PROMO_CODE_REDEEM,
        loadingPromoCode: false,
      });
      data && message(dispatch, data.mssg);
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: LOADING_PROMO_CODE_REDEEM,
        loadingPromoCode: false,
      });
      dispatch({
        type: PROMO_CODE_REDEEM,
        promoCode: 0,
      });
    });
};

export const editHoursDedicated = async (id, hours, dispatch) => {
  let status = false;
  let mssgOk = null
  await post(`${urlBookings}/${id}/modify_scheduled_hours`, { ...hours })
    .then((data) => {
      status = true;
      mssgOk = data?.data?.mssg;
    })
    .catch((error) => {
      status = false;
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      mssgOk = null
    });
    return { status, mssgOk };
}

export const uploadBulkReserved = async (dispatch, values) => {
  let status = false;
  await post(urlBulkScheduled, { ...values })
    .then(({ data }) => {
      if (data.not_valid_for_creation?.length > 0) {
        dispatch({
          type: BULK_SCHEDULED_FAILED,
          failedScheduled: data.not_valid_for_creation,
        })
        status = false;
      } else {
        status = true;
      }
      if (data.valid_for_creation?.length > 0) {
        dispatch({
          type: BULK_SCHEDULED_SUCCESS,
          successScheduled: data.valid_for_creation
        })
      }
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
};

export const cleanFailedScheduled = (dispatch) => {
  dispatch({
    type: BULK_SCHEDULED_FAILED,
    failedScheduled: null,
  });
};