import React, { useState, useContext } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Share from '@material-ui/icons/Share';
import { Link } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import { useStateValue } from 'context/store';
import { I18nContext } from 'translations';
import { lastRelaunched, activeForPassenger, modalMessage } from 'utils';
import {
  cancelBookingAction,
  relaunchedBookingAction,
} from 'context/booking/actions';
import { IDS_SERVICES_TYPE_PIBOX } from 'utils/constants';
import styles from './style';
import CollectionValue from './CollectionValue';

function HeaderActions({
  classes,
  booking,
  history,
  openTrackService,
  viewEvents,
  getBooking,
}) {
  const [, dispatch] = useStateValue();
  const [loading, setLoading] = useState(false);
  const isToken = localStorage.getItem('session_token');
  const { translate } = useContext(I18nContext);

  const messageLogin = () => {
    modalMessage(
      '',
      translate('app.booking.loginFirstToPerformThisAction'),
      'info',
      translate('app.booking.logIn'),
      true,
      true,
      translate('cancel')
    ).then((response) => {
      if (response.value) {
        history.push({
          pathname: '/login',
          search: `?id=${booking._id}`,
        });
      }
    });
  };

  const cancelBooking = async () => {
    if (isToken) {
      modalMessage(
        '',
        translate('app.booking.cancelTheService'),
        '',
        translate('accept'),
        true,
        true,
        translate('cancel')
      ).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const callback = await cancelBookingAction(
            booking._id,
            isToken,
            dispatch
          );
          callback && setLoading(false);
        }
      });
    } else {
      messageLogin();
    }
  };

  const relaunchBooking = async () => {
    if (isToken) {
      modalMessage(
        '',
        translate('app.booking.relaunchTheService'),
        '',
        translate('accept'),
        true,
        true,
        translate('cancel')
      ).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const callback = await relaunchedBookingAction(
            booking._id,
            dispatch,
            isToken
          );
          callback?.loadingRelaunched !== true && setLoading(false);
        }
      });
    } else {
      messageLogin();
    }
  };

  const renderOptionButton = () => {
    if (
      booking.status_cd === 4 ||
      IDS_SERVICES_TYPE_PIBOX[booking.requested_service_type_id] === undefined
    ) {
      // Finished by driver
      return null;
    }
    if (booking.relaunched_to) {
      // It was already relaunched
      const lastId = lastRelaunched(booking) && lastRelaunched(booking)._id;
      return (
        <Button
          type="button"
          color="secondary"
          size="small"
          variant="outlined"
          component={Link}
          to={`/bookings/${lastId}`}
          style={{
            boxShadow: 'none',
            borderRadius: '20px',
            margin: '0 7px 5px 0',
          }}
        >
          {translate('app.booking.btn.seeRelaunchedService')}
        </Button>
      );
    }
    if (activeForPassenger(booking)) {
      return (
        <Button
          type="button"
          color="default"
          size="small"
          variant="outlined"
          onClick={cancelBooking}
          disabled={loading}
          startIcon={loading && <CircularProgress size={18} color="inherit" />}
          style={{
            boxShadow: 'none',
            borderRadius: '20px',
            margin: '0 7px 5px 0',
          }}
        >
          {translate('app.booking.btn.cancelService')}
        </Button>
      );
    }
    return (
      <Button
        type="button"
        color="secondary"
        size="small"
        variant="outlined"
        onClick={relaunchBooking}
        disabled={loading}
        startIcon={loading && <CircularProgress size={18} color="inherit" />}
        style={{
          boxShadow: 'none',
          borderRadius: '20px',
          margin: '0 7px 5px 0',
        }}
      >
        {translate('app.booking.btn.relaunchService')}
      </Button>
    );
  };

  const shareLink = () => {
    const url = `${window.location.origin}/bookings/${booking._id}`;
    copy(url);
    modalMessage(
      '',
      translate('app.booking.linkToShareSuccessfullyCopied'),
      'success',
      translate('continue'),
      true
    );
  };

  return (
    <div className="text-right text-center-sm" style={{ marginTop: 15 }}>
      <Button
        type="button"
        size="small"
        variant="contained"
        onClick={shareLink}
        className={classes.btn}
      >
        <Share fontSize="small" />
        {translate('app.booking.btn.share')}
      </Button>
      <Button
        type="button"
        size="small"
        variant="contained"
        onClick={openTrackService}
        className={classes.btn}
      >
        <img src="/images/booking/box.svg" alt="box" />
        {viewEvents
          ? translate('app.booking.btn.update')
          : translate('app.booking.btn.tripEvents')}
      </Button>
      <CollectionValue
        booking={booking}
        classes={classes}
        getBooking={getBooking}
      />
      {renderOptionButton()}
    </div>
  );
}

export default withStyles(styles)(HeaderActions);