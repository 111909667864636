import React, { useState, Suspense, lazy, useContext } from 'react';
import { Grid, LinearProgress, Snackbar } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useStateValue } from 'context/store';
import { createPrepackages } from 'context/prepackages/actions';
import { message as closeMessage } from 'context/commons/actions';
import { I18nContext } from 'translations';
import PackagesGroupingForm from './Form';
import { schema } from './Form/schema';
import Loading from 'components/Loading';
import { modalMessage } from 'utils';
import City from './Form/City';
const MarkerMap = lazy(() => import('components/Maps/MarkerMap'));

export default function PackagesGrouping() {
  const { langCode, translate } = useContext(I18nContext);
  const [origin, setOrigin] = useState(null);
  const [expanded, setExpanded] = useState('origin');
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState([{ name: null, lat: null, lon: null }]);
  const [city, setCity] = useState(null);
  const [cleanStops, setCleanStops] = useState(false);
  const [{
    checkin: { configuration },
    commons: { message } },
    dispatch
  ] = useStateValue();

  const { handleSubmit, register, reset, formState: { isValid, errors } } = useForm({
    resolver: yupResolver(schema),
      mode: "onChange"
    });

  const messageOk = () => {
    setLoading(false);
    modalMessage(translate('congratulations'), translate('prepackages.form.prepackageCreatedSuccessfully'), 'success', 'Continue').then((result) => {
      window.location.href = `/app/list-prepackages`;
    });
  }

  const submit = async (values) => {
    setLoading(true);
    const callback = await createPrepackages(origin, address[0], values, langCode, dispatch);
    callback.status ? messageOk() : setLoading(false)
  }

  const handleSuggest = (info) => setOrigin(info);

  const handleAddress = (info, index, id) => {
    setAddress((prevAddres) => {
      const updateRoutes = [...prevAddres];
      updateRoutes[index] = { ...info, id };
      return updateRoutes;
    });
  };

  const resetForm = () => {
    reset();
    setAddress([]);
    setOrigin(null);
  };


  return (
    <div className="padding-page-full-height">
      <Grid container justify="center">
        <Grid item md={6} xs={12}>

          {loading && <Loading />}

          <div style={{ padding: '0 20px 0 10px' }}>
            <City
              setCity={setCity}
              cities={configuration?.cities_for_delivery}
              city={city}
              resetForm={resetForm}
              setExpanded={setExpanded}
            />
          </div>
          {city && (
            <PackagesGroupingForm
              register={register}
              handleSubmit={handleSubmit}
              city={city}
              cities={configuration?.cities_for_delivery}
              origin={origin}
              address={address}
              submit={submit}
              handleSuggest={handleSuggest}
              handleAddress={handleAddress}
              resetForm={resetForm}
              errors={errors}
              isValid={isValid}
              setExpanded={setExpanded}
              expanded={expanded}
            />
          )}
      
        </Grid>
        <Grid item md={6} xs={12} style={{ position: 'relative', minHeight: '87vh' }}>
          <Suspense fallback={<LinearProgress color="primary" />}>
            <MarkerMap
              city={{
                lon: city ? city.location.lon : configuration.cities_for_delivery.length > 0 ? configuration.cities_for_delivery[0].location.lon : '-74.134',
                lat: city ? city.location.lat : configuration.cities_for_delivery.length > 0 ? configuration.cities_for_delivery[0].location.lat : '4.674'
              }}
              origin={origin}
              stops={address}
              remove={[{ name: null, lat: null, lon: null }]}
              points={[origin].concat(address)}
              returnToOrigin={false}
              cleanStops={cleanStops}
              setCleanStops={setCleanStops}
              setAddress={setAddress}
            />
          </Suspense>
        </Grid>
      </Grid>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </div>
  )
}