import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Snackbar,
  CircularProgress
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { withStyles } from '@material-ui/core/styles';
import { createGroup, updateGroup } from 'context/settings/groups/actions';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import AddressInputs from 'components/CustomInputs/AddressInputs';
import { I18nContext } from 'translations';
import { modalMessage } from 'utils';
import schema from './schema';
import styles from './GroupsStyle';

function ModalGroups ({
  open,
  setOpen,
  idCompany,
  classes,
  getGroups,
  group,
  setGroup
}) {
  const { translate } = useContext(I18nContext);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [{ commons: { message } }, dispatch] = useStateValue();

  const { handleSubmit, register, control, errors, clearErrors } = useForm({
    resolver: yupResolver(schema)
  });

  const messageOk = () => {
    setLoading(false);
    setOpen(false);
    modalMessage(translate('congratulations'), translate('app.settings.groups.form.groupSavedSuccessfully'), 'success', translate('accept'), true).then(() => {
      getGroups();
      setGroup(null);
    })
  }

  const submit = async values => {
    setLoading(true);
    const callback = group ? await updateGroup(dispatch, group._id, { company_area: { ...values } }) : await createGroup(dispatch, idCompany, { company_area: { ...values } });
    callback.status ? messageOk() : setLoading(false);
  }

  const handleCity = (id) => setCityId(id);


  useEffect(() => {
    if (group && group.city_id) {
      setCityId(group.city_id);
    }
    if (group && group.address) {
      setAddress({
        name: group.address,
        lat: group.lat,
        lon: group.lon
      })
    }
  }, [group]) // eslint-disable-line

  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className="text-center" disableTypography>
        <Typography variant="h5" color="primary">
          {group ? translate('app.settings.groups.updateGroup') : translate('app.settings.groups.createGroup')}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.content}>

        <form onSubmit={handleSubmit(submit)} noValidate>
          <Grid container spacing={2} style={{ marginBottom: '5px' }}>
            <Grid item md={12} xs={12} style={{ marginBottom: 10 }}>
              <TextField
                name="name"
                label={translate('app.settings.groups.form.groupName')}
                size="small"
                type="text"
                defaultValue={group ? group.name : ''}
                fullWidth
                required
                inputRef={register}
                error={!!errors.name}
                helperText={errors.name && translate(errors.name.message)}
              />
            </Grid>
            <Grid item md={12} xs={12} style={{ marginBottom: 10 }}>
              <AddressInputs
                register={register}
                address={address}
                setAddress={setAddress}
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                twoColumns
                isAddress
                cityId={cityId}
                handleCity={handleCity}
                needCity={true}
              />
            </Grid>
            <Grid item xs={12} className={classes.contentButtonSave}>
              <Button
                variant="contained"
                color="primary"
                style={{ width: '200px' }}
                type="submit"
                startIcon={loading ? <CircularProgress size={18} /> : null}
                disabled={loading}
              >
                {translate('app.settings.groups.form.save')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setOpen(false);
          setGroup(null);
        }}>
          {translate('close')}
        </Button>
      </DialogActions>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </Dialog>
  )
}

export default withStyles(styles)(ModalGroups);