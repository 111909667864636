import React, { useState, useEffect, useCallback, useContext } from 'react';
import ThumbsUpDownOutlinedIcon from '@material-ui/icons/ThumbsUpDownOutlined';
import { useStateValue } from 'context/store';
import {
  getBorrowerList,
  getCommodateLoan,
  listCommodateLoan,
  cancelCommodateLoan,
  notifyCommodateLoan,
  clearGetCommodateLoan,
} from 'context/commodate/actions';
import { PER_PAGE, INITIAL_PAGE } from 'utils/constants';
import { modalMessageHtml, modalMessage, piboxAccessDeniedRolesCompany } from 'utils';
import { I18nContext } from 'translations';
import Title from 'components/Titles';
import Loading from 'components/Loading';
import ComodatoTable from 'components/Tables/Commodate';
import ModalRequestComodato from 'components/Modals/ModalCommodate';
import ModalReceiveCommodate from 'components/Modals/ModalCommodate/ModalReceiveCommodate';
import ComodatoTableFilters from 'components/Tables/Commodate/Filters/CommodateTableFilters';

export default function Commodate() {
  const [per_page] = useState(PER_PAGE);
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState(null);
  const { translate } = useContext(I18nContext);
  const [loadingActions, setLoadingActions] = useState(false);
  const [openReceive, setOpenReceive] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [
    {
      checkin: { checkin },
      commons: { loading },
      commodateLoan: { list, fetchCommodate, borrowerList }
    },
    dispatch,
  ] = useStateValue();

  const companyId = checkin && checkin.company?._id

  const openModal = () => {
    setOpen(!open)
    clearGetCommodateLoan(dispatch)
  }

  const openModalReceive = () => {
    setOpenReceive(!openReceive)
    clearGetCommodateLoan(dispatch)
  }

  const editComodato = async (id, isEdit) => {
    await getCommodateLoan(dispatch, companyId, id);
    setOpen(true)
    setIsEdit(isEdit)
  }

  const notifyCommodate = async (id) => {
    setLoadingActions(true);
    const callback = await notifyCommodateLoan(dispatch, companyId, id);
    callback.status ? messageOk('commodate.notifyCommodate.notifyOk', false) : setLoadingActions(false)
  }

  const receiveCommodate = async id => {
    await getCommodateLoan(dispatch, companyId, id);
    setOpenReceive(true)
  }

  const messageOk = (mssg, updateData) => {
    setLoadingActions(false);
    modalMessage(
      '',
      translate(mssg),
      'success',
      translate('continue')
    ).then(async () => {
      updateData && getCommodate();
    });
  };

  const cancelCommodate = async id => {
    modalMessageHtml(
      '',
      translate('commodate.cancelCommodate.cancelLoanMssgConfirm'),
      'success',
      translate('accept'),
      true,
      translate('cancel'),
      false
    ).then(async(response) => {
      if (response.isConfirmed) {
        setLoadingActions(true);
        const callback = await cancelCommodateLoan(dispatch, companyId, id)
        callback.status ? messageOk('commodate.cancelCommodate.cancelLoanMssgOk', true) : setLoadingActions(false)
      }
    });;
  }

  const onFilters = params => setFilters(params);

  const getCommodate = useCallback(
    async (page) => {
      await listCommodateLoan(dispatch, companyId, { page, per_page, ...filters });
    },
    [dispatch, companyId, filters, per_page]
  );

  const listBorrower = useCallback(
    async () => { 
      await getBorrowerList(dispatch, companyId) 
    }, [dispatch, companyId]
  );

  useEffect(() => {
    getCommodate(INITIAL_PAGE)
    listBorrower()
  }, [getCommodate, listBorrower])


  return (
    <div className="padding-page-full-height">
      {loading && <Loading />}
      {loadingActions && <Loading />}
      <Title
        title={translate('commodate.title')}
        icon={<ThumbsUpDownOutlinedIcon className="align-center" style={{ marginRight: 5 }} />}
      />
      <div className="marginFiltersTable">
        <ComodatoTableFilters
          openModal={openModal}
          open={open}
          onFilters={onFilters}
          borrowerList={borrowerList}
          disabledOptions={checkin?.profile?.roles && piboxAccessDeniedRolesCompany(checkin?.profile?.roles).length > 0 ? true : false}
        />
      </div>
      {list &&
        <ComodatoTable
          data={list}
          editComodato={editComodato}
          recordsPerPage={per_page}
          getCommodate={getCommodate}
          receiveCommodate={receiveCommodate}
          cancelCommodate={cancelCommodate}
          notifyCommodate={notifyCommodate}
          disabledOptions={checkin?.profile?.roles && piboxAccessDeniedRolesCompany(checkin?.profile?.roles).length > 0 ? true : false}
        />
      }

      {open &&
        <ModalRequestComodato
          open={open}
          openModal={openModal}
          commodateEdit={fetchCommodate ? fetchCommodate : null}
          companyId={companyId}
          getCommodate={getCommodate}
          isEdit={isEdit}
        />
      }
      {openReceive &&
        <ModalReceiveCommodate
          open={openReceive}
          openModal={openModalReceive}
          commodateEdit={fetchCommodate ? fetchCommodate : null}
          companyId={companyId}
          getCommodate={getCommodate}
        />
      }
    </div>
  );
}
