import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Snackbar } from '@material-ui/core';
import { I18nContext } from 'translations';
import AddIcon from '@material-ui/icons/Add';
import { useStateValue } from 'context/store';
import { listPassengers } from 'context/balances/actions';
import { message as closeMessage } from 'context/commons/actions';
import TitleWithDowload from 'components/Titles/TitleWithDowload';
import AssingBalancesTable from 'components/Tables/Balances/AssingBalancesTable';
import AssingBalancesTableFilters from 'components/Tables/Balances/Filters/AssingBalancesTableFilters';
import Loading from 'components/Loading';
import { INITIAL_PAGE, PER_PAGE } from 'utils/constants';
import { modalMessageHtml } from 'utils';

const AssignBalance = () => {
  const { translate } = useContext(I18nContext);
  const [PassengerInfo, setPassengerInfo] = useState([]);
  const [codeInfo, setCodeInfo] = useState([]);
  const [per_page] = useState(PER_PAGE);
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState(null);
  const [
    {
      checkin: { company },
      commons: { message },
      balances: { list, loadingBalances }
    }, dispatch
  ] = useStateValue();

  const onFilters = (params) => setFilters(params);

  const getlistPassengers = useCallback(
    async (page) => {
      listPassengers(dispatch, company._id, {
        page,
        per_page,
        ...filters
      });
    },
    [dispatch, per_page, company, filters]
  );

  useEffect(() => {
    company && getlistPassengers(INITIAL_PAGE)
  }, [getlistPassengers]) // eslint-disable-line

  const removeBalance = (passengerId) => {
    modalMessageHtml('', 'Esta seguro que desea eliminar el saldo a este usuario?', 'success', 'Aceptar', true, 'Cancelar', false)
      .then((response) => {
        response.isConfirmed && alert(`eliminar saldo de ${passengerId}`)
      });
  }

  return (
    <div className="padding-page-full-height">
      {loadingBalances && <Loading />}

      <TitleWithDowload
        title={translate('balances.title')} 
        icon={
          <AddIcon className="align-center" style={{ marginRight: 5 }} />
        }
      />

      <div className="marginFiltersTable">
        <AssingBalancesTableFilters
          onFilters={onFilters}
          filters={filters}
          codeInfo={codeInfo}
          setOpen={setOpen}
        />
      </div>

      {list && (  
        <AssingBalancesTable
          data={list}
          PassengerInfo={PassengerInfo}
          setPassengerInfo={setPassengerInfo}
          open={open}
          setOpen={setOpen}
          getlistPassengers={getlistPassengers}
          recordsPerPage={per_page}
          history={true}
          codeInfo={codeInfo}
          setCodeInfo={setCodeInfo}
          removeBalance={removeBalance}
        />
      )}

      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />

    </div>
  );
};

export default AssignBalance;
