import React from 'react';
import Integration from 'models/integrations/Integration';
import WooCommerceActivation from 'components/Integrations/WooCommerce/WooCommerceActivation';
import WooCommerceStoreConfiguration from 'components/Integrations/WooCommerce/WooCommerceStoreConfiguration';
import WooCommerceDisableStore from 'components/Integrations/WooCommerce/WooCommerceDisableStore';

class WooCommerceIntegration extends Integration {
  constructor(integration) {// eslint-disable-line
    super(integration);
  }
  getActivateIntegration(getIntegrations) {
    return <WooCommerceActivation getIntegrations={getIntegrations} />;
  }
  getDisableIntegration(account, getIntegrations) {
    return (
      <WooCommerceDisableStore
        account={account}
        getIntegrations={getIntegrations}
      />
    );
  }

  getEditStore(id) {
    return (
      <WooCommerceStoreConfiguration
        store={this.user_provider_integrations[id]}
      />
    );
  }
}

export default WooCommerceIntegration;
