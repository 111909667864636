import React, { useContext, useState } from 'react';
import { I18nContext } from 'translations';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import ButtonUploadFileModal from 'components/Buttons/ButtonUploadFileModal';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { yupResolver } from '@hookform/resolvers';
import {
  uploadCompanyInfo,
  setPostLoading,
} from 'context/settings/companyRegister/actions';
import { useStateValue } from 'context/store';
import { uploadFile } from 'utils/aws';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  cleanObject,
  findIndexByProperty,
  flatFormat,
  getFileNameFromAWSUrlKey,
  isObjectEmpty,
  modalMessage,
  replaceKeys,
} from 'utils';
import { FieldsContainer } from './style';
import {
  COLORS_BY_STATUS_FOR_DOCUMENT_HISTORY,
  COMPANY_REGISTRATION_STATUSES,
  COUNTRIES_IDS_BY_NAME,
  COUNTRY_CODE,
  CURRENCIES,
  TERMS_CORPORATE_URL,
  TERMS_GENERAL_URL,
} from 'utils/constants';

import BankAccount from './BankAccount';
import CompanyGeneral from './CompanyGeneral';
import AboutShipping from './AboutShipping';
import NumberFormat from 'react-number-format';
import generateSchema from './Schema';

const getDefaultValues = (
  companyRegister,
  defaultTentativeMaxDeclaredValue
) => ({
  name: companyRegister?.name || '',
  business_name: companyRegister?.business_name || '',
  fiscal_number: companyRegister?.fiscal_number || '',
  email: companyRegister?.email || '',
  phone: companyRegister?.phone || '',
  website: companyRegister?.website || '',
  tax_liability: companyRegister?.tax_liability || '',
  entity_type: companyRegister?.entity_type?.id || '',

  has_accepted_corporate_tc:
    companyRegister?.has_accepted_corporate_tc,
  has_authorized_personal_data_treatment:
    companyRegister?.has_authorized_personal_data_treatment,
  has_authorized_credit_report_inquiry:
    companyRegister?.has_authorized_credit_report_inquiry,

  bank_account_type_id:
    companyRegister?.last_bank_account?.bank_account_type?._id || '',
  bank_id: companyRegister?.last_bank_account?.bank._id || '',
  account_number: companyRegister?.last_bank_account?.number || '',
  bank_fiscal_number_type_id:
    companyRegister?.last_bank_account?.bank_fiscal_number_type._id || '',
  bank_fiscal_number: companyRegister?.last_bank_account?.fiscal_number || '',
  account_name: companyRegister?.last_bank_account?.name || '',
  last_name: companyRegister?.last_bank_account?.last_name || '',
  bank_certification: companyRegister?.bank_certification?.url || '',

  'credit_request?': companyRegister?.[`credit_request?`] ?? false,
  requested_credit_amount: companyRegister?.requested_credit_amount
    ? flatFormat(companyRegister?.requested_credit_amount)
    : '',
  last_year_financial_statements:
    companyRegister?.last_year_financial_statements?.url || '',
  last_income_tax_return: companyRegister?.last_income_tax_return?.url || '',

  'requires_prefecture?': companyRegister?.['requires_prefecture?'] || false,
  prefectural_closing_date: companyRegister?.prefectural_closing_date
    ? new Date(companyRegister?.prefectural_closing_date)
    : new Date(),

  tentative_max_declared_value: defaultTentativeMaxDeclaredValue,
  industry_type_cd: companyRegister?.industry_type?.id || '',

  rut_document: companyRegister?.rut_document?.url || '',
  legal_representative_document:
    companyRegister?.legal_representative_document?.url || '',
  certificate_of_existence_and_legal_representation:
    companyRegister?.certificate_of_existence_and_legal_representation?.url ||
    '',
});

const CompanyRegisterForm = ({
  company,
  companyRegister = null,
  maxDeclaredValueOptions,
}) => {
  const currencyObject =
    CURRENCIES[
      Object.values(COUNTRY_CODE).find(
        (country) => country[0] === company?.geo_fence_id
      )[4]
    ];

  const tentativeMaxDeclaredValueEvaluated = findIndexByProperty(
    maxDeclaredValueOptions,
    'subunits',
    companyRegister?.tentative_max_declared_value?.subunits
  );

  const defaultTentativeMaxDeclaredValue = () => {
    if (company?.geo_fence_id === COUNTRIES_IDS_BY_NAME.colombia._id) {
      return tentativeMaxDeclaredValueEvaluated >= 0
        ? tentativeMaxDeclaredValueEvaluated
        : '';
    } else {
      return companyRegister?.tentative_max_declared_value
        ? flatFormat(companyRegister?.tentative_max_declared_value)
        : '';
    }
  };
  const { handleSubmit, watch, control, errors } = useForm({
    defaultValues: getDefaultValues(
      companyRegister,
      defaultTentativeMaxDeclaredValue
    ),
    resolver: yupResolver(
      generateSchema({
        isFromMexico:
          company?.geo_fence_id === COUNTRIES_IDS_BY_NAME.mexico._id,
      })
    ),
  });
  const { translate } = useContext(I18nContext);
  const [, dispatch] = useStateValue();

  const [rut, setRut] = useState(null);
  const [legalRepresentantionDocument, setLegalRepresentativeDocument] =
    useState(null);
  const [
    legalRepresentationCertification,
    setLegalRepresentationCertification,
  ] = useState(null);

  const [bankCertification, setBankCertification] = useState(null);

  const [last_year_financial_statements, setLastYearFinancialStatement] =
    useState(null);
  const [last_income_tax_return, setLastYearTaxReturn] = useState(null);

  const messageOk = () => {
    modalMessage(
      '',
      translate('app.setting.companyRegister.ok'),
      'success',
      translate('accept'),
      true
    );
  };

  const evalCreditRequest = companyRegister['credit_request?'] !== watch('credit_request?') && watch('credit_request?') !== false;
  const evalRequiresPrefecture = companyRegister?.['requires_prefecture?'] !== watch('requires_prefecture?') && watch('requires_prefecture?') !== false;

  const evalIfCompanyNeedCreditOrPrefecture =  evalRequiresPrefecture || evalCreditRequest;

  const evalButtonDisabled = () =>{
    if (evalIfCompanyNeedCreditOrPrefecture){
      return false
    }

    return ![
      COMPANY_REGISTRATION_STATUSES.unapproved,
      COMPANY_REGISTRATION_STATUSES.refused,
    ].includes(companyRegister.registration_status) ||
    !isObjectEmpty(errors);
  }


  const uploadDocuments = async (values) => {
    const credit_request = values['credit_request?'];
    const filesToUpload = [];

    rut && filesToUpload.push(rut);

    bankCertification && filesToUpload.push(bankCertification);

    legalRepresentantionDocument &&
      filesToUpload.push(legalRepresentantionDocument);

    legalRepresentationCertification &&
      filesToUpload.push(legalRepresentationCertification);

    credit_request &&
      last_year_financial_statements &&
      filesToUpload.push(last_year_financial_statements);

    credit_request &&
      last_income_tax_return &&
      filesToUpload.push(last_income_tax_return);

      return await Promise.all(
        filesToUpload.map(
          async (file) => await uploadFile(file, Date.parse(new Date()))
        )
      )
        .then(async (files) => files)
        .catch((e) => setPostLoading(dispatch, false));

  }

  const createCompanyFiles = (values, files) => {
    const {
      rut_document,
      bank_certification,
      legal_representative_document,
      certificate_of_existence_and_legal_representation,
      last_year_financial_statements,
      last_income_tax_return,
    } = values;
  
    const company_files = {
      rut_document,
      bank_certification,
      legal_representative_document,
      certificate_of_existence_and_legal_representation,
      last_year_financial_statements,
      last_income_tax_return,
    };

    files.forEach((file) => {
      const key = getFileNameFromAWSUrlKey(file.key);
      company_files[key] = file.Location;
    });
  
    return company_files;
  };
  
  const createPayload = (values, company_files) => {
    const {
      bank_account_type_id,
      account_number,
      bank_id,
      account_name,
      last_name,
      bank_fiscal_number_type_id,
      tax_liability,
      bank_fiscal_number,
      tentative_max_declared_value,
      requested_credit_amount,
      last_year_financial_statements,
      last_income_tax_return,
      ...company_info
    } = values;
  
    const tentativeMaxDeclaredValueFormatted = replaceKeys(
      maxDeclaredValueOptions[tentative_max_declared_value],
      ['subunits', 'iso'],
      ['cents', 'currency_iso']
    );
  
    if (companyRegister.registration_status === COMPANY_REGISTRATION_STATUSES.approved) {
      let payload = {};
      if(evalCreditRequest){
        const { last_year_financial_statements, last_income_tax_return } = company_files
        payload = {
          company: {
            'credit_request?': true,
            requested_credit_amount: {
              cents: requested_credit_amount * currencyObject.subUnitToUnit,
              currency_iso: currencyObject.iso,
            }
          },
          company_files: cleanObject({
            last_year_financial_statements,
            last_income_tax_return,
            bank_certification: undefined,
          }),
      };
    }
    if(evalRequiresPrefecture){
      const { prefectural_closing_date } = values;
      payload = {
        ...payload,
        company: {
          ...payload?.company,
          prefectural_closing_date,
          'requires_prefecture?': true,
        },
      };
    }
    return payload;
  }
  
    return {
      company: {
        ...company_info,
        requested_credit_amount: {
          cents: requested_credit_amount * currencyObject.subUnitToUnit,
          currency_iso: currencyObject.iso,
        },
        form_solution: { tax_liability },
        tentative_max_declared_value:
          company?.geo_fence_id === COUNTRIES_IDS_BY_NAME.colombia._id
            ? tentativeMaxDeclaredValueFormatted
            : {
                cents: requested_credit_amount * currencyObject.subUnitToUnit,
                currency_iso: currencyObject.iso,
              },
      },
      bank_account: {
        bank_account_type_id,
        number: account_number,
        bank_id,
        name: account_name,
        last_name,
        bank_fiscal_number_type_id,
        fiscal_number: bank_fiscal_number,
        bank_certification: company_files.bank_certification,
      },
      company_files: cleanObject({
        ...company_files,
        bank_certification: undefined,
      }),
    };
  };
  
  const mapValuesForPayload = async (values,files) => {
    const company_files = createCompanyFiles(values, files);
    const payload = createPayload(values, company_files);
  
    const callback = await uploadCompanyInfo(dispatch, company._id, payload);
  
    if (callback?.status) {
      messageOk();
    }
  };

  const submit = async (values) => {
    setPostLoading(dispatch, true);
    const files = await uploadDocuments(values)
    mapValuesForPayload(values, files);
  };

  const {approved, pending_update} = COMPANY_REGISTRATION_STATUSES;

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container style={{ gap: '1rem' }}>
        <CompanyGeneral control={control} errors={errors} />
        <BankAccount
          control={control}
          errors={errors}
          companyRegister={companyRegister}
          setBankCertification={setBankCertification}
        />
        <FieldsContainer container item xs={12} md={5}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="credit_request?"
                render={({ onChange, value }) => (
                  <FormControl variant="outlined" size="small">
                    <FormControlLabel
                      value={value}
                      control={<Checkbox color="primary" checked={value} />}
                      onChange={(e) => onChange(e.target.checked)}
                      label={translate(
                        'app.setting.companyRegister.credit_request?'
                      )}
                      labelPlacement="end"
                    />
                  </FormControl>
                )}
              />
            </Grid>
            {watch('credit_request?') && (
              <>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="requested_credit_amount"
                    render={({ onChange, value }) => (
                      <NumberFormat
                        thousandSeparator
                        customInput={TextField}
                        variant="outlined"
                        size="small"
                        fullWidth
                        label={translate(
                          'app.setting.companyRegister.requested_credit_amount'
                        )}
                        value={value}
                        onValueChange={(values) => {
                          const { floatValue } = values;
                          onChange(floatValue);
                        }}
                        prefix={'$'}
                        allowNegative={false}
                        error={!!errors?.requested_credit_amount}
                        helperText={translate(
                          errors?.requested_credit_amount?.message
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="last_year_financial_statements"
                    render={({ onChange, value }) => (
                      <ButtonUploadFileModal
                        type_cd={4}
                        buttonColor={
                          COLORS_BY_STATUS_FOR_DOCUMENT_HISTORY[
                            companyRegister.last_year_financial_statements
                              ?.status
                          ]
                        }
                        onChange={(file, url) => {
                          onChange(url);
                          setLastYearFinancialStatement(file);
                        }}
                        error={!!errors?.last_year_financial_statements}
                        errorText={translate(
                          errors?.last_year_financial_statements?.message
                        )}
                        value={value}
                        buttonText={translate(
                          'app.setting.companyRegister.last_year_financial_statements'
                        )}
                        variant="outlined"
                        titleText={translate(
                          'app.setting.companyRegister.last_year_financial_statements'
                        )}
                        customValidationForHandleUpload={[approved, pending_update].includes(companyRegister.registration_status) && !evalCreditRequest}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="last_income_tax_return"
                    render={({ onChange, value }) => (
                      <ButtonUploadFileModal
                        type_cd={5}
                        buttonColor={
                          COLORS_BY_STATUS_FOR_DOCUMENT_HISTORY[
                            companyRegister.last_income_tax_return?.status
                          ]
                        }
                        onChange={(file, url) => {
                          onChange(url);
                          setLastYearTaxReturn(file);
                        }}
                        error={!!errors?.last_income_tax_return}
                        errorText={translate(
                          errors?.last_income_tax_return?.message
                        )}
                        value={value}
                        buttonText={translate(
                          'app.setting.companyRegister.last_income_tax_return'
                        )}
                        variant="outlined"
                        titleText={translate(
                          'app.setting.companyRegister.last_income_tax_return'
                        )}
                        customValidationForHandleUpload={[approved, pending_update].includes(companyRegister.registration_status) && !evalCreditRequest}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </FieldsContainer>
        <FieldsContainer item xs={12} md={5}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="requires_prefecture?"
                render={({ onChange, value }) => (
                  <FormControl variant="outlined" size="small">
                    <FormControlLabel
                      value={value}
                      onChange={(e) => onChange(e.target.checked)}
                      control={<Checkbox color="primary" checked={value} />}
                      label={translate(
                        'app.setting.companyRegister.requires_prefecture?'
                      )}
                      labelPlacement="end"
                    />
                  </FormControl>
                )}
              />
            </Grid>
            {watch('requires_prefecture?') && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="prefectural_closing_date"
                  render={({ onChange, value }) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        inputVariant="outlined"
                        size="small"
                        format="dd/MM/yyyy"
                        label={translate(
                          'app.setting.companyRegister.prefectural_closing_date'
                        )}
                        value={value}
                        onChange={(date) => onChange(date)}
                        error={Boolean(errors?.prefacture)}
                        helperText={
                          errors.prefacture &&
                          translate(errors.prefacture.message)
                        }
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </Grid>
            )}
          </Grid>
        </FieldsContainer>

        <AboutShipping control={control} errors={errors} />

        <FieldsContainer item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                {translate('app.setting.companyRegister.uploadDocuments')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="rut_document"
                render={({ onChange, value }) => (
                  <ButtonUploadFileModal
                    type_cd={0}
                    buttonColor={
                      COLORS_BY_STATUS_FOR_DOCUMENT_HISTORY[
                        companyRegister?.rut_document?.status
                      ]
                    }
                    onChange={(file, url) => {
                      onChange(url);
                      setRut(file);
                    }}
                    error={!!errors?.rut_document}
                    errorText={
                      errors?.rut_document &&
                      translate(errors.rut_document.message)
                    }
                    value={value}
                    buttonText={translate('app.setting.companyRegister.rut')}
                    variant="outlined"
                    titleText={translate('app.setting.companyRegister.rut')}
                    helperText={translate(
                      'app.setting.companyRegister.document.helperText'
                    )}
                    customValidationForHandleUpload={[approved, pending_update].includes(companyRegister.registration_status)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="legal_representative_document"
                render={({ onChange, value }) => (
                  <ButtonUploadFileModal
                    type_cd={1}
                    buttonColor={
                      COLORS_BY_STATUS_FOR_DOCUMENT_HISTORY[
                        companyRegister?.legal_representative_document?.status
                      ]
                    }
                    onChange={(file, url) => {
                      onChange(url);
                      setLegalRepresentativeDocument(file);
                    }}
                    error={!!errors?.legal_representative_document}
                    errorText={
                      errors?.legal_representative_document &&
                      translate(errors?.legal_representative_document.message)
                    }
                    value={value}
                    buttonText={translate(
                      'app.setting.companyRegister.legal_representative_document'
                    )}
                    variant="outlined"
                    titleText={translate(
                      'app.setting.companyRegister.legal_representative_document'
                    )}
                    customValidationForHandleUpload={[approved, pending_update].includes(companyRegister.registration_status)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="certificate_of_existence_and_legal_representation"
                render={({ onChange, value }) => (
                  <ButtonUploadFileModal
                    type_cd={2}
                    buttonColor={
                      COLORS_BY_STATUS_FOR_DOCUMENT_HISTORY[
                        companyRegister
                          ?.certificate_of_existence_and_legal_representation
                          ?.status
                      ]
                    }
                    onChange={(file, url) => {
                      onChange(url);
                      setLegalRepresentationCertification(file);
                    }}
                    error={
                      !!errors?.certificate_of_existence_and_legal_representation
                    }
                    errorText={
                      errors?.certificate_of_existence_and_legal_representation &&
                      translate(
                        errors
                          ?.certificate_of_existence_and_legal_representation
                          .message
                      )
                    }
                    value={value}
                    buttonText={translate(
                      'app.setting.companyRegister.certificate_of_existence_and_legal_representation'
                    )}
                    variant="outlined"
                    titleText={translate(
                      'app.setting.companyRegister.certificate_of_existence_and_legal_representation'
                    )}
                    customValidationForHandleUpload={[approved, pending_update].includes( )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </FieldsContainer>
        <Grid container item xs={12} justify="center">
          <Grid
            container
            direction="column"
            item
            xs={12}
            alignContent="center"
            sx={{ mb: '2rem' }}
          >
            <Controller
              control={control}
              name="has_accepted_corporate_tc"
              render={({ onChange, value }) => (
                <Grid container alignItems="center" direction="column">
                  <Grid item sx={12}>
                    <FormControlLabel
                      onChange={(e) => onChange(e.target.checked)}
                      control={<Checkbox checked={value} color="primary" />}
                      label={
                        <Link
                          href={TERMS_CORPORATE_URL}
                          target="_blank"
                          rel="noopener"
                        >
                          <Typography
                            variant="body1"
                            color="primary"
                            style={{ textDecoration: 'underline' }}
                          >
                            {translate(
                              'app.setting.companyRegister.termAndConditions'
                            )}
                          </Typography>
                        </Link>
                      }
                    />
                    {errors?.has_accepted_corporate_tc && (
                      <FormHelperText
                        error={Boolean(
                          errors.has_accepted_corporate_tc
                        )}
                        style={{ marginLeft: '1rem' }}
                      >
                        {translate(
                          errors.has_accepted_corporate_tc
                            .message
                        )}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
          <Grid
            container
            direction="column"
            item
            xs={12}
            alignContent="center"
            sx={{ mb: '2rem' }}
          >
            <Controller
              control={control}
              name="has_authorized_personal_data_treatment"
              render={({ onChange, value }) => (
                <Grid container alignItems="center" direction="column">
                  <Grid item sx={12}>
                    <FormControlLabel
                      onChange={(e) => onChange(e.target.checked)}
                      control={<Checkbox checked={value} color="primary" />}
                      label={
                        <Link
                          href={`${TERMS_GENERAL_URL}#page=10`}
                          target="_blank"
                          rel="noopener"
                        >
                        <Typography
                          variant="body1"
                          color="primary"
                          style={{ textDecoration: 'underline' }}
                          >
                          {translate(
                            'app.setting.companyRegister.authorizationForPersonalDataProcessingAccepted'
                            )}
                        </Typography>
                            </Link>
                      }
                    />
                    {errors?.has_authorized_personal_data_treatment && (
                      <FormHelperText
                        error={Boolean(
                          errors?.has_authorized_personal_data_treatment
                        )}
                        style={{ marginLeft: '1rem' }}
                      >
                        {translate(
                          errors?.has_authorized_personal_data_treatment.message
                        )}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
          <Grid
            container
            direction="column"
            item
            xs={12}
            alignContent="center"
            sx={{ mb: '2rem' }}
          >
            <Controller
              control={control}
              name="has_authorized_credit_report_inquiry"
              render={({ onChange, value }) => (
                <Grid container alignItems="center" direction="column">
                  <Grid item sx={12}>
                    <FormControlLabel
                      onChange={(e) => onChange(e.target.checked)}
                      control={<Checkbox checked={value} color="primary" />}
                      label={
                        <Link
                          href={`${TERMS_CORPORATE_URL}'#page=5`}
                          target="_blank"
                          rel="noopener"
                        >
                          <Typography
                            variant="body1"
                            color="primary"
                            style={{ textDecoration: 'underline' }}
                          >
                            {translate(
                              'app.setting.companyRegister.authorizationForConsultingCreditBureausAccepted'
                            )}
                          </Typography>
                        </Link>
                      }
                    />
                    {errors?.has_authorized_credit_report_inquiry && (
                      <FormHelperText
                        error={Boolean(
                          errors?.has_authorized_credit_report_inquiry
                        )}
                        style={{ marginLeft: '1rem' }}
                      >
                        {translate(
                          errors?.has_authorized_credit_report_inquiry.message
                        )}
                      </FormHelperText>
                    )}
                    <Grid item sx={12}>
                      <FormHelperText style={{ margin: '1rem' }}>
                        {translate(
                          'app.setting.companyRegister.termAndConditions.description'
                        )}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            />
          </Grid>

          <Button
            disabled={evalButtonDisabled()}
            color="primary"
            variant="contained"
            type="submit"
          >
            {translate('send')}
          </Button>
        </Grid>
        <Grid container item justify="center" sx={12}>
          {companyRegister?.registration_status === 'refused' &&
            companyRegister?.rejection_reason && (
              <Alert severity="error">
                <AlertTitle>
                  {translate('app.setting.companyRegister.rejection_reason')}
                </AlertTitle>
                {companyRegister?.rejection_reason}
              </Alert>
            )}
        </Grid>
      </Grid>
    </form>
  );
};

export default CompanyRegisterForm;
