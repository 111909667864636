import React, { useContext } from 'react';
import { I18nContext } from 'translations';
import { Backdrop, Button, Typography } from '@material-ui/core';

export default function BackdropSync({ text, onClose, showButton = true, txtButton, btnAction }) {
  const { translate } = useContext(I18nContext);

  return(
    <Backdrop open={true} style={{ zIndex: 9999 }}>
      <div style={{ background: 'white', padding: '40px 30px 20px', textAlign: 'center' }}>

        <img
          src="/images/general/sync.gif"
          alt="processing"
          style={{ height: '200px' }}
        />
        <br /><br />
        {text && <Typography>{translate(text)}</Typography>}

        {showButton &&
          <div className="text-right" style={{ marginTop: 15 }}>
            <Button
              onClick={txtButton ? () => btnAction() : onClose}
              variant="contained"
              color="primary"
              size="large"
            >
              {txtButton ? txtButton : translate('close')}
            </Button>
          </div>
        }
      </div>
    </Backdrop>
  )
}