const handleErrorMessage = (
  { response },
  dispatch,
  defaultMessage = 'error.OppsAnErrorHasOccurredTryAgainLater'
) => {
  if (response !== undefined) {
    if (response.status === 422) {
      if (response.data.mssg === ''){
        return defaultMessage
      }else{
        return response.data.mssg;
      }
    } else if (response.status === 403) {
      setTimeout(() => {
        dispatch({
          type: 'CLOSE_SESSION',
          session: null,
        });
      }, 5000);
      return response.data.mssg;
    } else {
      return defaultMessage;
    }
  }
  return defaultMessage;
};

export default handleErrorMessage;