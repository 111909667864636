import React, { useContext } from 'react';
import {
  Table,
  Checkbox,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  FormGroup,
  IconButton,
  FormControl,
  FormControlLabel
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { I18nContext } from 'translations';
import { CATEGORIES_COMMODATE } from 'utils/constants';

export default function ModalTableCategoryCommodate ({
  data,
  isReceive,
  commodateEdit,
  setDataRequest,
  selectedCategory,
  setSelectedCategory
}) {
  const { translate } = useContext(I18nContext);

  return (
    <Table size="small" stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>{translate('commodate.tableCategory.item')}</TableCell>
          <TableCell>{translate('commodate.tableCategory.itemStatus')}</TableCell>
          <TableCell>{translate('commodate.tableCategory.details')}</TableCell>
          {commodateEdit === null && <TableCell>{translate('commodate.tableCategory.actions')}</TableCell>}
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.commodates_attributes.map((info, index) => (
          <TableRow
            hover
            key={index}
          >
            <TableCell>
              {info.category_cd >= 0 ? translate(CATEGORIES_COMMODATE[info.category_cd]) : ''}
            </TableCell>
            <TableCell>
              {info.status?.name ?? 'N/A'}
            </TableCell>
            <TableCell>
              {info.description ?? 'N/A'}
            </TableCell>
            {commodateEdit === null && !isReceive &&
              <TableCell>
                <IconButton
                  disabled={data.commodates_attributes?.length === 1 && true}
                  onClick={() => {
                    setDataRequest((prevData) => {
                      const items = prevData?.commodates_attributes?.filter((item, i) => i !== index)
                      return {
                        ...prevData,
                        commodates_attributes: [...items]
                      }
                    })
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </TableCell>
            }
            {isReceive &&
              <TableCell>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox
                        color="primary"
                        disabled={!info.receivable}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedCategory([
                              ...selectedCategory,
                              {
                                id: info._id,
                                ack: e.target.checked
                              }
                            ]);
                          } else {
                            setSelectedCategory(selectedCategory.filter((cat) => cat.id !== info._id));
                          }
                        }}
                      />}
                    />
                  </FormGroup>
                </FormControl>
              </TableCell>
            }
            {info.incidents?.length > 0 ? info.incidents.map((p, index) => (
              <TableCell key={`incidents-${index}`}>
                {p.category}
                <small><br />{p.details}</small>
              </TableCell>
            )) : <TableCell></TableCell>}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
