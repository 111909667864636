import React, { useContext, useEffect, useState, useCallback } from 'react';
import {
  Grid,
  Dialog,
  Button,
  Select,
  Divider,
  MenuItem,
  Checkbox,
  TextField,
  Typography,
  InputLabel,
  FormControl,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useStateValue } from 'context/store';
import { getCostCenter } from 'context/express/actions';
import { createAssingBalance } from 'context/balances/actions';
import { I18nContext } from 'translations';
import Loading from 'components/Loading';
import { INITIAL_PAGE } from 'utils/constants';
import { toIsoString, modalMessage } from 'utils';
import schema from './schema'

function ModalAssignBalances ({
  open,
  setOpen,
  codeInfo,
  setCodeInfo,
  setIsCheckAll,
  getlistPassengers
}) {
  const { langCode, translate } = useContext(I18nContext);
  const [costCenter, setCostCenter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [{ checkin: { company } }, dispatch ] = useStateValue();

  const { handleSubmit, control, errors, register, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const listCostCenter = useCallback(async () => {
    const callback = company && (await getCostCenter(company._id));
    callback && callback.costCenter && setCostCenter(callback.costCenter);
  }, [company]);

  useEffect(() => {
    listCostCenter();
  }, [listCostCenter]);

  const messageOk = () => {
    setLoading(false);
    modalMessage('', translate('balances.modal.actions.assignedOk'), 'success', translate('continue'), true).then(async() => {
      setOpen(false);
      getlistPassengers(INITIAL_PAGE);
      setCodeInfo([])
    })
  }

  const submit = async (values) => {
    setLoading(true);
    
    let passengers = codeInfo.map((pssg => pssg._id))
    const params = {
      passengers: passengers,
      ...values,
      ini_date: values.ini_date ? toIsoString(values.ini_date) : null,
      end_date: values.end_date ? toIsoString(values.end_date) : null,
    };

    const callback = await createAssingBalance(dispatch, params, company._id, langCode);
    callback?.status ? messageOk() : setLoading(false)
  }

  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
      className="modal-assign-promo-code"
    >
      <DialogTitle className="text-center" disableTypography style={{ paddingTop: 30 }}>
        <Typography variant="h5" color="primary">
          {translate('balances.title')}
        </Typography>
        <Typography variant="h4" color="primary">
          {codeInfo && codeInfo?.length}
        </Typography>
      </DialogTitle>
      <DialogContent className="content-modal">
        {loading && <Loading />}
        <form
          onSubmit={handleSubmit(submit)}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={2}>
            {codeInfo?.length === 1 &&
              <Grid container className="content-form-assign-code">
                <Grid item xs={12}>
                  <Typography variant="subtitle1">{codeInfo[0].name}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">{codeInfo[0].email}</Typography>
                </Grid>
              </Grid>
            }
              <Grid container className="content-form-assign-code">
                
                <Grid item sm={12} xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item md={6} sm={4} xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <Controller
                          name="ini_date"
                          control={control}
                          defaultValue={null}
                          render={({ onChange, value }) => (
                            <KeyboardDateTimePicker  
                              size="small"
                              inputVariant="outlined"
                              label={translate('balances.modal.form.startDate')}
                              format="dd/MM/yyyy"
                              cancelLabel={translate('cancel')}
                              okLabel={translate('accept')}
                              minDate={new Date()}
                              invalidDateMessage={translate('invalidFormat')}
                              fullWidth
                              value={value}
                              onChange={onChange}
                              error={!!errors.ini_date}
                              helperText={errors.ini_date && translate(errors.ini_date.message)}
                            />
                          )}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={6} sm={4} xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <Controller
                          name="end_date"
                          control={control}
                          defaultValue={null}
                          render={({ onChange, value }) => (
                            <KeyboardDateTimePicker 
                              size="small"
                              inputVariant="outlined"
                              label={translate('balances.modal.form.expirationDate')}
                              format="dd/MM/yyyy"
                              cancelLabel={translate('cancel')}
                              okLabel={translate('accept')}
                              minDate={new Date()}
                              invalidDateMessage={translate('invalidFormat')}
                              fullWidth
                              value={value}
                              onChange={onChange}
                              error={!!errors.end_date}
                              helperText={errors.end_date && translate(errors.end_date.message)}
                            />
                          )}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={6} sm={4} xs={6}>
                      <Controller
                        control={control}
                        name="amount"
                        label={translate('balances.modal.form.totalBalance')}
                        defaultValue=""
                        as={
                          <TextField
                            type="text"
                            variant="outlined"  
                            fullWidth
                            size="small"
                            error={!!errors.amount}
                            helperText={errors.amount && translate(errors.amount.message)}
                          />
                        }
                      />
                    </Grid>
                    <Grid item md={6} sm={4} xs={6}>
                      <FormControl
                        variant="outlined"
                        size="small"
                        fullWidth
                      >
                        <InputLabel>
                          {translate('promoCode.form.costCenter')}
                        </InputLabel>
                        <Controller
                          control={control}
                          name="cost_center_id"
                          label={translate('promoCode.form.costCenter')}
                          variant="outlined"
                          defaultValue=""
                          as={
                            <Select>
                              <MenuItem value="">
                                {translate('app.bookings.filter.choose')}
                              </MenuItem>
                              {costCenter.map((center) => (
                                <MenuItem key={center._id} value={center._id}>
                                  {center.name}
                                </MenuItem>
                              ))}
                            </Select>
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Divider className="content-modal-form-divider" style={{ margin: '20px 0 25px' }} />
                </Grid>

                <Grid item sm={12} xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <Typography variant="subtitle2"><b>{translate('balances.modal.form.moveBalance')}</b> {translate('balances.modal.form.optional')}</Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormControl>
                        <FormControlLabel
                          control={<Checkbox
                            name="picap"
                            inputRef={register}
                            defaultChecked={false}
                          />}
                          label={translate('balances.modal.checkbox.picap')}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <FormControl>
                        <FormControlLabel
                          control={<Checkbox
                            name="pibox"
                            inputRef={register}
                            defaultChecked={false}
                          />}
                          label={translate('balances.modal.checkbox.pibox')}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  {(watch('picap') || watch('pibox')) &&
                    <Grid container spacing={2} alignItems="center" style={{ marginTop: 15 }}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">
                          {translate('balances.modal.form.sumValuesMustNotExceedTheTotalBalance')}*
                        </Typography>
                      </Grid>
                      {watch('picap') &&
                        <Grid item sm={6} xs={12}>
                          <Controller
                            control={control}
                            name="amount_picap"
                            label={translate('balances.modal.form.picapBalance')}
                            defaultValue=""
                            as={
                              <TextField
                                type="text"
                                variant="outlined"  
                                fullWidth
                                size="small"
                                error={!!errors.amount_picap}
                                helperText={errors.amount_picap && translate(errors.amount_picap.message)}
                              />
                            }
                          />
                        </Grid>
                      }
                      {watch('pibox') &&
                        <Grid item sm={6} xs={12}>
                          <Controller
                            control={control}
                            name="amount_pibox"
                            label={translate('balances.modal.form.piboxBalance')}
                            defaultValue=""
                            as={
                              <TextField
                                type="text"
                                variant="outlined"  
                                fullWidth
                                size="small"
                                error={!!errors.amount_pibox}
                                helperText={errors.amount_pibox && translate(errors.amount_pibox.message)}
                              />
                            }
                          />
                        </Grid>
                      }
                    </Grid>
                  }
                </Grid>
              </Grid>
          </Grid>
          
          <Grid item sm={12} xs={12}>
            <Grid container spacing={2} alignItems="center">
            <Grid item sm={4} xs={4}></Grid>
              <Grid item sm={4} xs={4}>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {translate('promoCode.form.assign.assignCode')}
                  </Button>
                  <Button
                    onClick={() => {
                      setOpen(false)
                      setCodeInfo([])
                      setIsCheckAll(false)
                    }}
                  >
                    {translate('close')}
                  </Button>
                </DialogActions>
              </Grid>
              <Grid item sm={4} xs={4}></Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default ModalAssignBalances;