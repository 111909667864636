import React, { useEffect, useState, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer
} from '@material-ui/core';
import { I18nContext } from 'translations';
import EmptyData from '../EmptyData';
import styles from '../style';
import { formatCost } from 'utils';
import { format } from 'date-fns';

const ListTransactionsTable = ({
  classes,
  data,
  recordsPerPage,
  getListTransactions
}) => {
  const [currentPage, setCurrentPage] = useState(data.page);
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    setCurrentPage(data.page);
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    getListTransactions(newPage);
  };

  if (!data || (data && data.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />;
  }

  return (
    <>
      <TableContainer>
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{translate('balances.balance.transactions.table.description')}</TableCell>
              <TableCell>{translate('balances.balance.transactions.table.date')}</TableCell>
              <TableCell>{translate('balances.balance.transactions.table.type')}</TableCell>
              <TableCell>{translate('balances.balance.transactions.table.balance')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data.data || []).map((balance) => (
              <TableRow
                hover
                key={balance.id}
                className={'active-table-code'}
              >
                <TableCell>
                  {balance?.description ?? '-'}
                </TableCell>
                <TableCell>
                  {balance?.created_at ? format(new Date(balance.created_at), 'dd/MM/yyyy, hh:mm a') : '-'} 
                </TableCell>
                <TableCell>
                  {balance?.type ?? '-'}
                </TableCell>
                <TableCell>
                  {formatCost(balance?.amount)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.total ? data.total : data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
    </>
  );
};

export default withStyles(styles)(ListTransactionsTable);
