import React, { useState, useContext } from 'react';
import { I18nContext } from 'translations';
import { Button } from '@material-ui/core';
import WooCommerceStoreConfigurationModal from './WooCommerceStoreConfigurationModal';

const WooCommerceStoreConfiguration = ({ store }) => {
  const { translate } = useContext(I18nContext);
  const [open, setOpen] = useState(false);
  return (
    <>
      <WooCommerceStoreConfigurationModal
        open={open}
        setOpen={() => setOpen(false)}
        store={store}
      />
      <Button color="secondary" size="small" onClick={() => setOpen(true)}>
        {translate('app.integrations.table.config')}
      </Button>
    </>
  );
};

export default WooCommerceStoreConfiguration;
