import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Link,
  Typography,
} from '@material-ui/core';
import { I18nContext } from 'translations';

const TermCheckBoxField = ({ control, errors, name, label, link }) => {
  const { translate } = useContext(I18nContext);

  return (
    <Controller
      name={name}
      control={control}
      render={({ onChange, value }) => (
        <FormControl error={!!errors?.[name]} required>
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
                name={name}
                color="primary"
              />
            }
            label={
              link ? (
                <Link href={link} target="_blank" color="primary">
                  <Typography
                    variant="body1"
                    color="primary"
                    style={{ textDecoration: 'underline' }}
                  >
                    {label}
                  </Typography>
                </Link>
              ) : (
                <Typography
                  variant="body1"
                  color="primary"
                  style={{ textDecoration: 'underline' }}
                >
                  {label}
                </Typography>
              )
            }
          />
          <FormHelperText>
            {errors?.[name] && translate(errors?.[name].message)}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default TermCheckBoxField;
