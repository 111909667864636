import { get, post, put } from 'api/requests';
import { urlGroupsCompanies, urlUpdateGroups } from 'api';
import { message } from '../../commons/actions';
import handleErrorMessage from 'utils/handleErrorMessage';
import { LIST_GROUPS, LIST_GROUPS_LOADING, GROUP_USER_LIST_LOADING, GROUP_USER_LIST } from './constants';

export const getListGroups = async (dispatch, idCompany, params) => {
  dispatch({ type: LIST_GROUPS_LOADING, loadingGroups: true });
  await get(`${urlGroupsCompanies}/${idCompany}/areas`, params)
    .then(({ data }) => {
      dispatch({
        type: LIST_GROUPS,
        listGroups: data
      });
      dispatch({ type: LIST_GROUPS_LOADING, loadingGroups: false });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({
        type: LIST_GROUPS_LOADING,
        listGroups: null,
        loadingGroups: false
      });
      message(dispatch, mssg);
    });
}

export const createGroup = async (dispatch, idCompany, company_area) => {
  let status = false;
  await post(`${urlGroupsCompanies}/${idCompany}/create_area`, { ...company_area })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message(dispatch, mssg);
    });
  return { status };
}

export const updateGroup = async (dispatch, id, company_area) => {
  let status = false;
  await put(`${urlUpdateGroups}/${id}`, { ...company_area })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message(dispatch, mssg);
    });
  return { status };
}

export const getGroupUserList = async (dispatch, params, idCompany) => {
  dispatch({ type: GROUP_USER_LIST_LOADING, loadingGroupUserList: true });
  await get(`${urlGroupsCompanies}/${idCompany}/users`, params)
    .then(({ data }) => {
      dispatch({
        type: GROUP_USER_LIST,
        groupUserList: data
      });
      dispatch({ type: GROUP_USER_LIST_LOADING, loadingGroupUserList: false });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({
        type: GROUP_USER_LIST_LOADING,
        groupUserList: null,
        loadingGroupUserList: false
      });
      message(dispatch, mssg);
    });
}