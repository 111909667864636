import React, { useContext } from 'react';
import { Typography, Dialog, Toolbar, IconButton, DialogContent, Button } from '@material-ui/core';
import { I18nContext } from 'translations';
import CloseIcon from '@material-ui/icons/Close';
import FailedScheduledTable from 'components/Tables/HoursDedicated/FailedScheduledTable';

const ModalFailedScheduled = ({ open, handleModalFailedScheduled, failedScheduled, successScheduled }) => {
  const { translate } = useContext(I18nContext);

  return (
    <Dialog fullWidth maxWidth="lg" open={open}>
      <Toolbar style={{ justifyContent: 'center' }}>
        <IconButton
          edge="end"
          color="primary"
          onClick={handleModalFailedScheduled}
          aria-label="close"
          style={{ position: 'absolute', right: 20, top: '10px' }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" color="textPrimary" className="text-center">
          {translate('scheduledReservations.servicesNotProcessed')}
        </Typography>
      </Toolbar>
      <DialogContent style={{ padding: '15px 30px 30px' }} className="text-center">
        <FailedScheduledTable
          data={failedScheduled}
        />
        {successScheduled &&
          <Button
            color="primary"
            href='/app/bookings'
            style={{ marginTop: '30px' }}
            variant="contained"
          >
            {translate('scheduledReservations.viewProcessedServices')}
          </Button>
        }
      </DialogContent>
    </Dialog>
  )
}

export default ModalFailedScheduled;