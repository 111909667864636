import React, { useState, useContext, useEffect } from 'react';
import {
  Dialog,
  Snackbar,
  Typography,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { modalMessage, formatDate } from 'utils';
import { INITIAL_PAGE, STEP_REQUEST_COMMODATE } from 'utils/constants';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { createCommodateLoan, updateCommodateLoan } from 'context/commodate/actions';
import { message as closeMessage } from 'context/commons/actions';
import Loading from 'components/Loading';
import FormRequestCommodate from './FormRequestCommodate';
import ModalConfirmRequestCommodate from './ModalConfirmRequestCommodate';
import styles from '../StyleModal';
import schema from './schema';

function ModalRequestCommodate ({
  open,
  isEdit,
  classes,
  openModal,
  companyId,
  getCommodate,
  commodateEdit
}) {
  const [dataRequest, setDataRequest] = useState(null);
  const [step, setStep] = useState(commodateEdit ? 1 : 0);
  const [loading, setLoading] = useState(false);
  const { translate } = useContext(I18nContext);
  const [
    {
      commons: { message },
      commodateLoan: { commodate }
    },
    dispatch,
  ] = useStateValue();

  const { handleSubmit, register, control, reset, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      commodates_attributes: [{}]
    }
  });

  const messageOk = () => {
    setLoading(false);
    modalMessage(
      '',
      translate('commodate.modalRequest.form.mssgOk'),
      'success',
      translate('accept'),
      true
    ).then(() => {
      openModal()
      getCommodate(INITIAL_PAGE);
    });
  };

  const goToNextStep = (values, driver) => {
    setLoading(false)
    setDataRequest({ ...values, driver });
    setStep(1);
  }

  const formatCommodateData = (values, step, commodate) => ({
    ...values,
    step: STEP_REQUEST_COMMODATE[step === 0 ? 0 : 1],
    return_date: formatDate(values?.return_date),
    driver: {
      ...commodate?.driver || null
    }
  });

  const submit = async values => {
    setLoading(true)
    setDataRequest(values)
    
    const commodateData = formatCommodateData(values, step, commodate);
    
    const callback = await createCommodateLoan(dispatch, companyId, { commodate_loan: { ...commodateData } })
    if (callback?.status) {
      step === 0 ? goToNextStep(values, callback.driver) : messageOk();
    } else {
      setLoading(false);
    }
  }
  
  const updateSubmit = async values => {
    setLoading(true)
    const callback = await updateCommodateLoan(dispatch, companyId, commodateEdit?._id, { commodate_loan: { ...values } })
    callback?.status && step === 1 ? messageOk() : setLoading(false);
  }

  const goBack = () => {
    setStep(0)
    reset({ ...dataRequest })
  }

  useEffect(() => {
    if (commodateEdit?._id) setDataRequest({ ...commodateEdit });
  }, [commodateEdit])

  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
      className={classes.modalPibox}
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">
          {commodateEdit ? `Comodato ${commodateEdit._id}` : translate('commodate.modalRequest.title')}
        </Typography>
      </DialogTitle>

      <DialogContent>
        {loading && <Loading />}
        {step === 0 ? 
          <FormRequestCommodate
            submit={submit}
            openModal={openModal}
            data={dataRequest}
            handleSubmit={handleSubmit}
            register={register}
            control={control}
            errors={errors}
          />
          :
          <ModalConfirmRequestCommodate
            data={dataRequest}
            openModal={openModal}
            goBack={goBack}
            submit={submit}
            setDataRequest={setDataRequest}
            commodateEdit={commodateEdit}
            updateSubmit={updateSubmit}
            isEdit={isEdit}
          />
        }
      </DialogContent>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </Dialog>
  )
}

export default withStyles(styles)(ModalRequestCommodate);
