import React, { useContext } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { I18nContext } from 'translations';
import ReplayIcon from '@material-ui/icons/Replay';

const QrComponent = ({ session, handleFetchQR, isLoading, isFetching }) => {
  const { translate } = useContext(I18nContext);

  return (
    <>
      <Grid
        container
        direction="column"
        item
        xs={6}
        justify="center"
        alignItems="center"
      >
        {isLoading || isFetching ? (
          <CircularProgress color="primary" />
        ) : (
          <>
            {session?.qrcode ? (
              <img src={session?.qrcode} alt="qrcode" />
            ) : (
              <Typography variant="h5" gutterBottom>
                {translate('whatsapp.qr.error')}
              </Typography>
            )}
            <Button
              type="button"
              color="primary"
              size="small"
              variant="contained"
              onClick={handleFetchQR}
              disabled={isLoading && isFetching}
            >
              {isFetching ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <ReplayIcon />
              )}
            </Button>
          </>
        )}
      </Grid>
      <Grid container item direction="column" xs={6}>
        <Typography variant="h4">
          {translate('whatsapp.instructions.title')}
        </Typography>
        <Typography variant="body1">
          {translate('whatsapp.instructions.body1')}
        </Typography>
        <Typography variant="body1">
          {translate('whatsapp.instructions.body2')}
        </Typography>
        <Typography variant="body1">
          {translate('whatsapp.instructions.body3')}
        </Typography>
        <Typography variant="body1">
          {translate('whatsapp.instructions.body4')}
        </Typography>
        <Typography variant="body1">
          {translate('whatsapp.instructions.body5')}
        </Typography>
        <Typography variant="body1">
          {translate('whatsapp.instructions.body6')}
        </Typography>
      </Grid>
    </>
  );
};

export default QrComponent;
