import React, { useContext, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
  Button
} from '@material-ui/core';
import { I18nContext } from 'translations';
import EmptyData from '../../EmptyData';
import styles from '../../style';

const FavoriteAddressesTable = ({
  classes,
  data,
  editAddress,
  removeAddress,
  chosenAddress,
	showSelectOption,
  recordsPerPage,
  getAddresses
}) => {
  const { translate } = useContext(I18nContext);
  const [currentPage, setCurrentPage] = useState(data.page);

  useEffect(() => {
    setCurrentPage(data.page)
  }, [data])

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage)
    getAddresses(newPage);
  }

  if (!data || (data && data.length <= 0)) {
   return <EmptyData message={translate('thereIsNoData')} />
  }

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell>{translate('app.settings.addresses.table.name')}</TableCell>
              <TableCell>{translate('app.settings.addresses.table.address')}</TableCell>
							<TableCell>{translate('app.settings.addresses.table.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data.map(address => (
              <TableRow hover key={address._id}>
                <TableCell className={classes.headquartersCell}>
                  {address?.name ?? translate('app.settings.addresses.table.noInformation')}
                </TableCell>
                <TableCell className={classes.headquartersCell}>
                  {address?.address ?? '-'}
                </TableCell>
                <TableCell className={showSelectOption ? classes.choseOption : classes.headquartersCell}>
                  {showSelectOption ? (
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() => chosenAddress(address)}
                    >
                      {translate('choose')}
                    </Button>
                  ) : (
                    <>
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        style={{ marginRight: 8 }}
                        onClick={() => editAddress(address)}
                      >
                        {translate('app.settings.addresses.table.edit')}
                      </Button>
                      <Button
                        color="secondary"
                        variant="contained"
                        size="small"
                        onClick={() => removeAddress(address)}
                      >
                        {translate('app.settings.addresses.table.remove')}
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.total ? data.total : data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
    </>
  );
}

function areEqual(prevProps, nextProps) {
  return prevProps.data === nextProps.data;
}

export default withStyles(styles)(React.memo(FavoriteAddressesTable, areEqual));

