import React, { useContext, Suspense, lazy } from 'react';
import { Typography, Dialog, Toolbar, IconButton, LinearProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { I18nContext } from 'translations';
import Loading from 'components/Loading';
const CoordinatesMap = lazy(() => import('components/Maps/CoordinatesMap'));

const ModalMap = ({
  open,
  handleModalMap,
  city,
  changeAddress,
  address,
  type,
  loadingAproxAddress,
  index,
  id
}) => {
  const { translate } = useContext(I18nContext);

  return (
    <Dialog fullScreen open={open} onClose={handleModalMap} style={{ zIndex: 9999 }}>
      <Toolbar style={{ minHeight: '110px', boxShadow: '2px 1px 4px #9e9e9e' }}>
        <IconButton
          edge="end"
          color="primary"
          onClick={handleModalMap}
          aria-label="close"
          style={{ position: 'absolute', right: 20, top: '10px' }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="body1" style={{ paddingLeft: 20 }}>
          <b>{translate('app.express.city')}: </b>{city.name}
          <br />
          <b>{translate('app.express.address')}: </b>{address ? address.name : ' - '}
          <br />
          <small style={{ color: '#828080' }}>
            {translate('app.express.moveMarkerOnTheMapToGetAnAddress')}
          </small>
        </Typography>
      </Toolbar>
      <div style={{ position: 'relative', minHeight: 'calc(100vh - 120px)', marginTop: 10 }}>
        {loadingAproxAddress && <Loading />}
        <Suspense fallback={<LinearProgress color="primary" />}>
          <CoordinatesMap
            city={city}
            address={address}
            changeAddress={changeAddress}
            type={type}
            index={index}
            id={id}
          />
        </Suspense>
      </div>
    </Dialog>
  )
}

export default ModalMap;