import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
const graphqlUrlBase = process.env.REACT_APP_API_URL_BASE_GRAPHQL;

const httpLink = createHttpLink({
  uri: `${graphqlUrlBase}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('session_token');
  return {
    headers: {
      ...headers,
      'x-pi-token': token ? token : "",
    }
  }
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});