import React, { useState, useContext, useEffect } from 'react';
import { Grid, TextField, Typography, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { I18nContext } from 'translations';
import AutoCompleteField from 'components/CustomInputs/AutoCompleteField';
import ModalMap from 'components/Modals/ModalMap';
import useAddressInput from './hooks/useAddressInputs';

export default function AddressInputs ({
  register,
  address,
  setAddress,
  control,
  errors,
  clearErrors,
  twoColumns,
  isAddress,
  cityId,
  handleCity,
  needCity,
  handleCityData = () => {}
}) {
  const { translate } = useContext(I18nContext);
  const [city, setCity] = useState(null);
  const { loadingAddress, handleModalMap, changeCoord, open, cities, addressPoint } = useAddressInput();
  
  useEffect(() => {
    setAddress(addressPoint)
  }, [addressPoint, setAddress])

  useEffect(() => {
    if (open && isAddress) {
      setAddress({name: control.getValues('address'), lat: control.getValues('lat'), lon: control.getValues('lon')})
    }
  }, [ setAddress, open, isAddress]) // eslint-disable-line

  useEffect(() => {
    if (!cityId && needCity) {
      handleCity(cities[0]._id)
      handleCityData(cities[0])
    }
  })

  return (
    <Grid item container justify="center" alignItems="flex-start" spacing={2}>
      {cities && cities.length && (
        <>
        <Grid item xs={12} md={twoColumns ? 6 : 12}>
          <Autocomplete
            id="cities"
            options={cities}
            autoHighlight
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
            value={city ? city : cityId ? cities.filter(id => id._id === cityId)[0] : cities[0]}
            onChange={(event, newValue) => {
              event.stopPropagation();
              setCity(newValue);
              needCity && handleCity(newValue ? newValue._id : null)
              needCity && handleCityData(newValue)
            }}
          />
        </Grid>
        <Grid item md={twoColumns ? 6 : 12} sm={6} xs={12}>
          <AutoCompleteField
            title={translate('app.express.address')}
            name="address"
            cityId={city ? city._id : cities[0]._id}
            control={control}
            defaultValue=""
            address={address}
            clearErrors={clearErrors}
            required
          />
          {errors.address && <small className="red">{translate(errors.address.message)}</small>}
          <Typography
            color="textSecondary"
            variant="caption"
            className="line-height-xs inline-block"
          >
            <Button
              type="button"
              size="small"
              onClick={handleModalMap}
              color="primary"
              style={{ fontSize: '0.75rem', padding: '0px 5px' }}
            >
              {translate('app.express.help')}
            </Button>
          </Typography>
        </Grid>
        </>
      )}
      <Grid item md={12} sm={6} xs={12}>
        {!isAddress && (
          <TextField
            type="text"
            name="secondary_address"
            variant="outlined"
            label={translate('app.express.supplementaryAddress')}
            fullWidth
            inputRef={register}
            size="small"
          />
        )}
      </Grid>
      {open && (
        <ModalMap
          open={open}
          handleModalMap={handleModalMap}
          city={{
            lon: city ? city.location.lon : cities.length > 0 ? cities[0].location.lon : 0,
            lat: city ? city.location.lat : cities.length > 0 ? cities[0].location.lat : 0,
            name: city ? city.name : cities[0].name
          }}
          changeAddress={changeCoord}
          address={address && address}
          type={0}
          loadingAproxAddress={loadingAddress}
        />
      )}
    </Grid>
  )
}