import { object, boolean } from 'yup';

const schema = (termsAndConditionsForEvalRequired) => {
  const schemaObject = {};

  Object.keys(termsAndConditionsForEvalRequired).forEach((key) => {
    schemaObject[key] = boolean().oneOf([true], 'acceptTermsError');
  });

  return object().shape({
    ...schemaObject,
  });
};

export default schema;
