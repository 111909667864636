import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  userMenu: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      position: 'relative',
      '& span': {
        margin: '0 5px'
      }
    }
  },
  line: {
    height: 20
  },
  marginTop5: {
    marginTop: 5
  },
  photo: {
    width: 28,
    height: 28,
    borderRadius: '100%',
    objectFit: 'cover'
  }
}));

export default styles;