import React, { useContext, useState } from 'react';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Button, Tooltip } from '@material-ui/core';
import { I18nContext } from 'translations';
import { SIZE_CD, PACKAGES_STATUSES } from 'utils/constants';
import { formatCostTemporal } from 'utils';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

export default function StopsData({
  location,
  letter,
  icon,
  finished,
  packages,
  customer,
  type,
  bookingStatus,
  payToOrigin
}) {
  const { translate } = useContext(I18nContext);
  const [showCustomer, setShowCustomer] = useState(false);
  const [showIndication, setShowIndication] = useState(false);

  return (
    <Accordion defaultExpanded={true} className="booking-stops" expanded={true} square>
      <AccordionSummary>
        {letter === 'A' ?
          <Tooltip title={translate('app.booking.originTooltip')} placement="right-start">
            <span className={`${finished ? 'point-style point-style-finished' : 'point-style'}`}>{letter}</span>
          </Tooltip>
          : <span className={`${finished ? 'point-style point-style-finished' : 'point-style'}`}>{letter}</span>
        }
        {icon}
        <Typography variant="body1" color="textPrimary">
          {location}
          {payToOrigin &&
            <small>
              <br />
              {translate('app.booking.driverMustPay')}:
              <b> {payToOrigin}</b>
           </small>
          }
        </Typography>
      </AccordionSummary>
      {type > 0 && packages && (
        <AccordionDetails style={{ display: 'block', padding: packages.length > 0 ? '8px 16px 7px' : '0' }}>
          {packages?.map(pkg => (
            <Grid container alignItems="center" key={pkg._id}>
              <Grid item md={3} sm={3} xs={12} className="text-center">
                <img
                  src="/images/landing/benefits/deliveries.svg"
                  alt="Box"
                  style={{ height: 70, marginRight: 15 }}
                />
              </Grid>
              <Grid item md={9} sm={9} xs={12} className="package-data">
                <Typography variant="body2" color="textPrimary">
                  {`${translate('app.booking.orderNumber')}:`}
                  <b> {pkg.reference}</b>
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  {`${translate('app.booking.packageSize')}:`}
                  <b> {translate(SIZE_CD[pkg.size_cd])}</b>
                </Typography>
                {pkg.counter_delivery && 
                  <Typography>
                    {translate('app.booking.driverCollectsMoney')}: {pkg.collected_value && formatCostTemporal(pkg.collected_value)}
                  </Typography>
                }
                <Typography variant="body2" color="textPrimary">
                  {`${translate('app.booking.declaredValue')}:`}
                  <b> {pkg.declared_value && formatCostTemporal(pkg.declared_value)}</b>
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  {`${translate('app.booking.status')}:`}
                  <b> {translate(PACKAGES_STATUSES[pkg.status_cd])}</b>
                </Typography>
                {pkg?.validation_code && (
                  <Typography variant="body2" color="textPrimary">
                    <Tooltip title={translate('app.booking.deliveryCodeText')} placement="right-start">
                      <InfoOutlinedIcon style={{ marginRight: 5, verticalAlign: 'sub', fontSize: '1.1rem' }} />
                    </Tooltip>
                    {`${translate('app.booking.deliveryCode')}: `}
                    <b>{pkg.validation_code}</b>
                  </Typography>
                )}
                <Typography variant="body2" color="textPrimary">
                  {`${translate('app.booking.indications')}:`}
                  {pkg.indications?.length > 72 ?
                    <>
                      <b>{showIndication ? ` ${pkg.indications}` : ` ${pkg.indications.substring(0, 72)}... `}</b>
                      <Button
                        onClick={() => setShowIndication(!showIndication)}
                        size="small"
                      >
                        <b>{showIndication ? ` ${translate('seeLess')}` : ` ${translate('seeMore')}` }</b>
                      </Button>
                    </>
                  : <b> {pkg.indications}</b>}
                </Typography>
                {bookingStatus === 14 &&
                  <div className="text-right">
                    <Button href={pkg.booking_id} color="primary" target="_blank">
                      {translate('goTracking')}
                    </Button>
                  </div>
                }
              </Grid>
            </Grid>
          ))}
          <Grid container alignItems="center">
            {customer && (customer.name || customer.email || customer.phone) && (
              <Grid item md={12} xs={12}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => setShowCustomer(!showCustomer)}
                >
                  <span style={{ marginTop: '-3px' }}>{translate('app.booking.viewCustomerData')}</span> 
                  {showCustomer ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" /> }
                </Button>
              </Grid>
            )}
            {showCustomer && (
              <>
              <Grid item md={7} className="package-data">
                <Typography variant="body2" color="textPrimary">
                  {translate('app.booking.customer.name')}:
                  <b> {customer?.name ?? ' - '}</b>
                </Typography>
              </Grid>
              <Grid item md={5} className="package-data">
                <Typography variant="body2" color="textPrimary">
                  {translate('app.booking.customer.phone')}:
                  {customer.phone ? (
                    <a href={`tel:${customer?.country_code}${customer?.phone}`}>
                      <b> {`${customer?.country_code} ${customer?.phone}`}</b>
                    </a>
                  ): ' - '}
                </Typography>
              </Grid>
              <Grid item md={12} className="package-data">
                <Typography variant="body2" color="textPrimary">
                  {translate('app.booking.customer.email')}:
                  <b> {customer?.email ? customer.email : ' - '}</b>
                </Typography>
              </Grid>
              </>
            )}
          </Grid>
        </AccordionDetails>
      )}
		</Accordion>
  )
}