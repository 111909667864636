import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Grid, Select, FormControl, InputLabel } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { I18nContext } from 'translations';
import { PQRS } from 'utils/constants';
import schema from './schema';

const ContactForm = ({ submit, loading, pqr }) => {
  const { translate } = useContext(I18nContext);
  const { handleSubmit, register, errors, reset } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    loading && reset();
  }, [loading, reset])

  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            name="name"
            error={!!errors.name}
            label={translate('landing.contact.fullName')}
            helperText={errors.name && translate(errors.name.message)}
            type="text"
            inputRef={register}
            variant="outlined"
            size="small"
            required
            fullWidth
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            name="email"
            error={!!errors.email}
            label={translate('email')}
            helperText={errors.email && translate(errors.email.message)}
            type="email"
            inputRef={register}
            variant="outlined"
            size="small"
            required
            fullWidth
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          {pqr ? (
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel>
                {translate('pqrs.subject')}
              </InputLabel>
              <Select
                native
                inputRef={register}
                label={translate('pqrs.subject')}
                name="message_type"
              >
                {PQRS.map(p => (
                  <option key={p.value} value={translate(p.name)}>
                    {translate(p.name)}
                  </option>
                ))}
              </Select>
            </FormControl>
          ): (
            <TextField
              name="company_name"
              label={translate('landing.contact.company')}
              type="text"
              inputRef={register}
              variant="outlined"
              size="small"
              fullWidth
            />
          )}
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            name="phone"
            error={!!errors.phone}
            label={translate('landing.contact.contactPhone')}
            helperText={errors.phone && translate(errors.phone.message)}
            inputRef={register}
            type="tel"
            variant="outlined"
            size="small"
            required
            fullWidth
          />
        </Grid>
        {pqr && (
          <Grid item xs={12}>
            <TextField
              name="company_name"
              label={translate('landing.contact.company')}
              type="text"
              inputRef={register}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            name="message"
            error={!!errors.message}
            label={translate('landing.contact.message')}
            helperText={errors.message && translate(errors.message.message)}
            type="text"
            inputRef={register}
            multiline
            rows={4}
            variant="outlined"
            size="small"
            required
            fullWidth
          />
        </Grid>
      </Grid>
      <br /><br />
      <Button
        type="submit"
        color="primary"
        variant="contained"
        size="large"
        className="btn-rounded"
        disabled={loading}
      > 
        {translate('landing.contact.send')}
      </Button>
    </form>
  );
}

ContactForm.propTypes = {
  submit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default ContactForm;
