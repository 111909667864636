import { get, post, patch } from 'api/requests';
import { urlPQR, urlTypePQR, urlBookings } from 'api';
import {
  MESSAGE,
  SHOW_PQR,
  LIST_CLAIMS,
  LIST_TYPE_PQR
} from './constants';
import handleErrorMessage from 'utils/handleErrorMessage';
import { loading } from 'context/commons/actions';

export const listClaims = async (dispatch, params) => {
  let status = false;
  loading(dispatch, true);
  await get(urlPQR, { ...params })
    .then(({ data }) => {
      dispatch({
        type: LIST_CLAIMS,
        list: data,
      });
      loading(dispatch, false);
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      loading(dispatch, false);
      status = false;
    });
  return { status };
};

export const listTypePqr = async (dispatch) => {
  let loadingTypePqr = true;
  await get(urlTypePQR, {})
    .then(({ data }) => {
      dispatch({
        type: LIST_TYPE_PQR,
        typePqr: data,
      });
      loadingTypePqr = false;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      loading(dispatch, false);
      loadingTypePqr = false;
    });
  return { loadingTypePqr }
};

export const createPqr = async (dispatch, id, values) => {
  let status = false;
  await post(`${urlBookings}/${id}/pqrs`, { ...values })
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      status = false;
    });
  return { status };
};

export const showPqr = async (dispatch, id) => {
  let status = false;
  await get(`${urlPQR}/${id}`, {})
    .then(({ data }) => {
      dispatch({
        type: SHOW_PQR,
        pqr: data,
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      status = false;
    });
  return { status }
};

export const answerPqr = async (dispatch, id, values) => {
  let status = false;
  await patch(`${urlPQR}/${id}`, { ...values })
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      status = false;
    });
  return { status };
};