import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const Title = ({ title = 'Pibox', icon, subtitle }) => (
  <Grid container spacing={1} alignContent="center">
    <Grid item md={12} xs={12}>
      <Typography variant="h5" color="primary">
        {icon && icon}
        <span className="align-center">
          {title}
        </span>
      </Typography>
      <Typography variant="subtitle2">
        {subtitle}
      </Typography>
    </Grid>
  </Grid>
)

export default Title;