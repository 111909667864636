import { string, object, boolean } from 'yup';

export const schema = object().shape({
  passenger: object().shape({
    name: string().required('requiredField'),
    email: string().required('requiredField').email('invalidEmailAddress'),
    phone: string().required('requiredField').matches(/^[0-9]+$/, 'onlyNumbers'),
    password: string().required('requiredField').min(6, 'minSixCharacters'),
    has_accepted_tc: boolean().oneOf([true], 'acceptTermsError')
  }),
});

export const schemaFacebook = object().shape({
  passenger: object().shape({
    name: string().required('requiredField'),
    email: string().required('requiredField').email('invalidEmailAddress'),
    phone: string().required('requiredField').matches(/^[0-9]+$/, 'onlyNumbers'),
    has_accepted_tc: boolean().oneOf([true], 'acceptTermsError')
  }),
});

export const schemaProfile = object().shape({
  passenger: object().shape({
    name: string().required('requiredField'),
    email: string().required('requiredField').email('invalidEmailAddress'),
    phone: string().required('requiredField').matches(/^[0-9]+$/, 'onlyNumbers')
  }),
});