import React, { useContext, useState } from 'react';
import { Button } from '@material-ui/core';
import { I18nContext } from 'translations';
import ModalActivationVtex from './ModalVtex';

const VtexActivation = ({getIntegrations}) => {
  const { translate } = useContext(I18nContext);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        size="small"
        color="primary"
        style={{ fontSize: '0.93rem' }}
        onClick={() => setOpen(true)}
      >
        {translate('app.integrations.table.activate')}
      </Button>
      <ModalActivationVtex open={open} setOpen={setOpen} getIntegrations={getIntegrations}/>
    </>
  );
};

export default VtexActivation;
