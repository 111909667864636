import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  containerWhite: {
    backgroundColor: '#fff',
    padding: '25px 30px',
    margin: '10px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '25px 15px',
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: '30px',
    fontSize: '1.6em',
  },
  subtitle: {
    fontSize: '1.4em',
  },
  date: {
    color: '#6f2f82',
    fontWeight: 'bold',
    fontSize: '1.5em',
  },
  containerEvent: {
    marginTop: '0.5rem',
    borderRadius: '10px',
    shadowBox: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f5f5f5',
  },
  containerDate: {
    '& b': {
      verticalAlign: 'middle',
      fontFamily: 'amaranthbold',
      fontSize: '1.15em',
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.95em',
      },
    },
    '& > span': {
      padding: '0 10px 0 0',
    },
  },
  dot: {
    width: '8px',
    height: '8px',
    border: 'solid 2.7px #6f2f82',
    backgroundColor: '#fb9fc6',
    borderRadius: '50%',
    padding: 0,
    margin: '5px 20px -2px 10px',
    display: 'inline-block',
  },
  box: {
    width: '25px',
    margin: '0px 15px 0px 3px',
    verticalAlign: 'middle',
  },
  accordionHeader: {
    margin: '.02rem 0',
    minHeight: 0,
  },
  accordionContent: {
    padding: 0,
  },
  list: {
    padding: 0,
  },
}));

export default styles;
