import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Button,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers';
import { useForm, Controller } from 'react-hook-form';
import CachedIcon from '@material-ui/icons/Cached';
import { I18nContext } from 'translations';
import { BALANCE_CORPORATIVE_PROMOCODE_TYPES } from 'utils/constants';
import styles from '../../styleTableFilters';
import { schema } from './schema';

const defaultValues = {
  assigned_passenger: '',
  type_cd: ''
};

const AssingBalancesTableFilters = ({ classes, onFilters, filters, codeInfo, setOpen }) => {
  const { translate } = useContext(I18nContext);

  const { handleSubmit, control, reset, errors } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema)
  });

  const onSubmit = (values) => {
    const params = {
      ...filters,
      ...values
    };
    Object.keys(params).forEach((key) => {
      if (params[key] === '' || params[key] == null) {
        delete params[key];
      }
    });
    onFilters(params);
  };

  const clearFilters = () => {
    reset(defaultValues);
    onFilters("");
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      noValidate
      className="relative"
    >
      <Grid container spacing={1}>
        <Grid item md={3} sm={4} xs={6} className={classes.filter}>
          <Controller
            name="assigned_passenger"
            control={control}
            defaultValue=""
            render={({ onChange, value }) => (
              <TextField
                type="text"
                variant="outlined"
                label={translate('balances.table.users')}
                fullWidth
                size="small"
                onChange={onChange}
                value={value}
                error={!!errors.assigned_passenger}
                helperText={errors.assigned_passenger && translate(errors.assigned_passenger.message)}
              />
            )}
          />
        </Grid>

        <Grid item md={3} sm={4} xs={6}>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.filter}
            fullWidth
          >
            <InputLabel>{translate('balances.table.service_type')}</InputLabel>
            <Controller
              control={control}
              name="type_cd"
              label={translate('balances.table.service_type')}
              variant="outlined"
              defaultValue=""
              as={
                <Select>
                  <MenuItem value="">
                    {translate('app.bookings.filter.choose')}
                  </MenuItem>
                  {Object.keys(BALANCE_CORPORATIVE_PROMOCODE_TYPES).map((key) => (
                    <MenuItem key={key} value={key}>
                      {translate(BALANCE_CORPORATIVE_PROMOCODE_TYPES[key])}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Grid>

        <Grid item md={6} xs={12} className="text-center-sm">
          <Button
            variant="contained"
            color="primary"
            size="small"
            type="submit"
            style={{ width: 120, marginRight: 10 }}
          >
            {translate('app.bookings.filter.search')}
          </Button>
          <Button
            color="primary"
            size="small"
            type="button"
            style={{ width: 100 }}
            onClick={clearFilters}
          >
            {translate('clear')}
          </Button>
        </Grid>

        <Grid item md={6}  xs={12} className={codeInfo?.length > 1 ? 'active-table-code text-center-sm' : 'disabled-table-code text-center-sm'}>
          <span style={{ marginRight: 10 }} className="hidden-sm">{translate('promoCode.form.blockActions')}</span>
          <Button
            variant="contained"
            type="button"
            size="small"
            disableElevation
            style={{ marginRight: 10 }}
            className="active-primary-btn"
            onClick={() => {
              setOpen(true)
              reset(defaultValues)
            }}
          >
            {translate('balances.table.actions.button.assing')}
          </Button>
        </Grid>

      </Grid>
      <Button
        color="primary"
        size="small"
        type="submit"
        style={{ position: 'absolute', right: 0 }}
      >
        <CachedIcon fontSize="small" />
      </Button>
      
    </form>
  );
};

export default withStyles(styles)(AssingBalancesTableFilters);