import { object, number } from 'yup';

export const schema = object().shape({
  number_of_vehicles: number().typeError('onlyNumbers').min(1, 'mustBeGreaterThanOne'),
  max_packages_per_driver: number().typeError('onlyNumbers').min(1, 'mustBeGreaterThanOne'),
  max_capacity: number()
  .typeError('onlyNumbers')
  .nullable()
  .moreThan(0, 'mustBeGreaterThanOne')
  .transform((_, val) => (val !== "" ? Number(val) : null)),
});
