import { urlContact, urlPqrs } from 'api';
import { post } from 'api/requests';
import handleErrorMessage from 'utils/handleErrorMessage';
import { loading, message } from '../commons/actions';

export const sendMessageContact = async (dispatch, values) => {
  loading(dispatch, true);
  await post(urlContact,  { ...values })
    .then(() => {
      loading(dispatch, false);
      message(dispatch, 'messageSentSuccesfully');
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      loading(dispatch, false);
      message(dispatch, mssg);
    });
}

export const sendPQRS = async (dispatch, values) => {
  loading(dispatch, true);
  await post(urlPqrs,  { ...values })
    .then(() => {
      loading(dispatch, false);
      message(dispatch, 'pqrs.messageSent');
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      loading(dispatch, false);
      message(dispatch, mssg);
    });
}