import { string, object } from 'yup';

const schema = object().shape({
  amount: string().required('requiredField'),
  code: string().required('requiredField'),
  name: string().when('code', {
    is: 'co_pse',
    then: string().required('requiredField'),
  }),
  id_type: string().when('code', {
    is: 'co_pse',
    then: string().required('requiredField'),
  }),
  person_id: string().when('code', {
    is: 'co_pse',
    then: string().required('requiredField'),
  }),
  person_type: string().when('code', {
    is: 'co_pse',
    then: string().required('requiredField'),
  }),
  phone_number: string()
    .when('code', {
      is: 'co_pse',
      then: string().required('requiredField'),
    })
    .matches(/^[0-9]+$/, 'onlyNumbers'),
  email: string()
    .when('code', {
      is: 'co_pse',
      then: string().required('requiredField'),
    })
    .email('invalidEmailAddress'),
  address: string().when('code', {
    is: 'co_pse',
    then: string().required('requiredField'),
  }),
  bank_code: string().when('code', {
    is: 'co_pse',
    then: string().required('requiredField'),
  }),
  credit_card_id: string().when('code', {
    is: 'co_credit_card',
    then: string().required('requiredField'),
  }),
});

export default schema;
