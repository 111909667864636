import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
 fullWidth: {
    width: '100%',
    '& > .MuiFormControlLabel-label': {
      width: '100%'
    }
  },
  titleAccordion: {
    '& > .MuiAccordionSummary-content': {
      margin: 0
    }
  },
  routeTime: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    fontSize: '0.95rem',
    textAlign: 'center',
  },
  containerAccordion: {
    boxShadow: 'none',
    borderTop: '1px solid #e7e5e5',
    borderBottom: '1px solid #e7e5e5',
    borderRadius: '0 !important'
  },
  accDetails: {
    padding: '2px 2px 20px'
  }
}));

export default styles;