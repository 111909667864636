import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Paper, Container, Grid, Typography, Button, Hidden } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { I18nContext } from 'translations';
import LayoutOuter from 'components/Layouts/LayoutOuter';
import Menu from 'components/Landing/Layouts/Menu';
import LinkAccount from '../components/LinkAccount';
import styles from './style';

function PreSignup({ classes }) {
  const { translate } = useContext(I18nContext);

  return (
    <LayoutOuter bg="#f8f8f8">
      <Menu />
      <Container maxWidth="md" style={{ marginTop: 70 }} className="container-width-lg">
        <Paper elevation={4} variant="outlined" className={classes.wrapper}>

          <Grid container alignItems="center" justify="center" spacing={2}>
            <Grid item sm={5} md={6} xl={7} className="text-center">
              <img
                src="/images/users/pibox-boxes.png"
                alt="signup"
                className={classes.principalImg}
              />
              <Hidden xsDown>
                <LinkAccount
                  text="doYouAlreadyHaveAnAccount"
                  link="/login"
                  textLink="loginHere"
                />
              </Hidden>
            </Grid>
            <Grid item sm={7} md={6} xl={5} className="text-center">
              <Typography variant="h4" color="textPrimary">
                {translate('welcomeToPibox')}
              </Typography>
              <br /><br />
              <Typography variant="body1" color="textSecondary">
                {translate('howDoYouWantToRegister')}
              </Typography>
              <br /><br />
              <Grid container style={{ padding: '10px 10px 25px' }} spacing={2}>
                <Grid item md={6} xs={12}>
                  <Button
                    component={Link}
                    variant="contained"
                    color="primary"
                    className="btn-rounded full-width"
                    size="medium"
                    to="/signup"
                  >
                    {translate('naturalPerson')}
                  </Button>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Button
                    component={Link}
                    variant="contained"
                    color="primary"
                    className="btn-rounded full-width"
                    size="medium"
                    to="/signup-company"
                  >
                    {translate('company')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Hidden smUp>
              <Grid item xs={12}>
                <LinkAccount
                  text="doYouAlreadyHaveAnAccount"
                  link="/login"
                  textLink="loginHere"
                />
              </Grid>
            </Hidden>
          </Grid>
        </Paper>
      </Container>
    </LayoutOuter>
  );
}

PreSignup.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PreSignup);
