import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  content: {
    padding: '30px 100px 30px',
    textAlign: 'center',
    minHeight: '300px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 15px 2px'
    }
  },
  contentButtonSave: {
    marginTop: 10,
    paddingBottom: 20,
    textAlign: 'center'
  }
}));

export default styles;