import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { format } from 'date-fns';
import { I18nContext } from 'translations';
import TextBooking from 'components/Booking/TextBooking';

export default function ScheduledData ({ scheduledAt, hoursDedicated }) {
  const { translate } = useContext(I18nContext);
  return (
    <Grid container alignItems="center" className="booking-data">
      <Grid item sm={6} xs={12}>
        <TextBooking
          label={translate('app.booking.scheduled')}
          text={format(new Date(scheduledAt), 'dd/MM/yyyy, hh:mm a')}
          space={hoursDedicated && true}
        />
      </Grid>
      {hoursDedicated && (
        <Grid item sm={6} xs={12}>
          <TextBooking
            label={translate('app.booking.forHours')}
            text={hoursDedicated && ` ${hoursDedicated} ${translate('app.booking.table.hours')}`}
            space
          />
        </Grid>
      )}
    </Grid>
  )
}