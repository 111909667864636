import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Button, Container, Hidden } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { RENT } from 'utils/constants';
import ItemsMenu from './ItemsMenu';
import styles from './style';

const Menu = ({ classes }) => {
  const isRent = process.env.REACT_APP_API_URL_BASE_IS_RENT === RENT;
  const [menu, setMenu] = useState(false);
  const toggleMenu = () => setMenu(!menu);

  return (
    <AppBar position="fixed" color="inherit" className={classes.menuBar}>
      <Container maxWidth="md" className="container-width-lg">
        <Toolbar className={classes.toolbar}>
          <Link to="/" style={{ width: '95px' }}>
            <img src={isRent ? "/images/logoRent.svg" : "/images/logo.svg"} className={isRent? classes.logoRent : classes.logo} alt="logo" />
          </Link>
          <Hidden mdUp>
            <div className="text-right" style={{ flexGrow: 1 }}>
              <Button onClick={toggleMenu}>
                <MenuIcon color="primary"/>
              </Button>
            </div>
            {menu && (
              <div className={classes.menuResponsive}>
                <ItemsMenu />
              </div>
            )}
          </Hidden>
          <Hidden smDown>
            <ItemsMenu />
          </Hidden>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Menu);