import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Language from 'components/Language';
import styles from './SmallMenuStyle';

const SmallMenu = ({ title, logo, lang, classes }) => (
  <div className="relative text-center">
    <Link to="/app">
      <img
        src={logo ? logo : "/images/logo.svg"}
        className={classes.logo}
        alt="Pibox"
      />
    </Link>
    <Typography
      variant="h4"
      color="textPrimary"
      className={classes.title}
      component="span"
    >
      {title}
    </Typography>
    {lang && (
      <span className={classes.language}>
        <Language
          lang={lang}
        />
      </span>
    )}
  </div>
);

SmallMenu.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  logo: PropTypes.string,
  lang: PropTypes.string
};

export default withStyles(styles)(SmallMenu);