import React, { useContext } from 'react';
import { I18nContext } from 'translations';
import { DropzoneDialog } from 'material-ui-dropzone';
import Loading from 'components/Loading';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
} from '@material-ui/core';
import { useState } from 'react';
import CompanyRegisterTable from 'components/Tables/Settings/CompanyRegister';
import ButtonFile from 'components/Buttons/ButtonFile';
import { useStateValue } from 'context/store';
import { getDocumentsHistoryByType } from 'context/settings/companyRegister/actions';
import { useEffect } from 'react';
import { FILES_CD } from 'utils/constants';

const ModalUploadFile = ({
  onChange,
  open,
  handleClose,
  LimitExceedMessage,
  FileAddedMessage,
  FileRemovedMessage,
  DropRejectMessage,
  titleText,
  subTitleText,
  loading,
  handleUpload,
  buttonText,
  dialogTitle,
  ModalUploadFile,
  type_cd,
  companyRegisterStatus,
}) => {
  const { translate } = useContext(I18nContext);
  const [openDropzone, setOpenDropzone] = useState(false);

  const [
    {
      checkin: { checkin },
      companyRegister: { documents_by_type, historyDocumentsLoading },
    },
    dispatch,
  ] = useStateValue();

  useEffect(() => {
    getDocumentsHistoryByType(dispatch, checkin?.company._id, type_cd);
  }, [checkin.company]) // eslint-disable-line react-hooks/exhaustive-deps

  function handleDrop(acceptedFiles) {
    const renamedFiles = acceptedFiles.map(
      (file) => new File([file], FILES_CD[type_cd], { type: file.type })
    );
    onChange(renamedFiles);
    // Maneja los archivos renombrados
  }

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      PaperProps={{
        style: {
          borderRadius: '1rem',
        },
      }}
    >
      <DialogTitle className="text-center" disableTypography>
        <Typography variant="h5" color="primary">
          {titleText}
        </Typography>
      </DialogTitle>

      <DialogContent style={{ padding: '2rem' }}>
        {historyDocumentsLoading ? (
          <Loading />
        ) : (
          <>
            <Grid container justify="center">
              <Grid item xs={12} sm={8}>
                <ButtonFile
                  onClick={() => setOpenDropzone(true)}
                  text={translate('uploadNewFile')}
                  isFromModal
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <DropzoneDialog
                  acceptedFiles={['image/jpeg', 'image/png', '.pdf']}
                  cancelButtonText={'cancel'}
                  dialogTitle={dialogTitle}
                  onClose={() => setOpenDropzone(false)}
                  maxFileSize={5000000}
                  filesLimit={1}
                  open={openDropzone}
                  dropzoneText={translate('app.bulkLoad.dragFileHereOrClick')}
                  getFileLimitExceedMessage={() => LimitExceedMessage}
                  getFileAddedMessage={(fileName) =>
                    `${fileName} ${FileAddedMessage}`
                  }
                  getFileRemovedMessage={(fileName) =>
                    `${fileName} ${FileRemovedMessage}`
                  }
                  onDrop={handleDrop}
                />
              </Grid>
            </Grid>
          </>
        )}

        <CompanyRegisterTable data={documents_by_type} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {translate('cancel')}
        </Button>
        {handleUpload && (
          <Button onClick={handleUpload} color="primary" autoFocus>
            {translate('upload')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ModalUploadFile;
