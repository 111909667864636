import React, { useState, useContext, useEffect, Suspense } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  Grid,
  LinearProgress
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useStateValue } from 'context/store';
import {  updatePrepackage } from 'context/prepackages/actions';
import { I18nContext } from 'translations';
import { schema } from 'pages/PackagesGrouping/Form/schema';
import { modalMessage } from 'utils';
import { withStyles } from '@material-ui/core/styles';
import styles from 'pages/PackagesGrouping/Form/FormStyle';
import PackagesGroupingForm from 'pages/PackagesGrouping/Form';
import Loading from 'components/Loading';
import City from 'pages/PackagesGrouping/Form/City';
import MarkerMap from 'components/Maps/MarkerMap';
import { getPrepackageById } from 'context/prepackages/actions';

function ModalPrepackages({
  handleClose,
  open,
  id,
}) {
  const { langCode, translate } = useContext(I18nContext);

  
  const [origin, setOrigin] = useState(null);
  const [expanded, setExpanded] = useState('origin');
  const [address, setAddress] = useState([{ name: null, lat: null, lon: null }]);
  const [city, setCity] = useState(null);
  const [cleanStops, setCleanStops] = useState(false);
  
  const [{
    checkin: { configuration },
    prepackages:{ prepackage, prepackageLoading }},
    dispatch
] = useStateValue();

  useEffect(() => {
    getPrepackage();
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (prepackage) {
      setOrigin({name:prepackage?.origin_address, lat: prepackage?.origin_address_geojson?.coordinates[1], lon: prepackage?.origin_address_geojson?.coordinates[0]});
      setAddress(
        (prevAddress) => {
          const updateRoutes = [...prevAddress];
          updateRoutes[0] = {name:prepackage?.end_address, lat: prepackage?.end_address_geojson?.coordinates[1], lon: prepackage?.end_address_geojson?.coordinates[0]}
          return updateRoutes;
        }
        );
    }
  }, [prepackage]);


  const getPrepackage = async () => {
    await getPrepackageById(dispatch, id);
  }


  const { handleSubmit, register, reset, formState: { isValid, errors } } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
    });

  const messageOk = () => {
    modalMessage(translate('congratulations'), translate('prepackages.form.prepackageUpdatedSuccessfully'), 'success', 'Continue').then((result) => {
      window.location.href = `/app/list-prepackages`;
    });
  }

  const submit = async (values) => {
    const callback = await updatePrepackage({origin, address:address[0],prePackageId:id, values, lang:langCode, dispatch});
    callback.status && messageOk() 
  }

  const handleSuggest = (info) => setOrigin(info);

  const handleAddress = (info, index, id) => {
    setAddress((prevAddress) => {
      const updateRoutes = [...prevAddress];
      updateRoutes[index] = { ...info, id };
      return updateRoutes;
    });
  };

  const resetForm = () => {
    reset();
    setAddress([]);
    setOrigin(null);
  };

  if (!prepackage) {
    return <Loading />
  }

  return (
    <Dialog 
    open={open}
      onClose={handleClose} 
      fullScreen
    >
      <DialogActions>
        <Button 
          onClick={handleClose} 
          color="secondary"
          variant="contained"
          size="large"
        >
          {translate('close')}
          
        </Button>
      </DialogActions>
      <DialogTitle disableTypography className="text-center">
        <Typography color="primary" variant="h5">
          {`${prepackage?.origin_address} -> ${prepackage?.end_address}`}
        </Typography>
      </DialogTitle>
    {(prepackageLoading) && <Loading />}

      <DialogContent className="text-center">
      <Grid container justify="center">
        <Grid item md={6} xs={12}>
          <div style={{ padding: '0 20px 0 10px' }}>
            <City
              setCity={setCity}
              cities={configuration?.cities_for_delivery}
              city={city}
              resetForm={resetForm}
              setExpanded={setExpanded}
            />
          </div>
          {city && (
            <PackagesGroupingForm
            register={register}
            handleSubmit={handleSubmit}
            city={city}
              cities={configuration?.cities_for_delivery}
              origin={origin}
              address={address}
              submit={submit}
              handleSuggest={handleSuggest}
              handleAddress={handleAddress}
              resetForm={resetForm}
              errors={errors}
              isValid={isValid}
              setExpanded={setExpanded}
              expanded={expanded}
              isModal
              prepackage={prepackage}
            />
          )}
        </Grid>
        <Grid item md={6} xs={12} style={{ position: 'relative', minHeight: '85vh' }}>
          <Suspense fallback={<LinearProgress color="primary" />}>
            <MarkerMap
              city={{
                lon: city ? city.location.lon : configuration.cities_for_delivery.length > 0 ? configuration.cities_for_delivery[0].location.lon : '-74.134',
                lat: city ? city.location.lat : configuration.cities_for_delivery.length > 0 ? configuration.cities_for_delivery[0].location.lat : '4.674'
              }}
              origin={origin}
              stops={address}
              remove={[{ name: null, lat: null, lon: null }]}
              points={[origin].concat(address)}
              returnToOrigin={false}
              cleanStops={cleanStops}
              setCleanStops={setCleanStops}
              setAddress={setAddress}
            />
          </Suspense>
        </Grid>
      </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(ModalPrepackages);