import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Snackbar } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { listPromoCode, exportPromoCodes } from 'context/promocode/actions';
import { I18nContext } from 'translations';
import { INITIAL_PAGE, PER_PAGE } from 'utils/constants';
import PromoCodesTableGroupsFilters from 'components/Tables/PromoCodes/Filters/PromoCodesGroupsFilters';
import PromoCodesTableFilters from 'components/Tables/PromoCodes/Filters/PromoCodesTableFilters';
import ActivePromoCodesTable from 'components/Tables/PromoCodes/ActivePromoCodes';
import TitleWithDowload from 'components/Titles/TitleWithDowload';
import Loading from 'components/Loading';

const PromoCodes = () => {
  const { translate } = useContext(I18nContext);
  const [per_page] = useState(PER_PAGE);
  const [codeInfo, setCodeInfo] = useState([]);
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState(null);
  const [loadingExport, setLoadingExport] = useState(false);
  const [
    {
      checkin: { company },
      commons: { message },
      promoCodes: { list, loadingPromoCode }
    }, dispatch
  ] = useStateValue();

  const onFilters = (params) => setFilters(params);

  const getListPromoCode = useCallback(
    async (page) => {
      listPromoCode(dispatch, company._id, {
        page,
        per_page,
        ...filters
      });
    },
    [dispatch, per_page, company, filters]
  );

  useEffect(() => {
    company && getListPromoCode(INITIAL_PAGE)
  }, [getListPromoCode]) // eslint-disable-line

  const exportFileOk = () => {
    setLoadingExport(false);
    window.open('/app/reports/promocodes', '_blank');
  };

  const exportFile = async () => {
    setLoadingExport(true);
    const callback = await exportPromoCodes(dispatch, company._id, { ...filters });
    callback.status ? exportFileOk() : setLoadingExport(false);
  };

  return (
    <div className="padding-page-full-height">
      {loadingPromoCode && <Loading />}

      <TitleWithDowload
        title={translate('promoCode.tripCodes')}
        icon={
          <ListAltIcon className="align-center" style={{ marginRight: 5 }} />
        }
        buttonExport={translate('app.bookings.export')}
        exportFile={exportFile}
        loadingExport={loadingExport}
      />

      <div className="marginFiltersTable">
        <PromoCodesTableFilters
          onFilters={onFilters}
          filters={filters}
        />
        <PromoCodesTableGroupsFilters
          onFilters={onFilters}
          filters={filters}
        />
      </div>

      {list && (  
        <ActivePromoCodesTable
          data={list}
          codeInfo={codeInfo}
          setCodeInfo={setCodeInfo}
          open={open}
          setOpen={setOpen}
          getListPromoCode={getListPromoCode}
          recordsPerPage={per_page}
          history={true}
        />
      )}

      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />

    </div>
  );
};

export default PromoCodes;
