import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField, InputAdornment } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import EmailIcon from '@material-ui/icons/Email';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { I18nContext } from 'translations';
import styles from './style';
import schema from './schema';

function FormLogin({ submit, classes, showOptions }) {
  const { translate } = useContext(I18nContext);
  const [showPassword, setShowPasswod] = useState(false);
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(schema)
  });

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classes.form}>

      <TextField
        autoFocus
        name="email"
        error={!!errors.email}
        label={translate('email')}
        helperText={errors.email && translate(errors.email.message)}
        type="email"
        inputRef={register}
        fullWidth
        className={classes.marginBottomField}
        InputProps={{
          endAdornment: <InputAdornment position="end"><EmailIcon className={classes.icon} /></InputAdornment>,
        }}
      />

      <TextField
        name="password"
        error={!!errors.password}
        label={translate('password')}
        helperText={errors.password && translate(errors.password.message)}
        type={showPassword ? "text" : "password"}
        inputRef={register}
        autoComplete="no-password"
        fullWidth
        InputProps={{
          endAdornment: <InputAdornment position="end" onClick={() => setShowPasswod(!showPassword)}>{showPassword ? <VisibilityOffIcon className={classes.iconHover} /> : <VisibilityIcon className={classes.iconHover} />}</InputAdornment>,
        }}
      />

      {showOptions ? (
        <div className="text-right">
          <Button
            color="primary"
            to="/recover-password"
            size="small"
            component={Link}
            className={classes.links}
          >
            {translate('forgotMyPassword')}
          </Button>
        </div>
      ) : <hr className={classes.divider} />}
      
      <Button
        type="submit"
        color="primary"
        variant="contained"
        size="large"
        className={classes.button}
      > 
        {translate('login')}
      </Button>

    </form>
  );
}

FormLogin.propTypes = {
  submit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FormLogin);
