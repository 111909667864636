import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  accordionContainer: {
    border: 0,
    borderRadius: 0,
    '&:before': {
      display: 'none'
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(0deg)'
    },
    '& .MuiCollapse-entered': {
      paddingBottom: 40
    },
    '& .MuiAccordionSummary-root.Mui-disabled': {
      opacity: 1
    }
  },
 
}));

export default styles;