import React, { useContext, useEffect, useCallback, useState } from 'react';
import {
  Grid,
  Select,
  Dialog,
  Button,
  Snackbar,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
  DialogTitle,
  FormControl,
  DialogContent, 
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers';
import { useForm, Controller } from 'react-hook-form';
import { withStyles } from '@material-ui/core/styles';
import { I18nContext } from 'translations';
import { useHistory } from 'react-router-dom';
import { modalMessage } from 'utils';
import { useStateValue } from 'context/store';
import { listTypePqr, createPqr } from 'context/claims/actions';
import { message as closeMessage } from 'context/commons/actions';
import Loading from 'components/Loading';
import UploadNormalFile from 'components/CustomInputs/UploadNormalFile';
import { uploadFile } from 'utils/aws';
import schema from './schema';
import styles from '../../Tables/styleTableFilters';

function ModalClaims ({
  open,
  openClaim,
  classes,
  bookingId
}) {
  const { translate } = useContext(I18nContext);
  const [loading, setLoading] = useState(false);
  const [messageImage, setMessageImage] = useState(null);
  const history = useHistory();
  const [{ commons: { message }, claims: { typePqr, loadingTypePqr } }, dispatch] = useStateValue(); 
  const { handleSubmit, register, control, errors, watch } = useForm({
    resolver: yupResolver(schema)
  });

  const getTypePQR = useCallback(
    async () => {
      await listTypePqr(dispatch);
    }, [dispatch]
  );

  useEffect(() => {
    getTypePQR()
  },[getTypePQR])

  const uploadFileAws = async (file) => {
    const date = Date.parse(new Date());
    if (file?.size <= 5000000) {
      return await uploadFile(file, `pqr/${date}`);
    } else  {
      return true
    }
  };

  const messageOk = () => {
    setLoading(false);
    modalMessage(
      '',
      translate('claims.form.mssgOk'),
      'success',
      translate('accept'),
      true
    ).then(() => {
      openClaim()
      history.push('/app/claims')
    });
  };

  const submit = async values => {
    setLoading(true)
    const responseComplaint = values.complaint_letter[0] ? await uploadFileAws(values.complaint_letter[0]) : null
    const responseInvoice = values.invoice_and_bank_certification[0] ? await uploadFileAws(values.invoice_and_bank_certification[0]) : null
    const data = {
      ...values,
      complaint_letter: responseComplaint?.Location,  
      invoice_and_bank_certification: responseInvoice?.Location
    }
    if (responseComplaint === true || responseInvoice === true) {
      setMessageImage('claims.errorSizeFile')
      setLoading(false)
    } else {
      setMessageImage(null)
      const callback = await createPqr(dispatch, bookingId, { pqr: { ...data } })
      callback.status ? messageOk() : setLoading(false);
    }
 }

  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">
          <b>{translate('claims.claimsForm')}</b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ padding: '10px 40px 40px' }}>
        {loadingTypePqr && <Loading />}
        {loading && <Loading />}
        <form onSubmit={handleSubmit(submit)} noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              {typePqr &&
                <FormControl
                  variant="outlined"
                  fullWidth
                  size="small"
                  className={classes.filterStyle}
                >
                  <InputLabel>{translate('claims.form.typeOfRequest')}</InputLabel>
                  <Controller
                    control={control}
                    name="type_of_request_cd"
                    label={translate('claims.form.typeOfRequest')}
                    variant="outlined"
                    defaultValue={typePqr[0]?.cd}
                    as={
                      <Select>
                        {typePqr?.map((type) => (
                          <MenuItem value={type.cd} key={`type-pqr-${type.cd}`}>
                            {type.text}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  />
                </FormControl>
              }
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="mssg"
                control={control}
                variant="outlined"
                defaultValue=""
                render={({ onChange, value }) => (
                  <TextField
                    size="small"
                    variant="outlined"
                    label={translate('claims.form.reason')}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    multiline
                    rows={4}
                    className={classes.filterStyle}
                    error={!!errors.mssg}
                    helperText={errors.mssg && translate(errors.mssg.message)}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6}>
              <UploadNormalFile
                text={translate('claims.form.uploadComplaintLetter')}
                register={register}
                fileName="complaint_letter"
                nameFile={watch('complaint_letter')}
                accept="image/*,.pdf"
                helpText=""
              />
              <p style={{ margin: 0 }}><small>{translate('claims.formatFile')}</small></p>
            </Grid>
            <Grid item sm={6}>
              <UploadNormalFile
                text={translate('claims.form.invoiceAndBankCertification')}
                register={register}
                fileName="invoice_and_bank_certification"
                nameFile={watch('invoice_and_bank_certification')}
                accept="image/*,.pdf"
                helpText=""
              />
              <p style={{ margin: 0 }}><small>{translate('claims.formatFile')}</small></p>
            </Grid>
            <Grid item xs={12} className="text-right">
              <Button
                variant="outlined"
                type="button"
                color="primary"
                style={{ marginRight: 10, borderRadius: '17px' }}
                onClick={openClaim}
              >
                {translate('cancel')}
              </Button>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                style={{ borderRadius: '17px' }}
              >
                {translate('claims.form.send')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!messageImage}
        autoHideDuration={6000}
        onClose={() => setMessageImage(null)}
        message={translate(messageImage)}
      />
    </Dialog>
  )
}

export default withStyles(styles)(ModalClaims);
