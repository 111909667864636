import { LIST_PREPACKAGES, LIST_PREPACKAGES_LOADING, CANCEL_PREPACKAGE, CANCEL_PREPACKAGES_LOADING, GET_PREPACKAGE, GET_PREPACKAGE_LOADING, UPDATE_PREPACKAGE_LOADING, UPDATE_PREPACKAGE } from './constants';

const prepackagesReducer = (state, action) => {
  switch (action.type) {
    case LIST_PREPACKAGES:
      return {
        ...state,
        list: action.list
      }
    case LIST_PREPACKAGES_LOADING:
      return {
        ...state,
        loadingPrepackages: action.loadingPrepackages
      }
      case GET_PREPACKAGE:
      return {
        ...state,
        prepackage: action.prepackage
      }
      case GET_PREPACKAGE_LOADING:
      return {
        ...state,
        loadingPrepackage: action.loadingPrepackage
      }
      case UPDATE_PREPACKAGE:
      return {
        ...state,
        prepackage: action.prepackage
      }
      case UPDATE_PREPACKAGE_LOADING:
      return {
        ...state,
        loadingEditPrepackage: action.loadingEditPrepackage
      }
      case CANCEL_PREPACKAGE:
        return {
          ...state,
          list: action.list
        }
      case CANCEL_PREPACKAGES_LOADING:
        return {
          ...state,
          loadingPrepackages: action.loadingPrepackages
        }
    default:
      return state
  }
}

export default prepackagesReducer;