import React from 'react';
import { Paper, Typography } from '@material-ui/core';

export default function CardEstimatedData({ label, text }) {
  return (
    <Paper style={{
      margin: '10px 0 0',
      border: '1px solid #979797',
      padding: '12px 10px',
      textAlign: 'center'
    }}>
      <Typography variant="body1" color="primary">
        {label}
      </Typography>
      <Typography variant="body1" color="textPrimary">
        {text}
      </Typography>
    </Paper>
  )
}