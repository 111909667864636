import { string, object } from 'yup';

const schemaWooCommerce = object().shape({
  store_name: string()
    .matches(/^\S*$/, 'canNotHaveSpacesMessage')
    .required('requiredField')
    .url('invalidUrl'),
  customer_key: string()
    .matches(/^\S*$/, 'canNotHaveSpacesMessage')
    .required('invalidUrl'),
  customer_secret: string()
    .matches(/^\S*$/, 'canNotHaveSpacesMessage')
    .required('requiredField'),
  address: string()
    .required('selectAnOptionFromTheList'),
});

export default schemaWooCommerce;
