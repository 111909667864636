import React, { useContext, useState } from 'react';
import { Button, TextField, Grid, CircularProgress } from '@material-ui/core';
import { I18nContext } from 'translations';
import { isObjectEmpty } from 'utils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import PasswordInput from 'components/CustomInputs/PasswordInput';
import schema from './schemaVtex';
import AddressInputs from 'components/CustomInputs/AddressInputs';

export default function FormActivationVtex({
  submit,
  loading,
  setCityData,
  setCityId,
  cityId
}) {
  const { translate } = useContext(I18nContext);
  const [address, setAddress] = useState(null);
  const { handleSubmit, register, errors, control, clearErrors } = useForm({
    resolver: yupResolver(schema),
   });

  const handleCity = (id) => setCityId(id);  
  const handleCityData = (city) => setCityData(city);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate autoComplete="off">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PasswordInput
            inputRef={register}
            name="vtex_app_key"
            type="text"
            variant="outlined"
            fullWidth
            label={translate('app.vtex.form.appKey')}
            size="small"
            error={!!errors.vtex_app_key}
            errortext={
              errors.vtex_app_key && translate(errors.vtex_app_key.message)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordInput
            inputRef={register}
            name="vtex_app_token"
            type="text"
            variant="outlined"
            fullWidth
            label={translate('app.vtex.form.appToken')}
            size="small"
            error={!!errors.vtex_app_token}
            errortext={
              errors.vtex_app_token &&
              translate(errors.vtex_app_token.message)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            name="account_name"
            type="text"
            variant="outlined"
            fullWidth
            label={translate('app.vtex.form.AccountName')}
            size="small"
            autoComplete="false"
            error={!!errors.account_name}
            helperText={
              errors.account_name && translate(errors.account_name.message)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            name="environment"
            type="text"
            variant="outlined"
            fullWidth
            label={translate('app.vtex.form.environment')}
            size="small"
            autoComplete="false"
            error={!!errors.environment}
            helperText={
              errors.environment && translate(errors.environment.message)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <hr style={{ margin: '15px 0 20px', borderColor: '#7c7d7c17' }} />
          <AddressInputs
            register={register}
            address={address}
            setAddress={setAddress}
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            twoColumns={false}
            isAddress
            cityId={cityId}
            handleCity={handleCity}
            needCity={true}
            handleCityData={handleCityData}
          />
        </Grid>
        <Grid item container xs={12} justify="center" style={{ marginTop: 15 }}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!isObjectEmpty(errors) || loading}
            startIcon={loading ? <CircularProgress size={18} /> : null}
          >
            {loading
              ? `${translate('app.integrations.form.activatingButton')}...`
              : translate('save')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
