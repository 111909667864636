import React, { useEffect, useState, useContext } from 'react';
import { Container, Snackbar } from '@material-ui/core';
import { I18nContext } from 'translations';
import GradeOutlinedIcon from '@material-ui/icons/GradeOutlined';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { rateBooking } from 'context/rates/actions';
import { getUnqulifiedBookings } from 'context/rates/actions';
import { INITIAL_PAGE, PER_PAGE } from 'utils/constants';
import { modalMessage } from 'utils';
import RateBookingsTable from 'components/Tables/RateBookings';
import Loading from 'components/Loading';
import Title from 'components/Titles';

export default function RateBookings() {
  const { translate } = useContext(I18nContext);
  const [loading, setLoading] = useState(false);
  const [per_page] = useState(PER_PAGE);
  const [{ unqulifiedBookings: { list }, commons: { message } }, dispatch] = useStateValue();
  
  const listUnqulifiedBookings = async (page) => await getUnqulifiedBookings(dispatch, { page, per_page });

  useEffect(() => {
    listUnqulifiedBookings(INITIAL_PAGE);
  }, []) // eslint-disable-line

  const messageOk = () => {
    setLoading(false);
    modalMessage(translate('congratulations'), translate('app.rate.messageServiceHasBeenSuccessfullyQualified'), 'success', translate('continue')).then(async() => {
      await listUnqulifiedBookings();
    })
  }

  const sendRate = async (id, rate) => {
    setLoading(true);
    const callback = await rateBooking(dispatch, id, rate);
    callback.status ? messageOk() : setLoading(false);
  }

  return(
    <div className="padding-page-full-height">
      {loading && <Loading />}
      <Title
        title={translate('app.rate.unqualifiedServices')}
        icon={<GradeOutlinedIcon  className="align-center" style={{ marginRight: 5 }} />}
        subtitle={translate('app.rate.unqualifiedServicesText')}
      />
      <br />
      <Container maxWidth="md">
        <div className="marginFiltersTable">
          {list &&
            <RateBookingsTable
              sendRate={sendRate}
              data={list}
              getUnqulifiedBookings={listUnqulifiedBookings}
              recordsPerPage={per_page}
            />
          }
        </div>
      </Container>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </div>
  )
}