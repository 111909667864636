import React, { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { I18nContext } from 'translations';
import styles from './style';

const DriverlessCard = ({ img, text }) => {
  const { translate } = useContext(I18nContext);

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      spacing={3}
      className="text-center-xs"
      style={{ padding: '15px 0' }}
    >
      <Grid item md={3} sm={3} xs={12} className="text-center">
        <img
          alt="driver"
          src={img}
        />
      </Grid>

      <Grid item md={8} sm={8} xs={12}>
        <Typography
          variant="body2"
          color="textSecondary"
          component="span"
        >
          {translate('tracking.driver')}
        </Typography>
        <br />
        <Typography
          variant="body1"
          color="textPrimary"
          component="span"
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  )
}


export default withStyles(styles)(DriverlessCard);