import React, { useContext } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { I18nContext } from 'translations';
import { COUNTRY_CODE } from 'utils/constants';

const CustomerFields = ({ index, register, errors, item }) => {
  const { langCode, translate } = useContext(I18nContext);
  return (
    <Grid item container spacing={2}>
      <Grid item sm={6} xs={12}>
        <TextField
          name={`stops[${index}].customer.name`}
          defaultValue={item && item.customer && item.customer.name}
          variant="outlined"
          label={translate('app.express.fullName')}
          id={`name${index}`}
          fullWidth
          inputRef={register()}
          size="small"
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          name={`stops[${index}].customer.country_code`}
          inputRef={register()}
          type="hidden"
          defaultValue={COUNTRY_CODE[langCode][3]}
        />
        <TextField
          name={`stops[${index}].customer.phone`}
          defaultValue={item && item.customer && item.customer.phone}
          variant="outlined"
          label={translate('app.express.phone')}
          fullWidth
          size="small"
          type="tel"
          inputProps={{ maxLength: '15' }}
          inputRef={register({
            pattern: {
              value: /^[\d ]*$/,
              message: `${translate('onlyNumbers')}`,
            },
          })}
          error={
            errors.stops &&
            errors.stops[index] &&
            errors.stops[index].customer &&
            !!errors.stops[index].customer.phone
          }
          helperText={
            errors.stops &&
            errors.stops[index] &&
            errors.stops[index].customer &&
            errors.stops[index].customer.phone &&
            errors.stops[index].customer.phone.message
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name={`stops[${index}].customer.email`}
          defaultValue={item && item.customer && item.customer.email}
          variant="outlined"
          label={translate('app.express.email')}
          fullWidth
          type="email"
          size="small"
          inputRef={register({
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: `${translate('invalidEmailAddress')}`,
            }
          })}
          error={
            errors.stops &&
            errors.stops[index] &&
            errors.stops[index].customer &&
            !!errors.stops[index].customer.email
          }
          helperText={
            errors.stops &&
            errors.stops[index] &&
            errors.stops[index].customer &&
            errors.stops[index].customer.email &&
            errors.stops[index].customer.email.message
          }
        />
      </Grid>
    </Grid>
  )
}

function areEqual(prevProps, nextProps) {
  return prevProps.item === nextProps.item;
}

export default React.memo(CustomerFields, areEqual);
