import React, { useContext, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
} from '@material-ui/core';
import { I18nContext } from 'translations';
import EmptyData from '../EmptyData';
import Subtable from './Subtable';
import styles from '../style';
// import IntegrationsAdapter from 'adapters/Integrations/IntegrationsAdapter';

function ListPlatformsTable({
  classes,
  data,
  getIntegrations,
  recordsPerPage,
}) {
  const { translate } = useContext(I18nContext);
  const [currentPage, setCurrentPage] = useState(data.page);

  useEffect(() => {
    setCurrentPage(data.page);
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    getIntegrations(newPage);
  };

  if (!data || (data && data.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />;
  }

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          stickyHeader
          aria-label="collapsible table"
        >
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                {translate('app.integrations.table.platform')}
              </TableCell>
              <TableCell>{translate('app.integrations.table.name')}</TableCell>
              <TableCell>
                {translate('app.integrations.table.status')}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data.data || []).map((platform) => (
              <Subtable
                key={platform._id}
                row={platform}
                getIntegrations={getIntegrations}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
    </>
  );
}

export default withStyles(styles)(ListPlatformsTable);
