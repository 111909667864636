import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  //TablePagination,
  TableContainer,
  Link
} from '@material-ui/core';
//import { format } from 'date-fns';
import { I18nContext } from 'translations';
import { bookingStatuses } from 'utils';
import { BOOKING_STATUSES } from 'utils/constants';
//import EmptyData from '../../EmptyData';
import styles from '../../style';

const ServicesOperationsTable = ({
  classes,
  data,
  recordsPerPage,
  getBookings
}) => {
  //const [currentPage, setCurrentPage] = useState(data.page);
  const { translate } = useContext(I18nContext);

  /*useEffect(() => {
    setCurrentPage(data.page)
  }, [data])

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage)
    getBookings(newPage);
  }

  if (!data || (data && data.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />
  }*/

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell>{translate('app.operations.services.table.id')}</TableCell>
              <TableCell>{translate('app.operations.services.table.status')}</TableCell>
              <TableCell>{translate('app.operations.services.table.date')}</TableCell>
              <TableCell>{translate('app.operations.services.table.city')}</TableCell>
              <TableCell>{translate('app.operations.services.table.address')}</TableCell>
              <TableCell>{translate('app.operations.services.table.customer')}</TableCell>
              <TableCell>{translate('app.operations.services.table.driver')}</TableCell>
              <TableCell>{translate('app.operations.services.table.value')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <TableCell>
                <Link
                  href={`https://web.picap.app/bookings/613ba4fac1377d032db5e3e3`}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary"
                  underline="hover"
                >
                  613ba4fac1377d032db5e3e3
                </Link>
              </TableCell>
              <TableCell className={classes.status}>
                <span className={bookingStatuses(102)} style={{ padding: '3px 7px' }}>
                  {translate(BOOKING_STATUSES[102])}
                </span>
              </TableCell>
              <TableCell>
                <span className={classes.date}>
                  10/09/2021, 01:33 PM
                </span>
              </TableCell>
              <TableCell>
                Bogotá
              </TableCell>
              <TableCell className={classes.address}>
                KR 68B # 24 - 39
              </TableCell>
              <TableCell>
                Pibox by Picap
              </TableCell>
              <TableCell>
                Alexander Rocha Henao
              </TableCell>
              <TableCell>
                COP 19,500.00
              </TableCell>
            </TableRow>
            {/*(data.data || []).map(booking => (
              <TableRow hover key={booking._id}>
                <TableCell className={classes.status}>
                  <span className={bookingStatuses(booking.status_cd)} style={{ padding: '3px 7px' }}>
                    {translate(BOOKING_STATUSES[booking.status_cd])}
                  </span>
                </TableCell>
                <TableCell>
                  <span className={classes.date}>
                    {format(new Date(booking.created_at), 'dd/MM/yyyy, hh:mm a')}
                  </span>
                </TableCell>
                <TableCell className={classes.address}>
                  <Link
                    href={`https://picappibox.netlify.app/bookings/${booking._id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="primary"
                    underline="hover"
                  >
                    {booking.address}
                  </Link>
                </TableCell>
                <TableCell>
                  {booking.scheduled_at
                    ? <span className={classes.date}>{format(new Date(booking.scheduled_at), 'dd/MM/yyyy, hh:mm a')}</span>
                    : translate('no')
                  }
                </TableCell>
                <TableCell>
                  {booking.stops.length ? booking.stops.length : '0' }
                </TableCell>
                <TableCell>
                  {translate(PAYMENT_METHODS[booking.payment_method_cd])}
                </TableCell>
                <TableCell>
                  {translate(booleanToString(booking.use_wallet_balance))}
                </TableCell>
                <TableCell>
                  {booking.company_final_cost || booking.company_estimated_cost
                    ? ( booking.company_final_cost
                      ? formatCost(booking.company_final_cost)
                      : formatCost(booking.company_estimated_cost))
                    : booking.final_cost
                      ? formatCost(booking.final_cost)
                      : formatCost(booking.estimated_cost)
                  }
                </TableCell>
                <TableCell>
                  <Link
                    href={`https://web.picap.app/bookings/${booking._id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="primary"
                    underline="hover"
                  >
                    {booking._id}
                  </Link>
                </TableCell>
              </TableRow>
                ))*/}
          </TableBody>
        </Table>
      </TableContainer>
      {/*<TablePagination
        component="div"
        count={data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />*/}
    </>
  );
}

function areEqual(prevProps, nextProps) {
  return prevProps.data === nextProps.data;
}

export default withStyles(styles)(React.memo(ServicesOperationsTable, areEqual));

