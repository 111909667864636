import React, { useState, useContext, useEffect, Suspense, lazy } from 'react';
import { Grid,  Snackbar, LinearProgress, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import Loading from 'components/Loading';
import { modalMessageHtml, modalMessage } from 'utils';
import Form from './Form';
import styles from './NewStyle';
import { formatCost } from 'utils';
import { cleanQuote } from 'context/express/actions';
const MarkerMap = lazy(() => import('components/Maps/MarkerMap'));

const NewOrder = ({ classes, isRent }) => {
  const { translate, langCode } = useContext(I18nContext);
  const [removeAddress, setRemoveAddress] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [address, setAddress] = useState([{ name: null, lat: null, lon: null }]);
  const [cleanStops, setCleanStops] = useState(false);
  const [returnToOrigin, setReturnToOrigin] = useState(false);
  const [city, setCity] = useState(null);
  const [{
    checkin: { configuration, checkin, company },
    commons: { loading, message },
    bookings: { booking, quote },
    optimizeRoutes: { originOptimizeOps },
    sales: { originSale }
  }, dispatch] = useStateValue();

  useEffect(() => {
    booking && handleMessageOk();
  }, [booking]); // eslint-disable-line

  const handleMessageOk = () => {
    if (booking.pickup_validation_code) {
      modalMessageHtml(translate('app.express.orderCreated'), `${translate('app.express.orderCreatedWithValidationCode')} <br /><b>${booking.pickup_validation_code}</b>`, 'success', 'Continue', false, '', false)
      .then(() => {
        window.location.href = `/app/bookings`;
      });
    } else {
      modalMessage(translate('congratulations'), translate('app.express.orderCreatedSuccessfully'), 'success', 'Continue').then((result) => {
        window.location.href = `/app/bookings`;
      });
    }
  }

  useEffect(() => {
    setOrigin(originOptimizeOps);
  }, [originOptimizeOps, setOrigin])

  useEffect(() => {
    originSale?.lat && setOrigin(originSale);
  }, [originSale, setOrigin])

  useEffect(() => {
    return () => {
       cleanQuote(dispatch);
    }
  }, []); // eslint-disable-line

  return (
    <Grid container justify="center" className={classes.fullPage}>
      {loading && <Loading />}
      {langCode === 'es_pe' ?
        <Typography variant="h5" component="h2" style={{ marginTop: 25 }}>
          {translate('servicesCannotBeCreatedInThisCountry')}
        </Typography>
        :
        isRent && company && checkin && checkin.available_service_type_groups?.filter(x => x === 'rent').length === 0 ?
          <>
            <Typography variant="h5" component="h2" style={{ marginTop: 25 }} className="text-center">
              {translate('notPermissionRequestRentServices')}
              <br /><br />
              <Button
                variant="contained"
                color="primary"
                size="large"
                className="btn-rounded"
                href="/app"
              >
                {translate('tryAgain')}
              </Button>
            </Typography>
          </>
        : configuration && configuration.cities_for_delivery && (
          <>
            <Grid item md={6} xs={12}>
              <Form
                configuration={configuration}
                city={city}
                setCity={setCity}
                address={address}
                setAddress={setAddress}
                cleanStops={cleanStops}
                setCleanStops={setCleanStops}
                setRemoveAddress={setRemoveAddress}
                setReturnToOrigin={setReturnToOrigin}
                origin={origin}
                setOrigin={setOrigin}
                isRent={isRent}
                availableServiceType={checkin?.available_service_type_groups?.length > 0 ? checkin?.available_service_type_groups : null}
              />
            </Grid>
            <Grid item md={6} xs={12} className={classes.containerMap}>
              {checkin?.balance_wallet &&
                <Button
                  type="button"
                  color="secondary"
                  variant="contained"
                  size="small"
                  style={{ position: 'absolute', left: 0, top: 10, zIndex: 999 }}
                >
                  {typeof checkin.balance_wallet === 'object' ? formatCost(checkin.balance_wallet) : checkin.balance_wallet}
                </Button>
              }
              <Suspense fallback={<LinearProgress color="primary" />}>
                <MarkerMap
                  city={{
                    lon: city ? city.location.lon : configuration.cities_for_delivery.length > 0 ? configuration.cities_for_delivery[0].location.lon : '-74.134',
                    lat: city ? city.location.lat : configuration.cities_for_delivery.length > 0 ? configuration.cities_for_delivery[0].location.lat : '4.674'
                  }}
                  origin={origin}
                  stops={address}
                  remove={removeAddress}
                  points={[origin].concat(address)}
                  returnToOrigin={returnToOrigin}
                  cleanStops={cleanStops}
                  setCleanStops={setCleanStops}
                  setAddress={setAddress}
                  quotation={quote && quote.quotation}
                />
              </Suspense>
            </Grid>
          </>
        )
      }
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </Grid>
  );
}

export default withStyles(styles)(NewOrder);
