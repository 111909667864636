import { CREATE_SESSION, PERSONAL_DATA, CLOSE_SESSION} from './constants';

const sessionReducer = (state, action) => {
  switch (action.type) {
    case CREATE_SESSION:
      action.session.session_token &&
        localStorage.setItem('session_token', action.session.session_token);
      return {
        ...state,
        session: action.session,
      };
    case PERSONAL_DATA:
      return {
        ...state,
        personalData: action.personalData,
      };
    case CLOSE_SESSION:
      localStorage.clear();
      return {
        ...state,
        session: action.session,
      };
    default:
      return state;
  }
};

export default sessionReducer;
