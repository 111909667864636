import React from 'react'
import { Typography, IconButton, Tooltip as TooltipText } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
);

export default function LineGraph ({ title, textLeft, pointRadius, total, dates, isSum, tooltipMssg }) {

  const sum = total?.length > 0 && total.reduce(function(a, b){
    return a + b;
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      },
      datalabels: {
        display: false
      }
    },
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: textLeft
        }
      },
      x: {
        grid: {
          display: false      
        },
      }
    }
  };

  const data= {
    labels: dates,
    datasets: [
      {
        data: total,
        backgroundColor: '#9351ca',
        borderColor: '#7825bd',
        pointRadius: pointRadius
      }
    ],
  };

  return (
    <>
      <Typography variant="body1">
        <b>
          {title} {isSum && sum && `: ${sum}`} 
          {tooltipMssg &&
            <TooltipText title={tooltipMssg} placement="top-start">
              <IconButton size="small">
                <InfoOutlinedIcon />
              </IconButton>
            </TooltipText>
          }
        </b>
      </Typography>
      <br />
      <Line options={options} data={data} />
    </>
  )
}