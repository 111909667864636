import React, { useContext, useEffect, useState } from 'react';
import { I18nContext } from 'translations';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { modalMessageHtml } from 'utils';
import Loading from 'components/Loading';
import { useStateValue } from 'context/store';
import { listStoresShopify } from 'context/sales/actions';
import { configurationShopify } from 'context/integrations/actions';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import FormConfigurationShopify from './FormConfigurationShopify';

const ShopifyStoreConfigurationModal = ({ open, setOpen, store }) => {
  
  const { translate } = useContext(I18nContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [
    {
      sales: { listShopifyStores },
    },
    dispatch,
  ] = useStateValue();

  const getStores = useCallback(async () => {
    return await listStoresShopify(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getStores();
  }, []); // eslint-disable-line

  const messageOk = () => {
    setLoading(false);
    modalMessageHtml(
      translate('congratulations'),
      translate('app.integration.settingStore.form.successful'),
      'success',
      translate('app.integration.settingStore.goToSales'),
      true,
      translate('app.integration.settingStore.continueSetting'),
      false
    ).then((response) => {
      response.isConfirmed ? history.push('/app/bookings/sales') : getStores();
    });
  };

  const submit = async (values) => {
    setLoading(true);
    const callback = await configurationShopify(
      dispatch,
      store.user_external_identificator,
      { ...values, store_name: store.user_external_identificator }
    );
    callback?.status ? messageOk() : setLoading(false);
  };

  return (
    <>
      <Dialog
        open={open}
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle className="text-center" disableTypography>
          <Typography variant="h5" color="primary">
            {translate('app.shopify.settingShopify.title')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ margin: '10px 0' }}>
          {loading && <Loading />}
          <FormConfigurationShopify
            listShopifyStores={listShopifyStores || []}
            submit={submit}
            storeName={store.user_external_identificator}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={setOpen}>
            {translate('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShopifyStoreConfigurationModal;
