import React, { useContext } from 'react';
import { Grid, TextField, Button, CircularProgress } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { I18nContext } from 'translations';
import schema from './ValidateUserSchema';

export default function ValidateUser({ submit, loading }) {
  const { translate } = useContext(I18nContext);
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(schema)
  });

  return (
    <form
      onSubmit={handleSubmit(submit)}
      noValidate
    >
      <Grid container spacing={2}>
        <Grid container alignItems="center" style={{ marginBottom: 15 }}>
          <Grid item md={4} xs={12}>
            <label>
              {translate('app.settings.users.form.fullName')}
            </label>
          </Grid>
          <Grid item md={8} xs={12}>
            <TextField
              name="name"
              size="small"
              type="text"
              defaultValue=""
              variant="outlined"
              fullWidth
              required
              inputRef={register}
              error={!!errors.name}
              helperText={errors.name && translate(errors.name.message)}
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center" style={{ marginBottom: 15 }}>
          <Grid item md={4} xs={12}>
            <label>
              {translate('app.settings.users.form.email')}
            </label>
          </Grid>
          <Grid item md={8} xs={12}>
            <TextField
              name="email"
              size="small"
              type="text"
              defaultValue=""
              variant="outlined"
              fullWidth
              required
              inputRef={register}
              error={!!errors.email}
              helperText={errors.email && translate(errors.email.message)}
            />
          </Grid>

          <Grid item xs={12} className="text-center" style={{ marginTop: 30 }}>
            <Button
              variant="contained"
              color="primary"
              style={{ width: '200px' }}
              type="submit"
              startIcon={loading ? <CircularProgress size={18} /> : null}
              disabled={loading}
            >
              {loading ? `${translate('app.settings.users.form.validating')}...` : translate('next')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

