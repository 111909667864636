import React, { useContext, useState } from 'react';
import { Typography, TextField, InputAdornment, Button } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useForm } from 'react-hook-form';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { withStyles } from '@material-ui/core/styles';
import { yupResolver } from '@hookform/resolvers';
import { schemaChangePassword } from './schemaChangePassword';
import styles from './style';

function ChangePassword( { profile, submit, classes, loadingPassword }) {
  const { translate } = useContext(I18nContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(schemaChangePassword)
  });

  if (profile) {
    return (
      <form onSubmit={handleSubmit(submit)} noValidate className={classes.form}>
        <Typography variant="subtitle1" color="primary" style={{ margin: '0px 0 8px' }}>
          {translate('app.profile.doYouWantToChangeYourPassword')}
        </Typography>
        <TextField
          name="password"
          error={errors && !!errors.password}
          label={translate('app.profile.newPassword')}
          helperText={errors && errors.password && translate(errors.password.message)}
          type={showPassword ? "text" : "password"}
          inputRef={register}
          autoComplete="no-password"
          className={classes.marginBottomField}
          fullWidth
          required
          InputProps={{
            endAdornment: <InputAdornment position="end" onClick={() => setShowPassword(!showPassword)}>{showPassword ? <VisibilityOffIcon className={classes.iconHover} /> : <VisibilityIcon className={classes.iconHover} />}</InputAdornment>,
          }}
        />
        <TextField
          name="passwordConfirmation"
          error={errors && !!errors.passwordConfirmation}
          label={translate('app.profile.confirmPassword')}
          helperText={errors && errors.passwordConfirmation && translate(errors.passwordConfirmation.message)}
          type={showConfirmPassword ? 'text' : 'password'}
          inputRef={register}
          autoComplete="no-password"
          className={classes.marginBottomField}
          fullWidth
          required
          InputProps={{
            endAdornment: <InputAdornment position="end" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>{showConfirmPassword ? <VisibilityOffIcon className={classes.iconHover} /> : <VisibilityIcon className={classes.iconHover} />}</InputAdornment>,
          }}
        />
        <Button
          type="submit"
          color="primary"
          variant="contained"
          size="large"
          className={classes.button}
          disabled={loadingPassword}
        > 
          {translate('changePassword')}
        </Button>
      </form>
    )
  }
}

export default withStyles(styles)(ChangePassword);