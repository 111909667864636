import React, { useContext, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { useForm, Controller } from 'react-hook-form';
import esLocale from 'date-fns/locale/es';
import DateFnsUtils from '@date-io/date-fns';
import AutocompleteListControlled from 'components/CustomInputs/AutocompleteListControlled';
import { COMMODATE_STATUSES } from 'utils/constants';
import { formatDate, cleanObject } from 'utils';
import { I18nContext } from 'translations';
import styles from '../../styleTableFilters';

const defaultValues = {
  created_at: null,
  return_date: null,
  status_cd: '',
  id: ''
};

const CommodateTableFilters = ({ classes, onFilters, openModal, borrowerList, disabledOptions }) => {
  const { translate } = useContext(I18nContext);
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const onSubmit = (values) => {
    let params = {
      ...values,
      created_at: formatDate(values?.created_at),
      return_date: formatDate(values.return_date),
      borrower: value ?? ''
    };
    params = cleanObject(params)
    onFilters(params);
  };

  const clearFilters = () => {
    reset(defaultValues);
    setValue('');
    setInputValue('');
    onFilters('');
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate className="relative">
      <Grid container spacing={2} alignItems="center">
        {borrowerList?.length > 0 &&
          <Grid item md={3} sm={4} xs={6}>
            <FormControl size="small" className={classes.filterStyle} fullWidth name="borrower">
              <AutocompleteListControlled
                data={borrowerList}
                label={translate('commodate.filter.selectADriver')}
                setValue={setValue}
                inputValue={inputValue}
                setInputValue={setInputValue}
              />
            </FormControl>
          </Grid>
        }
        <Grid item md={3} sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Controller
              name="created_at"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <KeyboardDatePicker
                  className={classes.filterStyle}
                  inputVariant="outlined"
                  label={translate('commodate.filter.creationDate')}
                  format="dd/MM/yyyy"
                  cancelLabel={translate('cancel')}
                  okLabel={translate('accept')}
                  invalidDateMessage={translate('invalidFormat')}
                  fullWidth
                  value={value}
                  onChange={onChange}
                  size="small"
                />
              )}
            />
          </MuiPickersUtilsProvider> 
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Controller
              name="return_date"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <KeyboardDatePicker
                  className={classes.filterStyle}
                  inputVariant="outlined"
                  label={translate('commodate.filter.returnDate')}
                  format="dd/MM/yyyy"
                  cancelLabel={translate('cancel')}
                  okLabel={translate('accept')}
                  invalidDateMessage={translate('invalidFormat')}
                  fullWidth
                  value={value}
                  onChange={onChange}
                  size="small"
                />
              )}
            />
          </MuiPickersUtilsProvider> 
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <FormControl
            variant="outlined"
            className={classes.filterStyle}
            fullWidth
            size="small"
          >
            <InputLabel>{translate('commodate.filter.state')}</InputLabel>
            <Controller
              control={control}
              name="status_cd"
              label={translate('commodate.filter.state')}
              variant="outlined"
              as={
                <Select>
                  <MenuItem value="">{translate('app.bookings.filter.choose')}</MenuItem>
                  {Object.keys(COMMODATE_STATUSES).map(key => (
                    <MenuItem key={key} value={COMMODATE_STATUSES[key]}>{translate(key)}</MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <Controller
            name="id"
            control={control}
            variant="outlined"
            defaultValue=""
            render={({ onChange, value }) => (
              <TextField
                size="small"
                className={classes.filterStyle}
                variant="outlined"
                label={translate('commodate.filter.id')}
                fullWidth
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>

        <Grid item md={6} xs={8} className="text-center-sm">
          <Button
            variant="outlined"
            color="primary"
            type="button"
            style={{ width: 120, marginRight: 10 }}
            onClick={clearFilters}
            className="btn-rounded-style"
          >
            {translate('clear')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ width: 120 }}
            className="btn-rounded-style"
          >
            {translate('commodate.filter.search')}
          </Button>
        </Grid>
        <Grid item md={borrowerList?.length > 0 ? 3 : 6} className="text-right text-center-sm">
          <Button
            variant="contained"
            className="btn-rounded-style btn-green"
            onClick={() => openModal()}
            disabled={disabledOptions}
            style={{ opacity: disabledOptions ? 0.8 : 1 }}
          >
            {translate('commodate.filter.addNew')}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default withStyles(styles)(CommodateTableFilters);