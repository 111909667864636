import platform from 'platform';

const device = {
  brand: platform.name,
  model: platform.version ? platform.version : 'web',
  os: 'web',
  os_version: platform.os.version || '1.0',
  app_version: 2.0,
};

export default device;
