import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField, InputAdornment } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { I18nContext } from 'translations';
import styles from './style';
import schema from './schema';

function Form({ submit, classes }) {
  const { translate } = useContext(I18nContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(schema)
  });

  return (
    <form onSubmit={handleSubmit(submit)} className={classes.form} noValidate>

      <TextField
        autoFocus
        name="password"
        error={!!errors.password}
        label={translate('password')}
        helperText={errors.password && translate(errors.password.message)}
        type={showPassword ? "text" : "password"}
        inputRef={register}
        fullWidth
        autoComplete="no-password"
        className={classes.marginBottomField}
        InputProps={{
          endAdornment: <InputAdornment position="end" onClick={() => setShowPassword(!showPassword)}>{showPassword ? <VisibilityOffIcon className={classes.iconHover} /> : <VisibilityIcon className={classes.iconHover} />}</InputAdornment>,
        }}
      />

      <TextField
        name="passwordConfirmation"
        error={!!errors.passwordConfirmation}
        label={translate('confirmPassword')}
        helperText={errors.passwordConfirmation && translate(errors.passwordConfirmation.message)}
        type={showConfirmPassword ? "text" : "password"}
        inputRef={register}
        autoComplete="no-password"
        fullWidth
        className={classes.marginBottomField}
        InputProps={{
          endAdornment: <InputAdornment position="end" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>{showConfirmPassword ? <VisibilityOffIcon className={classes.iconHover} /> : <VisibilityIcon className={classes.iconHover} />}</InputAdornment>,
        }}
      />

      <Button
        type="submit"
        color="primary"
        variant="contained"
        size="large"
        className={classes.button}
      > 
        {translate('send')}
      </Button>

    </form>
  );
}

Form.propTypes = {
  submit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Form);
