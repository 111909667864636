import React, { useEffect, useContext, useCallback, useState }  from 'react';
import { Snackbar } from '@material-ui/core';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import Loading from 'components/Loading';
import { useStateValue } from 'context/store';
import PackagesTable from 'components/Tables/Bookings/Packages';
import PackagesTableFilters from 'components/Tables/Bookings/Filters/PackagesTableFilters';
import PackagesTableGroupsFilters from 'components/Tables/Bookings/Filters/PackagesTableGroupsFilters';
import TitleWithDowload from 'components/Titles/TitleWithDowload';
import { message as closeMessage } from 'context/commons/actions';
import { listBookingsPackages, cleanPackages } from 'context/express/actions';
import { I18nContext } from 'translations';
import { INITIAL_PAGE } from 'utils/constants';
import  useGetDataExpress from 'hooks/Express/useGetDataExpress';
import { downloadExcelBooking, exportBookings } from 'context/express/actions';

const TYPE = 1
const ADMIN_COMPANY = 'admin';

const Packages = () => {
  const { translate } = useContext(I18nContext);
  const [loadingExport, setLoadingExport] = useState(false);
  const [{ bookings: { listPackages, loadingPackages }, checkin: { company } }] = useStateValue();
  const { message, roles, per_page, user_id, setUser_id, filters, onFilters, dispatch,
    getListGroups, myServices, listByGroups, inputSearchSelected, setInputSearchSelected,
  } = useGetDataExpress();

  const getBookingsPackages = useCallback(async (page) => {
    const filterArea = inputSearchSelected === ADMIN_COMPANY ? { 'admin' : true } : { area_id: inputSearchSelected }
    listBookingsPackages(dispatch, { page, per_page, ...filters, user_id, ...filterArea })
  }, [filters, dispatch, per_page, user_id, inputSearchSelected]);

  const downloadExcel = async () => {
    const filterArea = inputSearchSelected === ADMIN_COMPANY ? { 'admin' : true } : { area_id: inputSearchSelected }
    await downloadExcelBooking(dispatch, { ...filters, user_id, ...filterArea }, TYPE);
  }

  useEffect(() => {
    !inputSearchSelected && getBookingsPackages(INITIAL_PAGE);
  }, [getBookingsPackages, inputSearchSelected])

  useEffect(() => {
    return () => cleanPackages(dispatch);
  }, [dispatch])

  useEffect(() => {
    inputSearchSelected && !user_id && getBookingsPackages(INITIAL_PAGE);
  },[getBookingsPackages, inputSearchSelected, user_id])

  const exportFileOk = () => {
    setLoadingExport(false);
    window.open('/app/reports/packages', '_blank');
  }

  const exportFile = async () => {
    setLoadingExport(true);
    const filterArea = inputSearchSelected === ADMIN_COMPANY ? { 'admin' : true } : { area_id: inputSearchSelected }
    const callback = await exportBookings(dispatch, { ...filters, user_id, ...filterArea }, TYPE);
    callback.status ? exportFileOk() : setLoadingExport(false);
  }

  return (
    <div className="padding-page-full-height">
      {loadingPackages && <Loading />}

      <TitleWithDowload
        title={translate('app.packages')}
        button={translate('app.bookings.download')}
        icon={<ArchiveOutlinedIcon className="align-center" style={{ marginRight: 5 }} />}
        downloadExcel={downloadExcel}
        buttonExport={translate('app.bookings.export')}
        exportFile={exportFile}
        loadingExport={loadingExport}
      />

      <div className="marginFiltersTable">
        <PackagesTableFilters
          onFilters={onFilters}
          company={company && company}
        />
         {roles().length > 0 && (
          <PackagesTableGroupsFilters
            user_id={user_id}
            setUser_id={setUser_id}
            myPackages={myServices}
            listByGroups={listByGroups && listByGroups}
            getListGroups={getListGroups}
            setInputSearchSelected={setInputSearchSelected}
          />
        )}
      </div>

      {listPackages && (
        <PackagesTable
          data={listPackages}
          getData={getBookingsPackages}
          recordsPerPage={per_page}
          user_id={user_id}
          company={company && company}
        />
      )}

      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </div>
  );
}

export default Packages;
