import { post, get, patch } from 'api/requests';
import { urlPrepackages, urlGetCompanyPrepackages, urlGetUserPrepackages, urlCancelPrepackage } from 'api';
import { message } from '../commons/actions';
import handleErrorMessage from 'utils/handleErrorMessage';
import { COUNTRY_CODE } from 'utils/constants';
import { formatPrice } from 'utils';
import { LIST_PREPACKAGES, LIST_PREPACKAGES_LOADING, CANCEL_PREPACKAGES_LOADING, GET_PREPACKAGE, GET_PREPACKAGE_LOADING, UPDATE_PREPACKAGE_LOADING, UPDATE_PREPACKAGE } from './constants';

export const createPrepackages = async (origin, address, values, lang, dispatch) => {
  const data = {
    ...values,
    declared_value: {
      cents: formatPrice({
        sub_units: values.declared_value,
        iso: COUNTRY_CODE[lang][4],
      }),
      currency_iso: COUNTRY_CODE[lang][4]
    },
    collected_value: 
      values.collected_value ? {
        cents: formatPrice({
          sub_units: values.collected_value,
          iso: COUNTRY_CODE[lang][4],
        }),
        currency_iso: COUNTRY_CODE[lang][4]
      } : null,
    origin_address: origin.name,
    origin_lat: origin.lat,
    origin_lon: origin.lon,
    end_address: address.name,
    end_lat: address.lat,
    end_lon: address.lon,
    size_cd: parseInt(values.size_cd),
    customer: {
      ...values.customer,
      phone: values.customer.phone.replace(/\s/g, ''),
    },
  }
  let status = false;
  await post(urlPrepackages, { pre_package: data })
    .then((json) => {
      return json.data;
    })
    .then((data) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status }
}

export const listPrepackages = async (dispatch, params, user) => {
  dispatch({
    type: LIST_PREPACKAGES_LOADING,
    loadingPrepackages: true,
  });
  await get(user ? urlGetUserPrepackages : urlGetCompanyPrepackages, params)
    .then(({ data }) => {
      dispatch({
        type: LIST_PREPACKAGES,
        list: data,
      });
      dispatch({
        type: LIST_PREPACKAGES_LOADING,
        loadingPrepackages: false,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: LIST_PREPACKAGES_LOADING,
        loadingPrepackages: false
      });
    });
}

export const getPrepackageById = async (dispatch, prePackageId) => {
  dispatch({
    type: GET_PREPACKAGE_LOADING,
    loadingPrepackage: true,
  });
  await get(`${urlPrepackages}/${prePackageId}`)
    .then(({ data }) => {
      dispatch({
        type: GET_PREPACKAGE,
        prepackage: data,
      });
      dispatch({
        type: GET_PREPACKAGE_LOADING,
        loadingPrepackage: false,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: GET_PREPACKAGE_LOADING,
        loadingPrepackage: false
      });
    });
}

export const updatePrepackage = async ({origin, address, prePackageId, values, lang, dispatch}) => {
  dispatch({
    type: UPDATE_PREPACKAGE_LOADING,
    loadingEditPrepackage: true,
  });
  const data = {
    ...values,
    declared_value: {
      cents: formatPrice({
        sub_units: values.declared_value,
        iso: COUNTRY_CODE[lang][4],
      }),
      currency_iso: COUNTRY_CODE[lang][4]
    },
    collected_value: 
      values.collected_value ? {
        cents: formatPrice({
          sub_units: values.collected_value,
          iso: COUNTRY_CODE[lang][4],
        }),
        currency_iso: COUNTRY_CODE[lang][4]
      } : null,
    origin_address: origin.name,
    origin_lat: origin.lat,
    origin_lon: origin.lon,
    end_address: address.name,
    end_lat: address.lat,
    end_lon: address.lon,
    size_cd: parseInt(values.size_cd),
    customer: {
      ...values.customer,
      phone: values.customer.phone.replace(/\s/g, ''),
    },
  }
  let status = false;
  await patch(`${urlPrepackages}/${prePackageId}`, { pre_package: data })
    .then(({ data }) => {
      dispatch({
        type: UPDATE_PREPACKAGE,
        prepackage: data,
      });
      dispatch({
        type: UPDATE_PREPACKAGE_LOADING,
        loadingEditPrepackage: false,
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
      dispatch({
        type: UPDATE_PREPACKAGE_LOADING,
        loadingEditPrepackage: false
      });
    });
  return { status }
}

export const cancelPrepackage = async (dispatch, prePackageId, user) => {
  dispatch({
    type: CANCEL_PREPACKAGES_LOADING,
    loadingPrepackages: true,
  });
  await patch(urlCancelPrepackage, { pre_package_id: prePackageId })
  .then(({ data }) => {
    dispatch({
      type: CANCEL_PREPACKAGES_LOADING,
      loadingPrepackages: false,
    });
    listPrepackages(dispatch, {}, user);
  })
  .catch((error) => {
    const mssg = handleErrorMessage(error, dispatch);
    dispatch({
      type: CANCEL_PREPACKAGES_LOADING,
      loadingPrepackages: false
    });
    message(dispatch, mssg);
  });
};