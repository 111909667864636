import React, { useContext, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Avatar,
  TableContainer,
  TablePagination,
  Button
} from '@material-ui/core';
import { I18nContext } from 'translations';
import EmptyData from '../../../EmptyData';
import styles from '../../../style';

const GroupChooseTable = ({
  classes,
  data,
  getGroups,
  setChosenGroup,
  recordsPerPage,
  setOpen
}) => {
  const [currentPage, setCurrentPage] = useState(data.page);
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    setCurrentPage(data.page)
  }, [data])

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage)
    getGroups(newPage);
  }

  if (!data || (data && data.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />
  }

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          stickyHeader
        >
          <TableBody>
            {(data.data || []).map(group => (
              <TableRow hover key={group._id}>
                <TableCell>
                    <Avatar className="background-primary">
                      <small>
                        {group.name.split(' ').map(x => x.charAt(0)).join('').substr(0, 2).toUpperCase()}
                      </small>
                    </Avatar>
                </TableCell>
                <TableCell>
                  {group.name}
                </TableCell>
                <TableCell className="text-right">
                  <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setChosenGroup({
                        company_area_id: group._id,
                        company_area_name: group.name
                      });
                      setOpen(false);
                    }}
                  >
                    {translate('choose')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
    </>
  );
}

function areEqual(prevProps, nextProps) {
  return prevProps.data === nextProps.data;
}

export default withStyles(styles)(React.memo(GroupChooseTable, areEqual));

