import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
	root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    minHeight: '100vh',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'initial'
    }
  }
}));

const LayoutOuter = ({ children, bg }) => {
	const classes = styles();
  const isLoggedIn = localStorage.getItem('session_token');
  if(isLoggedIn){
  	return <Redirect to="/app" />;
  }
  return (
    <div className={classes.root} style={{ background: bg }}>
      {children}
    </div>
  );
}

LayoutOuter.propTypes = {
  children: PropTypes.node.isRequired
};

export default LayoutOuter;