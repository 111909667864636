import {
  LIST_PICASH_LOADING,
  LIST_PICASH,
  GET_BANK_ACCOUNTS,
  BANK_ACCOUNTS_LOADING,
  BANK_LIST,
  ACCOUNT_TYPE_LIST,
  DOCUMENT_TYPE_LIST,
  GET_BANK_ACCOUNTS_AVAILABLE,
  RECHARGE_METHODS_LIST,
  PSE_PARAMS,
  CREDIT_CARDS_LOADING,
  GET_CREDIT_CARDS,
  PSE_PARAMS_LOADING,
  LIST_BILLS,
  PAYMENT_DATA,
  LIST_BILL_LOADING,
  SHOW_BILL,
  BILL_LOADING,
  SELECTED_PAYMENT_METHOD
} from './constants';

const paymentsReducer = (state, action) => {
  switch (action.type) {
    case LIST_PICASH: 
      return {
        ...state,
        listPicash: action.listPicash
      }
    case LIST_PICASH_LOADING: 
      return {
        ...state,
        loadingPicash: action.loadingPicash
      }
    case GET_BANK_ACCOUNTS:
      return {
        ...state,
        bankAccounts: action.bankAccounts
      }
    case BANK_ACCOUNTS_LOADING: 
      return {
        ...state,
        bankAccountsLoading: action.bankAccountsLoading
      }
    case BANK_LIST:
      return {
        ...state,
        bankList: action.bankList
      }
    case ACCOUNT_TYPE_LIST:
      return {
        ...state,
        accountTypeList: action.accountTypeList
      }
    case DOCUMENT_TYPE_LIST:
      return {
        ...state,
        documentTypeList: action.documentTypeList
      }
    case GET_BANK_ACCOUNTS_AVAILABLE:
      return {
        ...state,
        bankAccountsAvailable: action.bankAccountsAvailable
      }
    case RECHARGE_METHODS_LIST:
      return {
        ...state,
        rechargeMethods: action.rechargeMethods
      }
    case PSE_PARAMS:
      return {
        ...state,
        pseParams: action.pseParams
      }
    case GET_CREDIT_CARDS:
    return {
      ...state,
      creditCards: action.creditCards
    }
    case CREDIT_CARDS_LOADING: 
      return {
        ...state,
        creaditCardsLoading: action.creaditCardsLoading
      }
    case PSE_PARAMS_LOADING: 
      return {
        ...state,
        pseParamsLoading: action.pseParamsLoading
      }
    case LIST_BILLS: 
      return {
        ...state,
        listBills: action.listBills
      }
    case LIST_BILL_LOADING: 
      return {
        ...state,
        loadingBills: action.loadingBills
      }
    case PAYMENT_DATA: 
      return {
        ...state,
        paymentData: action.paymentData
      }
    case SHOW_BILL: 
      return {
        ...state,
        showBill: action.showBill
      }
    case BILL_LOADING: 
      return {
        ...state,
        loadingBill: action.loadingBill
      }
    case SELECTED_PAYMENT_METHOD:
      return {
        ...state,
        paymentId: action.paymentId
      }
    default:
      return state
  }
}

export default paymentsReducer;
