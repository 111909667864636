import React, { useContext, useCallback, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import { useForm, Controller } from 'react-hook-form';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { getListByGroups } from 'context/express/actions';
import { BOOKING_STATUSES_TO_ANALYTICS, ALL_IDS_SERVICES_TYPE, DECLARED_VALUE_ANALYTICS } from 'utils/constants';
import { piboxAdminRolesCompany } from 'utils';
import styles from 'components/Tables/styleTableFilters';

const defaultValues = {
  startDate: null,
  endDate: null,
  cityId: '',
  serviceTypeId: '',
  companyAreaId: '',
  statusCd: '',
  declaredValueBoundary: ''
};

const AnalyticsFilters = ({ classes, onFilters }) => {
  const { translate } = useContext(I18nContext);
  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const [{
    checkin: { configuration, company, checkin },
    bookings: { listByGroups },
  }, dispatch] = useStateValue();


  const getListGroups = useCallback(async () => {
    if (company) {
		  const { _id } = company
      await getListByGroups(dispatch, _id);
    }
  },[dispatch, company])

  useEffect(() => {
    if (company) {
      getListGroups();
    }
	}, [company]) // eslint-disable-line

  const onSubmit = (values) => {
    const params = {
      ...values,
      startDate: values.startDate && format(values.startDate, "yyyy-MM-dd"),
      endDate: values.endDate && format(values.endDate, "yyyy-MM-dd"),
      statusCd: values.statusCd && parseInt(values.statusCd),
      adminUser: piboxAdminRolesCompany(checkin?.profile?.roles).length === 1 ? true : false
    };
    Object.keys(params).forEach((key) => {
      if (params[key] === '' || params[key] == null) {
        delete params[key];
      }
    });
    onFilters(params);
  };

  const clearFilters = () => {
    reset(defaultValues);
    onFilters({ adminUser: piboxAdminRolesCompany(checkin?.profile?.roles).length === 1 ? true : false });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate className="relative">
      <Grid container spacing={1}>
        <Grid item md={3} sm={4} xs={6}>
          {configuration?.cities_for_delivery &&
            <FormControl
              variant="outlined"
              size="small"
              className={classes.filter}
              fullWidth
            >
              <InputLabel>
                {translate('analytics.filters.city')}
              </InputLabel>
              <Controller
                control={control}
                name="cityId"
                label={translate('analytics.filters.city')}
                variant="outlined"
                as={
                  <Select>
                    <MenuItem value="">
                      {translate('app.bookings.filter.choose')}
                    </MenuItem>
                    {configuration.cities_for_delivery.map(city => (
                      <MenuItem value={city._id} key={city._id}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />
            </FormControl>
          }
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Controller
              name="startDate"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <KeyboardDatePicker
                  className={classes.filter}
                  size="small"
                  inputVariant="outlined"
                  label={translate('analytics.filters.startDate')}
                  format="dd/MM/yyyy"
                  cancelLabel={translate('cancel')}
                  okLabel={translate('accept')}
                  maxDate={new Date()}
                  invalidDateMessage={translate('invalidFormat')}
                  fullWidth
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </MuiPickersUtilsProvider> 
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Controller
              name="endDate"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <KeyboardDatePicker
                  className={classes.filter}
                  size="small"
                  inputVariant="outlined"
                  label={translate('analytics.filters.endDate')}
                  format="dd/MM/yyyy"
                  cancelLabel={translate('cancel')}
                  okLabel={translate('accept')}
                  maxDate={new Date()}
                  invalidDateMessage={translate('invalidFormat')}
                  fullWidth
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.filter}
            fullWidth
          >
            <InputLabel>
              {translate('analytics.filters.typeOfService')}
            </InputLabel>
            <Controller
              control={control}
              name="serviceTypeId"
              label={translate('analytics.filters.typeOfService')}
              variant="outlined"
              defaultValue=""
              as={
                <Select>
                  <MenuItem value="">
                    {translate('app.bookings.filter.choose')}
                  </MenuItem>
                  {Object.entries(ALL_IDS_SERVICES_TYPE).map((service) => {
                    return (
                      <MenuItem key={service[0]} value={service[0]}>
                        {translate(service[1])}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
            />
          </FormControl>
        </Grid>
        {piboxAdminRolesCompany(checkin?.profile?.roles).length === 1 &&
          <Grid item md={3} sm={4} xs={6}>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.filter}
              fullWidth
            >
              <InputLabel>
                {translate('analytics.filters.headquarter')}
              </InputLabel>
              <Controller
                control={control}
                name="companyAreaId"
                label={translate('analytics.filters.headquarter')}
                variant="outlined"
                defaultValue=""
                as={
                  <Select>
                    <MenuItem value="">
                      {translate('app.bookings.filter.choose')}
                    </MenuItem>
                    {listByGroups?.map(headquarter => (
                      <MenuItem value={headquarter.id === 'admin' ? 'default' : headquarter.id} key={headquarter.id}>
                        {translate(headquarter.title)}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />
            </FormControl>
          </Grid>
        } 
        <Grid item md={3} sm={4} xs={6}>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.filter}
            fullWidth
          >
            <InputLabel>{translate('analytics.filters.status')}</InputLabel>
            <Controller
              control={control}
              name="statusCd"
              label={translate('analytics.filters.status')}
              variant="outlined"
              defaultValue=""
              as={
                <Select>
                  <MenuItem value="">
                    {translate('app.bookings.filter.choose')}
                  </MenuItem>
                  {Object.keys(BOOKING_STATUSES_TO_ANALYTICS).map((key) => (
                    <MenuItem key={key} value={key}>
                      {translate(BOOKING_STATUSES_TO_ANALYTICS[key])}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Grid>

        <Grid item md={3} sm={4} xs={6}>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.filter}
            fullWidth
          >
            <InputLabel>
              {translate('analytics.filters.declaredValue')}
            </InputLabel>
            <Controller
              control={control}
              name="declaredValueBoundary"
              label={translate('analytics.filters.declaredValue')}
              variant="outlined"
              defaultValue=""
              as={
                <Select>
                  <MenuItem value="">
                    {translate('app.bookings.filter.choose')}
                  </MenuItem>
                  {Object.keys(DECLARED_VALUE_ANALYTICS).map((key) => (
                    <MenuItem key={key} value={key}>
                      {translate(DECLARED_VALUE_ANALYTICS[key])}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Grid>

        <Grid item md={6} xs={12} className="text-center-sm">
          <Button
            variant="contained"
            color="primary"
            size="small"
            type="submit"
            style={{ width: 120, marginRight: 10 }}
          >
            {translate('app.bookings.filter.search')}
          </Button>
          <Button
            color="primary"
            size="small"
            type="button"
            style={{ width: 100 }}
            onClick={clearFilters}
          >
            {translate('clear')}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default withStyles(styles)(AnalyticsFilters);