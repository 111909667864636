import { get, post, patch, getExcelFile } from 'api/requests';
import {
  urlSales,
  urlSynchronizeSales,
  urlExcelSales,
  urlGetShopifyOrders,
  urlGetShopifyStores,
  urlGetOrders,
  urlExportOrders
} from 'api';
import { message, loading } from '../commons/actions';
import handleErrorMessage from 'utils/handleErrorMessage';
import {
  GO_TO_FILE,
  LIST_SALES,
  CLEAN_SALES,
  CREATE_PIBOX,
  LIST_SALES_LOADING,
  LIST_SHOPIFY_STORES,
  CREATE_PIBOX_SHOPIFY,
} from './constants';

export const listSales = async (dispatch, params) => {
  dispatch({ type: LIST_SALES_LOADING, loading: true });
  await get(urlSales, params)
    .then(({ data }) => {
      dispatch({
        type: LIST_SALES,
        list: data,
      });
      dispatch({ type: LIST_SALES_LOADING, loading: false });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({
        type: LIST_SALES_LOADING,
        list: null,
        loading: false,
      });
      message(dispatch, mssg);
    });
};

export const syncSales = async (dispatch) => {
  let status = false;
  await post(urlSynchronizeSales, {})
    .then(() => {
      status = true;
    })
    .catch((error) => {
      status = false;
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
    });
  return { status };
};

export const cleanSales = (dispatch) => {
  dispatch({
    type: CLEAN_SALES,
    list: null,
  });
};

export const createPibox = (dispatch, saleList, isShopify) => {
  if (isShopify) {
    dispatch({
      type: CREATE_PIBOX_SHOPIFY,
      saleList,
    });
  } else {
    dispatch({
      type: CREATE_PIBOX,
      saleList,
    });
  }
};

export const cleanSalesPibox = (dispatch) => {
  dispatch({
    type: CREATE_PIBOX,
    saleList: null,
  });
};

export const updateAddress = async (dispatch, id, values) => {
  let status = false;
  await patch(`${urlSales}/${id}/set_address`, { ...values })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
};

export const downloadExcelSales = async (dispatch, params) => {
  loading(dispatch, true);
  await getExcelFile(urlExcelSales, params)
    .then(() => {
      loading(dispatch, false);
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      loading(dispatch, false);
    });
};

export const listStoresShopify = async (dispatch) => {
  await get(urlGetShopifyStores, {})
    .then(({ data }) => {
      dispatch({
        type: LIST_SHOPIFY_STORES,
        listShopifyStores: data.stores || [],
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
    });
};

export const cleanListStoresShopify = (dispatch) => {
  dispatch({
    type: LIST_SHOPIFY_STORES,
    listShopifyStores: null,
  });
};

export const listSalesShopify = async (dispatch, params) => {
  dispatch({ type: LIST_SALES_LOADING, loading: true });
  await get(urlGetShopifyOrders, { ...params })
    .then(({ data }) => {
      dispatch({
        type: LIST_SALES,
        list: {
          data: data.orders,
          total: data.total_orders,
          page: data.page,
          pages: data.pages,
        },
      });
      dispatch({ type: LIST_SALES_LOADING, loading: false });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: LIST_SALES_LOADING,
        list: null,
        loading: false,
      });
    });
};

export const listAllSales = async (dispatch, params) => {
  dispatch({ type: LIST_SALES_LOADING, loading: true });
  await get(urlGetOrders, { ...params })
    .then(({ data }) => {
      dispatch({
        type: LIST_SALES,
        list: {
          data: data.orders,
          total: data.total_orders,
          page: data.page,
          pages: data.pages,
        },
      });
      dispatch({ type: LIST_SALES_LOADING, loading: false });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: LIST_SALES_LOADING,
        list: null,
        loading: false,
      });
    });
};

export const createFile = async (url, type) => {
  if (typeof window === 'undefined') return // make sure we are in the browser
  const response = await fetch(url)
  const data = await response.blob()
  const metadata = {
    type: type || 'xlsx'
  }
  return new File([data], url, metadata)
}

export const getOptimizeRouteSelected = async (dispatch, orders) => {
  const order_ids = orders.map(order => order?.id)
  let status = false;
  let urlExcel = null;
  await post(urlExportOrders, { order_ids })
    .then(({ data }) => {
      status = true;
      urlExcel = data?.report?.url || ''
    })
    .catch((error) => {
      status = false;
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      urlExcel = null;
    });
  return { status, urlExcel };
}

export const getOptimizeRouteFilters = async (dispatch, params) => {
  let status = false;
  let urlExcel = null;
  await get(urlExportOrders, { ...params })
    .then(({ data }) => {
      status = true;
      urlExcel = data?.report?.url || ''
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
      urlExcel = null;
    });
  return { status, urlExcel };
}

export const getOrdersAllFilters = async (dispatch, params) => {
  let status = false;
  let urlExcel = null;
  await get(urlExportOrders, { ...params, sales_orders_all: true })
    .then(({ data }) => {
      status = true;
      urlExcel = data?.report?.url || ''
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
      urlExcel = null;
    });
  return { status, urlExcel };
}

export const goToFile = (dispatch, url) => {
  dispatch({ type: GO_TO_FILE, fileUrl: [url] });
}

export const cleanFileUrl = (dispatch) => {
  dispatch({
    type: GO_TO_FILE,
    fileUrl: null,
  });
};