import { createStyles } from '@material-ui/core/styles';

const styles = createStyles(theme => ({
  banner: {
    marginTop: '30px',
    alignItems: 'center',
    display: 'flex',
    textAlign: 'center',
    background: 'url("/images/tracking/tracking-code.jpg") no-repeat',
    backgroundSize: 'cover',
    minHeight: '100vh',
    width: '100%',
    position: 'relative',
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      backgroundColor: 'rgb(120,37,189,0.25)',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
    }
  },
  wrapper: {
    padding: '40px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    margin: '10px 4px',
    borderRadius: 20,
    boxShadow: '0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05)',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 20px 30px',
      margin: '0 15px'
    }
  },
  marginBottomField: {
    marginBottom: '30px'
  }
}));

export default styles;