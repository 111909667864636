import React, { useContext, useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { getGroupUserList } from 'context/settings/groups/actions';
import UsersTable from 'components/Tables/Settings/Users';
import { INITIAL_PAGE } from 'utils/constants';
import Loading from 'components/Loading';
import UsersTableFilters from 'components/Tables/Settings/Users/UsersTableFilters';

export default function ModalUsersCompany ({
  open,
  setOpen,
  selectUser
}) {
  const { translate } = useContext(I18nContext);
  const [userSearch, setUserSearch] = useState('');
  const [per_page] = useState(10);
  const [{ groups: { groupUserList, loadingGroupUserList }, checkin: { company }}, dispatch] = useStateValue();

  const getUserList = useCallback(async (page) => {
    getGroupUserList(dispatch, { page, per_page, user_search: userSearch }, company._id)
  }, [dispatch, per_page, company, userSearch]);

  useEffect(() => {
    company && getUserList(INITIAL_PAGE);
  }, [getUserList, company]);

  const onSubmit = ({ name, email }) => {
    email ? setUserSearch(email) : setUserSearch(name);
  }

  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <DialogTitle className="text-center" disableTypography style={{ padding: '20px' }}>
        <Typography variant="h5" color="primary">
          {translate('app.users.table.title')}
        </Typography>
        <Typography variant="body2">
          {translate('app.users.table.description')}
        </Typography>
      </DialogTitle>

      <DialogContent className="text-center" style={{ margin: '8px 0 2px' }}>
        {loadingGroupUserList && <Loading />}

        <UsersTableFilters submit={onSubmit} />
        <br />
        <UsersTable
          data={groupUserList}
          getUsers={getUserList}
          recordsPerPage={per_page}
          usersPerGroup={true}
          btnChoose={true}
          selectUser={selectUser}
        />
       
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setUserSearch('')
          setOpen(false)
        }}>
          {translate('close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}