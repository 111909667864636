import React, { useEffect, useContext } from 'react';
import {
  Grid,
  Paper,
  Select,
  Button,
  Snackbar,
  MenuItem,
  Typography,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import LocalAtmSharpIcon from '@material-ui/icons/LocalAtmSharp';
import { useForm, Controller } from 'react-hook-form';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { getPaymentData, getShowBill, selectedPaymentMethod } from 'context/payments/actions';
import { message as closeMessage } from 'context/commons/actions';
import TitleWithDowload from 'components/Titles/TitleWithDowload';
import { formatCost } from 'utils';
import Loading from 'components/Loading';

const defaultValues = {
  paymentId: ''
};

export default function PaymentMethodPipay({ history, match }) {
  const { translate } = useContext(I18nContext);
  const { params: { id } } = match;
  const { handleSubmit, control, watch } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const [{
      payments: { paymentData, showBill, loadingBill },
      commons: { message },
    },
    dispatch,
  ] = useStateValue();


  useEffect(() => {
    getPaymentData(dispatch, {});
  }, []); // eslint-disable-line

  useEffect(() => {
    id && getShowBill(dispatch, id)
  }, [id]); // eslint-disable-line

  const submit = values => {
    selectedPaymentMethod(dispatch, values?.paymentId)
    history.push(`/app/payments/pay-bill/${id}`);
  }

  if (loadingBill) return <Loading />

  return (
    <div style={{ background: '#fafafa' }}>
      <div className="padding-page-full-height">
        <TitleWithDowload
          title={translate('bill.billPayment')}
          icon={
            <LocalAtmSharpIcon
              className="align-center"
              style={{ marginRight: 7 }}
            />
          }
        />
        <br />
        <form onSubmit={handleSubmit(submit)} noValidate>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <Paper style={{ padding: '40px 35px', borderRadius: 15, height: '100%' }}>
                <Typography>
                  1. {translate('bill.checkInvoiceYouWantToPay')}
                </Typography>
                <div
                  style={{ width: '100%', border: '2px solid #7825bd', marginTop: '30px', borderRadius: 12 }}
                >
                  <embed
                    src={showBill?.file_url}
                    style={{ width: '100%', height: 'calc(100vh - 400px)' }}
                  />
                </div>

              </Paper>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Paper style={{ padding: '40px 35px', borderRadius: 15, height: '100%' }}>
                <div className="text-center" style={{ marginBottom: 60 }}>
                  <Typography color="primary" variant="body1">
                    {translate('bill.amountToPaid')}:
                  </Typography>
                  <Typography color="primary" variant="h5">
                    {formatCost(showBill?.amount)}
                  </Typography>
                </div>
                <FormControl
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel>{translate('bill.selectPaymentMethod')}</InputLabel>
                  <Controller
                    control={control}
                    name="paymentId"
                    label={translate('bill.selectPaymentMethod')}
                    variant="outlined"
                    as={
                      <Select>
                        {paymentData?.payment_method?.map(payment => (
                          <MenuItem key={payment._id} value={payment._id}>{payment.name}</MenuItem>
                        ))}
                      </Select>
                    }
                  />
                </FormControl>
              </Paper>
            </Grid>
          </Grid>
          <div className="text-right" style={{ marginTop: 25 }}>
            <Button
              href="/app/payments/pipay"
              variant="outlined"
              color="primary"
              className="btn-rounded"
              style={{ marginRight: 10 }}
            >
              {translate('cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="btn-rounded"
              type="submit"
              disabled={!watch('paymentId')}
            >
              {translate('continue')}
            </Button>
          </div>
        </form>
      </div>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </div>
  );
}
