import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  containerInputs: {
    '& .react-swipeable-view-container': {
     padding: '10px 0 15px'
    },
    '& > div > div > div': {
      [theme.breakpoints.up('sm')]: {
        margin: '0 20px'
      }
    }
  },
  icon: {
    fontSize: '18px',
    marginRight: '12px',
    verticalAlign: 'text-bottom'
  },
  tabsSignup: {
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent',
    },
    '& button': {
      fontSize: '0.95rem'
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiTabs-flexContainer': {
        display: 'block',
        '& button': {
          display: 'block',
          margin: 'auto'
        }
      }
    }
  }
}));

export default styles;