import AWS from 'aws-sdk';

export const configureS3 = () => {
  AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-east-1:54033641-88fa-491a-93e7-2d276f2fce78'
    })
  });
}

export const uploadFile = async (file, path) =>  {
  configureS3();
  var fileName = file.name;
  var upload = new AWS.S3.ManagedUpload({
    params: {
      ACL: 'public-read',
      ContentType: file.type,
      Bucket: 'static.pibox.app',
      Key: `${path}/${fileName}`,
      Body: file
    }
  });
  return upload.promise();
}