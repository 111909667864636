import React from 'react';
import Integration from 'models/integrations/Integration';
import VtexActivation from 'components/Integrations/Vtex/VtexActivation';
//import WooCommerceStoreConfiguration from 'components/Integrations/WooCommerce/WooCommerceStoreConfiguration';
import WooCommerceDisableStore from 'components/Integrations/WooCommerce/WooCommerceDisableStore';

class VtexIntegration extends Integration {
  constructor(integration) {// eslint-disable-line
    super(integration);
  }
  getActivateIntegration(getIntegrations) {
    return <VtexActivation getIntegrations={getIntegrations} />;
  }
  getDisableIntegration(account, getIntegrations) {
    return (
      <WooCommerceDisableStore
        account={account}
        getIntegrations={getIntegrations}
        type={1}
      />
    );
  }
}

export default VtexIntegration;
