import React from 'react';
import { Button} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const UploadNormalFile = ({
  text,
  register,
  fileName,
  nameFile,
  accept,
  helpText
}) => {
  return (
    <>
      <Button
        variant="outlined"
        component="label"
        style={{ justifyContent: 'left', borderRadius: '10px' }}
        fullWidth
      >
        {text}
        <input
          hidden
          type="file"
          name={fileName}
          ref={register}
          accept={accept}
        />
        <span
          style={{ position: 'absolute', right: 0, padding: '0px 8px', background: 'rgb(224,224,224)' }}
        >
          <CloudUploadIcon style={{ marginTop: 5 }} />
        </span>
      </Button>
      <small>{nameFile ? nameFile[0]?.name : helpText}</small>
    </>
  );
};

export default UploadNormalFile;
