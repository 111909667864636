import React, { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { I18nContext } from 'translations';

export default function ModalDriverCommodateInfo ({ data, commodateEdit, isReceive }) {
  const { translate } = useContext(I18nContext);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} style={{ marginBottom: 10 }}>
        <Typography>{translate('commodate.modalRequest.info.driver')}: {data?.driver ?? ''}</Typography> 
        <Typography>{translate('commodate.modalRequest.info.phone')}: {data?.driver_phone_number ?? ''}</Typography> 
        {isReceive && <Typography>{translate('commodate.modalRequest.info.startDate')}: {data.init_date}</Typography>}
        <Typography>{translate('commodate.modalRequest.info.returnDate')}: {commodateEdit ? data?.return_date : format(new Date(data?.return_date), "dd-MM-yyyy")}</Typography>
      </Grid>
    </Grid>
  )
}
