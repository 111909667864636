import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Snackbar,
  CircularProgress
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { createFavoriteAddress, updateFavoriteAddress } from 'context/settings/favoriteAddresses/actions';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { I18nContext } from 'translations';
import PinDropIcon from '@material-ui/icons/PinDrop';
import AddressInputs from 'components/CustomInputs/AddressInputs';
import { modalMessage } from 'utils';
import styles from './FavoriteAddressesStyles';

function ModalFavoriteAddresses ({
  open,
  setOpen,
  classes,
  getAddresses,
  addressEdit,
  setAddressEdit,
  isCompany
}) {
  const { translate } = useContext(I18nContext);
  const [address, setAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cityId, setCityId] = useState(null);
  const [{ commons: { message} }, dispatch] = useStateValue();

  const { handleSubmit, register, control, errors, clearErrors, reset } = useForm();

  const messageOk = () => {
    setLoading(false);
    setOpen(false);
    modalMessage(translate('congratulations'), translate('app.settings.addresses.form.messageOk'), 'success', translate('accept'), true).then(() => {
      getAddresses();
      setAddressEdit(null);
      setCityId(null);
      reset();
      setAddress(null);
    })
  }

  const submit = async values => {
    setLoading(true);
    const callback = addressEdit
      ? await updateFavoriteAddress(dispatch, addressEdit&&addressEdit._id, { ...values, city_id: cityId }, isCompany)
      : await createFavoriteAddress(dispatch, { ...values, city_id: cityId }, isCompany);
    callback.status ? messageOk() : setLoading(false);
  }

  useEffect(() => {
    if (addressEdit) {
      const { address, geojson, city_id } = addressEdit;
      setAddress({
        name: address,
        lat: geojson.coordinates[1],
        lon: geojson.coordinates[0]
      })
      setCityId(city_id ? city_id : null);
    }
  }, [addressEdit])

  const handleCity = (id) => setCityId(id);

  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className="text-center" disableTypography>
        <Typography variant="h5" color="primary">
          <PinDropIcon style={{ fontSize: '70px' }} className="left-right-animation" />
          <br />
          {addressEdit ? translate('app.settings.addresses.editFavoriteAddress') : translate('app.settings.addresses.addFavoriteAddress')}
        </Typography>
        <Typography variant="subtitle2">
          {translate('app.settings.addresses.addAddressText')}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <form onSubmit={handleSubmit(submit)} noValidate>
          <Grid container spacing={2} style={{ marginBottom: '5px' }}>
            <Grid item md={12} xs={12} style={{ marginBottom: 10 }}>
              <TextField
                name="name"
                label={translate('app.settings.addresses.form.name')}
                size="small"
                type="text"
                defaultValue={addressEdit?.name ? addressEdit.name : ''}
                fullWidth
                required
                focused
                variant="outlined"
                inputRef={register({
                  required: translate('requiredField')
                })}
                error={!!errors.name}
                helperText={errors.name && translate(errors.name.message)}
              />
            </Grid>
            <Grid item md={12} xs={12} style={{ marginBottom: 10 }}>
              <AddressInputs
                register={register}
                address={address}
                setAddress={setAddress}
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                twoColumns
                isAddress
                cityId={cityId}
                handleCity={handleCity}
                needCity={true}
              />
            </Grid>
            <Grid item xs={12} className={classes.contentButtonSave}>
              <Button
                variant="contained"
                color="primary"
                style={{ width: '200px' }}
                type="submit"
                startIcon={loading ? <CircularProgress size={18} /> : null}
                disabled={loading}
              >
                {translate('save')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={() => {
            setOpen(false)
            setAddressEdit(null);
            setCityId(null)
          }}
        >
          {translate('close')}
        </Button>
      </DialogActions>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </Dialog>
  )
}

export default withStyles(styles)(ModalFavoriteAddresses);