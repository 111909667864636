import React, { useState, useContext, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination
} from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { format } from 'date-fns';
import { formatCost } from 'utils';
import { I18nContext } from 'translations';
import { billPaymentStatusClass } from 'utils';
import { BILL_PAYMENT_STATUSES } from 'utils/constants';
import EmptyData from '../../EmptyData';
import styles from '../../style';

const PipayTable = ({
  classes,
  data,
  getListBills,
  recordsPerPage
}) => {
  const [currentPage, setCurrentPage] = useState(data.page);
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    setCurrentPage(data.page)
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage)
    getListBills(newPage);
  }

  if (!data || (data && data.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />
  }

  return (
    <>
      <TableContainer>
        <Table
          className={classes.tableStyle}
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell>{translate('bills.table.status')}</TableCell>
              <TableCell>{translate('bills.table.startDate')}</TableCell>
              <TableCell>{translate('bills.table.endDate')}</TableCell>
              <TableCell>{translate('bills.table.billN')}</TableCell>
              <TableCell>{translate('bills.table.amountPaid')}</TableCell>
              <TableCell>{translate('bills.table.bill')}</TableCell>
              <TableCell>{translate('bills.table.expirationDate')}</TableCell>
              <TableCell>{translate('bills.table.action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data.data || []).map(bill => (
              <TableRow hover key={bill._id}>
                <TableCell>
                  <p className={billPaymentStatusClass(bill.x_cod_response)}>
                    {translate(BILL_PAYMENT_STATUSES[bill.x_cod_response])}
                  </p>
                </TableCell>
                <TableCell>
                  <span className={classes.date}>
                    {format(new Date(bill.start_date), 'dd/MM/yyyy')}
                  </span>
                </TableCell>
                <TableCell>
                  <span className={classes.date}>
                    {format(new Date(bill.end_date), 'dd/MM/yyyy')}
                  </span>
                </TableCell>
                <TableCell>
                  <span className="primaryColor">
                    {bill.code}
                  </span>
                </TableCell>
                <TableCell>
                  {formatCost(bill.amount)}
                </TableCell>
                <TableCell>
                  <Button
                    href={bill.file_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="primary"
                    underline="hover"
                    startIcon={<VisibilityOutlinedIcon fontSize="small" />}
                    size="small"
                    style={{ fontSize: '0.8rem' }}
                  >
                    {translate('bills.table.actions.seeBill')}
                  </Button>
                </TableCell>
                <TableCell>
                  <span className={classes.dateExpiration}>
                    {format(new Date(bill.expiration_date), 'dd/MM/yyyy')}
                  </span>
                </TableCell>
                <TableCell>
                  {bill.x_cod_response === 1 ?
                    <Button
                      href={bill.voucher_url}
                      target="_blank"
                      color="primary"
                      variant="contained"
                      fullWidth
                      startIcon={<VisibilityOutlinedIcon fontSize="small" />}
                      className="btn-light-primary"
                      style={{ fontSize: '1em' }}
                    >
                      {translate('bills.table.actions.receipt')}
                    </Button>
                  : <Button
                      href={`/app/payments/payment-method/${bill._id}`}
                      color="primary"
                      variant="contained"
                      fullWidth
                    >
                      {translate('bills.table.actions.pay')}
                    </Button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.total ? data.total : data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
    </>
  );
}


export default withStyles(styles)(PipayTable);

