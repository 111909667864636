import { createStyles } from '@material-ui/core/styles';

const styles = createStyles(theme => ({
  wrapper: {
  	padding: '40px',
  	alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    margin: '10px 4px',
    borderRadius: 20,
    boxShadow: '0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05)',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 20px 30px'
    }
  },
  principalImg: {
    maxWidth: '90%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '55%'
    }
  }
}));

export default styles;