import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  icon: {
    marginRight: 10,
    verticalAlign: 'middle',
    cursor: 'pointer'
  },
  identifier: {
    marginRight: 20,
    verticalAlign: 'middle'
  },
}));

export default styles;