import React, { useContext, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton'
import { I18nContext } from 'translations';
import EmptyData from '../../EmptyData';
import styles from '../../style';

const BankAccountsTable = ({
  classes,
  data,
  getAccounts,
  recordsPerPage,
  editBankAccount,
  removeAccount,
  rolesDisabled
}) => {
  const [currentPage, setCurrentPage] = useState(data.page);
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    setCurrentPage(data.page)
  }, [data])

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage)
    getAccounts(newPage);
  }

  if (!data || (data && data.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />
  }

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
            <TableCell></TableCell>
              <TableCell>{translate('app.payments.bankAccounts.table.bank')}</TableCell>
              <TableCell>{translate('app.payments.bankAccounts.table.accountNumber')}</TableCell>
              <TableCell>{translate('app.payments.bankAccounts.table.accountType')}</TableCell>
              <TableCell>{translate('app.payments.bankAccounts.table.holder')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data.data || []).map(account => (
              <TableRow hover key={account._id}>
                <TableCell>
                  {account.bank && account.bank.logo_url ? (
                    <img src={account.bank.logo_url} alt={account.bank.name} style={{ width: '35px' }} />
                  ) : (
                    <span>
                      {account.bank.name}
                    </span>
                  )}
                </TableCell>
                <TableCell>
                  {account.bank.name}
                </TableCell>
                <TableCell>
                  {account.number}
                </TableCell>
                <TableCell>
                  {account.bank_account_type ? account.bank_account_type.name : ' - '}
                </TableCell>
                <TableCell>
                  {account.name ? `${account.name} ${account.last_name}` : ' - '}
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    style={{ marginRight: 8 }}
                    className="hover-primary"
                    onClick={() => editBankAccount(account)}
                    disabled={!account.bank_account_type || rolesDisabled}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    className="hover-primary"
                    disabled={rolesDisabled}
                    onClick={() => removeAccount(account)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
    </>
  );
}

function areEqual(prevProps, nextProps) {
  return prevProps.data === nextProps.data;
}

export default withStyles(styles)(React.memo(BankAccountsTable, areEqual));

