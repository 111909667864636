import React, { useEffect, useContext, useState, useCallback } from 'react';
import { Snackbar } from '@material-ui/core';
import LocalAtmSharpIcon from '@material-ui/icons/LocalAtmSharp';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { getBills } from 'context/payments/actions';
import { message as closeMessage } from 'context/commons/actions';
import Loading from 'components/Loading';
import PipayTable from 'components/Tables/Payments/Pipay'
import TitleWithDowload from 'components/Titles/TitleWithDowload';
import PipayTableFilters from 'components/Tables/Payments/Filters/PipayTableFilters';
import { INITIAL_PAGE, PER_PAGE } from 'utils/constants';

export default function Pipay() {
  const { translate } = useContext(I18nContext);
  const [per_page] = useState(PER_PAGE);
  const [filters, setFilters] = useState({
    desc_asc: 'desc'
  });
  const [
    {
      payments: { listBills, loadingBills },
      commons: { message },
    },
    dispatch,
  ] = useStateValue();

  const getListBills = useCallback(
    async (page) => {
      getBills(dispatch, { page, per_page, ...filters });
    },
    [dispatch, per_page, filters]
  );

  useEffect(() => {
    getListBills(INITIAL_PAGE)
  }, [getListBills]); // eslint-disable-line

  const onFilters = (params) => setFilters(params);

  return (
    <div style={{ background: '#fafafa' }}>
      <div className="padding-page-full-height">
        <TitleWithDowload
          title={translate('bills.myBills')}
          icon={
            <LocalAtmSharpIcon
              className="align-center"
              style={{ marginRight: 7 }}
            />
          }
        />
        {loadingBills && <Loading />}
        <div className="marginFiltersTable">
          <PipayTableFilters onFilters={onFilters} />
        </div>
        {listBills && (
          <PipayTable
            data={listBills}
            getListBills={getListBills}
            recordsPerPage={per_page}
          />
        )}
      </div>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </div>
  );
}
