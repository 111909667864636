import React, { useContext, useEffect, lazy, Suspense, useState } from 'react';
import { Divider, Grid, Hidden, Snackbar, Typography, LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { format } from 'date-fns';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { getTracking, sendRate } from 'context/tracking/actions';
import { message as closeMessage } from 'context/commons/actions';
import DriverCard from 'components/Cards/DriverCard';
import SmallMenu from 'components/Layouts/LayoutOuter/SmallMenu';
import Loading from 'components/Loading';
import { statusClass } from 'utils';
import { STATUSES, SIZE_CD, REASON_NOT_RECEIVED, REASON_CANCELED_PICKUP, COUNTRY_LANGUAJE } from 'utils/constants';
import TrackingInformation from './components/TrackingInformation';
import TrackingRating from './components/TrackingRating';
import Alert from '@material-ui/lab/Alert';
import withHelmet from 'utils/withHelmet';
import styles from './style';
const TrackingTimeline = lazy(() => import('components/Timelines/TrackingTimeline'));
const TrackingMap = lazy(() => import('components/Maps/TrackingMap'));

const Tracking = ({ classes, match, location }) => {
  const { translate } = useContext(I18nContext);
  const [redirect, setRedirect] = useState(false);
  const [{ tracking: { tracking, events, rating }, commons: { loading, message } }, dispatch] = useStateValue();
  const { params: { id } } = match;

  useEffect(() => {
    const getDataTracking = async () => await getTracking(dispatch, id, 0);
    getDataTracking();
  }, []); // eslint-disable-line 

  useEffect(() => {
    //if ( tracking && (tracking.status_cd === 900 || tracking.status_cd === 0 || tracking.status_cd === 1 || tracking.status_cd === 4)) {
    if (tracking) {
      const interval = setInterval(() => {
        const getDataTracking = async () => await getTracking(dispatch, id, 1);
        getDataTracking();
      }, 8000);
      return () => clearInterval(interval);
    }
  }, [tracking]); // eslint-disable-line

  const rateDelivered = async (value) => await sendRate(dispatch, id, { rating: value } );

  const failed = () => (
    <div className={classes.containerRight}>
      <img src="/images/tracking/sad-packages.gif" alt="ops" style={{ maxWidth: '55%' }} />
      <Typography variant="h5" color="textPrimary">
        {tracking.canceled_pickup_reason_cd ?
          translate(REASON_CANCELED_PICKUP[tracking.canceled_pickup_reason_cd]) : tracking.not_received_reason_cd ?
          translate(REASON_NOT_RECEIVED[tracking.not_received_reason_cd]) : translate('tracking.weCouldNotCompleteTheOrder')
        }
      </Typography>
      <br />
    </div>
  );

  const rate = () => (
    <div className={classes.containerRight}>
      <TrackingRating
        sendRate={rateDelivered}
        qualified={tracking.rating_by_customer ? tracking.rating_by_customer : rating ? rating : false}
        rate={tracking.rating_by_customer && tracking.rating_by_customer}
      />
    </div>
  );

  const map = () => (
    tracking.stop && (
      <div className={classes.containerMap}>
        <Suspense fallback={<LinearProgress color="primary" />}>
          <TrackingMap
            vehicle={tracking.vehicle && tracking.vehicle}
            stop={tracking.stop}
            status={tracking.status_cd}
          />
        </Suspense>
      </div>
    )
  );

  const timeText = () => {
    if (tracking.stop.driver_minutes_to_arrival) {
      return tracking.stop.driver_minutes_to_arrival === 1 ? tracking.stop.driver_minutes_to_arrival + ' ' + translate('minute') :  tracking.stop.driver_minutes_to_arrival + ' ' + translate('minutes')
    } else if (tracking.status_cd === 0 || tracking.status_cd === 1) {
      return translate('tracking.onRoute');
    }
    return ' - ';
  }

  const typeView = (status) => {
    switch (status) {
      case 3: case 4: case 5:
        return failed();
      case 0: case 1: case 900:
        return map();
      case 2:
        return rate();
      default:
        return null;
    }
  }

  const iterateRelaunched = (objeto) => {
    let flat = objeto;
    let last = null;
    while (flat !== null) {
      last = flat;
      flat = flat.relaunched_to;
    }
    return last;
  }

  const lastId = tracking && tracking.relaunched_to && iterateRelaunched(tracking) && iterateRelaunched(tracking)._id
  
  if (lastId) {
    setTimeout(() => {
      setRedirect(true);
    }, 5000);
  }

  if (redirect) return window.location.href = `/tracking/${lastId}`

  return (
    <Grid container>
      {loading && (<Loading />)}
      {lastId && (
        <Snackbar open={true}>
          <Alert severity="info">{translate('tracking.redirectedMessage')}, <a href={`/tracking/${lastId}`}>{translate('tracking.newGuide')}</a></Alert>
        </Snackbar>
      )}
      <Hidden mdUp>
        <Grid item xs={12} style={{ padding: '0 5px' }}>
        {tracking && (
          <SmallMenu
            title={translate('tracking.tracking')}
            logo={tracking.company && tracking.company.photo_url}
            lang={COUNTRY_LANGUAJE[tracking.country_id]}
          />
        )}
        </Grid>
      </Hidden>

      {location.search.split('=')[1] !== 'full' && (
        <Grid item md={5} sm={12} xs={12} className={classes.orderXs}>
          <div className={classes.scroll}>
            <Hidden smDown>
              {tracking && (
                <SmallMenu
                  title={translate('tracking.tracking')}
                  logo={tracking.company && tracking.company.photo_url} 
                  lang={COUNTRY_LANGUAJE[tracking.country_id]}
                />
              )}
              <Divider color="lightgray" className={classes.line} />
            </Hidden>

            <Grid container justify="center" alignItems="center">
              <Grid item sm={4} xs={12} className={classes.paddingInfo}>
                <TrackingInformation
                  title={translate('tracking.guideNumber')}
                  text={tracking ? tracking._id : ' - '}
                />
              </Grid>
              <Grid item sm={8} xs={12} className={classes.paddingInfo}>
                <DriverCard
                  driver={tracking && tracking.driver && tracking.driver}
                  vehicle={tracking && tracking.vehicle && tracking.vehicle}
                />
              </Grid>
            </Grid>
            <Divider color="lightgray" className={classes.line} />

            <Grid container alignItems="center" className={classes.paddingInfo}>
              <Grid item xs={12} className={classes.marginBottomInfo}>
                <img
                  src="/images/tracking/destination_marker.svg"
                  alt="address"
                  className={classes.imgAddress}
                />
                <Typography variant="body1" color="textPrimary" className={classes.infoAddress}>
                  {tracking ? tracking.stop.address : '-'}
                  <br />
                  <small style={{ color: '#828080' }}>
                    {tracking && tracking.stop.secondary_address}
                  </small>
                </Typography>
              </Grid>
            </Grid>
            <Divider color="lightgray" />

            <Grid container alignItems="center" className={classes.paddingInfo}>
              <Grid item md={4} sm={6} xs={6} className={classes.marginBottomInfo}>
                <TrackingInformation
                  title={translate('tracking.date')}
                  text={tracking ? format(new Date(tracking.created_at), 'dd/MM/yyyy') : '-'}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={6} className={classes.marginBottomInfo}>
                <TrackingInformation
                  title={translate('tracking.eta') }
                  text={tracking && timeText()}
                  helpText={translate('tracking.etaText')}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={6}>
                <TrackingInformation
                  title={translate('tracking.size')}
                  text={tracking ? translate(SIZE_CD[tracking.size_cd]) : '-'}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={6} className={classes.marginBottomInfo}>
                <TrackingInformation
                  title={`# ${translate('tracking.order')}`}
                  text={tracking ? tracking.reference : '-'}
                />
              </Grid>
              <Grid item md={8} sm={6} xs={6} className={classes.marginBottomInfo}>
                <TrackingInformation
                  title={translate('tracking.status')}
                  text={tracking ? translate(STATUSES[tracking.status_cd]) : '-'}
                  classStatus={tracking ? statusClass(tracking.status_cd) : ''}
                />
              </Grid>
              
            </Grid>
            {tracking?.delivery_validation_code && (
              <>
                <Divider color="lightgray" />
                <Grid container alignItems="center" className={classes.paddingInfo}>
                  <Grid item md={12} xs={12}>
                    <TrackingInformation
                      title={`${translate('tracking.validationCode')}:`}
                      text={tracking ? tracking.delivery_validation_code : '-'}
                      type={1}
                      helpText={`${translate('tracking.validationCode.text')}`}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Divider color="lightgray" />
            <Grid container justify="flex-start">
            <Grid item xs={12}>
              <Suspense fallback={<LinearProgress color="primary" />}>
                {events.length > 0 && events.map(event => (
                  <TrackingTimeline
                    key={event._id}
                    event={event}
                    driver={tracking.driver && tracking.driver}
                  />
                ))}
              </Suspense>
              <br />
            </Grid>
          </Grid>
          </div>
        </Grid>
      )}
      <Grid item md={location.search.split('=')[1] === 'full' ? 12 : 7} xs={12}>
        {tracking && typeView(tracking.status_cd)}
      </Grid>

      <Snackbar
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </Grid>
  )
}

Tracking.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withStyles(styles)(withHelmet({
  title: 'Rastrear Envíos | Pibox',
  description: 'Rastrea tu envío o pedido con nuestro Tracking Code de Pibox en tiempo real, aquí. Consulta ruta y tiempo de llegada.',
  image: 'https://pibox.app/images/landing/express.png'
})(Tracking));
