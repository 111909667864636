import React, { useContext, useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  FormControl,
  Button,
  Select,
  MenuItem,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  FormGroup
} from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { I18nContext } from 'translations';
import ModalListGroups from 'components/Modals/Settings/Groups/ModalListGroups';
import { ROLES, SERVICE_TYPE_USER } from 'utils/constants';
import { schema, schemaEditUser } from './CreateUserSchema';


export default function CreateUser({
  submit,
  userInfo,
  loading,
  setChosenGroup,
  chosenGroup,
  selectedUser,
  serviceTypeChecked
}) {
  const [open, setOpen] = useState(false);
  const [defaultServiceType, setDefaultServiceType] = useState(serviceTypeChecked ? serviceTypeChecked : SERVICE_TYPE_USER);
  const { translate } = useContext(I18nContext);
  const { handleSubmit, register, control, errors, watch, setValue } = useForm({
    resolver: yupResolver(selectedUser ? schemaEditUser : schema)
  });

  useEffect(() => {
    selectedUser && setChosenGroup(selectedUser ? selectedUser : null);
  }, [selectedUser, setChosenGroup]);

  useEffect(() => {
    watch('role') === ROLES[0].value && setChosenGroup(null);
  }, [setChosenGroup, watch]);

  const handleCheck = (e, item) => {
    const hasServiceType = defaultServiceType.filter(x => x.requestable_service_type_group_cd === item.requestable_service_type_group_cd);
    const selected = [{
      ...item,
      id: hasServiceType?.[0]?.id ? hasServiceType[0].id : null,
      is_enabled: e.target.checked
    }]
    
    let ids = new Set(selected.map(groupCd => groupCd.requestable_service_type_group_cd));
    let mergedServiceType = [...selected, ...defaultServiceType.filter(dType => !ids.has(dType.requestable_service_type_group_cd))];
    
    setDefaultServiceType(mergedServiceType)
    return mergedServiceType
  };

  useEffect(() => {
    !selectedUser && setValue('requestable_service_groups', SERVICE_TYPE_USER)
  }, []) // eslint-disable-line


  useEffect(() => {
    if (serviceTypeChecked) {
      let ids = new Set(serviceTypeChecked.map(groupCd => groupCd.requestable_service_type_group_cd));
      let mergedServiceType = [...serviceTypeChecked, ...SERVICE_TYPE_USER.filter(dType => !ids.has(dType.requestable_service_type_group_cd))];
      setValue('requestable_service_groups', mergedServiceType)
    }
  },[serviceTypeChecked]) // eslint-disable-line


  return(
    <form
      onSubmit={handleSubmit(submit)}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={2} style={{ marginBottom: '5px' }}>
        <Grid item md={12} xs={12} style={{ marginBottom: 0 }}>
          <Grid container alignItems="center" style={{ marginBottom: 15 }}>
            <Grid item md={4} xs={12}>
              <label>
                {translate('app.settings.users.form.fullName')}
              </label>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextField
                name="name"
                size="small"
                type="text"
                defaultValue={selectedUser ? selectedUser.name : userInfo.name}
                variant="outlined"
                fullWidth
                required
                inputRef={register}
                error={!!errors.name}
                helperText={errors.name && translate(errors.name.message)}
              />
            </Grid>
          </Grid>

          <Grid container alignItems="center" style={{ marginBottom: 15 }}>
            <Grid item md={4} xs={12}>
              <label>
                {translate('app.settings.users.form.email')}
              </label>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextField
                name="email"
                size="small"
                type="text"
                defaultValue={selectedUser ? selectedUser.email : userInfo.email}
                inputProps={
                  { readOnly: true, }
                }
                variant="outlined"
                fullWidth
                required
                inputRef={register}
                error={!!errors.email}
                helperText={errors.email && translate(errors.email.message)}
              />
            </Grid>
          </Grid>
            <Grid container alignItems="center" style={{ marginBottom: 15 }}>
              <Grid item md={4} xs={12}>
                <label>
                  {translate('app.settings.users.form.phone')}
                </label>
              </Grid>
              <Grid item md={8} xs={12}>
                <Grid container spacing={1}>
                  <Grid item md={4} sm={4} xs={4} className="phone-input-standard-border text-left">
                    <Controller
                      control={control}
                      name="country_code"
                      defaultValue={selectedUser ? selectedUser.country_code : '57'}
                      variant="outlined"
                      size="small"
                      as={
                        <PhoneInput
                          country="co"
                          defaultCountry="co"
                          regions={['america']}
                          preferredCountries={['co', 'mx', 'br', 'pe', 'py']}
                          inputProps={{ disabled: true }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item md={8} sm={8} xs={8}>
                    <TextField
                      name="phone"
                      variant="outlined"
                      type="tel"
                      inputRef={register}
                      defaultValue={selectedUser ? selectedUser.phone : userInfo.phone}
                      fullWidth
                      size="small"
                      required
                      inputProps={{ maxLength: "10" }}
                      error={!!errors.phone}
                      helperText={errors.phone && translate(errors.phone.message)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!selectedUser &&
              <>
              <Grid container alignItems="center" style={{ marginBottom: 15 }}>
                <Grid item md={4} xs={12}>
                  <label>
                    {translate('app.settings.users.form.password')}
                  </label>
                </Grid>
                
                <Grid item md={8} xs={12}>
                  <TextField
                    name="password"
                    size="small"
                    type="password"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    required
                    inputRef={register}
                    autoComplete="no-password"
                    error={!!errors.password}
                    helperText={errors.password && translate(errors.password.message)}
                  />
                </Grid>
              </Grid>
            </>
          }

          <Grid container alignItems="center">
            <Grid item md={4} xs={12}>
              <label>
                {translate('app.settings.users.form.role')}
              </label>
            </Grid>
            <Grid item md={8} xs={12}>
              <FormControl fullWidth className="text-left" variant="outlined" size="small">
                <Controller
                  control={control}       
                  name="role"
                  defaultValue={selectedUser ? selectedUser.role_id ? ROLES[ROLES.findIndex(r => r.id === selectedUser.role_id)].value : '' : ''}
                  error={!!errors.role}
                  as={
                    <Select>
                      {ROLES.map(rol => (
                        <MenuItem value={rol.value} key={rol.id}>
                          {translate(rol.name)}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
                {errors.role && (
                  <p className="error">
                    {translate(errors.role.message)}
                  </p>
                )}
              </FormControl>
            </Grid>
          </Grid>
          {((watch('role') === ROLES[1].value) || (chosenGroup && chosenGroup.role_id && ROLES[ROLES.findIndex(r => r.id === chosenGroup.role_id)].value === ROLES[1].value)) &&
            <Grid item xs={12} style={{ marginTop: 15 }}>
              <Grid container>
                {chosenGroup &&
                  <>
                    <Grid item md={4} xs={12}>
                      <label>{translate('app.settings.users.form.labelGroup')}:</label>
                    </Grid>
                    <Grid item md={8} xs={12}> 
                      <label>{chosenGroup ? chosenGroup.company_area_name : '' }</label>
                    </Grid>
                  </>
                }
                <Grid item sm={12} className="text-right">
                  <Button
                    color="primary"
                    onClick={() => setOpen(true)}
                  >
                    {translate('app.settings.users.form.selectGroup')}
                  </Button>
                </Grid>
              </Grid>
              <ModalListGroups
                open={open}
                setOpen={setOpen}
                setChosenGroup={setChosenGroup}
              />
            </Grid>
          }
        </Grid>
        <Grid container alignItems="center">
          <Grid item md={4} xs={12}>
            <label>
              {translate('app.settings.users.form.role.serviceType')}
            </label>
          </Grid>
          <Grid item md={8} xs={12}>
            <FormControl>
              <FormGroup row>  
                <Controller
                  name="requestable_service_groups"
                  control={control}
                  render={props =>
                    SERVICE_TYPE_USER.map((item) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => props.onChange(handleCheck(e, item))}
                            defaultChecked={serviceTypeChecked ?
                              serviceTypeChecked.filter(type => {
                                if (type.requestable_service_type_group_cd === item.requestable_service_type_group_cd && type.is_enabled) {
                                  return type.is_enabled
                                } return null
                              })[0]
                              : item.is_enabled
                            }
                          />
                        }
                        key={item.requestable_service_type_group_cd}
                        label={translate(item.label)}
                      />
                    ))
                  }
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12} className="text-center" style={{ paddingBottom: 0 }}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: '200px' }}
            type="submit"
            startIcon={loading ? <CircularProgress size={18} color="inherit" /> : null}
            disabled={loading || (watch('role') === ROLES[1].value ? chosenGroup && chosenGroup.company_area_name ? false : true : false)}
          >
            {translate('app.settings.users.form.saveUser')}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}