import React, { useEffect, useContext } from 'react';
import { Container, Typography } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { activationMercadoflex, closeMessage } from 'context/integrations/actions';
import { COUNTRY_CODE } from 'utils/constants';
import Loading from 'components/Loading';
import Error from 'components/Error';

export default function Activation ({ location, history }) {
  const [{ integrations: { message, loading } }, dispatch] = useStateValue();
  const { langCode, translate } = useContext(I18nContext);

  const query = new URLSearchParams(location.search);
  const code = query.get('code');

  useEffect(() => {
    activationMercadoflex(dispatch, { code, geo_fence_id: COUNTRY_CODE[langCode][0] }, history);
    return () => closeMessage(dispatch, null);
  }, [code, dispatch, history, langCode]);

  return (
    <Container maxWidth="md" className="padding-page-full-height text-center">
      {loading && <Loading />}
      <Typography variant="h5">
        {message ? <Error message={message} /> : translate('processing')}
      </Typography>
    </Container>
  )
}