import React, { useEffect, useState, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  Checkbox,
  Button,
  Link
} from '@material-ui/core';
import { format } from 'date-fns';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { I18nContext } from 'translations';
import ModalAssignPromoCode from 'components/Modals/PromoCode';
import { promoCodeStatuses, formatCostCurrency } from 'utils';
import EmptyData from '../EmptyData';
import styles from '../style';


const ActivePromoCodesTable = ({
  classes,
  data,
  recordsPerPage,
  codeInfo,
  setCodeInfo,
  getListPromoCode,
  history,
  setOpen,
  open,
  setIsEdit,
  removePromoCode
}) => {
  const [currentPage, setCurrentPage] = useState(data.page);
  const { translate } = useContext(I18nContext);
  const [isCheckAll, setIsCheckAll] = useState(false);

  useEffect(() => {
    setCurrentPage(data.page);
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    getListPromoCode(newPage);
  };

  if (!data || (data && data.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />;
  }

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    const info = data?.data.map(all => {
      return {
        ...all,
        checked: true
      }
    })
    setCodeInfo(info)
    if (isCheckAll) {
      setCodeInfo([]);
    }
    setIsEdit(false)
  };

  return (
    <>
      <TableContainer>
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              {!history &&
                <>
                  <TableCell>
                    <Checkbox
                      onChange={handleSelectAll}
                      checked={isCheckAll}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell style={{ minWidth: '235px' }}>
                    {translate('promoCode.table.activeCode.actions')}
                  </TableCell>
                </>
              }
              <TableCell>{translate('promoCode.table.code')}</TableCell>
              {history && <TableCell>{translate('promoCode.table.state')}</TableCell>}
              <TableCell>{translate('promoCode.table.activeCode.creationDate')}</TableCell>
              <TableCell>{translate('promoCode.table.validSince')}</TableCell>
              <TableCell>{translate('promoCode.table.validUntil')}</TableCell>
              <TableCell style={{ minWidth: '100px' }}>{translate('promoCode.table.loadedBalance')}</TableCell>
              <TableCell className={classes.address}>{translate('promoCode.table.costCenter')}</TableCell>
              {history && 
                <>
                  <TableCell className={classes.address}>
                    {translate('promoCode.table.beneficiary')}
                  </TableCell>
                  <TableCell style={{ minWidth: '100px' }}>
                    {translate('promoCode.table.usedBalance')}
                  </TableCell>
                  <TableCell>
                    {translate('promoCode.table.tripId')}
                  </TableCell>
                </>
              }
              <TableCell className={classes.address}>{history ? translate('promoCode.table.applicant') : translate('promoCode.table.activeCode.generatedBy')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data.data || []).map((code, index) => (
              <TableRow
                hover
                key={code._id}
                className={codeInfo?.filter((promo) => promo._id === code._id).length > 0 ? 'active-table-code' : 'disabled-table-code'}
              >
                {!history &&
                  <>
                    <TableCell>
                      <Checkbox
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCodeInfo([
                              ...codeInfo,
                              {
                                ...code,
                                checked: e.target.checked
                              }
                            ]);
                          } else {
                            setCodeInfo(
                              codeInfo.filter((promo) => promo._id !== code._id),
                            );
                          }
                          setIsEdit(false)
                        }}
                        value={codeInfo}
                        type="checkbox"
                        checked={codeInfo.filter((promo) => promo._id === code._id).length > 0 && true}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        disableElevation
                        size="small"
                        style={{ marginRight: 10 }}
                        onClick={() => {
                          setCodeInfo(codeInfo.filter((promo) => promo._id === code._id));
                          setOpen(true);
                          setIsCheckAll(false);
                        }}
                        className="active-primary-btn"
                      >
                        {translate('promoCode.table.activeCode.assign')}
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        disableElevation
                        style={{ marginRight: 10 }}
                        className="active-yellow-btn"
                        onClick={() => {
                          setCodeInfo(codeInfo.filter((promo) => promo._id === code._id));
                          setIsEdit(true)
                          setIsCheckAll(false);
                        }}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        disableElevation
                        className="active-red-btn"
                        onClick={() => {
                          setIsEdit(false);
                          setIsCheckAll(false);
                          removePromoCode(codeInfo.filter((promo) => promo._id === code._id))}
                        }
                      >
                        <DeleteOutlineOutlinedIcon fontSize="small" />
                      </Button>
                    </TableCell>
                  </>
                }
                <TableCell>
                  {code.code}
                </TableCell>
                {history &&
                  <TableCell>
                    {code.status && <span className={promoCodeStatuses(code.status.cd)}>{code.status.description}</span>}
                  </TableCell>
                }
                <TableCell>
                  <span className={classes.date}>
                    {format(new Date(code.created_at), 'dd/MM/yyyy, hh:mm a')}
                  </span>
                </TableCell>
                <TableCell>
                  {code.booking_id ?
                    <Link
                      href={`/bookings/${code.booking_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      color="primary"
                      underline="hover"
                    >
                      <span className={classes.date}>
                        {format(new Date(code.ini_date), 'dd/MM/yyyy, hh:mm a' )}
                      </span>
                    </Link>
                    : <span className={classes.date}>
                      {format(new Date(code.ini_date), 'dd/MM/yyyy, hh:mm a' )}
                    </span>
                  }
                </TableCell>
                <TableCell>
                  <span className={classes.date}>
                    {format(new Date(code.end_date), 'dd/MM/yyyy, hh:mm a')}
                  </span>
                </TableCell>
                <TableCell>
                  {code.amount
                    ? formatCostCurrency(code.amount)
                    : translate('nA')
                  }
                </TableCell>
                <TableCell>
                  {code.cost_center && code.cost_center.name ? code.cost_center.name : '-'}
                </TableCell>
                {history &&
                  <>
                    <TableCell>
                      {code.assigned_to_info ?
                        <>
                          <span>{code.assigned_to_info?.name}</span><br />
                          <span>{code.assigned_to_info?.email}</span>
                        </>
                        : ' - '
                      }
                    </TableCell>
                    <TableCell>
                      {code.consumed_amount}
                    </TableCell>
                    <TableCell>
                      {code.bookings && code.bookings.map(ids => (
                        <>
                          <Link
                            href={`/bookings/${ids}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            color="primary"
                            underline="hover"
                          >
                            {ids}
                          </Link><br />
                        </>
                      ))}
                    </TableCell>
                  </>
                }
                <TableCell>
                  {code.creator_info ?
                    <>
                      <span>{code.creator_info?.name}</span><br />
                      <span>{code.creator_info?.email}</span>
                    </>
                    : ' - '
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.total ? data.total : data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
      {codeInfo?.length > 0 &&
        <ModalAssignPromoCode
          open={open}
          setOpen={setOpen}
          codeInfo={codeInfo}
          setCodeInfo={setCodeInfo}
          getListPromoCode={getListPromoCode}
          setIsCheckAll={setIsCheckAll}
        />
      }
    </>
  );
};

export default withStyles(styles)(ActivePromoCodesTable);
