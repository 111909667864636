import { LOADING, MESSAGE } from './constants';

const commonReducer = (state, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.loading
      }
    case MESSAGE:
      return {
        ...state,
        message: action.message
      }
    default:
      return state
  }
}

export default commonReducer;
