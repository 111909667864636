import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField, InputAdornment, Grid, FormControl, FormControlLabel, Checkbox, FormHelperText, CircularProgress } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import PhoneInput from 'react-phone-input-2';
import EmailIcon from '@material-ui/icons/Email';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import styles from './style';
import { schema, schemaFacebook, schemaProfile } from './schemaSignupForm';
import { validatePhone } from 'context/settings/profile/actions';
import ModalValidatePhone from 'components/Modals/ModalValidatePhone';
import { TERMS_GENERAL_URL } from 'utils/constants';

function SignupForm({ submit, classes, passenger, match, profile, company }) {
  const { translate } = useContext(I18nContext);
  const [showPassword, setShowPassword] = useState(false);
  const [hasPhoneValidated, setHasPhoneValidated] = useState(profile?.has_phone_validated || false);
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [, dispatch] = useStateValue();
  const link = match.path.split('/');
  const { handleSubmit, register, control, errors, setValue } = useForm({
    resolver: yupResolver(passenger ? schemaFacebook : profile ? schemaProfile : schema)
  });

  useEffect(() => {
    if (passenger) {
      setValue("passenger.name", passenger.name);
      setValue("passenger.email", passenger.email);
    }
  }, [passenger, setValue]);

  const handleOk = () => {
    setLoading(false);
    setOpenModal(true)
  }

  const getValidatePhone = async () => {
    setLoading(true)
    const callback = await validatePhone(dispatch, profile._id);
    callback.status ? handleOk() : setLoading(false)
  }

  const handleClose = () => setOpenModal(false)

  return (
    <>
      <form onSubmit={handleSubmit(submit)} noValidate className={classes.form}>
        {passenger ? (
          <>
            <TextField
              autoFocus
              name="passenger.name"
              error={errors.passenger && !!errors.passenger.name}
              label={translate('fullName')}
              helperText={errors.passenger && errors.passenger.name && translate(errors.passenger.name.message)}
              type="text"
              inputRef={register}
              fullWidth
              className={classes.marginBottomField}
              required
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end"><PersonIcon className={classes.icon} /></InputAdornment>,
              }}
            />
            <TextField
              name="passenger.email"
              error={errors.passenger && !!errors.passenger.email}
              label={translate('email')}
              helperText={errors.passenger && errors.passenger.email && translate(errors.passenger.email.message)}
              type="email"
              inputRef={register}
              fullWidth
              className={classes.marginBottomField}
              required
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end"><EmailIcon className={classes.icon} /></InputAdornment>,
              }}
            />
          </>
        ) : (
          <>
            <TextField
              autoFocus
              name="passenger.name"
              error={errors.passenger && !!errors.passenger.name}
              label={translate('fullName')}
              helperText={errors.passenger && errors.passenger.name && translate(errors.passenger.name.message)}
              type="text"
              inputRef={register}
              fullWidth
              className={classes.marginBottomField}
              defaultValue={profile ? profile.name : ''}
              required
              InputProps={{
                endAdornment: <InputAdornment position="end"><PersonIcon className={classes.icon} /></InputAdornment>,
              }}
            />
            <TextField
              name="passenger.email"
              error={errors.passenger && !!errors.passenger.email}
              label={translate('email')}
              helperText={errors.passenger && errors.passenger.email && translate(errors.passenger.email.message)}
              type="email"
              inputRef={register}
              fullWidth
              className={classes.marginBottomField}
              defaultValue={profile ? profile.email : ''}
              required
              InputProps={{
                endAdornment: <InputAdornment position="end"><EmailIcon className={classes.icon} /></InputAdornment>,
              }}
            />
          </>
        )}

        <Grid container spacing={2}>
          <Grid item md={4} sm={4} xs={4} className="phone-input-standard text-left">
            <Controller
              control={control}
              name="passenger.country_code"
              defaultValue={profile ? profile.country_code.toString() : '57'}
              as={
                <PhoneInput
                  country="co"
                  defaultCountry="co"
                  regions={['america']}
                  preferredCountries={['co', 'mx', 'gt', 'py']}
                  inputProps={{ disabled: true }}
                />
              }
            />
          </Grid>
          <Grid item md={8} sm={8} xs={8}>
            <TextField
              name="passenger.phone"
              error={errors.passenger && !!errors.passenger.phone}
              label={translate('phone')}
              helperText={errors.passenger && errors.passenger.phone && translate(errors.passenger.phone.message)}
              type="tel"
              inputRef={register}
              fullWidth
              className={classes.marginBottomField}
              defaultValue={profile ? profile.phone : ''}
              disabled={profile ? true : false}
              required
              inputProps={{ maxLength: "10" }}
              InputProps={{
                endAdornment: <InputAdornment position="end"><PhoneIcon className={classes.icon} /></InputAdornment>,
              }}
            />
          </Grid>
          {profile && !company && !hasPhoneValidated &&
            <Grid item md={12} className="text-right" style={{ paddingTop: 0 }}>
              <Button
                size="small"
                className={classes.button}
                onClick={getValidatePhone}
                color="primary"
                variant="contained"
                disabled={loading}
                style={{ marginTop: 0 }}
                startIcon={loading && <CircularProgress size={16} color="inherit" />}
              >
                {translate('validatePhone.validateNumber')}
              </Button>
            </Grid>
          }
        </Grid>

        {!passenger && !profile && (
          <TextField
            name="passenger.password"
            error={errors.passenger && !!errors.passenger.password}
            label={translate('password')}
            helperText={errors.passenger && errors.passenger.password && translate(errors.passenger.password.message)}
            type={showPassword ? "text" : "password"}
            inputRef={register}
            autoComplete="no-password"
            className={classes.marginBottomField}
            fullWidth
            required
            InputProps={{
              endAdornment: <InputAdornment position="end" onClick={() => setShowPassword(!showPassword)}>{showPassword ? <VisibilityOffIcon className={classes.iconHover} /> : <VisibilityIcon className={classes.iconHover} />}</InputAdornment>,
            }}
          />
        )}

        {
          <FormControl error={!!errors.terms} required className={classes.marginBottomField} >
            <FormControlLabel
              disabled={link[1] === 'app'}
              control={<Checkbox
                name="passenger.has_accepted_tc"
                inputRef={register}
                defaultChecked={profile?.has_accepted_tc}
              />}
              label={
                <Link to={TERMS_GENERAL_URL} target="_blank" color="primary">
                  {translate('acceptTerms')} *
                </Link>
              }
            />
            <FormHelperText>{errors?.passenger?.has_accepted_tc && translate(errors?.passenger?.has_accepted_tc.message)}</FormHelperText>
          </FormControl>
        }
        <br />
        <Button
          type="submit"
          color="primary"
          variant="contained"
          size="large"
          className={classes.button}
        >
          {link[1] !== 'app' ? translate('signup') : translate('save')}
        </Button>
      </form>
      <ModalValidatePhone
        open={openModal}
        handleClose={handleClose}
        userId={profile?._id}
        setHasPhoneValidated={setHasPhoneValidated}
      />
    </>
  );
}

SignupForm.propTypes = {
  submit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withStyles(styles)(withRouter(SignupForm));
