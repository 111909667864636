import { CURRENCIES } from './constants';

const Money = class Money {
  constructor(hash) {
    this.subunits = hash.subunits;
    this.iso = hash.iso.toUpperCase();
  }

  toHash() {
    return { subunits: this.subunits, iso: this.iso };
  }

  currency() {
    return CURRENCIES[this.iso];
  }

  format() {
    const currency = this.currency();
    const amount = this.subunits / currency.subUnitToUnit;
    const intl_options = { style: 'currency', currency: currency.iso };
    const numberFormat = new Intl.NumberFormat('en-US', intl_options);
    return numberFormat.format(amount);
  }

  flatFormat() {
    const currency = this.currency();
    return this.subunits / currency.subUnitToUnit;
  }

  formatPrice() {
    const currency = this.currency();
    return this.subunits * currency.subUnitToUnit;
  }
};

export default Money;
