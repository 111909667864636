import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Avatar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import { I18nContext } from 'translations';
import styles from './style';

const DriverCard = ({ classes, driver, vehicle, isBooking }) => {
  const { translate } = useContext(I18nContext);

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      spacing={2}
      className="text-center-xs"
    >
      <Grid item md={3} sm={2} xs={12} className={isBooking ? '' : classes.borderLeft}>
        <Avatar
          alt="driver"
          src={driver && driver.photo_url ? driver.photo_url : ''}
          className={isBooking ? classes.avatarLarge : classes.avatar}
        />
      </Grid>

      <Grid item md={isBooking ? 7 : 9} sm={isBooking ? 7 : 8} xs={12}>
        <Typography
          variant="body2"
          color="textSecondary"
          component="span"
        >
          {translate('tracking.driver')}
        </Typography>
        <br />
        <Typography
          variant="body1"
          color="textPrimary"
          component="span"
        >
          {driver ? driver.name : translate('unassigned')}
          <br />
          {translate('tracking.plate')}: {' '}
          {vehicle ? vehicle.plates : ' - '}
          <br />
          <a
            href={`tel:+${driver && driver?.country_code ? driver?.country_code : ''}${driver && driver.phone}`}
            className={classes.links}
          >
            {driver && `${driver?.country_code ? driver?.country_code : ''} ${driver.phone}`}
          </a>
        </Typography>
      </Grid>
      {isBooking && (
        <Grid item md={2} sm={3} xs={12}>
          <StarIcon color="primary" style={{ verticalAlign: 'bottom', marginRight: 5 }} />
          <Typography
            variant="body1"
            color="textSecondary"
            component="span"
            style={{ fontSize: '1.1rem' }}
          >
            {driver?.rating_as_driver}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

DriverCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DriverCard);