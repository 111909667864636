import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import schema from './schema';
import { Grid, Button } from '@material-ui/core';
import { I18nContext } from 'translations';
import TermCheckBoxField from './TermCheckBoxField';
import { yupResolver } from '@hookform/resolvers';
import { TERMS_CORPORATE_URL, TERMS_GENERAL_URL } from 'utils/constants';

const TermsAndConditionsForm = ({
  termsForEvaluate,
  isLoading,
  onSubmitTermsAndConditions,
}) => {
  const { translate } = useContext(I18nContext);

  const TermsAndConditions = [
    {
      name: 'has_accepted_tc',
      label: translate('acceptTerms'),
      link: TERMS_GENERAL_URL,
    },
    {
      name: 'has_accepted_corporate_tc',
      label: translate('app.setting.companyRegister.termAndConditions'),
      link: TERMS_CORPORATE_URL,
    },
    {
      name: 'has_authorized_personal_data_treatment',
      label: translate(
        'app.setting.companyRegister.authorizationForPersonalDataProcessingAccepted'
      ),
      link: `${TERMS_GENERAL_URL}#page=10`,
    },
    {
      name: 'has_authorized_credit_report_inquiry',
      label: translate(
        'app.setting.companyRegister.authorizationForConsultingCreditBureausAccepted'
      ),
      link: `${TERMS_CORPORATE_URL}#page=5`
    },
  ];

  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(schema(termsForEvaluate)),
    defaultValues: termsForEvaluate,
  });

  const submit = (data) => {
    onSubmitTermsAndConditions(data);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container spacing={2}>
        {TermsAndConditions.map(
          (term) =>
            termsForEvaluate.hasOwnProperty(term.name) && (
              <Grid item xs={12} key={term.name}>
                <TermCheckBoxField
                  control={control}
                  errors={errors}
                  {...term}
                />
              </Grid>
            )
        )}
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            {translate('app.settings.users.updateAcceptanceTermsAndConditions.accept')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default TermsAndConditionsForm;
