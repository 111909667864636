import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { I18nContext } from 'translations';

export default function HeaderAccounts() {
  const { translate } = useContext(I18nContext);

  return (
    <div className="animation-background">
      <Typography variant="h5">
        <AccountBalanceIcon style={{ verticalAlign: 'middle', marginRight: 7 }} />
        <span style={{ verticalAlign: 'middle' }}>
          {translate('app.payments.bankAccounts')}
        </span>
      </Typography>
      <LocalAtmIcon className="circle" />
    </div>
  )
}