import { CHECKIN, CHECKIN_ERROR, LOADING_CHECKIN, HAS_SHOPIFY } from './constants';

const checkinReducer = (state, action) => {
  switch (action.type) {
    case CHECKIN:
      return {
        ...state,
        checkin: action.checkin,
        configuration: action.checkin && action.checkin.configuration,
        message: null,
        company: action.checkin && action.checkin.company && action.checkin.company,
        balance_wallet: action?.checkin?.balance_wallet
      }
    case LOADING_CHECKIN:
      return {
        ...state,
        loading: action.loading
      }
    case CHECKIN_ERROR:
      return {
        ...state,
        message: action.message
      }
    case HAS_SHOPIFY:
      return {
        ...state,
        hasShopify: action.hasShopify
      }
    default:
      return state
  }
}

export default checkinReducer;