import {
  GET_SESSION,
  GET_SESSION_CHECK,
  DELETE_SESSION,
  SESSION_LOADING,
  SESSION_FETCHING,
} from './constants';
import { urlCompany } from 'api';
import { get, post } from 'api/requests';
import handleErrorMessage from 'utils/handleErrorMessage';
import { message } from '../../commons/actions';

export const startSession = async (
  dispatch,
  company_id,
  isFetching = false
) => {
  isFetching
    ? dispatch({
        type: SESSION_FETCHING,
        isFetching: true,
      })
    : dispatch({
        type: SESSION_LOADING,
        isLoading: true,
      });
  return await get(`${urlCompany}/${company_id}/wp_start_session`)
    .then(({ data }) => {
      dispatch({
        type: GET_SESSION,
        session: data,
      });
      return { status: true };
    })
    .catch((error) => {
      return { status: false };
    })
    .finally(() =>
      isFetching
        ? dispatch({
            type: SESSION_FETCHING,
            isFetching: false,
          })
        : dispatch({
            type: SESSION_LOADING,
            isLoading: false,
          })
    );
};

export const checkSession = async (dispatch, company_id) => {
  let isSession = {};
  dispatch({
    type: SESSION_LOADING,
    isLoading: true,
  });
  await get(`${urlCompany}/${company_id}/wp_check_connection_session`)
    .then(({ data }) => {
      dispatch({
        type: GET_SESSION_CHECK,
        session: data,
      });
      dispatch({
        type: SESSION_LOADING,
        isLoading: false,
      });
      if (data?.message === 'Connected') isSession = { status: true };
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      isSession = { status: false, mssg: mssg };
    })
    .finally(() => {
      dispatch({
        type: SESSION_LOADING,
        isLoading: false,
      });
    });
  return isSession;
};

export const deleteSession = async (dispatch, company_id) => {
  let isSession = false;
  dispatch({
    type: SESSION_LOADING,
    isLoading: true,
  });
  await post(`${urlCompany}/${company_id}/wp_close_session`)
    .then(({ data }) => {
      dispatch({
        type: DELETE_SESSION,
        data: data,
      });
      dispatch({
        type: SESSION_LOADING,
        isLoading: false,
      });
      isSession = data.status;
    })
    .catch((error) => {
      isSession = false;
      dispatch({
        type: SESSION_LOADING,
        isLoading: false,
      });
    });
  return isSession;
};

export const fetchQR = (dispatch, company_id) => {
  startSession(dispatch, company_id, true);
};
