import React from 'react';
import { Button } from '@material-ui/core';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const FacebookComponent = ({ text, responseFacebook }) => (
  <FacebookLogin
    appId="244398439266510"
    fields="name,email,picture"
    callback={responseFacebook}
    disableMobileRedirect={true}
    render={renderProps => (
      <Button
        onClick={renderProps.onClick}
      >
        {text}
        <img
          src="/images/social/facebook-brands.svg"
          alt="facebook"
          style={{ width: '33px', marginLeft: 12 }}
        />
      </Button>
    )}
  />
);

export default FacebookComponent;