import { createStyles } from '@material-ui/core/styles';

const stylesFormCompanyData = createStyles((theme) => ({
  form: {
    padding: '0 20px 10px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 10px 20px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 0 10px',
      width: '90%',
      margin: 'auto'
    }
  },
  containerInputs: {
    '& .react-swipeable-view-container': {
     padding: '10px 0 15px'
    },
    '& > div > div > div': {
      margin: '0 20px',
    }
  },
  marginBottomField: {
    marginBottom: '15px'
  },
  icon: {
    color: '#757575',
    fontSize: '1rem'
  },
  links: {
    margin: '12px 0'
  },
  button: {
    margin: '10px 0 0',
    minWidth: '150px',
    borderRadius: 20
  },
  flags: {
    marginRight: 10,
    width: 18
  }
}));

export default stylesFormCompanyData;