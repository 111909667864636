import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, Button, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { I18nContext } from 'translations';
import Language from 'components/Language';
import styles from './UserMenuStyle';

const UserMenu = ({ classes, closeSession, profile, company, aproxCountry }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { translate } = useContext(I18nContext);
  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <div className={classes.userMenu}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleMenu}
      >
        {profile && profile.photo_url !== '/photos/original/missing.png'
          ? (
            <img src={profile && profile.photo_url} alt={profile.name.replace(/ .*/,'')} className={classes.photo} />
          ) : (
            <AccountCircleOutlinedIcon />
          )
        }
        <span>
          {profile && profile.name ? profile.name.replace(/ .*/,'') : translate('user')}
        </span>
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ top: 25, right: 0 }}
      >
        <MenuItem
          onClick={closeSession}
        >
          {translate('logout')}
        </MenuItem>
      </Menu>
      <Divider orientation="vertical" className={classes.line} />
      <div className={classes.marginTop5}>
        {profile && (
          <Language
            country={company ? company.geo_fence_id : aproxCountry}
            company={company && company}
          />
        )}
      </div>
    </div>
  );
}

UserMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  closeSession: PropTypes.func.isRequired
};

export default withStyles(styles)(UserMenu);