import { createStyles } from '@material-ui/core/styles';

const drawerWidth = 200;

const styles = createStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: 999,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
    '& > div': {
      minHeight: '50px',
      paddingLeft: 10
    }
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    minHeight: '50px !important'
  },
  content: {
    flexGrow: 1,
    padding: '16px 16px 0 16px',
    overflow: 'hidden'
  },
  bodyPage: {
    backgroundColor: '#fff',
    borderRadius: '2px'
  }
}));

export default styles;