import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Container } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { useStateValue } from 'context/store';
import { listReports } from 'context/express/actions';
import { I18nContext } from 'translations';
import Title from 'components/Titles';
import ReportsTable from 'components/Tables/Bookings/Reports';
import { INITIAL_PAGE, PER_PAGE } from 'utils/constants';
import { urlExports } from 'utils';

export default function DownloadReport({ match }) {
  const { translate } = useContext(I18nContext);
  const { params: { type } } = match;
  const [per_page] = useState(PER_PAGE);
  const [tempPage, setTempPage] = useState(1);
  const [{ bookings: { reports }, checkin: { company } }, dispatch] = useStateValue();

  const getReports = useCallback(async (page) => {
    setTempPage(page);
    listReports(dispatch, { page, per_page }, urlExports(type, company._id))
  }, [dispatch, per_page, type, company]);

  useEffect(() => {
    getReports(INITIAL_PAGE);
  }, [getReports]);

  useEffect(() => {
    if (tempPage === 1) {
      const interval = setInterval(() => {
        getReports(INITIAL_PAGE);
      }, 8000);
      return () => clearInterval(interval);
    }
  }, [getReports, tempPage]); // eslint-disable-line

  return(
    <div className="padding-page-full-height">
      <Title
        title={translate('reports')}
        icon={<ListAltIcon className="align-center" style={{ marginRight: 5 }} />}
      />
      <div className="marginFiltersTable">
        <Container maxWidth="lg">
          <>
            {reports && (
              <ReportsTable
                data={reports}
                getReports={getReports}
                recordsPerPage={per_page}
              />
            )}
          </>
        </Container>
      </div>
    </div>
  )
}