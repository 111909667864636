import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { Button, TextField } from '@material-ui/core';
import { I18nContext } from 'translations';
import schema from './schema';

export default function UsersTableFilters({ submit }) {
  const { translate } = useContext(I18nContext);
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(submit)} autoComplete="off" noValidate>
      <TextField
        name="name"
        label={translate('name')}
        type="text"
        inputRef={register}
        variant="outlined"
        size="small"
        style={{ marginRight: 7 }}
      />
      <TextField
        name="email"
        error={!!errors.email}
        label={translate('email')}
        helperText={errors.email && translate(errors.email.message)}
        type="email"
        inputRef={register}
        variant="outlined"
        size="small"
        style={{ marginRight: 7 }}
      />
      <Button type="submit" color="primary" variant="contained">
        {translate('search')}
      </Button>
    </form>
  );
}
