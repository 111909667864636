import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Snackbar } from '@material-ui/core';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { listBalances } from 'context/balances/actions';
import { I18nContext } from 'translations';
import { INITIAL_PAGE, PER_PAGE } from 'utils/constants';
import BalancesTableFilters from 'components/Tables/Balances/Filters/BalancesTableFilters';
import BalancesTable from 'components/Tables/Balances/BalancesTable';
import Loading from 'components/Loading';
import Title from 'components/Titles';

const Balances = () => {
  const { translate } = useContext(I18nContext);
  const [per_page] = useState(PER_PAGE);
  const [PassengerInfo, setPassengerInfo] = useState([]);
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState(null);

  const [
    {
      checkin: { company },
      commons: { message },
      balances: { list, loadingBalances }
    }, dispatch
  ] = useStateValue();

  const onFilters = (params) => setFilters(params);

  const getListBalances = useCallback(
    async (page) => {
      listBalances(dispatch, company._id, {
        page,
        per_page,
        ...filters
      });
    },
    [dispatch, per_page, company, filters]
  );

  useEffect(() => {
    company && getListBalances(INITIAL_PAGE)
  }, [getListBalances]) // eslint-disable-line

  return (
    <div className="padding-page-full-height">
      <Title
        title="Saldos"
        icon={<LocalAtmIcon className="align-center" style={{ marginRight: 5 }} />}
      />
      {loadingBalances && <Loading />}

      <div className="marginFiltersTable">
        <BalancesTableFilters
          onFilters={onFilters}
          filters={filters}
        />
      </div>

      {list && (  
        <BalancesTable
          data={list}
          PassengerInfo={PassengerInfo}
          setPassengerInfo={setPassengerInfo}
          open={open}
          setOpen={setOpen}
          getListBalances={getListBalances}
          recordsPerPage={per_page}
          history={true}
        />
      )}
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />

    </div>
  );
};

export default Balances;
