import { get, patch } from 'api/requests';
import { urlUnqulifiedBookings, urlRateBooking } from 'api';
import handleErrorMessage from 'utils/handleErrorMessage';
import { LIST_UNQULIFIED_BOOKINGS } from './constants';
import { MESSAGE } from 'context/commons/constants';

export const getUnqulifiedBookings = async (dispatch, params) => {
  await get(urlUnqulifiedBookings, params)
    .then(({ data }) => {
      dispatch({
        type: LIST_UNQULIFIED_BOOKINGS,
        unqulifiedBookings: data
      })
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
    });
}

export const rateBooking = async (dispatch, id, rate) => {
  let status = false;
  const booking = { 
    rate_to_driver: rate[id]
  }
  await patch(`${urlRateBooking}/${id}/rate_by_passenger`, { booking })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      status = false;
    });
  return { status };
}