import React, { useContext, useState } from 'react';
import {
  Grid,
  Button,
  Typography,
  DialogActions
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { I18nContext } from 'translations';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import { format, parseISO } from 'date-fns';
import ModalTableCategoryCommodate from './ModalTableCategoryCommodate';
import ModalDriverCommodateInfo from './ModalDriverCommodate';

export default function ModalConfirmRequestCommodate ({
  data,
  isEdit,
  goBack,
  submit,
  openModal,
  updateSubmit,
  commodateEdit,
  setDataRequest
}) {
  const { translate } = useContext(I18nContext);
  const [returnDate, setReturnDate] = useState(null)

  const formatedDate = commodateEdit ? data && data.return_date.split('/').reverse().join('-') : null;
  const textDate = formatedDate ? parseISO(formatedDate, "dd-MM-yyyy") : null;

  return (
    <div>
      <div className="bg-white">
        <Typography variant="h5" style={{ marginBottom: '15px' }}>
          {translate('commodate.modalRequest.commodateInformation')}
        </Typography>
        <Grid container spacing={1}>
          <ModalDriverCommodateInfo
            data={data}
            commodateEdit={commodateEdit}
          />
          {commodateEdit && isEdit &&
            <Grid item xs={12} style={{ margin: '10px 0 20px' }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  size="small"
                  inputVariant="outlined"
                  label={translate('commodate.modalRequest.form.returnDate')}
                  format="dd/MM/yyyy"
                  cancelLabel={translate('cancel')}
                  okLabel={translate('accept')}
                  invalidDateMessage={translate('invalidFormat')}
                  fullWidth
                  value={returnDate ? returnDate : textDate}
                  onChange={(date, value) => {
                    setReturnDate(date);
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          }
          <Grid item xs={12}>
            {data?.commodates_attributes?.length > 0 &&
              <ModalTableCategoryCommodate
                data={data}
                setDataRequest={setDataRequest}
                commodateEdit={commodateEdit}
              />
            }
          </Grid>
        </Grid>
      </div>
      <DialogActions>
        <Button
          onClick={() => openModal()}
          variant="outlined"
          className="btn-rounded-style"
          style={{ backgroundColor: '#fff' }}
        >
          {commodateEdit ? translate('commodate.modalRequest.form.exitWithoutSaving') : translate('commodate.modalRequest.form.exitWithoutCreating')}
        </Button>
        {commodateEdit === null &&
          <Button
            onClick={goBack}
            variant="outlined"
            className="btn-rounded-style"
            style={{ backgroundColor: '#fff' }}
          >
            {translate('commodate.modalRequest.form.editAgain')}
          </Button>
        }
        {isEdit && commodateEdit &&
          <Button
            color="primary"
            variant="contained"
            className="btn-rounded-style"
            onClick={async () => {
              if (returnDate !== null) {
                await updateSubmit({ return_date: format(new Date(returnDate), "dd-MM-yyyy") })
              } else {
                await submit(data)
              }
            }}
          >
            {translate('commodate.modalRequest.form.saveChanges')}
          </Button>
        }
        {commodateEdit === null &&
          <Button
            color="primary"
            variant="contained"
            className="btn-rounded-style"
            onClick={async () => {
              if (returnDate !== null) {
                await updateSubmit({ return_date: format(new Date(returnDate), "dd-MM-yyyy") })
              } else {
                await submit(data)
              }
            }}
          >
            {translate('commodate.modalRequest.form.createCommodateRequest')}
          </Button>
        }
      </DialogActions>
    </div>
  )
}
