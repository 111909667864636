import React, { useContext } from 'react';
import { Dialog, Button, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { I18nContext } from 'translations';
import ReactExport from '@ibrahimrahmani/react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default function ModalDownloadExcel ({
  openDownload,
  setOpenDownload,
  exportData
}) {
  const { translate } = useContext(I18nContext);
  return (
    <Dialog
      open={openDownload}
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
      style={{ zIndex: '9000'}}
    >
      <DialogTitle className="text-center">
        {translate('app.express.downloadRoutes')}
      </DialogTitle>
      <DialogContent className="text-center" style={{ margin: '10px 0' }}>
      <ExcelFile filename="Stops" element={
          <Button
            type="button"
            color="primary"
            size="small"
            variant="contained"
            startIcon={<CloudDownloadIcon />}
          >
            {translate('app.express.downloadExcel')}
          </Button>
        }>
          <ExcelSheet dataSet={exportData} name="Stops" />
        </ExcelFile>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDownload(false)}>
        {translate('app.express.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}