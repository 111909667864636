import React, { useContext, useState } from 'react';
import { Grid, Link, Typography, Snackbar, Button } from '@material-ui/core';
import { I18nContext } from 'translations';
import { message as closeMessage } from 'context/commons/actions';
import { useStateValue } from 'context/store';
import {
  confirmWaitDriver,
  confirmCancelBooking,
} from 'context/waitdriver/actions';
import Loading from 'components/Loading';
import { modalMessage } from 'utils';

export default function ConfirmWaitDriver({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [
    {
      commons: { message },
    },
    dispatch,
  ] = useStateValue();
  const {
    params: { id },
  } = match;
  const { translate } = useContext(I18nContext);
  const isToken = localStorage.getItem('session_token');

  const messageOk = (msg, isWaiting) => {
    setLoading(false);
    modalMessage('', msg, 'success', 'Continue').then((result) => {
      isWaiting
        ? (window.location.href = `/bookings/${id}`)
        : (window.location.href = `/app/bookings`);
    });
  };

  const messageLogin = () => {
    modalMessage(
      '',
      translate('app.booking.loginFirstToPerformThisAction'),
      'info',
      translate('app.booking.logIn'),
      true,
      true,
      translate('cancel')
    ).then((response) => {
      if (response.value) {
        history.push({
          pathname: '/login',
          search: `?idpage=${id}`,
        });
      }
    });
  };

  const waitDriver = async (isWaiting) => {
    if (isToken) {
      setLoading(true);
      const callback = await confirmWaitDriver(dispatch, id, {
        wait: isWaiting,
      });
      callback?.status
        ? messageOk(
            isWaiting
              ? translate('waitDriver.messageWaitDriver')
              : translate('waitDriver.messageNewDriver'),
            isWaiting
          )
        : setLoading(false);
    } else {
      messageLogin();
    }
  };

  const cancelConfirmationModal = (callback) => {
    modalMessage(
      '',
      translate('waitDriver.questionCancelBooking'),
      'question',
      translate('accept'),
      true,
      true,
      translate('cancel')
    ).then((result) => {
      result.isConfirmed && callback();
    });
  };

  const cancelBooking = async () => {
    if (isToken) {
      cancelConfirmationModal(async () => {
        setLoading(true);
        const callback = await confirmCancelBooking(dispatch, id);
        callback?.status &&
          messageOk(translate('waitDriver.cancelBookingConfirmation'));
        setLoading(false);
      });
    } else {
      messageLogin();
    }
  };

  return (
    <div className="confirm-wait-driver">
      {loading && <Loading />}
      <Grid container direction="row" alignItems="center" justify="center">
        <Grid item xs={12} className="text-center">
          <Link to="/">
            <img src="/images/logo.svg" alt="Pibox" />
          </Link>
        </Grid>
        <Grid item xs={12} className="text-center">
          <div className="container-info">
            <Typography
              variant="h5"
              color="primary"
              style={{ marginBottom: 50 }}
            >
              <b>{translate('waitDriver.ups')}</b>
            </Typography>
            <Typography className="wait-driver-text text-left">
              {translate('waitDriver.message')}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className="wait-driver-btn"
              onClick={() => waitDriver(true)}
            >
              {translate('waitDriver.btnWait')}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              className="wait-driver-btn"
              onClick={() => waitDriver(false)}
            >
              {translate('waitDriver.btnNewDriver')}
            </Button>
            <Button
              color="primary"
              size="large"
              className="wait-driver-btn"
              onClick={() => cancelBooking(false)}
            >
              {translate('waitDriver.btnCancelBooking')}
            </Button>
          </div>
        </Grid>
      </Grid>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </div>
  );
}
