import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  form: {
    padding: '25px 30px 10px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 10px 20px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 15px 10px'
    }
  },
  marginBottomField: {
    marginBottom: '25px'
  },
  icon: {
    color: '#757575',
    fontSize: '1rem',
  },
  iconHover: {
    color: '#757575',
    fontSize: '1rem',
    cursor: 'pointer'
  },
  links: {
    margin: '12px 0'
  },
  button: {
    margin: '10px 0 0',
    minWidth: '150px',
    borderRadius: 20
  },
  divider: {
    border: 0,
    margin: '15px 0'
  }
}));

export default styles;