import React, { useContext, useState } from 'react';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@material-ui/core';
import { useStateValue } from 'context/store';
import { validateUser, cleanUser, saveUserCompany } from 'context/settings/users/actions';
import { I18nContext } from 'translations';
import { modalMessage } from 'utils';
import ValidateUser from './Forms/ValidateUser';
import CreateUser from './Forms/CreateUser';
import { INITIAL_PAGE } from 'utils/constants';
import { listCheckin } from 'context/checkin/actions';
import { COUNTRY_CODE } from 'utils/constants';

function ModalUsers ({
  open,
  setOpen,
  companyId,
  getUsers,
  selectedUser,
  setUser
}) {
  const { translate, langCode } = useContext(I18nContext);
  const [chosenGroup, setChosenGroup] = useState(null);
  const [{ users: { userExists, validatingUser, loadingCreateUserCompany } }, dispatch ] = useStateValue();
  
  const validateUserCompany = async values => await validateUser(dispatch, values, setOpen, getUsers, INITIAL_PAGE);

  const messageOk = () => {
    modalMessage(translate('congratulations'), translate('app.settings.users.form.userSavedSuccessfully'), 'success', translate('accept'), true).then(async() => {
      setOpen(false);
      getUsers(INITIAL_PAGE);
      await listCheckin(dispatch, { country_id: COUNTRY_CODE[langCode][0] });
    })
  }

  const createUser = async values => {
    let serviceType = null;
    if (!selectedUser) {
      serviceType = values?.requestable_service_groups?.filter(type => {
        return type.is_enabled && type
      })
    } else {
      serviceType = values.requestable_service_groups?.filter(type => {
        return {
          ...type,
          id: type.id ? type.id : null
        }
      })
    }
    const data = {
      company_area_id: chosenGroup ? chosenGroup.company_area_id : null,
      company_id : companyId,
      ...values,
      requestable_service_groups: serviceType
    }

    const callback = await saveUserCompany(dispatch, data, selectedUser ? selectedUser._id : null);
    callback && callback.status && messageOk();
  }

  const close = () => {
    setOpen(false);
    cleanUser(dispatch);
    setChosenGroup(null);
    setUser(null);
  }

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="text-center" disableTypography>
        <Typography variant="h5" color="primary">
          {selectedUser ? translate('app.settings.users.editUser') : translate('app.settings.users.addNewUser')}
        </Typography>
        <br />
      </DialogTitle>

      <DialogContent>
        {!userExists && !selectedUser &&
          <ValidateUser
            submit={validateUserCompany}
            loading={validatingUser} 
          />
        }

        {((userExists) || (selectedUser)) &&
          <CreateUser
            userInfo={userExists && userExists}
            submit={createUser}
            loading={loadingCreateUserCompany}
            setChosenGroup={setChosenGroup}
            chosenGroup={chosenGroup}
            selectedUser={selectedUser && selectedUser}
            serviceTypeChecked={selectedUser?.requestable_service_groups && selectedUser.requestable_service_groups}
          />
        }
      </DialogContent>

      <DialogActions>
        <Button onClick={close} disabled={loadingCreateUserCompany}>
          {translate('close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalUsers;