import React, { useContext, useState, useEffect } from 'react';
import {
  Typography,
  Dialog,
  Toolbar,
  IconButton,
  DialogContent,
  Button,
  Snackbar,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DropzoneArea } from 'material-ui-dropzone';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { I18nContext } from 'translations';
import { uploadFile } from 'utils/aws';
import { modalMessageHtml } from 'utils';
import { uploadBulkLoad } from 'context/express/actions';
import Loading from 'components/Loading';
import ModalFailedStops from 'components/Modals/ModalFailedStops';

const ModalUpload = ({ open, handleModalUpload, city }) => {
  const [fileUpload, setFileUpload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openFailedStops, setOpenFailedStops] = useState(false);
  const { translate } = useContext(I18nContext);
  const [
    {
      commons: { message },
      bookings: { bulkLoadFailedStops },
    },
    dispatch,
  ] = useStateValue();
  const [failedStops, setFailedStops] = useState([]);

  const handleFile = async (file) => setFileUpload(file);
  const handleModalFailedStops = () => {
    setOpenFailedStops(!openFailedStops);
    openFailedStops && setFailedStops([]);
  };

  const handleUpload = async () => {
    setLoading(true);
    const date = Date.parse(new Date());
    const response = await uploadFile(
      fileUpload[0],
      `booking_bulk_load/${date}`
    );
    if (response) {
      const callback = await uploadBulkLoad(dispatch, {
        file_url: response.Location,
        city_id: city._id,
      });
      setLoading(false);
      callback && callback.status && handleMessageOk(callback.statusFailedStop);
    }
    setLoading(false);
  };

  const handleMessageOk = (statusFailedStop) => {
    handleModalUpload();
    modalMessageHtml(
      statusFailedStop
        ? translate('app.bulkLoad.bulkUploadFailedStop')
        : translate('congratulations'),
      statusFailedStop
        ? ''
        : translate('app.bulkLoad.bulkUploadSuccessMessage'),
      'success',
      statusFailedStop
        ? translate('app.bulkLoad.seeRoutes')
        : translate('continue'),
      statusFailedStop && true,
      statusFailedStop && translate('close')
    ).then((response) => {
      if (response.isConfirmed) {
        statusFailedStop && setOpenFailedStops(true);
      }
    });
  };

  useEffect(() => {
    if (bulkLoadFailedStops) {
      setFailedStops(bulkLoadFailedStops);
    }
  }, [bulkLoadFailedStops]); // eslint-disable-line


  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleModalUpload}
        style={{ zIndex: 9999 }}
      >
        <Toolbar style={{ justifyContent: 'center' }}>
          <IconButton
            edge="end"
            color="primary"
            onClick={handleModalUpload}
            aria-label="close"
            style={{ position: 'absolute', right: 20, top: '10px' }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" color="textPrimary" className="text-center">
            {translate('app.bulkLoad.loadExcelFile')}
          </Typography>
        </Toolbar>

        <DialogContent
          style={{ padding: '15px 30px 30px' }}
          className="text-center"
        >
          {loading && <Loading />}
          <Button
            color="secondary"
            href="https://s3.amazonaws.com/static.pibox.app/templates/template_bulk_2023.xlsx"
            size="small"
            download
            style={{ marginBottom: 10 }}
          >
            {translate('app.bulkLoad.downloadFormatHere')}
          </Button>
          <DropzoneArea
            onChange={handleFile}
            acceptedFiles={['.xlsx', '.xls', '.csv']}
            maxFileSize={5000000}
            filesLimit={1}
            dropzoneText={translate('app.bulkLoad.dragFileHereOrClick')}
            getFileLimitExceedMessage={() =>
              translate('app.bulkLoad.pleaseUploadOnlyOneFile')
            }
            getFileAddedMessage={(fileName) =>
              `${fileName} ${translate('app.bulkLoad.successfullyAdded')}`
            }
            getFileRemovedMessage={(fileName) =>
              `${fileName} ${'app.bulkLoad.removed'}`
            }
            getDropRejectMessage={(fileName) =>
              translate('app.bulkLoad.unsupportedFileType')
            }
          />
          <br />
          <div className="text-center">
            <Button
              variant="contained"
              size="large"
              type="submit"
              color="primary"
              onClick={handleUpload}
            >
              {translate('app.bulkLoad.uploadExcel')}
            </Button>
          </div>
        </DialogContent>

        <Snackbar
          style={{ zIndex: 99999 }}
          open={!!message}
          autoHideDuration={6000}
          onClose={() => closeMessage(dispatch, null)}
          message={translate(message)}
        />
      </Dialog>
      <ModalFailedStops
        handleModalFailedStops={handleModalFailedStops}
        open={openFailedStops}
        failedStops={failedStops}
        isOperations={false}
      />
    </>
  );
};

export default ModalUpload;
