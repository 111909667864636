import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Paper, Snackbar, Container, Grid, Typography, Hidden, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { createSession } from 'context/users/actions';
import { I18nContext } from 'translations';
import LayoutOuter from 'components/Layouts/LayoutOuter';
import Loading from 'components/Loading';
import Menu from 'components/Landing/Layouts/Menu';
import FacebookComponent from 'components/Social/FacebookComponent';
import styles from './style';
import FormLogin from 'components/Forms/Login';
import LinkAccount from '../components/LinkAccount';
import { RENT } from 'utils/constants';
import withHelmet from 'utils/withHelmet';

const isRent = process.env.REACT_APP_API_URL_BASE_IS_RENT === RENT;

function Login({ classes, history }) {
  const { translate } = useContext(I18nContext);
  const [{ commons: { loading, message } }, dispatch] = useStateValue();
  const query = new URLSearchParams(history.location.search);
  const id = query.get('id');
  const idWaitDriver = query.get('idpage');
  const search = history.location.search.split('?')[1];

  const shopifyData = query.get('shp_uuid') && `/app/integrations/shopify/activation?${search}`;

  const onSubmit = async values => await createSession(dispatch, { session: values }, history, { id }, search ? shopifyData : '', { idWaitDriver });

  const responseFacebook = (response) => {
    const oauth = {
      provider: 'facebook',
      uid: response.userID,
      token: response.accessToken
    }
    response && createSession(dispatch, { oauth }, history, null);
  }

  return (
    <LayoutOuter bg="#f8f8f8">
      <Menu />
      <Container maxWidth="md" style={{ marginTop: 70 }} className="container-width-lg">
        <Paper elevation={4} variant="outlined" className={classes.wrapper}>
          {loading && <Loading />}
          <Grid container alignItems="center" justify="center" spacing={2}>
            <Grid item sm={5} md={6} className="text-center">
              <img
                src={isRent ? "/images/rent/moto-rent.png" : "/images/users/truck-box.png"}
                alt="login"
                className={classes.principalImg}
              />
              <br />
              <Hidden xsDown>
                <LinkAccount
                  text="doNotYouHaveAnAccountYet"
                  link={isRent ? "/signup" : "/presignup"}
                  textLink="createNewAccount"
                />
              </Hidden>
            </Grid>
            <Grid item sm={7} md={6}>
              <Typography variant="h4" color="textPrimary">{translate('login')}</Typography>
              <br /><br />
              <FormLogin
                submit={onSubmit}
                showOptions
              />
              <br />
              <Divider color="lightgray" />
              <br />
              <FacebookComponent
                responseFacebook={responseFacebook}
                text={translate('orLoginWith')}
              />
            </Grid>
            <Hidden smUp>
              <Grid item xs={12}>
                <LinkAccount
                  text="doNotYouHaveAnAccountYet"
                  link={isRent ? "/signup" : "/presignup"}
                  textLink="createNewAccount"
                />
              </Grid>
            </Hidden>
          </Grid>
          <Snackbar
            open={!!message}
            autoHideDuration={6000}
            onClose={() => closeMessage(dispatch, null)}
            message={translate(message)}
          />
        </Paper>
      </Container>
    </LayoutOuter>
  );
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(
  withHelmet({
    title: isRent ? 'Picap Rent' : 'Envíos y Mensajería Urbana | Pibox',
    description: isRent ? 'Picap Rent' : 'Realiza envíos de paquetes y carga a todo el país',
    image: isRent ? '/faviconPicap.png' : 'https://pibox.app/images/landing/cargo.png',
    noIndex: true,
    favicon: isRent ? '/faviconPicap.png' : '/favicon.ico'
  })(Login)
);