import React, { useState, useContext } from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  Collapse,
  Box,
  Typography,
} from '@material-ui/core';
import { I18nContext } from 'translations';
import { format } from 'date-fns';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IntegrationsAdapter from 'adapters/IntegrationsAdapter';

export default function Subtable({ row, getIntegrations }) {
  const integration = IntegrationsAdapter(row);
  
  const [open, setOpen] = useState(
    integration?.integrator_id === 'shopify' ? true : false
  );

  const { translate } = useContext(I18nContext);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row.integration"
            size="small"
            onClick={() => setOpen(!open)}
            disabled={
              integration?.user_provider_integrations?.length === 0 && true
            }
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {integration?.photo_url ? (
            <img
              src={integration.photo_url}
              alt={integration.photo_url}
              style={{ width: '100px', height: '28px' }}
            />
          ) : (
            integration?.name
          )}
        </TableCell>
        <TableCell>{integration?.name}</TableCell>
        <TableCell>
          {integration?.getActivateIntegration(getIntegrations)}
        </TableCell>
        {/* <TableCell>
          <Button color="secondary" size="small" disabled={true}>
            {translate('app.integrations.table.tutorial')}
          </Button>
        </TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box p="1.5rem 2.5rem 3.5rem">
              <Typography variant="subtitle1" gutterBottom>
                {translate('app.integrations.table.accounts')}
              </Typography>
              <Table>
                <TableHead style={{ border: 0, background: 'transparent' }}>
                  <TableRow>
                    <TableCell>
                      {translate('app.integrations.table.id')}
                    </TableCell>
                    <TableCell>
                      {translate('app.integrations.table.date')}
                    </TableCell>
                    <TableCell>
                      {translate('app.integrations.table.identificator')}
                    </TableCell>
                    <TableCell>
                      {translate('app.integrations.table.actions')}
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {integration?.user_provider_integrations &&
                    integration?.user_provider_integrations?.map(
                      (account, index) => (
                        <TableRow key={account._id}>
                          <TableCell scope="row" className="font-size-1em">
                            {account._id}
                          </TableCell>
                          <TableCell className="font-size-1em">
                            {format(
                              new Date(account.created_at),
                              'dd/MM/yyyy, hh:mm a'
                            )}
                          </TableCell>
                          <TableCell>
                            {account.user_external_identificator}
                          </TableCell>
                          <TableCell className="font-size-1em">
                            {integration.getDisableIntegration(
                              account,
                              getIntegrations
                            )}
                            {integration?.getEditStore(index) || null}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
