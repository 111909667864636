import React, { useContext } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { I18nContext } from 'translations';

const PaymentHeaderForm = ({ setStep }) => {
  const { translate } = useContext(I18nContext);
  return (
    <div style={{ padding: '11px 20px', borderBottom: '2px solid lightgray' }}>
      <Grid container>
        <Grid item xs={6}>
          <Button
            type="button"
            size="small"
            color="primary"
            onClick={e => {
              e.stopPropagation();
              setStep(0)}
            }
            startIcon={
              <ArrowBackIcon
                fontSize="small"
              />
            }
          >
            {translate('app.express.back')}
          </Button>
        </Grid>
        <Grid item xs={6} className="text-right">
          <Typography
            variant="subtitle1"
            color="textPrimary"
          >
            {translate('app.express.paymentMethod')}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default PaymentHeaderForm;