import React, { useContext, useEffect} from 'react';
import { I18nContext } from 'translations';
import Title from 'components/Titles';
import CompanyRegisterForm from './CompanyRegisterForm';
import {
  Chip,
  Snackbar,
} from '@material-ui/core';
import {
  getCompanyRegister,
  closeMessage,
  getTaxLiabilityOptions,
  getMaxDeclaredValueOptions 
} from 'context/settings/companyRegister/actions';
import { useStateValue } from 'context/store';
import Loading from 'components/Loading';
import { COUNTRIES_IDS_BY_NAME } from 'utils/constants';

const CompanyRegister = () => {
  const { translate } = useContext(I18nContext);
  const [
    {
      checkin: { checkin },
      companyRegister: { getLoading, postLoading, data, message, maxDeclaredValueOptions },
    },
    dispatch,
  ] = useStateValue();

  useEffect(() => {
    if (checkin?.company) { 
      getCompanyRegister(dispatch, checkin?.company._id);
      checkin.company.geo_fence_id === COUNTRIES_IDS_BY_NAME.colombia._id &&  getTaxLiabilityOptions(dispatch, checkin?.company._id);
      getMaxDeclaredValueOptions(dispatch, checkin?.company._id);
    }
  }, [checkin.company]); // eslint-disable-line

  return (
    <>
      <Title title={translate('app.setting.companyRegister.status.title')} />
      {postLoading && <Loading />}
      {getLoading ? (
        <Loading />
      ) : (
        checkin?.company && (
          <>
            <Chip
              label={translate(
                `app.setting.companyRegister.status.${data?.registration_status}`
              )}
              color={
                data?.registration_status === 'refused'
                  ? 'secondary'
                  : 'primary'
              }
              style={{ margin: '2rem 0' }}
            />
            {
              maxDeclaredValueOptions && 
              <CompanyRegisterForm
              company={checkin?.company}
              companyRegister={data}
              maxDeclaredValueOptions={maxDeclaredValueOptions}
              />
            }
          </>
        )
      )}

      <Snackbar
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </>
  );
};

export default CompanyRegister;
