import React, { useContext, useEffect, useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { I18nContext } from 'translations';
import ModalConfirmCollection from 'components/Modals/ModalConfirmCollection';
import { modalMessage } from 'utils';
import {
  confirmCollection,
  getPendingCollection,
} from 'context/booking/actions';
import { useStateValue } from 'context/store';

const CollectionValue = ({ booking, classes, getBooking }) => {
  const { translate } = useContext(I18nContext);
  const [open, setOpen] = useState(false);
  const [loadingCollection, setLoadingCollection] = useState(false);
  const [
    {
      booking: { amountLeftToCollectByCompany },
    },
    dispatch,
  ] = useStateValue();

  useEffect(() => {
    getPendingCollection(dispatch, booking._id);
  }, [booking._id, dispatch]);

  const messageOkCollection = () => {
    setLoadingCollection(false);
    modalMessage(
      translate('congratulations'),
      translate('app.booking.collection.messageOk'),
      'success',
      translate('continue')
    ).then(async () => {
      await getBooking('init');
      setOpen(false);
      getPendingCollection(dispatch, booking._id);
    });
  };

  const validateCollection = async (value) => {
    setLoadingCollection(true);
    const isThereDecimalDot = value.includes('.');
    const isThereDecimalComma = value.includes(',');
    const valueFormatedWithOutDecimalSeparator = isThereDecimalDot
      ? value.replace('.', '')
      : value.replace(',', '');
    const valueInDecimals =
      isThereDecimalDot || isThereDecimalComma
        ? valueFormatedWithOutDecimalSeparator
        : `${valueFormatedWithOutDecimalSeparator}00`;
    const amount = {
      sub_units: parseInt(valueInDecimals),
      currency: booking?.company_estimated_cost?.iso,
    };
    const callback = await confirmCollection(dispatch, booking._id, amount);
    callback.status ? messageOkCollection() : setLoadingCollection(false);
  };

  return (
    <>
      {amountLeftToCollectByCompany && (
        <ModalConfirmCollection
          open={open}
          isLoading={loadingCollection}
          handleClose={() => setOpen(false)}
          handleSumbit={validateCollection}
          amountLeftToCollectByCompany={amountLeftToCollectByCompany}
        />
      )}
      {booking?.show_collect_payments_button && booking?.status_cd === 4 && (
        <Button
          type="button"
          color="primary"
          size="small"
          variant="outlined"
          onClick={() => setOpen(true)}
          className={classes.btn}
          disabled={loadingCollection}
          startIcon={
            loadingCollection && <CircularProgress size={18} color="inherit" />
          }
        >
          {translate('app.booking.btn.confirmCollection')}
        </Button>
      )}
    </>
  );
};

export default CollectionValue;
