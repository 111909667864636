import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { I18nContext } from 'translations';
import { formatCost } from 'utils';

export default function HeaderPayment({ account }) {
  const { translate } = useContext(I18nContext);

  return (
    <div className="animation-background">
      <Typography variant="body2" style={{ color: 'white' }}>
        {translate('app.payments.availableBalance')}:
      </Typography>
      <Typography variant="h5">
        {account ? formatCost(account.balance) : '$ 0.0'}
      </Typography>
      <LocalAtmIcon className="circle" />
    </div>
  )
}