import React, { lazy, Suspense} from 'react';
import { LinearProgress } from '@material-ui/core';
import withHelmet from 'utils/withHelmet';
const LayoutLanding = lazy(() => import('components/Landing/Layouts'));
const BannerMercadoflex = lazy(() => import('components/Landing/Banners/BannerMercadoflex'));
const UseMercadoflex = lazy(() => import('components/Landing/Mercadoflex/UseMercadoflex'));
const Contact = lazy(() => import('components/Landing/Layouts/Contact'));

const LandingMercadoflex = () => (
  <Suspense fallback={<LinearProgress color="primary" />}>
    <LayoutLanding>
      <BannerMercadoflex />
      <UseMercadoflex />
      <Contact />
    </LayoutLanding>
  </Suspense>
)

export default withHelmet({
  title: 'Mercadoenvíos E-commerce | Pibox',
  description: 'La alianza de Mercadoenvíos y Mercadolibre con Pibox te permitirá llegar a todos tus clientes y sus productos mejorando tus tiempos de entrega. Conoce más aquí.',
  linkCanonical: 'https://pibox.app/mercadoflex',
  image: 'https://pibox.app/images/landing/mercadoflex/mercadoenvios.png'
})(LandingMercadoflex)
