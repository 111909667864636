import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  form: {
    width: '100%',
    backgroundColor: '#fff',
    position: 'absolute',
    bottom: 0
  },
  inputSubmit: {
    marginTop: '10px',
    width: '100%'
  },
  btnSubmit: {
    marginTop: '10px'
  },
  boxChat: {
    width: '375px',
    height: '477px',
    backgroundColor: '#f5f5f5',
    position: 'fixed',
    right: '65px',
    bottom: '55px',
    zIndex: '9999',
    boxShadow: '0px 0px 10px 3px rgba(0, 0, 0, 0.8)',
    [theme.breakpoints.down('xs')]: {
      width: '320px',
      right: '25px'
    },
    '@media(max-width: 355px)': {
      width: '300px',
      right: '10px'
    },
  },
  header: {
    width: '100%',
    height: '83px',
    backgroundColor: '#ffffff'
  },
  avatar: {
    width: '62px',
    height: '62px',
    display: 'inline-block',
    '& svg': {
      fontSize: '45px',
      marginTop: '7px'
    }
  },
  btnClose: {
    position: 'absolute',
    right: '-3px',
    top: '-5px',
    '& svg': {
      fontSize: '22px'
    }
  },
  textCenter: {
    textAlign: 'center'
  },
  containerMessages: {
    overflow: 'auto',
    height: '330px',
    padding: '15px 15px 10px',
    display: 'block',
    textAlign: 'center'
  }
}));

export default styles;