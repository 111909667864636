import React from 'react';
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useStateValue } from 'context/store';

const TitleWithDowload = ({
  title = 'Pibox',
  button,
  icon,
  downloadExcel,
  buttonSync,
  sync,
  showOptionsShopify,
  syncShopify,
  buttonExport,
  exportFile,
  loadingExport,
}) => {
  const [
    {
      commons: { loading },
    },
  ] = useStateValue();
  return (
    <Grid container spacing={1}>
      <Grid item md={sync ? 6 : 9} xs={sync ? 12 : 7}>
        <Typography variant="h5" color="primary">
          {icon && icon}
          <span className="align-center">{title}</span>
        </Typography>
      </Grid>
      <Grid
        item
        md={sync ? 6 : 3}
        xs={sync ? 12 : 5}
        className={sync ? 'text-right text-center-xs' : 'text-right'}
      >
        {buttonSync && (
          <Button
            type="button"
            variant="outlined"
            color="primary"
            size="small"
            onClick={sync}
            style={{ marginRight: 7 }}
          >
            {buttonSync}
          </Button>
        )}
        {buttonExport && (
          <Button
            type="button"
            variant="outlined"
            color="primary"
            size="small"
            onClick={exportFile}
            startIcon={
              loadingExport ? (
                <CircularProgress size={18} />
              ) : (
                <CloudUploadIcon />
              )
            }
            style={{ marginRight: 7 }}
            disabled={loadingExport}
          >
            {buttonExport}
          </Button>
        )}
        {button && (
          <Button
            type="button"
            variant="outlined"
            color="primary"
            size="small"
            startIcon={
              loading ? <CircularProgress size={18} /> : <CloudDownloadIcon />
            }
            onClick={downloadExcel}
            disabled={loading}
          >
            {button}
          </Button>
        )}
      </Grid>
      {showOptionsShopify && (
        <Grid item xs={12} md={6} className="text-right text-center-xs">
          <Button
            type="button"
            variant="outlined"
            color="primary"
            size="small"
            onClick={syncShopify}
            style={{ marginRight: 7 }}
          >
            {buttonSync}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default TitleWithDowload;
