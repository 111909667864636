import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function EmptyData({ message }) {
  return (
    <div className="text-center" style={{ marginTop: 80 }}>
      <Typography variant="h5" align="center">
        {message}
        <img
          alt="no data"
          src="/images/404.gif"
          className="align-center"
          style={{ width: '140px' }}
        />
      </Typography>
    </div>
  )
} 