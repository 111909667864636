import { patch } from 'api/requests';
import { urlBookingSurvey } from 'api';
import handleErrorMessage from 'utils/handleErrorMessage';
import { message, loading } from '../commons/actions';


export const sendSurvey = async (dispatch, id, values, token) => {
  let status = false;
  loading(dispatch, true);
  await patch(`${urlBookingSurvey}/${id}/fill_customer_form`, { form: { ...values } }, token)
    .then(() => {
     status = true;
     loading(dispatch, false);
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
      loading(dispatch, false);
    });
  return { status }
}


