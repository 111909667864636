import { string, object } from 'yup';

export const schema = object().shape({
  name: string().required('requiredField'),
  phone: string().required('requiredField').matches(/^[0-9]+$/, 'onlyNumbers'),
  password: string().required('requiredField').min(6, 'minSixCharacters'),
  role: string().required('requiredField')
});

export const schemaEditUser = object().shape({
  name: string().required('requiredField'),
  role: string().required('requiredField')
});