import { createStyles } from '@material-ui/core/styles';

const styles = createStyles(theme => ({
  filter: {
    marginBottom: 8,
    '& .MuiOutlinedInput-input': {
      paddingTop: '8px',
      paddingBottom: '8px'
    },
    '& label': {
      fontSize: '0.78rem'
    },
    '& button': {
      padding: 4,
      '& svg': {
        fontSize: '1.2rem'
      }  
    },
    '& svg': {
      top: 'calc(50% - 14px)'
    }
  },
  filterStyle: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 10
    },
  },
  swithStyle: {
    width: 68,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(30px)',
      },
    },
    '& .MuiSwitch-thumb': {
      width: 32,
      height: 32,
    },
    '& .MuiSwitch-track': {
      borderRadius: 20 / 2,
    },
  }
}));

export default styles;