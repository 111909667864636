import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  fullPage: {
    height: 'calc(100vh - 70px)',
    [theme.breakpoints.down('xs')]: {
      height: 'auto'
    },
    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0,
      borderRadius: 0
    }
  },
  scroll: {
    overflowY: 'auto',
    padding: '0 5px 70px',
    height: 'calc(100vh - 230px)',
    [theme.breakpoints.down('xs')]: {
      height: 'auto !important',
      minHeight: 'calc(100vh - 235px)'
    },
  },
  accordionContainer: {
    boxShadow: 'none',
    border: 0,
    borderRadius: 0,
    borderBottom: '1px solid lightgray',
    '&:before': {
      display: 'none'
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(0deg)'
    },
    '& .MuiCollapse-entered': {
      paddingBottom: 40
    }
  },
  iconSwap: {
    position: 'absolute',
    right: 47,
    zIndex: 9
  },
  iconDelete: {
    position: 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer' 
  },
  flexColumn: {
    flexDirection: "column"
  },
  linePadding: {
    borderTop: '1px solid lightgray',
    padding: '6px 15px'
  },
  footerForm: {
    borderTop: '2px solid lightgray',
    position: 'relative',
    backgroundColor: '#fff',
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      position: 'sticky',
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%'
    }
  },
  containerMap: {
    position: 'relative',
    minHeight: '87vh',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

export default styles;