import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Landing from 'pages/Landings';
import LandingMercadoflex from 'pages/Landings/Mercadoflex';
import LandingShopify from 'pages/Landings/Integrations/Shopify';
import LandingWooCommerce from 'pages/Landings/Integrations/WooCommerce';
import LandingVtex from 'pages/Landings/Integrations/Vtex';
import Terms from 'pages/Landings/Terms';
import TermsPicash from 'pages/Landings/TermsPicash';
import Privacy from 'pages/Landings/Privacy';
import PrivacyInformation from 'pages/Landings/PrivacyInformation';
import Login from 'pages/Users/Login';
import PreSignup from 'pages/Users/Signup/PreSignup';
import Signup from 'pages/Users/Signup';
import SignupCompany from 'pages/Users/Signup/SignupCompany';
import RecoverPassword from 'pages/Users/RecoverPassword';
import UpdatePassword from 'pages/Users/RecoverPassword/UpdatePassword';
import TrackingCode from 'pages/Tracking/TrackingCode';
import Tracking from 'pages/Tracking';
import BookingDetail from 'pages/BookingDetail';
import LoginIntegration from 'pages/Integrations/Login';
import Pqrs from 'pages/Pqrs';
import ConfirmWaitDriver from 'pages/ConfirmWaitDriver';

import Layout from 'components/Layouts/Layout';
import Error from 'components/Error';
import NewOrder from 'pages/Express/New';
import Bookings from 'pages/Express/Bookings';
import Packages from 'pages/Express/Packages';
import Sales from 'pages/Express/Sales';
import PackageGrouping from 'pages/PackagesGrouping';
import ListPrepackages from 'pages/PackagesGrouping/ListPrepackages';
import Integrations from 'pages/Integrations';
import Activation from 'pages/Integrations/Mercadoflex/Activation';
import ActivationShopify from 'pages/Integrations/Shopify/ActivationShopify';

import ConfigurationShopify from 'pages/Integrations/Configurations/ConfigurationShopify';

import Picash from 'pages/Payments/Picash';
import BankAccounts from 'pages/Payments/BankAccounts';
import Settings from 'pages/Settings';
import HeinekenForm from 'pages/Surveys/Forms/HeinekenForm';
import DownloadReport from 'pages/Express/DownloadReports';

import GeneratePromoCode from 'pages/PromoCodes/GeneratePromoCode';
import PromoCodes from 'pages/PromoCodes';

import AssignBalance from 'pages/Balances/AssignBalance';
import Balances from 'pages/Balances';

import ServicesOperations from 'pages/Operations/Services';
import OptimizeRoutes from 'pages/Operations/OptimizeRoutes';
import RoutesFileList from 'pages/Operations/OptimizeRoutes/RoutesFileList';
import ListOptimizedRoutes from 'pages/Operations/OptimizeRoutes/ListOptimizedRoutes';
import RateBookings from 'pages/RateBookings';
import { useStateValue } from 'context/store';
import AccessDenied from 'components/Error/AccessDenied';
import CreditCards from 'pages/Payments/CreditCards';
import PaymentBalances from 'pages/Payments/Balances';
import Analytics from 'pages/Analytics';
import { ADDONS, RENT } from 'utils/constants';
import Pipay from 'pages/Payments/Pipay';
import PaymentMethodPipay from 'pages/Payments/Pipay/PaymentMethodPipay';
import PayBill from 'pages/Payments/Pipay/PayBill';
import HourlyReservations from 'pages/Express/HourlyReservations';
import Commodate from 'pages/Commodate';
import Claims from 'pages/Claims';

const Routes = () => {
  const [
    {
      checkin: { company, loading, checkin },
    },
  ] = useStateValue();
  
  const isRent = process.env.REACT_APP_API_URL_BASE_IS_RENT === RENT;

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/mercadoflex" component={LandingMercadoflex} />
        <Route exact path="/shopify" component={LandingShopify} />
        <Route exact path="/woocommerce" component={LandingWooCommerce} />
        <Route exact path="/vtex" component={LandingVtex} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/terms/picash" component={TermsPicash} />
        <Route exact path="/privacy" component={Privacy} />
        <Route
          exact
          path="/privacy-information"
          component={PrivacyInformation}
        />
        <Route exact path="/login" component={Login} />
        <Route exact path="/presignup" component={PreSignup} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/signup-company" component={SignupCompany} />
        <Route exact path="/recover-password" component={RecoverPassword} />
        <Route exact path="/update-password" component={UpdatePassword} />
        <Route exact path="/tracking-code" component={TrackingCode} />
        <Route exact path="/tracking/:id" component={Tracking} />
        <Route exact path="/bookings/:id" component={BookingDetail} />
        <Route exact path="/pqrs" component={Pqrs} />
        <Route exact path="/integration/login" component={LoginIntegration} />
        <Route exact path="/survey/heineken" component={HeinekenForm} />
        <Route exact path="/confirm-wait-driver/:id" component={ConfirmWaitDriver} />
        <Route path="/app">
          <Layout>
            <Switch>
              <Route exact path="/app">
                <NewOrder isRent={isRent} />
              </Route>
              <Route exact path="/app/bookings">
                <Bookings isRent={isRent} />
              </Route>
              <Route path="/app/bookings/new">
                <NewOrder isRent={isRent} />
              </Route>
              <Route path="/app/bookings/packages" component={Packages} />
              <Route path="/app/bookings/sales" component={Sales} />
              <Route path="/app/list-prepackages" component={ListPrepackages} />
              <Route path="/app/reports/:type" component={DownloadReport} />

              <Route exact path="/app/packages-grouping">
                <AccessDenied
                  company={company}
                  loading={loading}
                  roles={checkin?.profile?.roles}
                >
                  <PackageGrouping />
                </AccessDenied>
              </Route>
              <Route exact path="/app/integrations">
                <AccessDenied
                  company={company}
                  loading={loading}
                  roles={checkin?.profile?.roles}
                >
                  <Integrations />
                </AccessDenied>
              </Route>
              <Route
                exact
                path="/app/integrations/mercadoflex/activation"
                component={Activation}
              />
              <Route
                exact
                path="/app/integrations/shopify/activation"
                component={ActivationShopify}
              />
              <Route
                exact
                path="/app/integrations/shopify/configuration"
                component={ConfigurationShopify}
              />

              <Route exact path="/app/integrations">
                <AccessDenied
                  company={company}
                  loading={loading}
                  roles={checkin?.profile?.roles}
                >
                  <Integrations />
                </AccessDenied>
              </Route>
              <Route
                exact
                path="/app/integrations/mercadoflex/activation"
                component={Activation}
              />
              <Route
                exact
                path="/app/integrations/shopify/activation"
                component={ActivationShopify}
              />
              <Route
                exact
                path="/app/integrations/shopify/configuration"
                component={ConfigurationShopify}
              />
              <Route
                exact
                path="/app/integrations/shopify/configuration"
                component={ConfigurationShopify}
              />
              <Route exact path="/app/payments/picash" component={Picash} />
              <Route
                exact
                path="/app/payments/bank-accounts"
                component={BankAccounts}
              />
              <Route
                exact
                path="/app/payments/credit-cards"
                component={CreditCards}
              />
              <Route
                exact
                path="/app/payments/balances"
                component={PaymentBalances}
              />
              <Route
                exact
                path="/app/payments/pipay"
                component={Pipay}
              />
              <Route
                exact
                path="/app/payments/payment-method/:id"
                component={PaymentMethodPipay}
              />

              <Route
                exact
                path="/app/payments/pay-bill/:id"
                component={PayBill}
              />

              <Route exact path="/app/settings" component={Settings} />

              <Route
                exact
                path="/app/operations/services"
                component={ServicesOperations}
              />
              <Route exact path="/app/operations/optimize-routes">
                <AccessDenied
                  company={company}
                  loading={loading}
                  roles={checkin?.profile?.roles}
                >
                  <OptimizeRoutes />
                </AccessDenied>
              </Route>
              <Route exact path="/app/operations/routes-file-list">
                <AccessDenied company={company} loading={loading}>
                  <RoutesFileList />
                </AccessDenied>
              </Route>
              <Route exact path="/app/operations/optimized-routes/:id">
                <AccessDenied company={company} loading={loading}>
                  <ListOptimizedRoutes />
                </AccessDenied>
              </Route>
              <Route exact path="/app/commodate">
                <AccessDenied company={company} isAddon={ADDONS[4]} loading={loading}>
                  <Commodate />
                </AccessDenied>
              </Route>
              <Route exact path="/app/reserved">
                <AccessDenied
                  company={company}
                  loading={loading}
                  roles={checkin?.profile?.roles}
                >
                  <HourlyReservations />
                </AccessDenied>
              </Route>

              <Route exact path="/app/rate-bookings" component={RateBookings} />

              <Route exact path="/app/analytics" component={Analytics} />

              <Route exact path="/app/promo-codes/new" component={GeneratePromoCode} />
              <Route exact path="/app/promo-codes" component={PromoCodes} />

              <Route exact path="/app/claims" component={Claims} />
              <Route exact path="/app/balances/new" component={AssignBalance} />
              <Route exact path="/app/balances" component={Balances} />

              <Route component={Error} />
            </Switch>
          </Layout>
        </Route>
        <Route path="*" component={Error} status={404} />
      </Switch>
    </Router>
  );
};

export default Routes;
