import { urlPackages, urlRate } from 'api';
import { get, patch } from 'api/requests';
import handleErrorMessage from 'utils/handleErrorMessage';
import { loading, message } from '../commons/actions';
import { GET_TRACKING, TRACKING_RATING } from './constants';

export const getTrackingCode = async (dispatch, values) => {
  const { id } = values;
  loading(dispatch, true);
  await get(`${urlPackages}/${id}/tracking_link`)
    .then(json => {
      return json.data;
    })
    .then(data => {
      loading(dispatch, false);
      window.location.href = data.tracking_link;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      loading(dispatch, false);
      message(dispatch, mssg);
    });
}

export const getTracking = async (dispatch, id, type) => {
  type === 0 && loading(dispatch, true);
  await get(`${urlPackages}/${id}/track`)
    .then(json => {
      return json.data;
    })
    .then(data => {
      dispatch({
        type: GET_TRACKING,
        tracking: data
      })
      type === 0 && loading(dispatch, false);
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      type === 0 && loading(dispatch, false);
      message(dispatch, mssg);
    });
}

export const sendRate = async (dispatch, id, rate) => {
  loading(dispatch, true)
  await patch(`${urlRate}/${id}/rate_by_customer`, { ...rate })
    .then(() => {
      loading(dispatch, false);
      dispatch({
        type: TRACKING_RATING,
        rate: true
      })
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      loading(dispatch, false);
      message(dispatch, mssg);
    });
}