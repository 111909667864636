import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback
} from 'react';
import mapboxgl from 'mapbox-gl';
import polyline from '@mapbox/polyline';

// mapboxgl.accessToken = process.env.REACT_APP_API_URL_BASE_MAPS_API_TOKEN;
const STYLE_URL = process.env.REACT_APP_API_URL_BASE_MAPS_API_STYLES_URL;


function PolylineMap({
  points,
  selectedRoutes,
  routes,
  selectedStop,
  handleChangeSelectedStop,
}) {
  const [markers, setMarkers] = useState([]);
  const [mapInstance, setMapInstance] = useState();
  const mapContainerRef = useRef(null);
  const arrayCoordinates = [];

  const jobs = useMemo(() => {
    if (selectedRoutes && selectedRoutes.length) {
      return routes.filter((route) =>
        selectedRoutes.some((item) => item.id === route.route_index)
      );
    }
    return routes;
  }, [routes, selectedRoutes]);

  const originAddress = useMemo(() => {
    if (routes[0].address_lon && routes[0].address_lat) {
      return [routes[0].address_lon, routes[0].address_lat];
    }
    return null;
  }, [routes]);

  const coordinates = useCallback(() => {
    jobs.map((job) =>
      job.stops.map((task) => arrayCoordinates.push([task.lon, task.lat]))
    );
    if (originAddress) arrayCoordinates.push(originAddress);
    return arrayCoordinates;
  }, [jobs]); // eslint-disable-line

  const fitBounds = (map) => {
    const bounds = coordinates().reduce(
      (bnd, coord) => bnd.extend(coord),
      new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
    );

    map.fitBounds(bounds, {
      padding: 80,
      maxZoom: 14,
      duration: 2000,
    });
  };

  const getTaskMarker = (map, task, job, taskIndex) => {
    const el = document.createElement('div');
    el.innerHTML = `
      <div class="marker-text">
        <span>${taskIndex + 1}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="37" viewBox="0 0 14 17">
          <g fill="none" fill-rule="evenodd" transform="translate(2 1.928)">
            <path fill=#${
              job.color_hex_code
            } d="M5.397 7.163c-1.155 0-2.091-.936-2.091-2.09s.936-2.09 2.09-2.09 2.09.936 2.09 2.09-.936 2.09-2.09 2.09m5.16-1.97C10.384 2.56 8.286.225 5.396.225S.408 2.56.237 5.193C.15 6.477.578 7.72 1.328 8.747L2.93 10.89l2.467 3.296 2.487-3.326 1.58-2.112c.771-1.028 1.178-2.29 1.092-3.554"/>
            <path stroke="white" stroke-width=".5" d="M5.397 7.163c-1.155 0-2.091-.936-2.091-2.09s.936-2.09 2.09-2.09 2.09.936 2.09 2.09-.936 2.09-2.09 2.09zm5.16-1.97C10.383 2.56 8.286.225 5.396.225 2.506.225.407 2.56.236 5.193.15 6.477.578 7.72 1.328 8.747L2.93 10.89l2.467 3.296 2.487-3.326 1.58-2.112c.771-1.028 1.178-2.29 1.092-3.554z"/>
            <circle cx="5.396" cy="5.289" r="3.082" fill=#${job.color_hex_code} />
          </g>
        </svg>
      </div>`;
    el.id = 'marker';
    el.addEventListener('click', (e) => {
      handleChangeSelectedStop(e, task, job);
    });
    return new mapboxgl.Marker(el).setLngLat([task.lon, task.lat]).addTo(map);
  };

  const getOriginMarker = (map) => {
    const el = document.createElement('div');
    el.innerHTML = `
      <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
        <path fill="#a168b1" d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path>
      </svg>`;
    el.id = 'marker';

    const popup = new mapboxgl.Popup({
      closeButton: true,
      closeOnClick: true,
      offset: 20,
    }).setText('Origen');

    return new mapboxgl.Marker(el)
      .setLngLat(originAddress)
      .setPopup(popup)
      .addTo(map);
  };

  const getPolyline = (map, job) => {
    const source = `job${job.route_index}`;
    const layer = `job${job.route_index}`;
    const polylineCoordinates = polyline
      .decode(job.polyline)
      .map((coord) => coord.reverse());

    map.addSource(source, {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: polylineCoordinates,
        },
      },
    });

    map.addLayer({
      id: layer,
      type: 'line',
      source,
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': `#${job.color_hex_code}`,
        'line-width': 2,
      },
    });
  };

  const getStraightLine = (map, job) => {
    const source = `job${job.route_index}`;
    const layer = `job${job.route_index}`;
    const coord = job.stops.map((task) => [task.lon, task.lat]);

    map.addSource(source, {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: [originAddress].concat(coord),
        },
      },
    });

    map.addLayer({
      id: layer,
      type: 'line',
      source,
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': `#${job.color_hex_code}`,
        'line-width': 2,
      },
    }); 
  };

  const updateLayers = (map) => {
    points
      .filter((point) => jobs.some((job) => job.route_index === point.route_index))
      .forEach((job) => {
        job.polyline ? getPolyline(map, job) : getStraightLine(map, job);
      });
  };

  const updateMarkers = (map) => {
    let newMarkers = [];
    jobs.forEach((job) => {
      const taskMarkers = job.stops.map((task, taskIndex) =>
        getTaskMarker(map, task, job, taskIndex)
      );
      newMarkers = newMarkers.concat(taskMarkers);
    });
    setMarkers(newMarkers);
  };

  const removeLayers = (map) => {
    routes.forEach((job) => {
      const source = `job${job.route_index}`;
      const layer = `job${job.route_index}`;

      if (map.getLayer(layer)) map.removeLayer(layer);
      if (map.getSource(source)) map.removeSource(source);
      
    });
  };

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: STYLE_URL,
      zoom: 12,
      center: [jobs[0].stops[0].lon, jobs[0].stops[0].lat]
    });

    map.addControl(new mapboxgl.NavigationControl());

    map.on('load', () => {
      fitBounds(map);
      updateMarkers(map);
      updateLayers(map);
      setMapInstance(map);
      if (originAddress) getOriginMarker(map);
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (mapInstance) {
      markers.map((marker) => marker.remove());
      removeLayers(mapInstance);
      updateMarkers(mapInstance);
      updateLayers(mapInstance);

      if (!mapInstance.isZooming()) fitBounds(mapInstance);
    }
  }, [jobs, points]); // eslint-disable-line

  useEffect(() => {
    if (mapInstance && selectedStop) {
      let zoom = mapInstance.getZoom();
      zoom = zoom < 14 ? 14 : zoom;
      mapInstance.flyTo({
        center: [selectedStop.lon, selectedStop.lat],
        zoom,
      });

      new mapboxgl.Popup({ closeButton: true, closeOnClick: true, offset: 20 })
        .setLngLat([selectedStop.lon, selectedStop.lat])
        .setText(selectedStop.address)
        .addTo(mapInstance);
    }
  }, [selectedStop, mapInstance]);

  return (
    <div style={{ height: '75vh', width: '100%' }} ref={mapContainerRef} />
  );
}

export default PolylineMap;
