import { post, get, patch } from 'api/requests';
import { urlCompanyPromoCode } from 'api';
import { message } from '../commons/actions';
import handleErrorMessage from 'utils/handleErrorMessage';
import { COUNTRY_CODE } from 'utils/constants';
import { formatPrice  } from 'utils';
import { LIST_PROMO_CODES, LIST_PROMO_CODES_LOADING } from './constants';

export const createPromoCode = async (dispatch, values, companyId, lang) => {
  const data = {
    ...values,
    amount: {
      sub_units: formatPrice({
        sub_units: values.amount,
        iso: COUNTRY_CODE[lang][4],
      }),
      currency: COUNTRY_CODE[lang][4]
    },
    multiplier: parseInt(values.multiplier)
  }

  let status = false;
  await post(`${urlCompanyPromoCode}/${companyId}/corporative_promo_codes`, { promo_code_settings: data })
    .then((json) => {
      return json.data;
    })
    .then((data) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status }
}

export const listPromoCode = async (dispatch, companyId, params) => {
  dispatch({
    type: LIST_PROMO_CODES_LOADING,
    loadingPromoCode: true,
  });
  await get(`${urlCompanyPromoCode}/${companyId}/corporative_promo_codes`, { ...params })
    .then(({ data }) => {
      dispatch({
        type: LIST_PROMO_CODES,
        list: data,
      });
      dispatch({
        type: LIST_PROMO_CODES_LOADING,
        loadingPromoCode: false,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: LIST_PROMO_CODES_LOADING,
        loadingPromoCode: false,
      });
    });
}

export const assingPromoCode = async (dispatch, companyId, values) => {
  let status = false;
  let unasignables = [];
  await patch(`${urlCompanyPromoCode}/${companyId}/corporative_promo_codes/allocate`, { ...values })
    .then((json) => {
      return json.data;
    })
    .then((data) => {
      status = true;
      if (data?.unasignables) {
        unasignables.push(...data.unasignables)
      }
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status, unasignables }
}


export const updatePromoCode = async (dispatch, companyId, id, values, lang) => {
  const data = {
    ...values,
    amount: {
      sub_units: formatPrice({
        sub_units: values.amount,
        iso: COUNTRY_CODE[lang][4],
      }),
      currency: COUNTRY_CODE[lang][4]
    },
    cost_center: values.cost_center_id
  }

  let status = false;
  await patch(`${urlCompanyPromoCode}/${companyId}/corporative_promo_codes/${id}`, { ...data })
    .then((json) => {
      return json.data;
    })
    .then((data) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status }
}

export const cancelPromoCode = async (dispatch, companyId, values) => {
  let status = false;
  let nonCancellable = [];
  await patch(`${urlCompanyPromoCode}/${companyId}/corporative_promo_codes/cancel`, { ...values })
    .then((json) => {
      return json.data;
    })
    .then((data) => {
      status = true;
      if (data?.non_cancellable_codes) {
        nonCancellable.push(...data.non_cancellable_codes)
      }
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status, nonCancellable }
}

export const exportPromoCodes = async (dispatch, companyId, params) => {
  let status = false;
  await post(`${urlCompanyPromoCode}/${companyId}/corporative_promo_codes/export` , { ...params })
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
};