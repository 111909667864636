import React, { useContext } from 'react';
import {
  Grid,
  Button,
  Select,
  TextField,
  IconButton,
  InputLabel,
  Typography,
  FormControl,
  DialogActions
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import ControlPointOutlinedIcon from '@material-ui/icons/ControlPointOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Controller, useFieldArray } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import { I18nContext } from 'translations';
import esLocale from 'date-fns/locale/es';
import { CATEGORIES_COMMODATE } from 'utils/constants';

export default function FormRequestComodato ({ submit, openModal, handleSubmit, register, control, errors }) {

  const { translate } = useContext(I18nContext);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "commodates_attributes"
  });

  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <div className="bg-white">
        <Typography variant="h5" style={{ marginBottom: '35px' }}>
          {translate('commodate.modalRequest.commodateInformation')}
        </Typography>
        <Grid container spacing={3} style={{ marginBottom: '20px' }}>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              name="driver_phone_number"
              label={translate('commodate.modalRequest.form.pilotPhone')}
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              required
              inputRef={register}
              error={!!errors.driver_phone_number}
              helperText={errors.driver_phone_number && translate(errors.driver_phone_number.message)}
            />
          </Grid>
          <Grid item md={6} sm={4} xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <Controller
                name="return_date"
                control={control}
                defaultValue={new Date()}
                render={({ onChange, value }) => (
                  <KeyboardDatePicker
                    size="small"
                    inputVariant="outlined"
                    label={translate('commodate.modalRequest.form.returnDate')}
                    format="dd/MM/yyyy"
                    cancelLabel={translate('cancel')}
                    okLabel={translate('accept')}
                    invalidDateMessage={translate('invalidFormat')}
                    fullWidth
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
          
        <Typography variant="h5">
          {translate('commodate.modalRequest.form.items')}
        </Typography>
        <small>{translate('commodate.modalRequest.form.itemsText')}</small>

        {fields.map((item, index) => (
          <Grid container spacing={3} style={{ marginTop: '20px' }} key={item.id}>
            <Grid item md={6} sm={6} xs={12}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel htmlFor={`commodates_attributes${index}`} id={`commodates_attributes${index}`}>
                  {translate('commodate.modalRequest.form.item')}
                </InputLabel>
                <Select
                  native
                  inputRef={register()}
                  label={translate('commodate.modalRequest.form.item')}
                  name={`commodates_attributes[${index}].category_cd`}
                  defaultValue={item.name ? item.name : ''}
                >
                  {Object.keys(CATEGORIES_COMMODATE).map((key) => (
                    <option value={key} key={`cat-commodate-${key}`}>
                      {translate(CATEGORIES_COMMODATE[key])}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={fields?.length > 1 ? 5 : 6} sm={6} xs={12}>
              <TextField
                name={`commodates_attributes[${index}].description`}
                label={translate('commodate.modalRequest.form.description')}
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                required
                inputRef={register()}
                defaultValue={item.description ? item.description : ''}
                multiline
                rows={3}
              />
              <small>{translate('commodate.modalRequest.form.descriptionText')}</small>
            </Grid>
            {fields?.length > 1 &&
              <Grid item md={1} sm={6} xs={12}>
                <IconButton onClick={() => remove(index)} aria-label="delete">
                  <DeleteOutlineIcon />
                </IconButton>
              </Grid>
            }
          </Grid>
        ))}
          
        <Button color="primary" startIcon={<ControlPointOutlinedIcon />} onClick={() => append({})}>
          {translate('commodate.modalRequest.form.addItem')}
        </Button>
        
      </div>
      <DialogActions>
        <Button
          onClick={() => openModal()}
          variant="outlined"
          className="btn-rounded-style"
          style={{ backgroundColor: '#fff' }}
        >
          {translate('commodate.modalRequest.form.exitWithoutCreating')}
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          className="btn-rounded-style"
        >
          {translate('commodate.modalRequest.form.continue')}
        </Button>
      </DialogActions>
    </form>
  )
}
