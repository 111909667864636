import React from 'react';
import { Typography, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const TrackingInformation = ({ title, text, classStatus, helpText, type = 0 }) => (
  <>
    {type === 0 ? (
      <>
        <Typography variant="body2" color="textSecondary">
          {title}
          {helpText && (
            <Tooltip title={helpText} placement="top" arrow style={{ fontSize: '15px', position: 'relative', top: '-2px', left: '3px' }}>
              <HelpOutlineIcon />
            </Tooltip>
          )}
        </Typography>
        <Typography variant="body1" color="textPrimary" className={`break-all ${classStatus}`} style={{ marginTop: '2px' }}>
          {text}
        </Typography>
      </>
    ) : (
      <>
        <Typography variant="body2" color="textSecondary">
          {title}
          {helpText && (
            <Tooltip title={helpText} placement="top" arrow style={{ fontSize: '15px', position: 'relative', top: '-2px', left: '3px' }}>
              <HelpOutlineIcon />
            </Tooltip>
          )}
          <b style={{ marginLeft: helpText ? 15 : 2 }}>{text}</b>
        </Typography>
      </>
    )}
  </>
);

TrackingInformation.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.any,
  helpText: PropTypes.string
};

export default TrackingInformation;