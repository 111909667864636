import firebase from 'firebase/app';
import 'firebase/database';


export const FirebaseChat = class FirebaseChat {
  CONFIG = {
    apiKey: process.env.REACT_APP_API_URL_BASE_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_API_URL_BASE_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_API_URL_BASE_FIREBASE_DATABASE_URL,
    storageBucket: process.env.REACT_APP_API_URL_BASE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_API_URL_BASE_FIREBASE_MESSAGING_SENDER_ID
  };

  // SENDER TYPES
  AS_PASSENGER = 0;
  AS_DRIVER = 1;
  AS_PICAP = 2;
  AS_SHOPPER = 3;

  // DEFAULT MESSAGES
  DEFAULT_CONFIRM_MESSAGE = 'Hola! Estas recibiendo un servicio de mensajerÃ­a corporativa de Picap , sigue las instrucciones de la app y si tienes dudas estaremos pendientes de ti en la linea telefÃ³nica.'

  // Lyfecycle
  initialize = () => {
    try {
      const fApp = firebase.app('chat');
      return fApp;
    } catch (error) {
      return firebase.initializeApp(this.CONFIG, 'chat');
    }
  }

  // Methods
  sendMessage = (message, senderType, bookingId) => {
    const init = this.initialize();
    const { key } = init.database()
      .ref()
      .child('chat_messages')
      .child(bookingId)
      .push();

    const chatMessage = {
      message,
      sender: senderType,
      createdAt: firebase.database.ServerValue.TIMESTAMP
    };

    const update = {};
    update['/chat_messages/' + bookingId + '/' + key] = chatMessage;
    init.database().ref().update(update);
  }

  listenForMessages = (bookingId, onChildAdded) => {
    const init = this.initialize();
    const messagesRef = init.database()
      .ref('chat_messages/' + bookingId)
      .orderByKey();
    messagesRef.on('child_added', (data) => {
      onChildAdded(data.val());
    });
  }

  listenLastMessages = (bookingId, onChildAdded) => {
    const init = this.initialize();
    const messagesRef = init.database()
      .ref(`chat_messages/${bookingId}`)
      .orderByKey().limitToLast(1);
    messagesRef.on('child_added', (data) => {
      onChildAdded(data.val());
    });
  }
};

export default FirebaseChat;