import React, { useContext, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { I18nContext } from 'translations';
import NumberFormat from 'react-number-format';

const ModalConfirmCollection = ({
  open,
  handleClose,
  handleSumbit,
  amountLeftToCollectByCompany,
  isLoading,
}) => {
  const collectionValue =
    amountLeftToCollectByCompany?.amount_left_to_collect?.sub_units;
  const collectionValueFormated = collectionValue.toString().slice(0, -2);
  const decimals = collectionValue?.toString().slice(-2);
  const { translate } = useContext(I18nContext);
  const [value, setValue] = useState(collectionValueFormated + `,${decimals}`);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'sm'}
      aria-labelledby="form-collection-value-dialog-title"
    >
      <DialogTitle id="form-collection-value-dialog-title">
        {translate('app.booking.collection.modal.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {amountLeftToCollectByCompany?.mssg}
        </DialogContentText>
        <NumberFormat
          isNumericString={true}
          thousandSeparator="."
          decimalSeparator=","
          prefix="$"
          fullWidth
          customInput={TextField}
          value={value}
          onValueChange={(values) => {
            const { value } = values;
            setValue(value);
          }}
          placeholder={translate(
            'app.booking.collection.modal.collectionValue'
          )}
          allowNegative={false}
          allowLeadingZeros={false}
          size="small"
          label={translate('app.booking.collection.modal.collectionValue')}
          helperText={
            value === ''
              ? translate('app.booking.collection.modal.enterCollectionValue')
              : null
          }
          error={value === ''}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="secondary">
          {translate('cancel')}
        </Button>
        <Button
          disabled={value === ''}
          variant="contained"
          onClick={() => handleSumbit(value)}
          color="primary"
        >
          {isLoading ? <CircularProgress size={28} /> : translate('send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirmCollection;
