import React, { useEffect, useContext, useCallback, useState } from 'react';
import { Snackbar, Button, CircularProgress, Container } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { piboxAccessDeniedRolesCompany } from 'utils';
import { message as closeMessage } from 'context/commons/actions';
import HeaderAccounts from 'components/Headers/HeaderAccounts';
import BankAccountsTable from 'components/Tables/Payments/BankAccounts';
import ModalAccounts from 'components/Modals/ModalAccounts';
import Loading from 'components/Loading';
import { INITIAL_PAGE, PER_PAGE, COUNTRY_CODE } from 'utils/constants';
import {
  getBankAccounts,
  getBankList,
  getAccountTypeList,
  getDocumentTypeList,
  removeBankAccount
} from 'context/payments/actions';
import { modalMessage } from 'utils';

export default function BankAccounts() {
  const { langCode, translate } = useContext(I18nContext);
  const [open, setOpen] = useState(false);
  const [per_page] = useState(PER_PAGE);
  const [bankAccountSelected, setBankAccountSelected] = useState(null);

  const [{
    payments: { bankAccounts, bankAccountsLoading, bankList, accountTypeList, documentTypeList },
    commons: { message },
    checkin: { company, checkin } },
    dispatch
  ] = useStateValue();

  const getAccounts = useCallback(async (page) => {
    await getBankAccounts(dispatch, { page, per_page});
  }, [dispatch, per_page]);

  const getConfigBank = useCallback(async () => {
    const geo_fence_id = company ? company.geo_fence_id : COUNTRY_CODE[langCode][0]; 
    getBankList(dispatch, { geo_fence_id });
    getAccountTypeList(dispatch, { geo_fence_id });
    getDocumentTypeList(dispatch, { geo_fence_id });
  }, [dispatch, company, langCode]);

  
  useEffect(() => {
    getAccounts(INITIAL_PAGE);
    getConfigBank();
  }, [getAccounts, getConfigBank]);

  const editBankAccount = (account) => {
    setOpen(true)
    setBankAccountSelected(account);
  }

  const removeAccount = (account) => {
    modalMessage('', translate('app.payments.bankAccount.youWantToDeleteThisAccount'), 'warning', translate('accept'), true, true, translate('cancel')).then(async (status) => {
      if(status.isConfirmed) {
        const callback = await removeBankAccount(dispatch, account._id);
        callback.status && getAccounts(INITIAL_PAGE);
      }
    })
  }

  const role = () => {
    let rol = [];
    if (checkin && checkin.profile.roles) {
      rol = piboxAccessDeniedRolesCompany(checkin.profile.roles);
    }
    return rol;
  }
  
  return (
    <div style={{ background: '#fafafa' }}>
      {bankAccountsLoading && <Loading />}
      <HeaderAccounts />

      <div className="padding-page-full-height" style={{ background: 'white' }}>
        <Container>
          <div className="text-right" style={{ margin: '0 0 12px' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setBankAccountSelected(null);
                setOpen(true);
              }}
              disabled={(!bankList && !accountTypeList && !documentTypeList) || role().length > 0}
              startIcon={!bankList && !accountTypeList && !documentTypeList ? <CircularProgress size={18} /> : null}
            >
              {translate('app.payments.bankAccounts.addAccount')}
            </Button>
          </div>
          {bankAccounts && (
            <BankAccountsTable
              data={bankAccounts}
              getAccounts={getAccounts}
              recordsPerPage={per_page}
              editBankAccount={editBankAccount}
              removeAccount={removeAccount}
              rolesDisabled={role().length > 0}
            />
          )}
          {open && (
            <ModalAccounts
              open={open}
              setOpen={setOpen} 
              bankList={bankList} 
              accountTypeList={accountTypeList}
              documentTypeList={documentTypeList}
              getAccounts={getAccounts}
              bankAccountSelected={bankAccountSelected}
            />
          )}
        </Container>
      </div>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </div>
  )
}