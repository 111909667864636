import React, { useContext } from 'react';
import { Typography, Dialog, Toolbar, IconButton, DialogContent } from '@material-ui/core';
import { I18nContext } from 'translations';
import CloseIcon from '@material-ui/icons/Close';
// import AutorenewIcon from '@material-ui/icons/Autorenew';
// import { excelFailedStops } from 'context/express/actions';
import FailedStopsTable from 'components/Tables/Bookings/FailedStopsTable';
// import ModalDownloadExcel from 'components/Modals/ModalDownloadExcel';

const ModalFailedStops = ({ open, handleModalFailedStops, failedStops, isOperations }) => {
  //const [exportData, setExportData] = useState([]);
  //const [openDownload, setOpenDownload] = useState(false);
  const { translate } = useContext(I18nContext);

  /*const generateExcel = async() => {
    const exportStops = await excelFailedStops({ failedStops }, translate);
    setExportData(exportStops);
    setOpenDownload(true);
  }*/

  return (
    <Dialog fullWidth maxWidth="lg" open={open}>
      <Toolbar style={{ justifyContent: 'center' }}>
        <IconButton
          edge="end"
          color="primary"
          onClick={handleModalFailedStops}
          aria-label="close"
          style={{ position: 'absolute', right: 20, top: '10px' }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" color="textPrimary" className="text-center">
          {translate('app.bulkLoad.routesThatWereNotProcessed')}
        </Typography>
      </Toolbar>
      <DialogContent style={{ padding: '15px 30px 30px' }} className="text-center">
        {failedStops && (
          <FailedStopsTable 
            data={failedStops}
            isOperations={isOperations}
          />
        )}
        <br /><br />
        {/*<Button
          type="button"
          color="primary"
          variant="contained"
          endIcon={<AutorenewIcon />}
          onClick={async () => await generateExcel()}
        >
          {translate('app.express.generateExcel')}
        </Button>
        
        <ModalDownloadExcel
          openDownload={openDownload}
          setOpenDownload={setOpenDownload}
          exportData={exportData}
        /> */}
        
      </DialogContent>
    </Dialog>
  )
}

export default ModalFailedStops;