import React from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
//import { I18nContext } from 'translations';
import styles from './style';

const AnalyticsCard = ({ title, count, icon }) => {
  //const { translate } = useContext(I18nContext);

  return (
    <Paper style={{ width: '100%', padding: '12px 12px 5px', borderRadius: 12 }}>
      <Typography variant="body1" style={{ marginBottom: 15 }}>
        {title}
      </Typography>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <Typography color="primary" variant="h3" style={{ fontSize: '2.2rem' }}>
            {count}
          </Typography>
        </Grid>
        <Grid item xs={6} className="text-right">
          {icon}
        </Grid>
      </Grid>
    </Paper>
  )
}


export default withStyles(styles)(AnalyticsCard);