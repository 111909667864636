import { get, post, patch } from 'api/requests';
import { loading, message } from '../../commons/actions';
import handleErrorMessage from 'utils/handleErrorMessage';
import {
  LIST_USERS,
  LIST_USERS_LOADING,
  VALIDATE_USER,
  CLEAN_USER,
  VALIDATING_USER,
  LOADING_SAVE_USER_COMPANY
} from './constants';
import {
  urlGroupsCompanies,
  urlUserExists,
  urlCreateUserCompany,
  urlEditUserCompany,
  urlRemoveUserCompany,
  urlToggleActiveUser,
  urlUpdateTermsAndConditions
} from 'api';
import { MESSAGE } from 'context/commons/constants';

export const getUserList = async (dispatch, idCompany, params) => {
  dispatch({ type: LIST_USERS_LOADING, loadingUsers: true });
  await get(`${urlGroupsCompanies}/${idCompany}/users`, params)
    .then(({ data }) => {
      dispatch({
        type: LIST_USERS,
        listUsers: data
      });
      dispatch({ type: LIST_USERS_LOADING, loadingUsers: false });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({
        type: LIST_USERS_LOADING,
        listUsers: null,
        loadingUsers: false
      });
      message(dispatch, mssg);
    });
}

export const validateUser = async (dispatch, params, setOpen, getUsers, INITIAL_PAGE) => {
  const { name, email } = params;
  dispatch({
    type: VALIDATING_USER,
    validatingUser: true
  })
  await get(urlUserExists, { email })
    .then(({ data }) => {
      if (data) {
        message(dispatch, 'userAlreadyExistingAndWasAddedSuccessfully');
        setOpen(false);
        getUsers(INITIAL_PAGE);
      } else {
        dispatch({
          type: VALIDATE_USER,
          userExists: { name, email }
        })
      }
      dispatch({
        type: VALIDATING_USER,
        validatingUser: false
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({
        type: VALIDATING_USER,
        validatingUser: false
      });
      message(dispatch, mssg);
    });
}

export const saveUserCompany = async(dispatch, values, user_id) => {
  let status = false;
  dispatch({
    type: LOADING_SAVE_USER_COMPANY,
    loadingCreateUserCompany: true
  });
  if (user_id) {
    await patch(urlEditUserCompany, { ...values, user_id })
      .then(({ data }) => {
        status = true;
        dispatch({
          type: LOADING_SAVE_USER_COMPANY,
          loadingCreateUserCompany: false
        });
      })
      .catch((error) => {
        const mssg = handleErrorMessage(error, dispatch);
        message(dispatch, mssg);
        dispatch({
          type: LOADING_SAVE_USER_COMPANY,
          loadingCreateUserCompany: false
        });
        status = false;
      });
  } else {
    await post(urlCreateUserCompany, { passenger: { ...values } })
      .then(({ data }) => {
        status = true;
        dispatch({
          type: LOADING_SAVE_USER_COMPANY,
          loadingCreateUserCompany: false
        });
      })
      .catch((error) => {
        const mssg = handleErrorMessage(error, dispatch);
        message(dispatch, mssg);
        dispatch({
          type: LOADING_SAVE_USER_COMPANY,
          loadingCreateUserCompany: false
        });
        status = false;
      });
  }
  return { status };
}

export const associateUserCompany = async(dispatch, id, values) => {
  let status = false;
  dispatch({
    type: LOADING_SAVE_USER_COMPANY,
    loadingCreateUserCompany: true
  });
  await patch(`${urlGroupsCompanies}/${id}/add_user_to_company`, { ...values })
    .then(({ data }) => {
      status = true;
      dispatch({
        type: LOADING_SAVE_USER_COMPANY,
        loadingCreateUserCompany: false
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: LOADING_SAVE_USER_COMPANY,
        loadingCreateUserCompany: false
      });
      status = false;
    });

  return { status };
}

export const removeUserCompany = async(dispatch, user_id) => {
  let status = false;
  await patch(urlRemoveUserCompany, { ...user_id })
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
}

export const cleanUser = dispatch => {
  dispatch({
    type: CLEAN_USER,
    userExists: null
  })
}

export const toggleUser = async(dispatch, user_id) => {
  let status = false;
  await patch(urlToggleActiveUser, { ...user_id })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
}

export const updateTermsAndConditions = async (dispatch, values) => {
  loading(dispatch, true);
  const { passengerId, ...payload } = values;
  await patch(urlUpdateTermsAndConditions(passengerId), { ...payload})
    .then(() => {
      dispatch({
        type: MESSAGE,
        message: 'app.settings.users.updateAcceptanceTermsAndConditions.success',
      });
      loading(dispatch, false);
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      loading(dispatch, false);
      message(dispatch, mssg);
    });
}