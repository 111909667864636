import React from 'react';
import { useContext } from 'react';
import { I18nContext } from 'translations';
import { FieldsContainer } from './style';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import {
  COMPANY_LEGAL_ENTITY_TYPES,
  COUNTRIES_IDS_BY_NAME,
} from 'utils/constants';
import { useStateValue } from 'context/store';

const CompanyGeneral = ({ control, errors }) => {
  const { translate } = useContext(I18nContext);

  const [
    {
      checkin: { company },
      companyRegister: { taxLiabilityOptions },
    },
  ] = useStateValue();

  const taxLiabilities = taxLiabilityOptions?.map(({ value, name }) => ({
    value,
    label: name,
  }));

  const companyLegalEntityTypes = Object.entries(
    COMPANY_LEGAL_ENTITY_TYPES
  ).map(([key, value]) => ({
    value: key,
    label: translate(value),
  }));

  return (
    <FieldsContainer item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            {translate('app.settings.companyRegister.generalInformation')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="name"
            render={({ onChange, value }) => (
              <TextField
                fullWidth
                onChange={onChange}
                value={value}
                label={translate('app.setting.companyRegister.name')}
                variant="outlined"
                size="small"
                error={Boolean(errors?.name)}
                helperText={errors.name && translate(errors.name.message)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="business_name"
            render={({ onChange, value }) => (
              <TextField
                fullWidth
                onChange={onChange}
                value={value}
                label={translate('app.setting.companyRegister.buisness_name')}
                variant="outlined"
                size="small"
                error={Boolean(errors?.business_name)}
                helperText={
                  errors.business_name &&
                  translate(errors.business_name.message)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="fiscal_number"
            render={({ onChange, value }) => (
              <TextField
                fullWidth
                onChange={onChange}
                value={value}
                label={translate('app.setting.companyRegister.fiscal_number')}
                variant="outlined"
                size="small"
                error={Boolean(errors?.fiscal_number)}
                helperText={
                  errors.fiscal_number &&
                  translate(errors.fiscal_number.message)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="email"
            render={({ onChange, value }) => (
              <TextField
                fullWidth
                onChange={onChange}
                value={value}
                label={translate('app.setting.companyRegister.email')}
                variant="outlined"
                size="small"
                error={Boolean(errors?.email)}
                helperText={errors.email && translate(errors.email.message)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="phone"
            render={({ onChange, value }) => (
              <TextField
                fullWidth
                onChange={onChange}
                value={value}
                label={translate('app.setting.companyRegister.phone')}
                variant="outlined"
                size="small"
                error={Boolean(errors?.phone)}
                helperText={errors?.phone && translate(errors.phone.message)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="website"
            render={({ onChange, value }) => (
              <TextField
                fullWidth
                onChange={onChange}
                value={value}
                label={translate('app.setting.companyRegister.website')}
                variant="outlined"
                size="small"
                error={Boolean(errors?.website)}
                helperText={errors.website && translate(errors.website.message)}
              />
            )}
          />
        </Grid>
        {company.geo_fence_id === COUNTRIES_IDS_BY_NAME.colombia._id && (
          <Grid item xs={12} md={6} lg={4}>
            <Controller
              control={control}
              name="tax_liability"
              render={({ onChange, value }) => (
                <FormControl
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={errors?.tax_liability}
                >
                  <InputLabel error={errors?.tax_liability}>
                    {translate(
                      'app.setting.companyRegister.fiscalResponsibility'
                    )}
                  </InputLabel>
                  <Select
                    fullWidth
                    onChange={onChange}
                    value={value}
                    label={translate(
                      'app.setting.companyRegister.fiscalResponsibility'
                    )}
                    variant="outlined"
                  >
                    <MenuItem value="" style={{ minHeight: '3rem' }} />
                    {taxLiabilities?.map((fiscalResponsibility) => (
                      <MenuItem
                        key={fiscalResponsibility.value}
                        value={translate(fiscalResponsibility.value)}
                      >
                        {translate(fiscalResponsibility.label)}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors?.tax_liability && (
                    <FormHelperText error>
                      {translate(errors?.tax_liability?.message)}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="entity_type"
            render={({ onChange, value }) => (
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                error={errors?.entity_type}
              >
                <InputLabel error={errors?.entity_type}>
                  {translate('app.setting.companyRegister.legalType')}
                </InputLabel>
                <Select
                  fullWidth
                  onChange={onChange}
                  value={value}
                  label={translate('app.setting.companyRegister.legalType')}
                  variant="outlined"
                  error={Boolean(errors?.entity_type)}
                >
                  <MenuItem
                    value=""
                    label={translate('nA')}
                    style={{ minHeight: '3rem' }}
                  />
                  {companyLegalEntityTypes?.map((legalType) => (
                    <MenuItem
                      key={legalType.value}
                      value={translate(legalType.value)}
                    >
                      {translate(legalType.label)}
                    </MenuItem>
                  ))}
                </Select>
                {errors?.entity_type && (
                  <FormHelperText error>
                    {translate(errors?.entity_type?.message)}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </FieldsContainer>
  );
};

export default CompanyGeneral;
