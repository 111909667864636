import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  content: {
    margin: '10px 20px 25px',
    textAlign: 'center'
  },
  logo: {
    width: '25px',
    marginRight: 10
  },
  buttons: {
    marginTop: 20
  },
  label: {
    marginBottom: '5px',
    fontWeight: 'bold'
  },
  amount: {
    minWidth: '270px'
  }
 
}));

export default styles;