import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Container, Paper, Typography, TextField, InputAdornment, Button, Snackbar } from '@material-ui/core';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { getTrackingCode } from 'context/tracking/actions';
import LayoutLanding from 'components/Landing/Layouts';
import Loading from 'components/Loading';
import { message as closeMessage } from 'context/commons/actions';
import withHelmet from 'utils/withHelmet';
import styles from './style';
import schema from './schema';

const TrackingCode = ({ classes, history }) => {
  const [{ commons: { loading, message } }, dispatch] = useStateValue();
  const { translate } = useContext(I18nContext);

  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const submit = async values => getTrackingCode(dispatch, values, history);

  return (
    <LayoutLanding>
      <div className={classes.banner}>
        <Container maxWidth="sm">
          <Paper elevation={4} variant="outlined" style={{ position: 'relative', zIndex: 1 }} className={classes.wrapper}>
            <Typography variant="h4" color="textPrimary">{translate('tracking.trackYourOrder')}</Typography>
            <br />
            <Typography variant="body1" color="textSecondary">
              {translate('tracking.pleaseEnterTheCodeOfYourGuide')}
            </Typography>
            <br /><br /><br />
            {loading && <Loading />}
            <form onSubmit={handleSubmit(submit)} noValidate>
              <TextField
                autoFocus
                name="id"
                error={!!errors.id}
                label={translate('tracking.trackingCode')}
                helperText={errors.id && translate(errors.id.message)}
                type="text"
                inputRef={register}
                variant="outlined"
                fullWidth
                required
                className={classes.marginBottomField}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><LocalShippingIcon className={classes.icon} /></InputAdornment>,
                }}
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                size="large"
                className="btn-rounded"
              > 
                {translate('tracking.search')}
              </Button>
            </form>
            <br />
          </Paper>
          <Snackbar
            open={!!message}
            autoHideDuration={6000}
            onClose={() => closeMessage(dispatch, null)}
            message={translate(message)}
          />
        </Container>
      </div>
    </LayoutLanding>
  )
}

TrackingCode.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withHelmet({
  title: 'Rastrear Envíos | Pibox',
  description: 'Rastrea tu envío o pedido con nuestro Tracking Code de Pibox en tiempo real, aquí. Consulta ruta y tiempo de llegada.',
  linkCanonical: 'https://pibox.app/tracking-code',
  image: 'https://pibox.app/images/landing/express.png'
})(TrackingCode));