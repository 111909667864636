import React, { useContext, useEffect } from 'react';
import { Container, Typography, Grid, Snackbar } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { updatePasswordUser, updateProfileUser, cleanUser } from 'context/settings/profile/actions';
import SignupForm from 'components/Forms/Signup/SignupForm';
import ChangePassword from 'components/Forms/ChangePassword';
import Loading from 'components/Loading';

export default function MyAccount() {
  const { translate } = useContext(I18nContext);
  const [{ checkin: { checkin }, profile: { loadingPassword, loadingProfile }, commons: { message } }, dispatch] = useStateValue();

  const onSubmit = async (values) => {
    const { _id } = checkin.profile;
    await updateProfileUser(dispatch, _id, values);
  }

  const onSubmitPassword = async (values) => {
    const { password } = values;
    await updatePasswordUser(dispatch, password);
  }

  useEffect(() => {
    return () => cleanUser(dispatch);
  }, [dispatch])
  
  return (
    <>
      <Typography variant="h5" color="primary">
        {translate('app.settings.myAccount')}
      </Typography>
      <br />
      <Container maxWidth="md" className="padding0-xs">
        <Grid container alignItems="center" justify="center" spacing={3}>
          <Grid item sm={12} md={6} xl={6} className="text-center">
            {checkin &&
              <>
                {loadingProfile && <Loading />}
                <SignupForm
                  submit={onSubmit}
                  profile={checkin.profile}
                  company={checkin.company}
                />
                <ChangePassword
                  submit={onSubmitPassword}
                  profile={checkin.profile}
                  loadingPassword={loadingPassword}
                />
              </>
            }
          </Grid>
        </Grid>
        <Snackbar
          style={{ zIndex: 99999 }}
          open={!!message}
          autoHideDuration={6000}
          onClose={() => closeMessage(dispatch, null)}
          message={translate(message)}
        />
      </Container>
    </>
  )
}