import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { activationWoocommerce } from 'context/integrations/actions';
import { useStateValue } from 'context/store';
import { I18nContext } from 'translations';
import { message as closeMessage } from 'context/commons/actions';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Snackbar,
} from '@material-ui/core';
import schemaWooCommerce from './schemaWooCommerce';
import { modalMessage, removeEmptyFilters } from 'utils';
import FormActivationWooCommerce from './FormActivationWooCommerce';

export default function ModalWooCommerceForm({
  openWooCommerce,
  setOpenWooCommerce,
}) {
  const [cityId, setCityId] = useState(null);
  const [cityData, setCityData] = useState(null);
  const { translate } = useContext(I18nContext);
  const [
    {
      commons: { message },
    },
    dispatch,
  ] = useStateValue();
  const [loading, setLoading] = useState(false);

  const { handleSubmit, register, errors, control, clearErrors } = useForm({
    resolver: yupResolver(schemaWooCommerce),
  });

  const messageOk = () => {
    setLoading(false);
    modalMessage(
      '',
      translate('app.integrations.storeAddSuccessfully'),
      'success',
      translate('accept'),
      true
    ).then(() => {
      window.open('/app/bookings/sales', '_self');
    });
  };

  const submit = async (values) => {
    const data = {
      main_city: cityData?.name,
      main_address: values.address,
      ...values
    }
    setLoading(true);
    removeEmptyFilters(values);
    const response = await activationWoocommerce(dispatch, data);

    response.status ? messageOk() : setLoading(false);
  };

  return (
    <Dialog
      open={openWooCommerce}
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle className="text-center" disableTypography>
        <Typography variant="h5" color="primary">
          {translate('app.integrations.integrationWithWooCommerce')}
        </Typography>
      </DialogTitle>
      <DialogContent className="text-center" style={{ margin: '10px 0' }}>
        <form onSubmit={handleSubmit(submit)} noValidate>
          <FormActivationWooCommerce
            submit={submit}
            loading={loading}
            errors={errors}
            register={register}
            setCityData={setCityData}
            setCityId={setCityId}
            cityId={cityId}
            control={control}
            clearErrors={clearErrors}
          />
        </form>
        <Snackbar
          style={{ zIndex: 99999 }}
          open={!!message}
          autoHideDuration={6000}
          onClose={() => closeMessage(dispatch, null)}
          message={translate(message)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={() => {
            setOpenWooCommerce(false);
            setCityData(null);
            setCityId(null);
          }}
        >
          {translate('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
