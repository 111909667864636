import React, { useContext, useState } from 'react';
import { Button } from '@material-ui/core';
import { I18nContext } from 'translations';
import { INITIAL_PAGE } from 'utils/constants';
import { disableAccount } from 'context/integrations/actions';
import { useStateValue } from 'context/store';
import { modalMessage } from 'utils';

const MercadoFlexDisable = ({ account: { _id }, getIntegrations }) => {
  const { translate } = useContext(I18nContext);
  const [, dispatch] = useStateValue();
  const [loading, setLoading] = useState(false);

  const messageOk = () => {
    setLoading(false);
    modalMessage(
      '',
      translate('app.integrations.storeRemovedSuccessfully.mercadoFlex'),
      'success',
      translate('accept'),
      true
    ).then(() => {
      getIntegrations(INITIAL_PAGE);
    });
  };

  const disableIntegrationMercadoFlex = async (id) => {
    modalMessage(
      '',
      translate('app.integrations.messageConfirmRemoveStore'),
      'warning',
      'Continue',
      true,
      true,
      translate('cancel')
    ).then(async (response) => {
      if (response.isConfirmed) {
        setLoading(true);
        const callback = await disableAccount(dispatch, id);
        callback && callback.status ? messageOk() : setLoading(false);
      }
    });
  };

  return (
    <Button
      color="secondary"
      size="small"
      disabled={loading}
      onClick={() => disableIntegrationMercadoFlex(_id)}
    >
      {translate('app.integrations.table.disable')}
    </Button>
  );
};

export default MercadoFlexDisable;
