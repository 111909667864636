import { get, post, patch } from 'api/requests';
import { urlCompanyBalances } from 'api';
import { message } from '../commons/actions';
import handleErrorMessage from 'utils/handleErrorMessage';
import { LIST_BALANCES, LIST_BALANCES_LOADING, LIST_TRANSACTIONS_BALANCES, LIST_TRANSACTIONS_BALANCES_LOADING } from './constants';
import { COUNTRY_CODE } from 'utils/constants';
import { formatPrice  } from 'utils';

export const listBalances = async (dispatch, companyId, params) => {
  dispatch({
    type: LIST_BALANCES_LOADING,
    loadingBalances: true,
  });
  await get(`${urlCompanyBalances}/${companyId}/corporative_balances`, { ...params })
    .then(({ data }) => {
      dispatch({
        type: LIST_BALANCES,
        list: data,
      });
      dispatch({
        type: LIST_BALANCES_LOADING,
        loadingBalances: false,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: LIST_BALANCES_LOADING,
        loadingBalances: false,
      });
    });
}

export const listPassengers = async (dispatch, companyId, params) => {
  dispatch({
    type: LIST_BALANCES_LOADING,
    loadingBalances: true,
  });
  await get(`${urlCompanyBalances}/${companyId}/corporative_balances/passengers`, { ...params })
    .then(({ data }) => {
      dispatch({
        type: LIST_BALANCES,
        list: data,
      });
      dispatch({
        type: LIST_BALANCES_LOADING,
        loadingBalances: false,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: LIST_BALANCES_LOADING,
        loadingBalances: false,
      });
    });
}

export const createAssingBalance = async (dispatch, values, companyId, lang) => {
  const amount = [
    {
      sub_units: formatPrice({
        sub_units: values?.amount,
        iso: COUNTRY_CODE[lang][4],
      }),
      currency: COUNTRY_CODE[lang][4],
      type: 0
    },
    values?.amount_pibox && {
      sub_units: formatPrice({
        sub_units: values?.amount_pibox,
        iso: COUNTRY_CODE[lang][4],
      }),
      currency: COUNTRY_CODE[lang][4],
      type: 1
    },
    values?.amount_picap && {
      sub_units: formatPrice({
        sub_units: values?.amount_picap,
        iso: COUNTRY_CODE[lang][4],
      }),
      currency: COUNTRY_CODE[lang][4],
      type: 2
    }
  ]
  const amountFinal = amount?.filter(amnt => amnt)

  const data = {
    ini_date: values.ini_date,
    end_date: values.end_date,
    passengers: values.passengers,
    cost_center_id: values.cost_center_id,
    amount: amountFinal
  }

  let status = false;
  await post(`${urlCompanyBalances}/${companyId}/corporative_balances/assign`, { corporative_balances: data })
    .then((json) => {
      return json.data;
    })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status }
}

export const withdrawalBalance = async (dispatch, values, companyId, lang) => {

  const amount = [
    {
      sub_units: formatPrice({
        sub_units: values?.amount,
        iso: COUNTRY_CODE[lang][4],
      }),
      currency: COUNTRY_CODE[lang][4],
      type: values.type_cd
    }
  ]

  const data = {
    passengers: values.passengers,
    amount: amount
  }

  let status = false;
  await patch(`${urlCompanyBalances}/${companyId}/corporative_balances/withdrawal`, { corporative_balances: data })
    .then((json) => {
      return json.data;
    })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status }
}

export const getBalances = async (dispatch, companyId, params) => {
  dispatch({
    type: LIST_BALANCES_LOADING,
    loadingBalances: true,
  });
  await get(`${urlCompanyBalances}/${companyId}/corporative_balances/balances`, { ...params })
    .then(({ data }) => {
      dispatch({
        type: LIST_BALANCES,
        list: data,
      });
      dispatch({
        type: LIST_BALANCES_LOADING,
        loadingBalances: false,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: LIST_BALANCES_LOADING,
        loadingBalances: false,
      });
    });
}

export const setStateBalance = async (dispatch, values, companyId) => {
  let loading = true;
  let status = false;
  await patch(`${urlCompanyBalances}/${companyId}/corporative_balances/activate`, { corporative_balances: values })
    .then((json) => {
      return json.data;
    })
    .then(() => {
      status = true;
      loading = false;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
      loading = false;
    });
  return { status, loading }
}

export const getTransactionBalance = async (dispatch, companyId, params) => {
  dispatch({
    type: LIST_TRANSACTIONS_BALANCES_LOADING,
    loadingTransactions: true,
  });
  await get(`${urlCompanyBalances}/${companyId}/corporative_balances/transactions`, { ...params })
    .then(({ data }) => {
      dispatch({
        type: LIST_TRANSACTIONS_BALANCES,
        listTransactions: data,
      });
      dispatch({
        type: LIST_TRANSACTIONS_BALANCES_LOADING,
        loadingTransactions: false,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: LIST_TRANSACTIONS_BALANCES_LOADING,
        loadingTransactions: false,
      });
    });
}