export const LIST_PICASH = 'LIST_PICASH';
export const LIST_PICASH_LOADING = 'LIST_PICASH_LOADING';
export const GET_BANK_ACCOUNTS = 'GET_BANK_ACCOUNTS';
export const BANK_ACCOUNTS_LOADING = 'BANK_ACCOUNTS_LOADING';
export const BANK_LIST = 'BANK_LIST';
export const ACCOUNT_TYPE_LIST = 'ACCOUNT_TYPE_LIST';
export const DOCUMENT_TYPE_LIST = 'DOCUMENT_TYPE_LIST';
export const GET_BANK_ACCOUNTS_AVAILABLE = 'GET_BANK_ACCOUNTS_AVAILABLE';
export const RECHARGE_METHODS_LIST = 'RECHARGE_METHODS_LIST';
export const PSE_PARAMS = 'PSE_PARAMS';
export const PSE_PARAMS_LOADING = 'PSE_PARAMS_LOADING';
export const CREDIT_CARDS_LOADING = 'CREDIT_CARDS_LOADING';
export const GET_CREDIT_CARDS = 'GET_CREDIT_CARDS';
export const LIST_BILLS = 'LIST_BILLS';
export const PAYMENT_DATA = 'PAYMENT_DATA';
export const LIST_BILL_LOADING = 'LIST_BILL_LOADING';
export const SHOW_BILL = 'SHOW_BILL';
export const BILL_LOADING = 'BILL_LOADING';
export const SELECTED_PAYMENT_METHOD = 'SELECTED_PAYMENT_METHOD';
