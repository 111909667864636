import React, { useEffect, useContext } from 'react';
import { Container, Typography } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { confirmShopify, closeMessage } from 'context/integrations/actions';
import Loading from 'components/Loading';
import Error from 'components/Error';

export default function Activation ({ location, history }) {
  const [{ integrations: { message, loading } }, dispatch] = useStateValue();
  const { translate } = useContext(I18nContext);

  const query = new URLSearchParams(location.search);

  useEffect(() => {
    confirmShopify(dispatch, query, history);
    return () => closeMessage(dispatch, null);
  }, []); // eslint-disable-line

  return (
    <Container maxWidth="md" className="padding-page-full-height text-center">
      {loading && <Loading />}
      <Typography variant="h5">
        {message ? <Error message={message} /> : translate('processing')}
      </Typography>
    </Container>
  )
}