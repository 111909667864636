import React, { useContext } from 'react';
import { Button, Grid, FormControl } from '@material-ui/core';
import { I18nContext } from 'translations';
import AutocompleteFromList from 'components/CustomInputs/AutocompleteFromList';

export default function PackagesTableGroupsFilters({
	user_id,
	setUser_id,
	myPackages,
	listByGroups,
	setInputSearchSelected
}) {
	const { translate } = useContext(I18nContext);
	return (
		<div style={{ margin: '7px 0 -18px' }}>
			<Button
				color={user_id ? 'default' : 'primary'}
				size="medium"
				style={{
					borderBottom: '2px solid',
					borderRadius: 0,
					marginRight: 15,
					borderColor: user_id ? 'gray' : '#7825bd'
				}}
				onClick={() => setUser_id(null)}
			>
				{translate('app.bookings.filter.allPackages')}
			</Button>
			<Button
				color={user_id ? 'primary' : 'default'}
				size="medium"
				style={{ 
					borderBottom: '2px solid',
					borderRadius: 0,
					borderColor: user_id ? '#7825bd' : 'gray'
				}}
				onClick={myPackages}
			>
				{translate('app.bookings.filter.myPackages')}
			</Button>
			{!user_id && listByGroups && listByGroups.length > 0 && (
				<Grid container>
					<Grid item md={12} className="text-right">
						<FormControl style={{ width: 300 }} className="text-left">
							<AutocompleteFromList
								data={listByGroups}
								label="app.bookings.filter.selectAGroup"
								setValue={setInputSearchSelected}
							/>
						</FormControl>
					</Grid>
				</Grid>
			)}
		</div>
	)
}