import React, { useContext, useEffect } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { I18nContext } from 'translations';
import { COUNTRY_CODE, COUNTRY_LANGUAJE } from 'utils/constants';

const Language = ({ lang, country, company }) => {
  const { langCode , dispatch } = useContext(I18nContext);
  const lg = lang && lang !== langCode ? lang : langCode;
  const countryLang = country ? COUNTRY_LANGUAJE[country] : langCode;

  useEffect(() => {
    lang ? onLenguageChange(lg) : countryLang && onLenguageChange(countryLang)
  }, [lang]) // eslint-disable-line

  const onLenguageChange = code => {
    dispatch({ type: 'SET_LANGUAGE', payload: code });
    localStorage.setItem('lang', code);
  }

  const onSelectCountry = countryCode => {
    switch (countryCode) {
      case 'CO':
        return onLenguageChange('es');
      // case 'BR':
        // return onLenguageChange('pt');
      case 'MX':
        return onLenguageChange('es_mx');
      // case 'PE':
        // return onLenguageChange('es_pe');
      case 'GT':
        return onLenguageChange('es_gt');
      case 'PY':
        return onLenguageChange('es_pyg');
      default:
        return onLenguageChange('es');
    }
  }

  return (
    <ReactFlagsSelect
      countries={["CO", "MX", "GT", "PY"]}
      defaultCountry={country ? COUNTRY_CODE[countryLang][1] : COUNTRY_CODE[lg][1]}
      showSelectedLabel={false}
      onSelect={onSelectCountry}
      disabled={company && true}
      className={`menu-flags ${company && 'disabled-flags'}`}
    />
  );
};

export default Language;
