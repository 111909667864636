import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { useStateValue } from 'context/store';
import { I18nContext } from 'translations';
import {
  checkSession,
  deleteSession,
  fetchQR,
  startSession,
} from 'context/settings/whatsapp/actions';
import QrComponent from './QrComponent';
import { message as closeMessage } from 'context/commons/actions';

const Whatsapp = () => {
  const { translate } = useContext(I18nContext);
  const [timeOutId, settimeOutId] = useState(null);
  const [mssgSession, setMssgSession] = useState(null);

  const [
    {
      checkin: { checkin },
      commons: { message },
      whatsapp: { session, isLoading, isFetching },
    },
    dispatch,
  ] = useStateValue();

  useEffect(() => {
    if (checkin?.company) {
      (async () => {
        const { status: isConnected, mssg } = await checkSession(
          dispatch,
          checkin?.company._id
        );
        mssg && setMssgSession(mssg);
        if (isConnected === false && !mssg) {
          startSession(dispatch, checkin?.company._id);
          getQrInInterval();
        }
      })();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (session?.status === 'CONNECTED' && timeOutId) {
      clearTimeout(timeOutId);
      settimeOutId(null);
    }
  }, [session]); // eslint-disable-line

  function getQrInInterval() {
    const newTimeOutId = setTimeout(async () => {
      await fetchQR(dispatch, checkin?.company._id);
      getQrInInterval();
    }, 60000);
    settimeOutId(newTimeOutId);
  }

  const disconnectWhatsapp = async () => {
    const isDisconnected = await deleteSession(dispatch, checkin?.company._id);
    if (isDisconnected) {
      startSession(dispatch, checkin?.company._id);
      getQrInInterval();
    }
  };

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: 'calc(100vh - 120px)' }}
      >
        {isLoading ? (
          <CircularProgress color="primary" />
        ) : session?.message === 'Connected' ||
          session?.status === 'CONNECTED' ? (
          <Grid
            container
            justify="center"
            direction="column"
            alignItems="center"
          >
            <img src="/images/whatsapp/whatsapp.gif" alt="animation-whatsapp" />
            <Typography variant="h4" gutterBottom>
              {translate('whatsapp.connected')}
            </Typography>
            <Typography variant="caption" gutterBottom>
              {translate('whatsapp.disconnect.message')}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              disabled={isLoading && isFetching}
              onClick={disconnectWhatsapp}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                translate('whatsapp.disconnect')
              )}
            </Button>
          </Grid>
        ) : mssgSession ? (
          <Typography variant="h5">{translate('whatsapp.notAddon')}</Typography>
        ) : (
          <QrComponent
            session={session}
            handleFetchQR={() => fetchQR(dispatch, checkin?.company._id)}
            isLoading={isLoading}
            isFetching={isFetching}
          />
        )}
      </Grid>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={message}
      />
    </>
  );
};

export default Whatsapp;
