import React, { useContext, useState } from 'react';
import {
  Grid,
  Dialog,
  Button,
  Select,
  Divider,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
  FormControl,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useStateValue } from 'context/store';
import { withdrawalBalance } from 'context/balances/actions';
import { I18nContext } from 'translations';
import Loading from 'components/Loading';
import { INITIAL_PAGE } from 'utils/constants';
import { modalMessage } from 'utils';
import schema from './schema'
import { BALANCE_CORPORATIVE_PROMOCODE_TYPES } from 'utils/constants';

function ModalWithdrawalBalance ({
  open,
  setOpen,
  codeInfo,
  setCodeInfo,
  setIsCheckAll,
  getlistPassengers
}) {
  const { langCode, translate } = useContext(I18nContext);
  const [loading, setLoading] = useState(false);
  const [{ checkin: { company } }, dispatch ] = useStateValue();

  const { handleSubmit, control, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const messageOk = () => {
    setLoading(false);
    modalMessage('', translate('balances.modal.withdrawal.actions.assignedOk'), 'success', translate('continue'), true).then(async() => {
      setOpen(false);
      getlistPassengers(INITIAL_PAGE);
      setCodeInfo([])
    })
  }

  const submit = async (values) => {
    setLoading(true);
    
    let passengers = codeInfo.map((pssg => pssg._id))
    const params = {
      passengers: passengers,
      ...values
    };

    const callback = await withdrawalBalance(dispatch, params, company._id, langCode);
    callback?.status ? messageOk() : setLoading(false)
  }

  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
      className="modal-assign-promo-code"
    >
      <DialogTitle className="text-center" disableTypography style={{ paddingTop: 30 }}>
        <Typography variant="h5" color="primary">
          {translate('balances.modal.withdrawal.form.title')}
        </Typography>
      </DialogTitle>
      <DialogContent className="content-modal">
        {loading && <Loading />}
        <form
          onSubmit={handleSubmit(submit)}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={2}>
            {codeInfo?.length === 1 &&
              <Grid container className="content-form-assign-code">
                <Grid item xs={12}>
                  <Typography variant="subtitle1">{codeInfo[0].name}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">{codeInfo[0].email}</Typography>
                </Grid>
              </Grid>
            }
              <Grid container className="content-form-assign-code">
                
                <Grid item sm={12} xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item md={6} sm={4} xs={6}>
                      <Controller
                        control={control}
                        name="amount"
                        label={translate('balances.modal.withdrawal.form.totalBalance')}
                        defaultValue=""
                        as={
                          <TextField
                            type="text"
                            variant="outlined"  
                            fullWidth
                            size="small"
                            error={!!errors.amount}
                            helperText={errors.amount && translate(errors.amount.message)}
                          />
                        }
                      />
                    </Grid>
                    <Grid item md={6} sm={4} xs={6}>
                      <FormControl
                        variant="outlined"
                        size="small"
                        fullWidth
                      >
                        <InputLabel>
                          {translate('balances.modal.withdrawal.form.type_cd')}
                        </InputLabel>
                        <Controller
                          control={control}
                          name="type_cd"
                          label={translate('balances.modal.withdrawal.form.type_cd')}
                          variant="outlined"
                          defaultValue=""
                          as={
                            <Select>
                              <MenuItem value="">
                                {translate('app.bookings.filter.choose')}
                              </MenuItem>
                              {Object.keys(BALANCE_CORPORATIVE_PROMOCODE_TYPES).map((key) => (
                                <MenuItem key={key} value={key}>
                                  {translate(BALANCE_CORPORATIVE_PROMOCODE_TYPES[key])}
                                </MenuItem>
                              ))}
                            </Select>
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Divider className="content-modal-form-divider" style={{ margin: '20px 0 25px' }} />
                </Grid>
              </Grid>
          </Grid>
          
          <Grid item sm={12} xs={12}>
            <Grid container spacing={2} alignItems="center">
            <Grid item sm={4} xs={4}></Grid>
              <Grid item sm={4} xs={4}>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {translate('balances.modal.withdrawal.form.button')}
                  </Button>
                  <Button
                    onClick={() => {
                      setOpen(false)
                      setCodeInfo([])
                      setIsCheckAll(false)
                    }}
                  >
                    {translate('close')}
                  </Button>
                </DialogActions>
              </Grid>
              <Grid item sm={4} xs={4}></Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default ModalWithdrawalBalance;