import React, { useEffect, useContext, useState } from 'react';
import {
  Grid,
  Paper,
  Select,
  Button,
  MenuItem,
  Snackbar,
  TextField,
  InputLabel,
  Typography,
  FormControl,
} from '@material-ui/core';
import LocalAtmSharpIcon from '@material-ui/icons/LocalAtmSharp';
import { yupResolver } from '@hookform/resolvers';
import { useForm, Controller } from 'react-hook-form';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { sendToPay } from 'context/payments/actions';
import { message as closeMessage } from 'context/commons/actions';
import { getPaymentData, getShowBill } from 'context/payments/actions';
import { LEGAL_CUSTOMER_TYPE, DOCUMENT_TYPE } from 'utils/constants';
import TitleWithDowload from 'components/Titles/TitleWithDowload';
import { formatCost } from 'utils';
import Loading from 'components/Loading';
import schema from './schemaPayBill';

export default function PayBill({ match, history }) {
  const [loadingForm, setLoadingForm] = useState(false);
  const { translate } = useContext(I18nContext);
  const { params: { id } } = match;
  const { handleSubmit, control, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const [
    {
      payments: { paymentData, showBill, loadingBill, paymentId },
      checkin: { checkin },
      commons: { message }
    },
    dispatch,
  ] = useStateValue();

  const submit = async values => {
    setLoadingForm(true);
    const callback = await sendToPay(dispatch, id, { electronic_bill_payment: { ...values, payment_method_id: paymentId } })
    if (callback.urlPay) {
      setLoadingForm(false);
      window.open(callback.urlPay, '_blank');
      history.push('/app/payments/pipay');
    } else {
      setLoadingForm(false)
    }
  }

  useEffect(() => {
    getPaymentData(dispatch, {});
  }, []); // eslint-disable-line

  useEffect(() => {
    id && getShowBill(dispatch, id)
  }, [id]); // eslint-disable-line

  if (loadingBill) return <Loading />

  if (!paymentId) {
    return <div className="padding-page-full-height text-center">
      <Typography variant="h5" style={{ margin: '40px 0 12px' }}>
        {translate('bill.pleaseSelectAPaymentMethod')}
      </Typography>
      <Button
        href={id ? `/app/payments/payment-method/${id}` : '/app/payments/pipay'}
        variant="contained"
        color="primary"
      >
        {translate('tryAgain')}
      </Button>
    </div>
  }

  return (
    <div style={{ background: '#fafafa' }}>
      <div className="padding-page-full-height">
        <TitleWithDowload
          title={translate('bill.billPayment')}
          icon={
            <LocalAtmSharpIcon
              className="align-center"
              style={{ marginRight: 7 }}
            />
          }
        />
        <br />
        <form onSubmit={handleSubmit(submit)} noValidate>
          {loadingForm && <Loading />}
          <Grid container spacing={3}>
            <Grid item md={5} sm={6} xs={12}>
              <Paper style={{ padding: '40px 35px', borderRadius: 15, height: '100%' }}>
                <Typography color="primary" variant="body1" style={{ marginBottom: 20 }}>
                  <b>{translate('bill.collectionInformation')}:</b>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Typography variant="body1">{translate('bill.commerceName')}:</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant="body2">{showBill?.company_name}</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant="body1">{translate('bill.id')}:</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant="body2">{showBill?.nit}</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant="body1">{translate('bill.referenceN')}:</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant="body2"color="primary">{showBill?.code}</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant="body1">{translate('bill.description')}:</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant="body2">
                      {showBill?.start_date} {translate('bill.to')} {showBill?.end_date}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant="body1">{translate('bill.valueToCollect')}:</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant="body2">{formatCost(showBill?.amount)}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={7} sm={6} xs={12}>
              <Paper style={{ padding: '40px 35px', borderRadius: 15, height: '100%' }}>
                <div style={{ marginBottom: 30 }}>
                  <Typography color="primary" variant="body1">
                    <b>{translate('bill.customerInformation')}:</b>
                  </Typography>
                </div>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      size="small"
                    >
                      <InputLabel>{translate('bill.customerType')}</InputLabel>
                      <Controller
                        control={control}
                        name="person_type"
                        label={translate('bill.customerType')}
                        variant="outlined"
                        defaultValue=""
                        error={!!errors.person_type}
                        as={
                          <Select>
                            {Object.entries(LEGAL_CUSTOMER_TYPE).map((type) => {
                              return (
                                <MenuItem key={type[0]} value={type[0]}>
                                  {translate(type[1])}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        }
                      />
                      <span className="error">{errors.person_type && translate(errors.person_type.message)}</span>
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      size="small"
                    >
                      <InputLabel>{translate('bill.idType')}</InputLabel>
                      <Controller
                        control={control}
                        name="id_type"
                        label={translate('bill.idType')}
                        variant="outlined"
                        defaultValue=""
                        error={!!errors.id_type}
                        as={
                          <Select>
                            {Object.entries(DOCUMENT_TYPE).map((type) => {
                              return (
                                <MenuItem key={type[0]} value={type[0]}>
                                  {translate(type[1])}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        }
                      />
                      <span className="error">{errors.id_type && translate(errors.id_type.message)}</span>
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <Controller
                      name="person_id"
                      control={control}
                      variant="outlined"
                      defaultValue={showBill?.nit ? showBill.nit : ''}
                      render={({ onChange, value }) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          label={translate('bill.documentNumber')}
                          fullWidth
                          value={value}
                          onChange={onChange}
                          error={!!errors.person_id}
                          helperText={errors.person_id && translate(errors.person_id.message)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Controller
                      name="phone_number"
                      control={control}
                      variant="outlined"
                      defaultValue={checkin?.profile?.phone ? checkin?.profile?.phone : ''}
                      render={({ onChange, value }) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          label={translate('bill.phone')}
                          fullWidth
                          value={value}
                          onChange={onChange}
                          error={!!errors.phone_number}
                          helperText={errors.phone_number && translate(errors.phone_number.message)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Controller
                      name="company_name"
                      control={control}
                      variant="outlined"
                      defaultValue={showBill?.company_name ? showBill.company_name : ''}
                      render={({ onChange, value }) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          label={translate('bill.companyName')}
                          fullWidth
                          value={value}
                          onChange={onChange}
                          error={!!errors.company_name}
                          helperText={errors.company_name && translate(errors.company_name.message)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Controller
                      name="name"
                      control={control}
                      variant="outlined"
                      defaultValue={checkin?.profile?.name ? checkin?.profile?.name : ''}
                      render={({ onChange, value }) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          label={translate('bill.name')}
                          fullWidth
                          value={value}
                          onChange={onChange}
                          error={!!errors.name}
                          helperText={errors.name && translate(errors.name.message)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Controller
                      name="email"
                      control={control}
                      variant="outlined"
                      defaultValue={checkin?.profile?.email ? checkin?.profile?.email : ''}
                      render={({ onChange, value }) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          label={translate('bill.email')}
                          fullWidth
                          value={value}
                          onChange={onChange}
                          error={!!errors.email}
                          helperText={errors.email && translate(errors.email.message)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Controller
                      name="city_state"
                      control={control}
                      variant="outlined"
                      defaultValue=""
                      render={({ onChange, value }) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          label={translate('bill.department')}
                          fullWidth
                          value={value}
                          onChange={onChange}
                          error={!!errors.city_state}
                          helperText={errors.city_state && translate(errors.city_state.message)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Controller
                      name="city"
                      control={control}
                      variant="outlined"
                      defaultValue=""
                      render={({ onChange, value }) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          label={translate('bill.city')}
                          fullWidth
                          value={value}
                          onChange={onChange}
                          error={!!errors.city}
                          helperText={errors.city && translate(errors.city.message)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      size="small"
                    >
                      <InputLabel>{translate('bill.financialEntity')}</InputLabel>
                      <Controller
                        control={control}
                        name="bank_code"
                        label={translate('bill.financialEntity')}
                        variant="outlined"
                        defaultValue=""
                        as={
                          <Select>
                            {paymentData?.banks.map((bank) => {
                              return (
                                <MenuItem key={bank.financial_institution_code} value={bank.financial_institution_code}>
                                  {bank.financial_institution_name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        }
                      />
                      <span className="error">{errors.bank_code && translate(errors.bank_code.message)}</span>
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <Controller
                      name="address"
                      control={control}
                      variant="outlined"
                      defaultValue=""
                      render={({ onChange, value }) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          label={translate('bill.billingAddress')}
                          fullWidth
                          value={value}
                          onChange={onChange}
                          error={!!errors.address}
                          helperText={errors.address && translate(errors.address.message)}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <div className="text-right" style={{ marginTop: 25 }}>
            <Button
              href="/app/payments/pipay"
              variant="outlined"
              color="primary"
              className="btn-rounded"
              style={{ marginRight: 10 }}
            >
              {translate('cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="btn-rounded"
              type="submit"
            >
              {translate('continue')}
            </Button>
          </div>
        </form>
      </div>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </div>
  );
}
