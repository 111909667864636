import React from 'react'
import { Typography } from '@material-ui/core'
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
);

export default function BarGraph ({ title, textBottom, bookings, names }) {

  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
      },
      title: {
        display: true,
        text: textBottom,
        position: 'bottom'
      },
      datalabels: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false      
        }
      },
      y: {
        grid: {
          display: false      
        },
        ticks: {
          font: {
              size: 13,
              family:'montserratmedium'
          }
        },
      },
    }
  };
  
  const labels = names
  
  const data = {
    labels,
    datasets: [
      {
        data: bookings,
        borderColor: '#7825bd',
        backgroundColor: '#9351ca',
        hoverBackgroundColor: "#cbc2fa",
        borderRadius: 2
      },
    ],
  };

  return (
    <>
      <Typography variant="h3" style={{ marginBottom: 40, fontSize: '1.2rem' }}>
        <b>{title}</b>
      </Typography>
      <Bar
        options={options}
        data={data}
      />
    </>
  )
}