import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer
} from '@material-ui/core';
import { I18nContext } from 'translations';
import EmptyData from '../EmptyData';
import styles from '../style';
import RowBookingTable from './RowBookingsTable';

const BookingsTable = ({
  classes,
  data,
  role,
  recordsPerPage,
  getBookings,
  user_id,
  company,
  isRent,
  openClaim
}) => {
  const [currentPage, setCurrentPage] = useState(data.page);
  const { translate } = useContext(I18nContext)

  useEffect(() => {
    setCurrentPage(data.page);
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    getBookings(newPage);
  };

  if (!data || (data && data.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />;
  }

  return (
    <>
      <TableContainer>
        <Table className={classes.table} size="small" stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{translate('app.bookings.table.state')}</TableCell>
              <TableCell>{translate('app.bookings.table.date')}</TableCell>
              <TableCell>
                {translate('app.bookings.table.originAddress')}
              </TableCell>
              {!isRent && 
                <>
                  <TableCell>{translate('app.bookings.table.scheduled')}</TableCell>
                  <TableCell>{translate('app.bookings.table.nOfStops')}</TableCell>
                </>
              }
              <TableCell>{translate('app.bookings.vehicle')}</TableCell>
              {!isRent && 
                <>
                  <TableCell>{translate('app.bookings.table.collectedValue')}</TableCell>
                  <TableCell>{translate('app.bookings.table.collectedValueFee')}</TableCell>
                </>
              }
              <TableCell>
                {translate('app.bookings.table.paymentMethod')}
              </TableCell>
              <TableCell>{translate('app.bookings.table.wallet')}</TableCell>
              <TableCell>{translate('app.bookings.table.price')}</TableCell>
              <TableCell>{translate('app.bookings.table.id')}</TableCell>
              {!user_id && company && (
                <TableCell>{translate('app.bookings.table.venue')}</TableCell>
              )}
              {company && (
                <TableCell>
                  {translate('app.bookings.table.costCenter')}
                </TableCell>
              )}
              <TableCell>{translate('app.bookings.table.applicant')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data.data || []).map((booking, index) => (
              <RowBookingTable
                booking={booking}
                key={booking._id + booking.created_at}
                index={index}
                isRent={isRent}
                company={company}
                user_id={user_id}
                classes={classes}
                getBookings={getBookings}
                openClaim={openClaim}
                role={role}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.total ? data.total : data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
    </>
  );
};

function areEqual(prevProps, nextProps) {
  return prevProps.data === nextProps.data;
}

export default withStyles(styles)(React.memo(BookingsTable, areEqual));