import { LIST_UNQULIFIED_BOOKINGS } from './constants';

const unqulifiedBookingsReducer = (state, action) => {
  switch (action.type) {
    case LIST_UNQULIFIED_BOOKINGS: 
      return {
        ...state,
        list: action.unqulifiedBookings
      }
    default:
      return state
  }
}

export default unqulifiedBookingsReducer;