import { LOADING, MESSAGE, LOADING_ACTIVATION, LIST_INTEGRATIONS } from './constants';

const integrationReducer = (state, action) => {
  switch (action.type) {
    case LIST_INTEGRATIONS: {
      return {
        ...state,
        list: action.list
      }
    }
    case LOADING: {
      return {
        ...state,
        loading: action.loading
      }
    }
    case MESSAGE:
      return {
        ...state,
        message: action.message
      }
    case LOADING_ACTIVATION:
      return {
        ...state,
        loading: action.loading
      }
    default:
      return state
  }
}

export default integrationReducer;
