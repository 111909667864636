import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { I18nContext } from 'translations';
import TermsAndConditions from 'components/Forms/TermsAndConditions';
import { piboxAdminRolesCompany } from 'utils';
import { COUNTRY_CODE } from 'utils/constants';

const ModalTermsAndConditions = ({ checkin }) => {
  const { translate, langCode } = useContext(I18nContext);
  const [open, setOpen] = useState(false);
  const [termsStatus, setTermsStatus] = useState([]);
  

  const mapTermsAndConditions = useCallback(() => {
    const termsStatusList = {};
    
    if (checkin?.company) {
      const {
        has_accepted_corporate_tc,
        has_authorized_personal_data_treatment,
        has_authorized_credit_report_inquiry,
      } = checkin.company;
      termsStatusList['has_accepted_corporate_tc'] =
        has_accepted_corporate_tc;
      termsStatusList['has_authorized_personal_data_treatment'] =
        has_authorized_personal_data_treatment;
      termsStatusList['has_authorized_credit_report_inquiry'] =
        has_authorized_credit_report_inquiry;
    }
    setTermsStatus(termsStatusList);
  }, [checkin]);

  useEffect(() => {
    const roles = piboxAdminRolesCompany(checkin?.profile?.roles)
    roles.length > 0 && mapTermsAndConditions();
  }, [mapTermsAndConditions]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkTermsAcceptance = useCallback((termsAndConditions) => {
    let isTermsAccepted = true;
    Object.keys(termsAndConditions).forEach((key) => {
      if (!termsStatus[key]) {
        isTermsAccepted = false;
      }
    });
    return isTermsAccepted;
  },[termsStatus]);

  useEffect(() => {
    const isTermsAccepted = checkTermsAcceptance(termsStatus);

    setOpen(!isTermsAccepted && COUNTRY_CODE[langCode][1] === 'CO');
  }, [termsStatus, checkTermsAcceptance]); // eslint-disable-line react-hooks/exhaustive-deps

  

  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        className="text-center"
        disableTypography
        style={{ padding: '20px' }}
      >
        <Typography variant="h5" color="primary">
          {translate('app.settings.users.updateAcceptanceTermsAndConditions.title')}
        </Typography>
      </DialogTitle>

      <DialogContent className="text-center" style={{ margin: '8px 0 2px' }}>
        <DialogContentText>{translate('app.settings.users.updateAcceptanceTermsAndConditions.description')}</DialogContentText>
        <TermsAndConditions termsStatus={termsStatus}  companyId={checkin?.company?._id} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          >
          {translate('cancel')}
          </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalTermsAndConditions;
