import React, { useContext } from 'react';
import {
  Dialog,
  Snackbar,
  Typography,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import ModalConfirmReceiveCommodate from './ModalConfirmReceiveCommodate';
import styles from '../../StyleModal';

function ModalReceiveCommodate ({
  open,
  classes,
  openModal,
  companyId,
  getCommodate
}) {
  const { translate } = useContext(I18nContext);
  const [
    {
      commons: { message },
      commodateLoan: { fetchCommodate }
    },
    dispatch,
  ] = useStateValue();

  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
      className={classes.modalPibox}
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">
          {translate('commodate.receiveCommodate.receiveCommodateItems')}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <ModalConfirmReceiveCommodate
          data={fetchCommodate}
          openModal={openModal}
          companyId={companyId}
          commodateId={fetchCommodate?._id ?? ''}
          getCommodate={getCommodate}
        />
      </DialogContent>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </Dialog>
  )
}

export default withStyles(styles)(ModalReceiveCommodate);
