import React, { useContext } from 'react';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@material-ui/core';
import { I18nContext } from 'translations';
import RequestDriverTable from 'components/Tables/RequestDriver';

export default function ModalServiceDriver ({
  open,
  setOpen,
  listReservations,
  bookingReservationId,
  selectedPrincipalBooking
}) {
  const { translate } = useContext(I18nContext);

  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle className="text-center" disableTypography style={{ padding: '20px' }}>
        <Typography variant="h5" color="primary">
          {translate('app.bookingReserved.selectDriverForHours')}
        </Typography>
        <Typography variant="body2">
          {translate('app.bookingReserved.description')}
        </Typography>
      </DialogTitle>

      <DialogContent className="text-center" style={{ margin: '8px 0 2px' }}>
        
        <RequestDriverTable
          selectedPrincipalBooking={selectedPrincipalBooking}
          data={listReservations}
          bookingReservationId={bookingReservationId}
        />
        
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>
          {translate('close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}