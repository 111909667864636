import React, { useContext } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { I18nContext } from 'translations';
import { formatCost } from 'utils';

const EstimatedValues = ({ quote, loadingQuote, totalWeight, isRent }) => {
  const { translate } = useContext(I18nContext);

  if (loadingQuote){
    return (
      <div style={{ padding: '6px 0'}} className="text-center">
        <CircularProgress />
      </div>
    )
  }
  return (
    <Grid container justify="center" style={{ padding: '6px 0'}} className="text-center">
      <Grid item sm={isRent ? 4 : 3} xs={3}>
        <small>{translate('app.express.time')}</small>
        <p className="margin0">
          {quote && quote.quotation.estimated_duration ? quote.quotation.estimated_duration : ' - '}
        </p>
      </Grid>
      <Grid item sm={isRent ? 4 : 3} xs={3}>
        <small>{translate('app.express.distance')}</small>
        <p className="margin0">
          {quote && quote.quotation.estimated_distance ? quote.quotation.estimated_distance : ' - '}
        </p>
      </Grid>
      {!isRent &&
        <Grid item sm={2} className="hidden-xs">
          <small>{translate('app.express.weight')}</small>
          <p className="margin0">
            {totalWeight || 0} Gr
          </p>
        </Grid>
      }
      <Grid item sm={4} xs={6}>
        <small>{translate('app.express.value')}</small>
        <p className="margin0">
          <strong>{quote && quote.quotation.estimated_fare ? formatCost(quote.quotation.estimated_fare) : ' - '}</strong>
        </p>
      </Grid>
    </Grid>
  )
}

function areEqual(prevProps, nextProps) {
  return prevProps.quote === nextProps.quote
    && prevProps.loadingQuote === nextProps.loadingQuote
}

export default React.memo(EstimatedValues, areEqual);