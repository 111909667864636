import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  banner: {
    //marginTop: '68px',
    background: 'linear-gradient(125deg, rgb(212, 54, 201) 20%, rgb(120, 37, 189) 80%)',
    minHeight: '70vh',
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto',
      textAlign: 'center'
    }
  },
  img: {
    maxWidth: '330px',
    margin: '10px 0',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '230px'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '125px',
      marginTop: '20px',
    },
  }
}));
  
export default styles;