import React, { useContext, useState } from 'react';
import { Button, TextField, Grid, CircularProgress } from '@material-ui/core';
import { I18nContext } from 'translations';
import { isObjectEmpty } from 'utils';
import PasswordInput from 'components/CustomInputs/PasswordInput';
import AddressInputs from 'components/CustomInputs/AddressInputs';


export default function FormActivationWooCommerce({
  submit,
  loading,
  errors,
  register,
  control,
  clearErrors,
  setCityData,
  setCityId,
  cityId
}) {
  const { translate } = useContext(I18nContext);
  const [address, setAddress] = useState(null);

  const handleCity = (id) => setCityId(id);  
  const handleCityData = (city) => setCityData(city);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PasswordInput
            inputRef={register}
            name="customer_key"
            type="text"
            variant="outlined"
            fullWidth
            label={translate('app.woocommerce.settingWooCommerce.api_key')}
            size="small"
            error={!!errors.customer_key}
            helperText={
              errors.customer_key && translate(errors.customer_key.message)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordInput
            inputRef={register}
            name="customer_secret"
            type="text"
            variant="outlined"
            fullWidth
            label={translate('app.woocommerce.settingWooCommerce.api_secret')}
            size="small"
            error={!!errors.customer_secret}
            helperText={
              errors.customer_secret &&
              translate(errors.customer_secret.message)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            name="store_name"
            type="text"
            variant="outlined"
            fullWidth
            label={translate('app.woocommerce.settingWooCommerce.store_name')}
            size="small"
            error={!!errors.store_name}
            helperText={
              errors.store_name && translate(errors.store_name.message)
            }
          />
        </Grid>
        <Grid item xs={12}>
        <hr style={{ margin: '15px 0 20px', borderColor: '#7c7d7c17' }} />
          <AddressInputs
            register={register}
            address={address}
            setAddress={setAddress}
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            twoColumns={false}
            isAddress
            cityId={cityId}
            handleCity={handleCity}
            needCity={true}
            handleCityData={handleCityData}
          />
        </Grid>
        <Grid item container xs={12} justify="center">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!isObjectEmpty(errors) || loading}
            startIcon={loading ? <CircularProgress size={18} /> : null}
          >
            {loading
              ? `${translate('app.integrations.form.activatingButton')}...`
              : translate('save')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
