import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Link,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
} from '@material-ui/core';
import { I18nContext } from 'translations';
import { COMPANY_DOCUMENT_HISTORY_STATUS } from 'utils/constants';
import { processingDocumentsStatusesClass } from 'utils';
import styles from './style';
import { format } from 'date-fns';

const CompanyRegisterTable = ({ classes, data }) => {
  const { translate } = useContext(I18nContext);

  return (
    <>
      <TableContainer className={classes.container}>
        <Table className={classes.table} size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                {translate('app.setting.companyRegister.tableDocuments.status')}
              </TableCell>
              <TableCell>
                {translate(
                  'app.setting.companyRegister.tableDocuments.created_at'
                )}
              </TableCell>
              <TableCell>
                {translate(
                  'app.setting.companyRegister.tableDocuments.document'
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((doc) => (
              <TableRow hover key={doc._id}>
                <TableCell>
                  <span
                    className={processingDocumentsStatusesClass(doc.status_cd)}
                  >
                    {translate(COMPANY_DOCUMENT_HISTORY_STATUS[doc.status_cd])}
                  </span>
                </TableCell>
                <TableCell>
                  {format(new Date(doc.created_at), 'dd/MM/yyyy, hh:mm a')}
                </TableCell>
                <TableCell>
                  <Link
                    href={doc.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="primary"
                    underline="hover"
                  >
                    {translate(
                      'app.setting.companyRegister.tableDocuments.viewDocument'
                    )}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

function areEqual(prevProps, nextProps) {
  return prevProps.data === nextProps.data;
}

export default withStyles(styles)(React.memo(CompanyRegisterTable, areEqual));
