import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Container, Button } from '@material-ui/core';
import { I18nContext } from 'translations';
import styles from './style';

const BannerVtex = ({ classes }) => {
  const { translate } = useContext(I18nContext);

  return (
    <section className={classes.banner}>
      <Container maxWidth="md" className="container-width-lg">
        <Grid container justify="center" alignItems="center">
          <Grid item md={5}>
            <img src="/images/landing/vtex/logo-vtex.png" className={classes.img} alt="pibox" />
          </Grid>

          <Grid item md={7}>
            <Typography variant="h5" className="text-white" style={{ fontSize: '2.2rem' }}>
              {translate('landing.vtex.title')}
            </Typography>
            <br />
            <Typography variant="body1" className="text-white">
              {translate('landing.vtex.description')}
            </Typography>
            <br/><br />
            <Button variant="outlined" className="btn-white" size="large" href="/signup">
              {translate('landing.banner.start')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

export default withStyles(styles)(BannerVtex);