import React, { useContext, useState } from 'react';
import { activationVtex } from 'context/integrations/actions';
import { useStateValue } from 'context/store';
import { I18nContext } from 'translations';
import { message as closeMessage } from 'context/commons/actions';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Snackbar,
} from '@material-ui/core';
import { modalMessage } from 'utils';
import FormActivationVtex from './FormActivationVtex';

export default function ModalVtex({
  open,
  setOpen,
}) {
  const [cityId, setCityId] = useState(null);
  const [cityData, setCityData] = useState(null);
  const { translate } = useContext(I18nContext);
  const [
    {
      commons: { message }
    },
    dispatch,
  ] = useStateValue();
  const [loading, setLoading] = useState(false);

  const messageOk = () => {
    setLoading(false);
    modalMessage(
      '',
      translate('app.integrations.storeAddSuccessfully'),
      'success',
      translate('accept'),
      true
    ).then(() => {
      window.open('/app/bookings/sales', '_self');
    });
  };

  const submit = async (values) => {
    const data = {
      main_city: cityData?.name,
      main_address: values.address,
      ...values
    }
    setLoading(true);
    const response = await activationVtex(dispatch, data);
    response.status ? messageOk() : setLoading(false)
  };

  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle className="text-center" disableTypography>
        <Typography variant="h5" color="primary">
          {translate('app.integrations.integrationWithVtex')}
        </Typography>
      </DialogTitle>
      <DialogContent className="text-center" style={{ margin: '10px 0' }}>
        <FormActivationVtex
          submit={submit}
          loading={loading}
          setCityData={setCityData}
          setCityId={setCityId}
          cityId={cityId}
        />
        <Snackbar
          style={{ zIndex: 99999 }}
          open={!!message}
          autoHideDuration={6000}
          onClose={() => closeMessage(dispatch, null)}
          message={translate(message)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={() => {
            setOpen(false);
            setCityData(null);
            setCityId(null);
          }}
        >
          {translate('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
