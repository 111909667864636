import { object, number } from 'yup';

const schema = object().shape({
  order_synchronization_days: number()
    .integer('app.shopify.settingShopify.form.mustBeAnInteger')
    .typeError('app.shopify.settingShopify.form.mustBeNumber')
    .min(1, 'app.shopify.settingShopify.form.cannotBeLessThanOne')
    .max(60, 'app.shopify.settingShopify.form.cannotBeGreaterThanSixty')
});

export default schema;