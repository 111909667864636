import React, { useContext, useState } from 'react';
import { modalMessage } from 'utils';
import { disabledStoreShopify } from 'context/integrations/actions';
import { useStateValue } from 'context/store';
import { I18nContext } from 'translations';
import { INITIAL_PAGE } from 'utils/constants';
import { Button } from '@material-ui/core';

const ShopifyDisableStore = ({ account, getIntegrations }) => {
  const { _id, user_external_identificator } = account;
  const [, dispatch] = useStateValue();
  const { translate } = useContext(I18nContext);
  const [loadingShopify, setLoadingShopify] = useState(false);

  const messageOk = () => {
    setLoadingShopify(false);
    modalMessage(
      '',
      translate('app.integrations.storeRemovedSuccessfully'),
      'success',
      translate('accept'),
      true
    ).then(() => {
      getIntegrations(INITIAL_PAGE);
    });
  };
  const disableIntegrationShopify = async (storeName, id) => {
    modalMessage(
      '',
      translate('app.integrations.messageConfirmRemoveStore'),
      'warning',
      'Continue',
      true,
      true,
      translate('cancel')
    ).then(async (response) => {
      if (response.isConfirmed) {
        setLoadingShopify(true);
        const callback = await disabledStoreShopify(
          dispatch,
          storeName.replace(/\s+/g, ''),
          id
        );
        callback && callback.status ? messageOk() : setLoadingShopify(false);
      }
    });
  };

  return (
    <Button
      color="secondary"
      size="small"
      disabled={loadingShopify}
      onClick={() =>
        disableIntegrationShopify(user_external_identificator, _id)
      }
    >
      {translate('app.integrations.table.disable')}
    </Button>
  );
};

export default ShopifyDisableStore;
