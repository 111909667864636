import { get, post, patch } from 'api/requests';
import { urlCommodateLoan } from 'api';
import {
  MESSAGE,
  FETCH_DATA,
  GET_COMMODATE,
  GET_BORROWER_LIST,
  LIST_COMMODATE_LOAN
} from './constants';
import handleErrorMessage from 'utils/handleErrorMessage';
import { loading } from 'context/commons/actions';

export const listCommodateLoan = async (dispatch, id, params) => {
  let status = false;
  loading(dispatch, true);
  await get(`${urlCommodateLoan}/${id}/commodate_loans`, params)
    .then(({ data }) => {
      dispatch({
        type: LIST_COMMODATE_LOAN,
        list: data,
      });
      loading(dispatch, false);
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      loading(dispatch, false);
      status = false;
    });
  return { status };
};

export const createCommodateLoan = async (dispatch, id, values) => {
  let status = false;
  let driver = null;
  await post(`${urlCommodateLoan}/${id}/commodate_loans`, { ...values })
    .then(({ data }) => {
      status = true;
      driver = data?.commodate_loan?.driver?.name || null;
      console.log('net ', data)
      dispatch({
        type: FETCH_DATA,
        commodate: data?.commodate_loan
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      status = false;
      driver = null;
    });
  return { status, driver };
};

export const getCommodateLoan = async (dispatch, companyId, commodateId) => {
  await get(`${urlCommodateLoan}/${companyId}/commodate_loans/${commodateId}`, {})
    .then(({ data }) => {
      dispatch({
        type: GET_COMMODATE,
        fetchCommodate: data
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      loading(dispatch, false);
    });
};

export const updateCommodateLoan = async (dispatch, companyId, id, values) => {
  let status = false;
  await patch(`${urlCommodateLoan}/${companyId}/commodate_loans/${id}`, { ...values })
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      status = false;
    });
  return { status };
};

export const clearGetCommodateLoan = (dispatch) => {
  dispatch({
    type: GET_COMMODATE,
    fetchCommodate: null
  });
}

export const confirmReceivedCommodateLoan = async (dispatch, companyId, id, values) => {
  let status = false;
  await patch(`${urlCommodateLoan}/${companyId}/commodate_loans/${id}/lender_ack`, { ...values })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      status = false;
    });
  return { status };
};

export const getBorrowerList = async (dispatch, companyId) => {
  await get(`${urlCommodateLoan}/${companyId}/commodate_loans/borrower_list`, {})
    .then(({ data }) => {
      dispatch({
        type: GET_BORROWER_LIST,
        borrowerList: data.borrower_list
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      loading(dispatch, false);
    });
};

export const cancelCommodateLoan = async (dispatch, companyId, id) => {
  let status = false;
  await patch(`${urlCommodateLoan}/${companyId}/commodate_loans/${id}/cancel`, {})
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      status = false;
    });
  return { status };
};

export const notifyCommodateLoan = async (dispatch, companyId, id) => {
  let status = false;
  await patch(`${urlCommodateLoan}/${companyId}/commodate_loans/${id}/deliver_reminder`, {})
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      status = false;
    });
  return { status };
};