import React, { useContext } from 'react';
import {
  Button,
  Grid,
  FormControl,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { I18nContext } from 'translations';
import AutocompleteFromList from 'components/CustomInputs/AutocompleteFromList';
import ModalUsersCompany from 'components/Modals/ModalUsersCompany';

export default function BookingTableGroupsFilters({
  user_id,
  setUser_id,
  myServices,
  listByGroups,
  selectUser,
  openUsers,
  setOpenUsers,
  passenger,
  setInputSearchSelected,
  onFilters,
  filters,
  isRent
}) {
  const { translate } = useContext(I18nContext);

  return (
    <div style={{ margin: '7px 0 -18px' }}>
      <Button
        color={user_id ? 'default' : 'primary'}
        size="medium"
        style={{
          borderBottom: '2px solid',
          borderRadius: 0,
          marginRight: 15,
          borderColor: user_id ? 'gray' : '#7825bd',
        }}
        onClick={() => setUser_id(null)}
      >
        {translate('app.bookings.filter.allTheServices')}
      </Button>
      <Button
        color={user_id ? 'primary' : 'default'}
        size="medium"
        style={{
          borderBottom: '2px solid',
          borderRadius: 0,
          marginRight: 15,
          borderColor: user_id ? '#7825bd' : 'gray',
        }}
        onClick={myServices}
      >
        {translate('app.bookings.filter.myServices')}
      </Button>
      {!user_id && (
        <Button
          color={user_id ? 'primary' : 'default'}
          size="medium"
          style={{
            borderBottom: '2px solid',
            borderRadius: 0,
            borderColor: user_id ? '#7825bd' : 'gray',
          }}
          onClick={() => setOpenUsers(true)}
        >
          {passenger ? passenger : translate('app.bookings.filter.selectAUser')}
        </Button>
      )}

      {!user_id && listByGroups && listByGroups.length > 0 && !isRent && (
        <Grid container>
          <Grid item md={7} className="text-left">
            <FormControlLabel
              control={
                <Switch
                  checked={Boolean(filters?.payments_collected_by_company)}
                  onChange={() => {
                    onFilters({
                      ...filters,
                      payments_collected_by_company:
                        !filters.payments_collected_by_company,
                    });
                  }}
                  color="primary"
                />
              }
              label={translate('app.bookings.filter.collectedValueFee?')}
            />
          </Grid>
          <Grid item md={5} className="text-right">
            <FormControl style={{ width: 300 }} className="text-left">
              <AutocompleteFromList
                data={listByGroups}
                label="app.bookings.filter.selectAGroup"
                setValue={setInputSearchSelected}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
      <ModalUsersCompany
        open={openUsers}
        setOpen={setOpenUsers}
        selectUser={selectUser}
      />
    </div>
  );
}
