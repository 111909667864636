import React from 'react';
import { withRouter, Redirect} from 'react-router-dom';
import Menu from 'components/Landing/Layouts/Menu';
import Footer from 'components/Landing/Layouts/Footer';
import { RENT } from 'utils/constants';

const LayoutLanding = ({ children }) => {
  const isRent = process.env.REACT_APP_API_URL_BASE_IS_RENT === RENT;

  if (isRent) {
    return (
      <Redirect
        exact
        to='/login'
      />
    );
  }

  return (
    <div>
      <Menu />
      {children}
      <Footer />
    </div>
  );
}

export default withRouter(LayoutLanding);