import { string, object, date } from 'yup';

export const schema = object().shape({
  ini_date: date().typeError("requiredField"),
  end_date: date().typeError('requiredField'),
  multiplier: string().required('requiredField').matches(/^[0-9]+$/, 'onlyNumbers'),
  amount: string().required('requiredField').matches(/^[0-9]+$/, 'onlyNumbers')
});

export const schemaEdit = object().shape({
  ini_date: date().typeError("requiredField"),
  end_date: date().typeError('requiredField'),
  amount: string().required('requiredField').matches(/^[0-9]+$/, 'onlyNumbers')
});