import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Paper, Typography, Snackbar, Container, Grid, Hidden } from '@material-ui/core';
import { I18nContext } from 'translations';
import { message as closeMessage } from 'context/commons/actions';
import { useStateValue } from 'context/store';
import { updatePassword } from 'context/users/actions';
import Loading from 'components/Loading';
import LayoutOuter from 'components/Layouts/LayoutOuter';
import Menu from 'components/Landing/Layouts/Menu';
import { modalMessage } from 'utils';
import LinkAccount from '../../components/LinkAccount';
import Form from './Form';
import styles from '../style';

function UpdatePassword({ location, history, classes }) {
  const { translate } = useContext(I18nContext);
  const [{ commons: { loading, message } }, dispatch] = useStateValue();

  const handleMessageOk = () => {
    modalMessage(translate('congratulations'), translate('passwordUpdateCorrectly'), 'success', translate('accept')).then((result) => {
      result.value && history.push('login');
    });
  }

  const onSubmit = async values => {
    const b_link = location.search.split('=')[1];
    const callback = await updatePassword(dispatch, { ...values, b_link });
    callback.status && handleMessageOk();
  }

  return (
    <LayoutOuter bg="#f8f8f8">
    <Menu />
    <Container maxWidth="md" style={{ marginTop: 70 }} className="container-width-lg">
      <Paper elevation={4} variant="outlined" className={classes.wrapper}>
        {loading && <Loading />}

        <Grid container alignItems="center" justify="center" spacing={2}>
          <Grid item sm={5} md={6} className="text-center">
            <img
              src="/images/users/truck-box.png"
              alt="recover-password"
              className={classes.principalImg}
            />
            <br />
            <Hidden xsDown>
              <LinkAccount
                text="doYouAlreadyHaveAnAccount"
                link="/login"
                textLink="loginHere"
              />
            </Hidden>
          </Grid>
          <Grid item sm={7} md={6}>
            <Typography variant="h4" color="textPrimary">Ingrese su nueva contraseña</Typography>
            <br /><br />
            <Form
              submit={onSubmit}
            />
          </Grid>
          <Hidden smUp>
            <Grid item xs={12}>
              <LinkAccount
                text="doYouAlreadyHaveAnAccount"
                link="/login"
                textLink="loginHere"
              />
            </Grid>
          </Hidden>
        </Grid>
        <Snackbar
          open={!!message}
          autoHideDuration={6000}
          onClose={() => closeMessage(dispatch, null)}
          message={translate(message)}
        />
      </Paper>
    </Container>
  </LayoutOuter>
  );
}

UpdatePassword.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(UpdatePassword);
