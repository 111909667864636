import { post, get, put, deleteP, patch } from 'api/requests';
import { urlFavoriteAddresses, urlCompanyFavoriteAddresses, urlCompanyUseOriginsAsFavorites } from 'api';
import { message } from '../../commons/actions';
import handleErrorMessage from 'utils/handleErrorMessage';
import { LIST_FAVORITE_ADDRESSES, LIST_FAVORITE_ADDRESSES_LOADING } from './constants';


export const createFavoriteAddress = async (dispatch, values, isCompany) => {
  let status = true;
  await post(isCompany ? urlCompanyFavoriteAddresses : urlFavoriteAddresses, { favorite_place: { ...values, type_cd: 2 } })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message(dispatch, mssg);
    });
  return { status };
}

export const getListFavoriteAddresses = async (dispatch, params, isCompany) => {
  const data = {
    page: params.page,
    per_page: params.per_page,
    city_id: params.cityId
  }
  dispatch({ type: LIST_FAVORITE_ADDRESSES_LOADING, loadingAddresses: true });
  await get(isCompany ? urlCompanyFavoriteAddresses : urlFavoriteAddresses, params.cityId ? { ...data } : { ...params })
    .then(({ data }) => {
      dispatch({
        type: LIST_FAVORITE_ADDRESSES,
        listAddresses: data
      });
      dispatch({ type: LIST_FAVORITE_ADDRESSES_LOADING, loadingAddresses: false });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({
        type: LIST_FAVORITE_ADDRESSES_LOADING,
        listAddresses: null,
        loadingAddresses: false
      });
      message(dispatch, mssg);
    });
}

export const updateFavoriteAddress = async (dispatch, id, values, isCompany) => {
  let status = false;
  await put(isCompany ? `${urlCompanyFavoriteAddresses}/${id}` : `${urlFavoriteAddresses}/${id}`, { favorite_place: { ...values } })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message(dispatch, mssg);
    });
  return { status };
}

export const removeFavoriteAddress = async (dispatch, id, isCompany) => {
  let status = false;
  await deleteP(isCompany ? `${urlCompanyFavoriteAddresses}/${id}` : `${urlFavoriteAddresses}/${id}`, {})
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
}

export const originCompanyFavoriteAddress = async (dispatch) => {
  let status = false;
  await patch(urlCompanyUseOriginsAsFavorites, {})
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      status = false;
      message(dispatch, mssg);
    });
  return { status };
}