import {
  GET_COMPANY_REGISTER_LOADING,
  POST_COMPANY_REGISTER_LOADING,
  SET_COMPANY_REGISTER,
  MESSAGE,
  GET_DOCUMENTS_HISTORY,
  GET_DOCUMENTS_HISTORY_TYPE,
  TOGGLE_DOCUMENTS_HISTORY_TYPE_LOADING,
  SET_MAX_DECLARED_VALUE_OPTIONS,
  SET_TAX_LIABILITY_OPTIONS,
} from './constants';
import { urlCompanyRegister, urlCompanyUpdateAcceptanceOfTermsAndConditions } from 'api';
import { get, patch } from 'api/requests';
import { loading, message } from 'context/commons/actions';
import handleErrorMessage from 'utils/handleErrorMessage';

export const uploadCompanyInfo = async (
  dispatch,
  company_id,
  companyPayload
) => {
  dispatch({
    type: POST_COMPANY_REGISTER_LOADING,
    postLoading: true,
  });
  return await patch(`${urlCompanyRegister}/${company_id}`, {
    company: companyPayload.company,
    company_files: companyPayload.company_files,
    bank_account: companyPayload.bank_account,
  })
    .then(({ data }) => {
      dispatch({
        type: SET_COMPANY_REGISTER,
        data: data,
      });
      dispatch({
        type: POST_COMPANY_REGISTER_LOADING,
        postLoading: false,
      });
      return { status: true };
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      dispatch({
        type: POST_COMPANY_REGISTER_LOADING,
        postLoading: false,
      });
      return { status: false };
    });
};

export const getCompanyRegister = async (dispatch, company_id) => {
  dispatch({
    type: GET_COMPANY_REGISTER_LOADING,
    getLoading: true,
  });
  await get(`${urlCompanyRegister}/${company_id}`)
    .then(({ data }) => {
      dispatch({
        type: SET_COMPANY_REGISTER,
        data: data,
      });
      dispatch({
        type: GET_COMPANY_REGISTER_LOADING,
        getLoading: false,
      });
      data.registration_status === 'pending' &&
        dispatch({ type: MESSAGE, message: 'Ya hay un registro pendiente' });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({
        type: GET_COMPANY_REGISTER_LOADING,
        getLoading: false,
      });
      dispatch({ type: MESSAGE, message: mssg });
    });
};

export const closeMessage = (dispatch) => {
  dispatch({
    type: MESSAGE,
    message: null,
  });
};

export const setPostLoading = (dispatch, state) => {
  dispatch({
    type: POST_COMPANY_REGISTER_LOADING,
    postLoading: state,
  });
};

export const getDocumentsHistory = async (dispatch, company_id) => {
  await get(`${urlCompanyRegister}/${company_id}/company_document_history`)
    .then(({ data }) => {
      dispatch({
        type: GET_DOCUMENTS_HISTORY,
        documents: data,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
    });
};

export const getDocumentsHistoryByType = async (
  dispatch,
  company_id,
  type_cd
) => {
  dispatch({
    type: GET_DOCUMENTS_HISTORY_TYPE,
    documents: [],
  });
  dispatch({
    type: TOGGLE_DOCUMENTS_HISTORY_TYPE_LOADING,
    isLoading: true,
  });
  await get(
    `${urlCompanyRegister}/${company_id}/company_document_history/type?type_cd=${type_cd}`
  )
    .then(({ data }) => {
      dispatch({
        type: GET_DOCUMENTS_HISTORY_TYPE,
        documents: data,
      });
      dispatch({
        type: TOGGLE_DOCUMENTS_HISTORY_TYPE_LOADING,
        isLoading: false,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      dispatch({
        type: TOGGLE_DOCUMENTS_HISTORY_TYPE_LOADING,
        isLoading: false,
      });
    });
};

export const updateDocuments = async (dispatch, company_id, company_info) => {
  let status = false;
  await patch(`${urlCompanyRegister}/${company_id}/update_files`, {
    company_files: company_info,
  })
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
      status = false;
    });
  return { status };
};

export const getTaxLiabilityOptions = async (dispatch,company_id) => {
  await get(`${urlCompanyRegister}/${company_id}/tax_liability_options`)
    .then(( {data} ) => {
      dispatch({
        type: SET_TAX_LIABILITY_OPTIONS,
        taxLiabilityOptions: data,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
    });
}

export const getMaxDeclaredValueOptions = async (dispatch, company_id) => {
  await get(`${urlCompanyRegister}/${company_id}/max_declared_value_options`)
    .then(( {data} ) => {
      dispatch({
        type: SET_MAX_DECLARED_VALUE_OPTIONS,
        maxDeclaredValueOptions: data,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({ type: MESSAGE, message: mssg });
    });
}

export const updateAcceptanceOfTermsAndConditions = async (dispatch,values) => {
  loading(dispatch, true);
  const { companyId, ...payload } = values;
  await patch(urlCompanyUpdateAcceptanceOfTermsAndConditions(companyId), { ...payload })
    .then(() => {
      dispatch({
        type: MESSAGE,
        message: 'app.settings.users.updateAcceptanceTermsAndConditions.success',
      });
      loading(dispatch, false);
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      loading(dispatch, false);
      message(dispatch, mssg);
    });
}




