import React, { useState, useContext } from 'react';
import {
  Grid,
  Button,
  Typography,
  DialogActions
} from '@material-ui/core';
import { I18nContext } from 'translations';
import ModalTableCategoryCommodate from '../ModalTableCategoryCommodate';  
import ModalDriverCommodateInfo from '../ModalDriverCommodate';
import { confirmReceivedCommodateLoan } from 'context/commodate/actions';
import { useStateValue } from 'context/store';
import { modalMessageHtml, modalMessage } from 'utils';
import Loading from 'components/Loading';

export default function ModalConfirmReceiveCommodate ({
  data,
  openModal,
  companyId,
  commodateId,
  getCommodate,
  setDataRequest
}) {
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [,dispatch] = useStateValue();
  const { translate } = useContext(I18nContext);

  const messageOk = () => {
    setLoading(false);
    modalMessage(
      translate('congratulations'),
      translate('commodate.receiveCommodate.receiveItems.mssgReceivedOk'),
      'success',
      translate('continue')
    ).then(async () => {
      getCommodate()
      openModal();
    });
  };

  const confirmReceivedCommodate = async () => {
    modalMessageHtml(
      '',
      translate('commodate.receiveCommodate.receiveItems.mssgReceivedQuestion'),
      'success',
      translate('accept'),
      true,
      translate('cancel'),
      false
    ).then(async(response) => {
      if (response.isConfirmed) {
        setLoading(true);
        const callback = await confirmReceivedCommodateLoan(dispatch, companyId, commodateId, { commodates: selectedCategory });
        callback.status ? messageOk() : setLoading(false)
      }
    });
  }

  return (
    <div>
      <div className="bg-white">
        <Typography variant="h5" style={{ marginBottom: '15px' }}>
          {translate('commodate.modalRequest.commodateInformation')}
        </Typography>
        <Grid container spacing={1}>
          {loading && <Loading />}
          <ModalDriverCommodateInfo
            data={data}
            commodateEdit={data}
            isReceive
          />
          <Grid item xs={12}>
            {data?.commodates_attributes?.length > 0 &&
              <ModalTableCategoryCommodate
                data={data}
                setDataRequest={setDataRequest}
                commodateEdit={null}
                isReceive
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />
            }
          </Grid>
        </Grid>
      </div>
      <DialogActions>
        <Button
          onClick={() => openModal()}
          variant="outlined"
          className="btn-rounded-style"
          style={{ backgroundColor: '#fff' }}
        >
          {translate('commodate.receiveCommodate.leaveWithoutReceiving')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          className="btn-rounded-style"
          onClick={confirmReceivedCommodate}
          disabled={selectedCategory?.length === 0}
        >
          {translate('commodate.receiveCommodate.receiveItems')}
        </Button>
      </DialogActions>
    </div>
  )
}
