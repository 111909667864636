import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import Paper from '@material-ui/core/Paper';
import { MenuItem, TextField, CircularProgress } from '@material-ui/core'; 
import { urlSuggestionAddress } from 'api';
import { get } from 'api/requests';

const getSuggestions = async (value, cityId) => {
  const req = get(urlSuggestionAddress, {
    address: value,
    city_id: cityId
  });
  const data = await req;
  const sugg = data.data.map(da => ({ name: da.suggestion, lat: da.loc.lat, lon: da.loc.lon }))
  return sugg
};

const getSuggestionValue = suggestion => suggestion;

const renderSuggestion = suggestion => (
  <MenuItem component="div">
    <span>{suggestion.name}</span>
  </MenuItem>
);

function debounce(func, wait, immediate) {
  let timeout, result;
  return function() {
    let context = this, args = arguments;
    let later = function() {
      timeout = null;
      if (!immediate) result = func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) result = func.apply(context, args);
    return result;
  };
};

function renderInputComponent(inputProps) {
  const {
    classes, inputRef = () => { }, ref, value, ...other
  } = inputProps;
  return (
    <TextField
      variant="outlined"
      fullWidth
      size="small"
      InputProps={{
        value,
        inputRef: node => {
          ref(node);
          inputRef(node);
        }
      }}
      {...other}
    />
  );
}

class AutocompleteInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: { name: '' },
      suggestions: [],
      isLoading: false
    };
    this.debouncedLoadSuggestions = debounce(this.loadSuggestions, 1000);
  }

  setVoidAddress = () => { this.setState({ value: { name: '' } }); }

  componentDidUpdate(prevProps) {
    const { address } = this.props;
    if (!address && address !== prevProps.address) {
      this.setVoidAddress();
    }
  }

  componentDidMount() {
    const { focus, name } = this.props;
    focus && name && setTimeout(() => { document.getElementById(name) && document.getElementById(name).focus(); }, 50) 
  }

  loadSuggestions = value => {
    const { cityId } = this.props;
    this.setState({ isLoading: true});
    setTimeout(async () => {
      const suggestions = await getSuggestions(value, cityId);
      
      if (value === this.state.value.name) {
        this.setState({
          isLoading: false,
          suggestions
        });
      } else {
        this.setState({ isLoading: false });
      }
    }, 100);
  }
  
  onChange = (event, { newValue }) => {
    const { handleChange, pos: { lat, lon } } = this.props;
    if (typeof newValue === 'string') {
      this.setState({
        value: { name: newValue }
      });
      handleChange({
        name: newValue,
        loc: {
          lat,
          lon
        }
      });
    } else if (typeof newValue === 'object') {
      this.setState({ value: newValue });
      handleChange(newValue);
    }
  };
  
  onSuggestionsFetchRequested = ({ value }) => this.debouncedLoadSuggestions(value);
  onSuggestionsClearRequested = () => this.setState({ suggestions: [] });

  render() {
    const { value, suggestions, isLoading } = this.state;
    const { title, name, address, margin, disabled } = this.props;
    const stops = address && address.name;

    const inputProps = {
      placeholder: title,
      value: stops || value.name,
      onChange: this.onChange,
      name,
      label: title,
      required: true,
      margin: margin,
      id: name,
      disabled: disabled
    };

    const classes = {
      container: {
        position: 'relative',
      },
      suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 3,
        left: 0,
        right: 0,
        maxHeight: 250,
        overflow: 'auto'
      },
      suggestionsList: {
        paddingLeft: 0
      },
      suggestion: {
        display: 'block',
      }
    };
    return (
      <div className="relative">
        {isLoading && <CircularProgress color="primary" size={20} style={{ position: 'absolute', right: 20, top: 10 }} />}
        <Autosuggest
          suggestions={suggestions}
          theme={classes}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          renderInputComponent={renderInputComponent}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
      </div> 
    );
  }
}

AutocompleteInput.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.any,
  pos: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default AutocompleteInput;
