import React from 'react';
import { useStateValue } from 'context/store';
import { updateAcceptanceOfTermsAndConditions } from 'context/settings/companyRegister/actions';
import TermsAndConditionsForm from './TermsAndConditionsForm';

const TermsAndConditions = ({ termsStatus,companyId }) => {
  const termsForEvaluate = {};
  Object.entries(termsStatus).forEach(([key, value]) => {
    termsForEvaluate[key] = value;
  });

  const [{commons:{loading}},dispatch] = useStateValue();

  const onSubmit = async(data) => {
    const termsPromise = []
    companyId && termsPromise.push(updateAcceptanceOfTermsAndConditions(dispatch, { ...data, companyId }))
    Promise.all([
      ...termsPromise
    ]).then(() => {
      window.location.reload();
    });
  };

  return (
    <TermsAndConditionsForm
    termsForEvaluate={termsForEvaluate}
      onSubmitTermsAndConditions={onSubmit}
      isLoading={loading}
    />
  );
};

export default TermsAndConditions;
