import React, { useContext, useState } from 'react';
import {
  Button,
  InputLabel,
  TextField,
  Grid,
  Typography,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { I18nContext } from 'translations';
import schema from './schema';

export default function FormConfigurationShopify({
  listShopifyStores,
  submit,
  storeName,
}) {
  const { translate } = useContext(I18nContext);
  const { handleSubmit, register, errors, setValue, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  const findStore = () =>
    listShopifyStores?.find(
      (listShopifyStore) => listShopifyStore.store_name === storeName
    );

  const [selectedStore] = useState(findStore());
  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      {listShopifyStores?.length > 0 && selectedStore ? (
        <>
          <Grid
            container
            style={{ marginBottom: 10 }}
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} style={{ marginBottom: 5 }}>
              <InputLabel>
                {translate('app.integration.settingStore.form.syncEvery')}
              </InputLabel>
            </Grid>
            <Grid item sm={12}>
              <TextField
                type="text"
                name="order_synchronization_days"
                variant="outlined"
                label={translate('app.integration.settingStore.form.days')}
                inputRef={register}
                size="small"
                fullWidth
                defaultValue={selectedStore.order_synchronization_days || 15}
                error={!!errors.order_synchronization_days}
                helperText={
                  errors.order_synchronization_days &&
                  translate(errors.order_synchronization_days.message)
                }
              />
            </Grid>
          </Grid>
          <input
            ref={register}
            name="activate_webhooks"
            value={getValues('activate_webhooks')}
            type="checkbox"
            defaultChecked={selectedStore.activate_webhooks || true}
            onChange={(e) => setValue('activate_webhooks', e.target.checked)}
            style={{ marginBottom: 10 }}
          />
          <Typography component="span">
            {' '}
            {translate('app.shopify.settingShopify.form.sync')}
          </Typography>
        </>
      ) : (
        <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
          {translate('app.integration.settingStore.form.doNotHaveStores')}
        </Typography>
      )}
      <div className="text-center">
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={listShopifyStores?.length <= 0}
        >
          {translate('save')}
        </Button>
      </div>
    </form>
  );
}
