import React, { useContext } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  Typography,
  ListItemText,
  Hidden
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { BOOKING_EVENTS } from 'utils/constants';
import { I18nContext } from 'translations';
import Loading from 'components/Loading';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './style';

function BookingEvents({ events, loading, createdAt, classes }) {
  const { translate } = useContext(I18nContext);
  const renderDateTitle = () => <Grid item md={4} xs={12}>
    <Typography variant="subtitle1" className={classes.subtitle}>
      {translate('app.booking.events.date')}
    </Typography>
  </Grid>;

  const renderTimeTitle = () => <Grid item md={4} xs={6}>
    <Typography variant="subtitle1" className={classes.subtitle}>
      {translate('app.booking.events.time')}
    </Typography>
  </Grid>;

  const renderEventTitle = () => <Grid item md={4} xs={6}>
    <Typography variant="subtitle1" className={classes.subtitle}>
      {translate('app.booking.events.event')}
    </Typography>
  </Grid>;

  return (
    <Grid
      container
      direction="row"
      justify="center"
      className={classes.containerWhite}
    >
      <Grid item md={9} xs={12}>
        <Typography variant="h4" className={classes.title}>
          {translate('app.booking.events.tripEvents')}
        </Typography>
        {loading && <Loading />}

        <Grid container spacing={1}>
          <Hidden smDown>
            {renderDateTitle()}
            {renderTimeTitle()}
            {renderEventTitle()}
          </Hidden>
           <Grid item xs={12} container alignItems='center' className={classes.containerEvent}>
            <Grid item md={4} xs={12} className={classes.containerDate}>
              <Hidden mdUp> {renderDateTitle()}</Hidden>
              <Typography className={classes.date}>
                {format(new Date(createdAt), 'dd/MM/yyyy')}
              </Typography>
            </Grid>
            <Grid item md={4} xs={12} className={classes.containerDate}>
              <Hidden mdUp>{renderTimeTitle()}</Hidden>
              <img
                src="/images/booking/box.svg"
                alt="box"
                className={classes.box}
              />
              <b>{format(new Date(createdAt), 'hh:mm a')}</b>
            </Grid>
            <Grid item md={4} xs={12}>
              <Hidden mdUp>{renderEventTitle()}</Hidden>
              <Typography variant="body1">
                {translate('app.eventBooking.createdAt')}
              </Typography>  
            </Grid>
          </Grid>
          {events.length > 0 &&
            events.map((event, index) => (
              <Grid item xs={12} container alignItems='center' key={event._id} className={classes.containerEvent}>
                <Grid item md={4} xs={12} className={classes.containerDate}>
                  <Hidden mdUp> {renderDateTitle()}</Hidden> 
                  <Typography className={classes.date}>
                    {format(new Date(event?.created_at), 'dd/MM/yyyy')}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12} className={classes.containerDate}>
                  <Hidden mdUp> {renderTimeTitle()}</Hidden> 
                  { event.event_cd === 23 ? (
                    <img
                      src="/images/tracking/destination_marker.svg"
                      alt="flag"
                      className={classes.box}
                    />
                  ) : (
                    <figure className={classes.dot} />
                  )}
                  <b>{format(new Date(event?.created_at), 'hh:mm a')}</b>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Hidden mdUp> {renderEventTitle()}</Hidden> 
                  {BOOKING_EVENTS[event.event_cd] ? (
                    event.event_cd === 28 && event.packages_info ? (
                      <Accordion>
                        <AccordionSummary
                          className={classes.accordionHeader}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`${event._id}${index}-content`}
                          id={`${event._id}${index}`}
                        >
                          <Typography variant="body1">
                            {translate(BOOKING_EVENTS[event.event_cd])}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionContent}>
                          <List dense={false} className={classes.list}>
                            {event.packages_info?.map((packageId, index) => (
                              <ListItem key={`packageId${index}`} alignItems="flex-start" style={{ 'flex-direction': 'column', padding: '2px 16px' }}>
                                <>
                                  <ListItemText
                                    style={{ marginBottom: 0 }}
                                    primary={translate('app.eventBooking.arrivalAtPlaceOfDelivery.id')}
                                    secondary={
                                      <Typography
                                        component="span"
                                        variant="caption"
                                      >
                                        {packageId.id}
                                      </Typography>
                                    }
                                  />
                                  <ListItemText
                                    primary={translate('app.eventBooking.arrivalAtPlaceOfDelivery.reference')}
                                    secondary={
                                      <Typography
                                        component="span"
                                        variant="caption"
                                      >
                                        {packageId.reference}
                                      </Typography>
                                    }
                                  />
                                </>
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      <Typography variant="body1">
                        {translate(BOOKING_EVENTS[event.event_cd])}
                      </Typography>
                    )
                  ) : (
                    '-'
                  )}
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(BookingEvents);
