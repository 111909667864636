import { string, object, number } from 'yup';

export const schema = object().shape({
  indications: string().required('requiredField'),
  declared_value: number().typeError('onlyNumbers'),
  reference: string().required('requiredField'),
  collected_value: number().typeError('onlyNumbers'),
  customer: object().shape({
    email: string().email('invalidEmailAddress'),
    phone: string().nullable().notRequired().matches(/^[\d ]*$/, 'onlyNumbers')
  }),
});