class Integration {
  constructor({
    _id,
    name,
    auth_url,
    photo_url,
    integrator_id,
    user_provider_integrations,
  }) {
    this._id = _id;
    this.name = name;
    this.auth_url = auth_url;
    this.photo_url = photo_url;
    this.integrator_id = integrator_id;
    this.user_provider_integrations = user_provider_integrations;
  }
  getIntegration() {
    return this;
  }
  getActivateIntegration() {
    return null;
  }
  getDisableIntegration() {
    return null;
  }
  getEditStore(store) {
    return null;
  }
}

export default Integration;
