import { LIST_USERS, LIST_USERS_LOADING, CLEAN_USER, VALIDATE_USER, VALIDATING_USER, LOADING_SAVE_USER_COMPANY } from './constants';

const usersReducer = (state, action) => {
  switch (action.type) {
    case LIST_USERS_LOADING: {
      return {
        ...state,
        loadingUsers: action.loadingUsers
      }
    }
    case LIST_USERS:
      return {
        ...state,
        listUsers: action.listUsers
      }
    case VALIDATE_USER:
      return {
        ...state,
        userExists: action.userExists
      }
    case VALIDATING_USER:
      return {
        ...state,
        validatingUser: action.validatingUser
      }
    case CLEAN_USER:
      return {
        ...state,
        userExists: null
      }
    case LOADING_SAVE_USER_COMPANY: {
      return {
        ...state,
        loadingCreateUserCompany: action.loadingCreateUserCompany
      }
    }
    default:
      return state
  }
}

export default usersReducer;
