import React from 'react';
import Stops from './Stops';

const ListStops = ({
  fields,
  expanded,
  handleChange,
  origin,
  address,
  moveStops,
  removeStops,
  handleAddress,
  Controller,
  register,
  errors,
  city,
  configuration,
  changeCoord,
  loadingAproxAddress,
  customer,
  setCustomer,
  counterDelivery,
  control,
  showPackage,
  isChecked,
  checkStops,
  cities,
  company,
  isRent
}) => (
  fields.map((item, index) => (
    <Stops
      key={item.id}
      expanded={expanded}
      item={item}
      handleChange={handleChange}
      origin={origin}
      index={index}
      fields={fields}
      address={address}
      moveStops={moveStops}
      removeStops={removeStops}
      handleAddress={handleAddress}
      register={register}
      errors={errors}
      city={city}
      configuration={configuration}
      changeCoord={changeCoord}
      loadingAproxAddress={loadingAproxAddress}
      customer={customer}
      setCustomer={setCustomer}
      counterDelivery={counterDelivery}
      Controller={Controller}
      control={control}
      showPackage={showPackage}
      isChecked={isChecked}
      checkStops={checkStops}
      cities={cities}
      company={company}
      isRent={isRent}
    />
  ))
)

export default ListStops;