import React, { useContext, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  Button
} from '@material-ui/core';
import { format } from 'date-fns';
import { I18nContext } from 'translations';
import { PROCESSING_FILES } from 'utils/constants';
import { processingfilesStatusesClass } from 'utils';
import EmptyData from '../../EmptyData';
import styles from '../../style';

const ReportsTable = ({
  classes,
  data,
  recordsPerPage,
  getReports
}) => {
  const [currentPage, setCurrentPage] = useState(data.page);
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    setCurrentPage(data.page)
  }, [data])

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage)
    getReports(newPage);
  }

  if (!data || (data && data.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />
  }

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell>{translate('app.reports.table.file.name')}</TableCell>
              <TableCell>{translate('app.reports.table.file.creationDate')}</TableCell>
              <TableCell>{translate('app.reports.table.file.expirationDate')}</TableCell>
              <TableCell>{translate('app.reports.table.file.status')}</TableCell>
              <TableCell>{translate('app.reports.table.file.files')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data.data || []).map(file => (
              <TableRow hover key={file._id}>
                <TableCell>
                  {file.file_name}
                </TableCell>
                <TableCell>
                  <span className={classes.date}>
                    {format(new Date(file.created_at), 'dd/MM/yyyy, hh:mm a')}
                  </span>
                </TableCell>
                <TableCell>
                  <span className={classes.date}>
                    {format(new Date(file.url_expiration_date), 'dd/MM/yyyy, hh:mm a')}
                  </span>
                </TableCell>
                <TableCell>
                  <b className={processingfilesStatusesClass(file.status_cd)}>
                    {translate(PROCESSING_FILES[file.status_cd])}
                  </b>
                </TableCell>
                <TableCell>
                  <Button
                    href={file.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="primary"
                    underline="hover"
                    size="small"
                    download
                    disabled={file.status_cd !== 1 && true}
                  >
                    <b>{translate('app.reports.table.file.downloadFile')}</b>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
    </>
  );
}

export default withStyles(styles)(ReportsTable);