import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import NumberFormat from 'react-number-format';
import { I18nContext } from 'translations';

const ValidationModal = ({ open, handleClose, handleSumbit, isLoading }) => {
  const { translate } = useContext(I18nContext);

  const [value, setValue] = useState('');

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'sm'}
      aria-labelledby="form-collection-value-dialog-title"
    >
      <DialogTitle id="form-collection-value-dialog-title">
        {translate('app.payments.creditCard.validation.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translate('app.payments.creditCard.validation.intructions')}
        </DialogContentText>
        <NumberFormat
          isNumericString={true}
          thousandSeparator="."
          decimalSeparator=","
          prefix="$"
          fullWidth
          customInput={TextField}
          value={value}
          onValueChange={(values) => {
            const { value } = values;
            setValue(value);
          }}
          placeholder={translate(
            'app.booking.collection.modal.collectionValue'
          )}
          allowNegative={false}
          allowLeadingZeros={false}
          size="small"
          label={translate('app.booking.collection.modal.collectionValue')}
          helperText={
            value === ''
              ? translate('app.booking.collection.modal.enterCollectionValue')
              : null
          }
          error={value === ''}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="secondary">
          {translate('cancel')}
        </Button>
        <Button
          disabled={value === ''}
          variant="contained"
          onClick={() => handleSumbit(value)}
          color="primary"
        >
          {isLoading ? <CircularProgress size={28} /> : translate('send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ValidationModal;
