import { post, get, getExcelFile, patch } from 'api/requests';
import { message, loading  } from '../../commons/actions';
import handleErrorMessage from 'utils/handleErrorMessage';
import { SERVICE_TYPE_ID } from 'utils/constants';
import {
  urlOptimizeRoutes,
  urlOptimizedStops,
  urlCreateMultipleBookings,
  urlHistoryOptimizedRoutes,
  urlExcelOptimizedRoutes,
} from 'api';
import {
  OPTIMIZE_ROUTES_FAILED,
  LIST_OPTIMIZED_ROUTES,
  LOADING_LIST_ROUTE_FILE,
  LIST_ROUTE_FILE,
  LIST_ROUTE_NOT_OPTIMIZED,
  LOADING_LIST_OPTIMIZED_ROUTES,
  GO_TO_CREATE_PIBOX,
  LIST_STOP_POINT,
} from './constants';

export const uploadOptimizeRoute = async (dispatch, values) => {
  //dispatch({ type: BULK_LOAD, bulkLoad: null, loadingBulkLoad: true });
  let status = false;
  let statusInvalidTasks = false;
  await post(urlOptimizeRoutes, { ...values })
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      if (error.response?.data?.failed_stops) {
        statusInvalidTasks = true;
        dispatch({
          type: OPTIMIZE_ROUTES_FAILED,
          failedRoutes: error.response.data.failed_stops,
        });
      } else {
        const mssg = handleErrorMessage(error, dispatch);
        message(dispatch, mssg);
        status = false;
        statusInvalidTasks = false;
      }
    });
  return { status, statusInvalidTasks };
};

export const listRouteFileListOperations = async (dispatch, params) => {
  let status = false;
  dispatch({ type: LOADING_LIST_ROUTE_FILE, loadingListRouteFile: true });
  await get(urlHistoryOptimizedRoutes, params)
    .then(({ data }) => {
      dispatch({ type: LIST_ROUTE_FILE, listRouteFile: data });
      dispatch({ type: LOADING_LIST_ROUTE_FILE, loadingListRouteFile: false });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: LOADING_LIST_ROUTE_FILE,
        loadingListRouteFile: false,
      });
    });
  return { status };
};

export const getListOptimizedRoutes = async (dispatch, params, id) => {
  dispatch({
    type: LOADING_LIST_OPTIMIZED_ROUTES,
    loadingListOptimizedRoutes: true,
  });
  await get(`${urlOptimizedStops}/${id}/jobs`, params)
    .then(({ data }) => {
      dispatch({
        type: LIST_OPTIMIZED_ROUTES,
        listOptimizedRoutes: {
          data: [...data.data],
          page: data.page ? data.page : 1,
          pages: data.pages ? data.pages : 1,
          total: data.total,
        },
      });
      dispatch({
        type: LOADING_LIST_OPTIMIZED_ROUTES,
        loadingListOptimizedRoutes: false,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      dispatch({
        type: LOADING_LIST_OPTIMIZED_ROUTES,
        loadingListOptimizedRoutes: false,
      });
    });
};

export const getListRouteNotOptimized = async (dispatch, id) => {
  let status = false;
  await get(`${urlOptimizedStops}/${id}/unassigned_stops`, {})
    .then(({ data }) => {
      dispatch({
        type: LIST_ROUTE_NOT_OPTIMIZED,
        routeNotOptimized: data.data,
      });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
};

export const getStopPoint = async (dispatch, id) => {
  let status = false;
  await get(`${urlOptimizedStops}/${id}/stops_points`, {})
    .then(({ data }) => {
      dispatch({ type: LIST_STOP_POINT, listStopPoint: data });
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
};

export const goToCreatePibox = (dispatch, route) => {
  dispatch({
    type: GO_TO_CREATE_PIBOX,
    routeOptimizeOps: route,
    originOptimizeOps: route ? route[0] : null,
  });
};

export const cleanRouteOptimizeOps = (dispatch) => {
  dispatch({
    type: GO_TO_CREATE_PIBOX,
    routeOptimizeOps: null,
  });
};

export const createMultipleBookings = async (dispatch, routes) => {
  const bookings = routes.map((val) => {
    const externalId = val.stops?.length > 0 ? val.stops[0].external_id : null
    return {
      requested_service_type_id: SERVICE_TYPE_ID,
      address: val.address,
      lat: val.address_lat,
      lon: val.address_lon,
      return_to_origin: false,
      requires_a_driver_with_base_money: false,
      use_wallet_balance: true,
      payment_method: 1,
      cost_center_id: val.cost_center_id ? val.cost_center_id : null,
      integration_relation_order_uuid: externalId ? crypto.randomUUID() : null,
      stops: val.stops.map((stop) => {
        return {
          ...stop,
          packages: stop.packages.map((pg) => {
            return {
              ...pg,
              declared_value: {
                sub_units: pg.declared_value?.sub_units,
                currency: pg.declared_value?.iso,
              },
              collected_value: pg.collected_value ? {
                sub_units: pg.collected_value?.sub_units,
                currency: pg.collected_value?.iso,
              } : null
            };
          }),
        };
      }),
    };
  });
  let status = false;
  await post(urlCreateMultipleBookings, { bookings })
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
};

export const downloadExcelOptimizeRoutes = async ({dispatch,id, params, type}) => {
  loading(dispatch, true);
  try {
    await getExcelFile(
      `${urlExcelOptimizedRoutes}/${id}/export_routes`,
      params
    );
    loading(dispatch, false);
  } catch (error) {
    const mssg = handleErrorMessage(error, dispatch);
    message(dispatch, mssg);
    loading(dispatch, false);
  }
};

export const retryOptimizeRoutes = async (id) => {
  let status = false;
  await patch(`${urlHistoryOptimizedRoutes}/${id}/retry_solution_fetch`, { })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      status = false
    });
  return { status }
}