import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Button
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { formatISO, startOfDay, endOfDay } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import { useForm, Controller } from 'react-hook-form';
import { I18nContext } from 'translations';
import { PACKAGES_STATUSES, SIZE_CD } from 'utils/constants';
import CachedIcon from '@material-ui/icons/Cached';
import styles from '../../styleTableFilters';

const defaultValues = {
  ini_date: null,
  end_date: null,
  status_cd: '',
  size_cd: '',
  counter_delivery: '',
  reference: ''
};

const PackagesTableFilters = ({ classes, onFilters, company }) => {
  const { translate } = useContext(I18nContext);
  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const onSubmit = (values) => {
    const params = {
      ...values,
      ini_date: values.ini_date && formatISO(startOfDay(values.ini_date)),
      end_date: values.end_date && formatISO(endOfDay(values.end_date))
    };
    Object.keys(params).forEach((key) => {
      if (params[key] === '' || params[key] == null) {
        delete params[key];
      }
    });
    onFilters(params);
  };

  const clearFilters = () => {
    reset(defaultValues);
    onFilters("");
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate className="relative">
      <Grid container spacing={1}>
        <Grid item md={3} sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Controller
              name="ini_date"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <KeyboardDatePicker
                  className={classes.filter}
                  size="small"
                  inputVariant="outlined"
                  label={translate('app.bookings.filter.dateFrom')}
                  format="dd/MM/yyyy"
                  cancelLabel={translate('cancel')}
                  okLabel={translate('accept')}
                  maxDate={new Date()}
                  invalidDateMessage={translate('invalidFormat')}
                  fullWidth
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </MuiPickersUtilsProvider> 
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Controller
              name="end_date"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <KeyboardDatePicker
                  className={classes.filter}
                  size="small"
                  inputVariant="outlined"
                  label={translate('app.bookings.filter.dateTo')}
                  format="dd/MM/yyyy"
                  cancelLabel={translate('cancel')}
                  okLabel={translate('accept')}
                  maxDate={new Date()}
                  invalidDateMessage={translate('invalidFormat')}
                  fullWidth
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item md={3} sm={4} xs={6}>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.filter}
            fullWidth
          >
            <InputLabel>{translate('app.bookings.filter.state')}</InputLabel>
            <Controller
              control={control}
              name="status_cd"
              label={translate('app.bookings.filter.state')}
              variant="outlined"
              as={
                <Select>
                  <MenuItem value="">{translate('app.bookings.filter.choose')}</MenuItem>
                  {Object.keys(PACKAGES_STATUSES).map(key => (
                    <MenuItem key={key} value={key}>{translate(PACKAGES_STATUSES[key])}</MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.filter}
            fullWidth
          >
            <InputLabel>{translate('app.bookings.filter.size')}</InputLabel>
            <Controller
              control={control}
              name="size_cd"
              label={translate('app.bookings.filter.size')}
              variant="outlined"
              defaultValue=""
              as={
                <Select>
                  <MenuItem value="">{translate('app.bookings.filter.choose')}</MenuItem>
                  {Object.keys(SIZE_CD).map(key => (
                    <MenuItem key={key} value={key}>{translate(SIZE_CD[key])}</MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Grid>

        {company && (
          <>
            <Grid item md={3} sm={4} xs={6}>
              <FormControl
                variant="outlined"
                size="small"
                className={classes.filter}
                fullWidth
              >
                <InputLabel>{translate('app.bookings.filter.counterDelivery')}</InputLabel>
                <Controller
                  control={control}
                  name="counter_delivery"
                  label={translate('app.bookings.filter.counterDelivery')}
                  variant="outlined"
                  defaultValue=""
                  as={
                    <Select>
                      <MenuItem value="">{translate('app.bookings.filter.choose')}</MenuItem>
                      <MenuItem value={true}>{translate('yes')}</MenuItem>
                      <MenuItem value={false}>{translate('no')}</MenuItem>
                    </Select>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item md={3} sm={4} xs={6} className={classes.filter}>
              <Controller
                name="reference"
                control={control}
                variant="outlined"
                defaultValue=""
                render={({ onChange, value }) => (
                  <TextField
                    size="small"
                    variant="outlined"
                    label={translate('app.bookings.filter.orderNumber')}
                    fullWidth
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
          </>
        )}
        <Grid item md={company ? 6 : 12} xs={12} className={`text-center-sm ${!company && 'text-center'}`}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            type="submit"
            style={{ width: 120, marginRight: 10 }}
          >
            {translate('app.bookings.filter.search')}
          </Button>
          <Button
            color="primary"
            size="small"
            type="button"
            style={{ width: 100 }}
            onClick={clearFilters}
          >
            {translate('clear')}
          </Button>
        </Grid>
      </Grid>
      <Button
        color="primary"
        size="small"
        type="submit"
        style={{ position: 'absolute', right: 0 }}
      >
        <CachedIcon fontSize="small" />
      </Button>
    </form>
  )
}

export default withStyles(styles)(PackagesTableFilters);