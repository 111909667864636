import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Typography, Container, Button, Snackbar, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { getListFavoriteAddresses, removeFavoriteAddress, originCompanyFavoriteAddress } from 'context/settings/favoriteAddresses/actions';
import { listCheckin } from 'context/checkin/actions';
import ModalFavoriteAddresses from 'components/Modals/Settings/FavoriteAddresses';
import FavoriteAddressesTable from 'components/Tables/Settings/FavoriteAddresses';
import Loading from 'components/Loading';
import { modalMessage } from 'utils';
import { INITIAL_PAGE, PER_PAGE, COUNTRY_CODE } from 'utils/constants';

export default function CompanyFavoriteAddresses () {
  const { translate, langCode } = useContext(I18nContext);
  const [{ commons: { message }, addresses: { listAddresses, loadingAddresses }, checkin: {configuration }}, dispatch] = useStateValue();
	const [open, setOpen] = useState(false);
  const [per_page] = useState(PER_PAGE);
  const [addressEdit, setAddressEdit] = useState(null);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const getAddresses = useCallback(async (page) => {
    getListFavoriteAddresses(dispatch, { page, per_page }, true);
  }, [dispatch, per_page]);

  const editAddress = (address) => {
    setOpen(true);
    setAddressEdit(address);
  }

  const messageOk = () => {
    modalMessage(translate('congratulations'), translate('app.settings.addresses.addressDeletedSuccessfully'), 'success', translate('accept'), true).then(() => {
      setOpen(false);
      getAddresses();
    })
  }

  const removeAddress = async (address) => {
    modalMessage('', translate('app.settings.addresses.messageConfirmRemove'), 'warning', 'Continue', true, true, translate('cancel')).then(async (response) => {
      if (response.isConfirmed) {
        const { _id } = address
        const callback = await removeFavoriteAddress(dispatch, _id, true)
        callback.status && messageOk();
      }
    });
  }

  useEffect(() => {
    getAddresses(INITIAL_PAGE);
  }, [getAddresses])

  const messageOkOrigin = () => {
    setLoading(false);
    modalMessage(
      '',
      translate('app.companyAddresses.limitOriginOk'),
      'success',
      'Continue',
    ).then(async () => {
        await listCheckin(dispatch, { country_id: COUNTRY_CODE[langCode][0] });
      }
    )
  }

  const confirmChecked = (isCheck) => {
    modalMessage(
      '',
      isCheck ? translate('app.companyAddresses.limitPointOriginEnabledConfirm') : translate('app.companyAddresses.limitPointOriginDisabledConfirm'),
      'info',
      translate('accept'),
      true,
      true,
      translate('cancel')
    ).then(async (status) => {
      if(status.isConfirmed) {
        setLoading(true);
        const callback = await originCompanyFavoriteAddress(dispatch);
        callback.status ? messageOkOrigin() : setLoading(false)
      } else {
        configuration && setChecked(configuration.use_favorites_as_origins)
      }
    })
  }

  const handleChangeChecked = async (e) => {
    setChecked(e.target.checked);
    confirmChecked(e.target.checked)
  }

  useEffect(() => {
    configuration && setChecked(configuration.use_favorites_as_origins)
  },[configuration])


	return (
		<>
			<Typography variant="h5" color="primary">
        {translate('app.companyAddresses.title')}
      </Typography>
			<div className="text-center">
        <ModalFavoriteAddresses
          open={open}
          setOpen={setOpen}
          getAddresses={getAddresses}
          addressEdit={addressEdit}
          setAddressEdit={setAddressEdit}
          isCompany={true}
				/>
				<Container maxWidth="lg" className="padding0-xs">
          <Grid container>
            <Grid item md={7} className="text-left">
              {listAddresses?.total > 0 &&
                <>
                  <FormControlLabel
                    control={<Checkbox checked={checked} onChange={e => handleChangeChecked(e)} name="limitOrigin" />}
                    label={translate('app.companyAddresses.limitPointOrigin')}
                    style={{ paddingTop: 7 }}
                  />
                  {loading && <Loading />}
                </>
              }
            </Grid>
            <Grid item md={5} className="text-right">
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => setOpen(true)}
              >
                {translate('app.settings.addresses.addAddress')}
              </Button>
            </Grid>
          </Grid>
          <br />
          {loadingAddresses && <Loading />}
          {listAddresses && (
            <FavoriteAddressesTable
              editAddress={editAddress}
              data={listAddresses}
              removeAddress={removeAddress}
              getAddresses={getAddresses}
              recordsPerPage={per_page}
            />
          )}
				</Container>
        <Snackbar
          style={{ zIndex: 99999 }}
          open={!!message}
          autoHideDuration={6000}
          onClose={() => closeMessage(dispatch, null)}
          message={translate(message)}
        />
			</div>
		</>
	)
}