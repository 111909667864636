import { string, object, date } from 'yup';

const schema = object().shape({
  ini_date: date().typeError("requiredField"),
  end_date: date().typeError("requiredField"),
  amount: string().required('requiredField').matches(/^[0-9]+$/, 'onlyNumbers'),
  amount_pibox:
    string()
    .test(
      'oneOfRequired',
      'balances.modal.form.mustEqualTheTotalBalance',
      function(item) {
        if (item) {
          if (this.parent.amount_picap) {
            if ((parseInt(this.parent.amount_picap) + parseInt(item)) === parseInt(this.parent.amount)) {
              return true
            }
          } else {
            if (this.parent.amount === item) {
              return true
            } else {
              return false
            }
          }
        } else {
          return true
        }
      }
    ),
  amount_picap:
    string()
    .test(
      'oneOfRequired',
      'balances.modal.form.mustEqualTheTotalBalance',
      function(item) {
        if (item) {
          if (this.parent.amount_pibox) {
            if ((parseInt(this.parent.amount_pibox) + parseInt(item)) === parseInt(this.parent.amount)) {
              return true
            }
          } else {
            if (this.parent.amount === item) {
              return true
            } else {
              return false
            }
          }
        } else {
          return true
        }
      }
    )
})

export default schema;