import React, { useContext, useState } from 'react';
import { Paper, Snackbar, Container, Grid, Typography, Hidden } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { recoverPassword } from 'context/users/actions';
import { I18nContext } from 'translations';
import LayoutOuter from 'components/Layouts/LayoutOuter';
import Loading from 'components/Loading';
import Menu from 'components/Landing/Layouts/Menu';
import styles from './style';
import Form from './Form';
import LinkAccount from '../components/LinkAccount';
import { modalMessage } from 'utils';
import { RENT } from 'utils/constants';

function RecoverPassword({ classes }) {
  const isRent = process.env.REACT_APP_API_URL_BASE_IS_RENT === RENT;
  const { translate } = useContext(I18nContext);
  const [{ commons: { loading, message } }, dispatch] = useStateValue();
  const [resetForm, setResetForm] = useState(false);

  const onSubmit = async values => {
    const callback = await recoverPassword(dispatch, values);
    callback.status && 
      modalMessage('', translate('recovery.linkHasBeenSentToTheEmail'), 'success', translate('accept'), true).then(() => {
        setResetForm(true);
      })
  }

  return (
    <LayoutOuter bg="#f8f8f8">
      <Menu />
      <Container maxWidth="md" style={{ marginTop: 70 }} className="container-width-lg">
        <Paper elevation={4} variant="outlined" className={classes.wrapper}>
          {loading && <Loading />}

          <Grid container alignItems="center" justify="center" spacing={2}>
            <Grid item sm={5} md={6} className="text-center">
              <img
                src={isRent ? "/images/rent/moto-rent.png" : "/images/users/truck-box.png"}
                alt="recover-password"
                className={classes.principalImg}
              />
              <br />
              <Hidden xsDown>
                <LinkAccount
                  text="doYouAlreadyHaveAnAccount"
                  link="/login"
                  textLink="loginHere"
                />
              </Hidden>
            </Grid>
            <Grid item sm={7} md={6}>
              <Typography variant="h4" color="textPrimary">{translate('recoverPassword')}</Typography>
              <br /><br />
              <Typography variant="body1" color="textSecondary">
                {translate('textRecoverPassword')}
              </Typography>
              <br />
              <Form
                submit={onSubmit}
                resetForm={resetForm}
              />
            </Grid>
            <Hidden smUp>
              <Grid item xs={12}>
                <LinkAccount
                  text="doYouAlreadyHaveAnAccount"
                  link="/login"
                  textLink="loginHere"
                />
              </Grid>
            </Hidden>
          </Grid>
          <Snackbar
            open={!!message}
            autoHideDuration={6000}
            onClose={() => closeMessage(dispatch, null)}
            message={translate(message)}
          />
        </Paper>
      </Container>
    </LayoutOuter>
  );
}

RecoverPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RecoverPassword);
