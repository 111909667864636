import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Select,
  Button,
  MenuItem,
  Checkbox,
  InputLabel,
  FormControl,
  TextField,
  FormControlLabel
} from '@material-ui/core';
import { redeemPromoCode } from 'context/express/actions';
import { I18nContext } from 'translations';

const PaymentMethodFieldsRent = ({
  Controller,
  control,
  company,
  dispatch,
  loadingPromoCode,
  promoCode
}) => {
  const { translate } = useContext(I18nContext);
  const [redeem, setRedeem] = useState('');

  useEffect(() => {
    promoCode === 0 && setRedeem('')
  }, [promoCode])

  return (
    <Grid container justify="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth size="small">
          <InputLabel htmlFor="size_cd">
            {translate('app.express.selectAPaymentMethod')}
          </InputLabel>
          <Controller
            control={control}
            name="payment_method"
            label= {translate('app.express.selectAPaymentMethod')}
            variant="outlined"
            defaultValue=""
            as={
              <Select>
                <MenuItem value="">
                  <em>{translate('app.express.none')}</em>
                </MenuItem>
                <MenuItem key={1} value={1}>
                  {translate('app.express.cash')}
                </MenuItem>
              </Select>
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
      <FormControlLabel
        control={
          <Controller
            name="use_wallet_balance"
            control={control}
            defaultValue={true}
            render={({ value, onChange }) => (
              <Checkbox
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
              />
            )}
          />
        }
        label={translate('app.express.useWallet')}
      />
      </Grid>
      <Grid item xs={9}>
        <TextField
          type="text"
          name="code"
          defaultValue=""
          variant="outlined"
          label={translate('promoCode.form.tripCode')}
          fullWidth
          value={redeem}
          onChange={e => setRedeem(e.target.value)}
          size="small"
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="contained"
          type="button"
          onClick={() => redeemPromoCode(dispatch, company?._id, redeem)}
          disabled={loadingPromoCode}
          color="primary"
          fullWidth
        >
          {translate('promoCode.form.validateCode')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default PaymentMethodFieldsRent;