import React, { useContext, useState, useCallback, useEffect } from 'react';
import { Container, Snackbar } from '@material-ui/core';
import AppsIcon from '@material-ui/icons/Apps';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import {
  listIntegrations,
  closeMessage,
  cleanIntegrations,
} from 'context/integrations/actions';
import { COUNTRY_CODE } from 'utils/constants';
import Title from 'components/Titles';
import Loading from 'components/Loading';
import ListPlatformsTable from 'components/Tables/Integrations/ListPlatforms';
import { INITIAL_PAGE, PER_PAGE } from 'utils/constants';

export default function Integrations() {
  const [
    {
      integrations: { list, message },
    },
    dispatch,
  ] = useStateValue();
  const [per_page] = useState(PER_PAGE);
  const [loading, setLoading] = useState(false);
  const { langCode, translate } = useContext(I18nContext);

  const getIntegrations = useCallback(
    async (page, init) => {
      init && setLoading(true);
      
      const callback = await listIntegrations(dispatch, {
        page,
        per_page,
        geo_fence_id: COUNTRY_CODE[langCode][0],
      });
      init && callback && setLoading(false);
    },
    [dispatch, langCode, per_page]
  );

  useState(() => {
    getIntegrations(INITIAL_PAGE, 'init');
  }, [getIntegrations]);

  useEffect(() => {
    return () => cleanIntegrations(dispatch);
  }, [dispatch]);

  return (
    <div className="padding-page-full-height">
      <Title
        title={translate('app.integrations')}
        icon={<AppsIcon className="align-center" style={{ marginRight: 5 }} />}
        subtitle={translate('app.integrations.subtitle')}
      />
      <br />
      {loading && <Loading />}
      {list && (
        <Container maxWidth="md">
          <ListPlatformsTable
            data={list}
            getIntegrations={getIntegrations}
            recordsPerPage={per_page}
          />
        </Container>
      )}
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </div>
  );
}
