import React, { useState, useContext, useEffect } from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  Typography,
  Avatar,
  Button,
  Grid,
  Link,
  withStyles
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { I18nContext } from 'translations';
import { format } from 'date-fns';
import EmptyData from '../EmptyData';
import styles from '../style';

const RateBookingsTable = ({
  classes,
  data,
  sendRate,
  recordsPerPage,
  getUnqulifiedBookings
}) => {
  const [rating, setRating] = useState(0); 
  const [currentPage, setCurrentPage] = useState(data.page);
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    setCurrentPage(data.page)
  }, [data])

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage)
    getUnqulifiedBookings(newPage);
  }

  const onChangeRating = (event, newValue) => {
    setRating({
      [event.target.name]: newValue
    });
  }

  if (!data || (data && data.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />
  }

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell>{translate('app.rate.table.booking')}</TableCell>
              <TableCell>{translate('app.rate.table.date')}</TableCell>
              <TableCell>{translate('app.rate.table.driver')}</TableCell>
              <TableCell>{translate('app.rate.table.rate')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data.data || []).map(booking => (
              <TableRow hover key={booking._id}>
                <TableCell>
                  <Link
                    href={`/bookings/${booking._id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="primary"
                    underline="hover"
                  >
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      component="span"
                    >
                      {booking._id}
                    </Typography>
                  </Link>
                </TableCell>
                <TableCell style={{ minWidth: 170 }}>
                  <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    component="span"
                  >
                    {format(new Date(booking.created_at), 'dd/MM/yyyy, hh:mm a')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Grid container alignItems="center" spacing={1} style={{ minWidth: 205 }}>
                    <Grid item sm={3} xs={2}>
                      <Avatar
                        alt="driver"
                        src={booking.driver && booking.driver.photo_url ? booking.driver.photo_url : ''}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        component="span"
                      >
                        {booking.driver ? booking.driver.name : ' - '}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell style={{ minWidth: 230 }}>
                  <Rating
                    size="medium"
                    name={booking._id}
                    value={rating === 0 ? rating : rating[booking._id]}
                    className="align-center"
                    onChange={(event, newValue) => onChangeRating(event, newValue)}
                    style={{ marginRight: 10 }}
                  />
                  <Button variant="contained" color="primary" className="btn-rounded align-center" onClick={() => sendRate(booking._id, rating)}>
                    {translate('send')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.total ? data.total : data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
    </>
  );
}

export default withStyles(styles)(RateBookingsTable);

