import { CHECKIN, CHECKIN_ERROR, LOADING_CHECKIN, HAS_SHOPIFY } from './constants';
import { urlCheckin, urlGetShopityTemporal } from 'api';
import { post, get } from 'api/requests';
import device_metadata from 'utils/device';
import handleErrorMessage from 'utils/handleErrorMessage';

export const listCheckin = async (dispatch, country_id) => {
  dispatch({
    type: LOADING_CHECKIN,
    loading: true
  });
  await post(urlCheckin, { device_metadata, ...country_id })
    .then(({ data }) => {
      dispatch({
        type: CHECKIN,
        checkin: data
      });
      dispatch({
        type: LOADING_CHECKIN,
        loading: false
      })
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({
        type: CHECKIN_ERROR,
        message: mssg
      });
      dispatch({
        type: LOADING_CHECKIN,
        loading: false
      })
    });
}

export const getShopify = async (dispatch) => {
  await get(urlGetShopityTemporal, {})
    .then(({ data }) => {
      dispatch({
        type: HAS_SHOPIFY,
        hasShopify: data?.has_stores ? true : false
      });
    })
    .catch((error) => {
      //const mssg = handleErrorMessage(error, dispatch);
      //message(dispatch, mssg);
    });
}