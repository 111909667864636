import React, { useState, useContext } from 'react';
import { modalMessage } from 'utils';
import { disabledStoreWoocommerce, disabledStoreVtex } from 'context/integrations/actions';
import { useStateValue } from 'context/store';
import { I18nContext } from 'translations';
import { INITIAL_PAGE } from 'utils/constants';
import { Button } from '@material-ui/core';

const WooCommerceDisableStore = ({ account, getIntegrations, type = 0 }) => {
  const { _id, user_external_identificator } = account;
  const [, dispatch] = useStateValue();
  const { translate } = useContext(I18nContext);
  const [loading, setLoading] = useState(false);

  const messageOk = () => {
    setLoading(false);
    modalMessage(
      '',
      translate(type === 1 ? 'app.integrations.storeRemovedSuccessfully.vtex' : 'app.integrations.storeRemovedSuccessfully.woocommerce'),
      'success',
      translate('accept'),
      true
    ).then(() => {
      getIntegrations(INITIAL_PAGE);
    });
  };

  const disableIntegration = async (storeName, id) => {
    modalMessage(
      '',
      translate('app.integrations.messageConfirmRemoveStore'),
      'warning',
      'Continue',
      true,
      true,
      translate('cancel')
    ).then(async (response) => {
      if (response.isConfirmed) {
        setLoading(true);
        if (type === 1) {
          const callback = await disabledStoreVtex(
            dispatch,
            storeName.replace(/\s+/g, ''),
            id
          );
          callback && callback.status ? messageOk() : setLoading(false);
        } else {
          const callback = await disabledStoreWoocommerce(
            dispatch,
            storeName.replace(/\s+/g, ''),
            id
          );
          callback && callback.status ? messageOk() : setLoading(false);
        }
      }
    });
  };

  return (
    <Button
      color="secondary"
      size="small"
      disabled={loading}
      onClick={() => disableIntegration(user_external_identificator, _id)}
    >
      {translate('app.integrations.table.disable')}
    </Button>
  );
};

export default WooCommerceDisableStore;
