import { CREATE_SESSION, PERSONAL_DATA, CLOSE_SESSION} from './constants';
import { CHECKIN } from '../checkin/constants';
import {
  urlLogin,
  urlSignupUser,
  urlSignupCompany,
  urlRecoverPassword,
  urlUpdatePassword,
} from 'api';
import { post, patch } from 'api/requests';
import device_metadata from 'utils/device';
import handleErrorMessage from 'utils/handleErrorMessage';
import { loading, message } from '../commons/actions';

export const createSession = async (
  dispatch,
  values,
  history,
  id,
  shopifyData,
  idWaitDriver
) => {
  loading(dispatch, true);
  await post(urlLogin, { device: { ...device_metadata }, ...values })
    .then(({ data }) => {
      dispatch({
        type: CREATE_SESSION,
        session: data,
      });
      loading(dispatch, false);
      id.id
        ? history.push(`/bookings/${id.id}`)
        : idWaitDriver?.idWaitDriver
        ? history.push(`/confirm-wait-driver/${idWaitDriver.idWaitDriver}`)
        : history.push(shopifyData ? shopifyData : '/app');
    })
    .catch((error) => {
      loading(dispatch, false);
      if (
        error.response !== undefined &&
        error.response.status === 422 &&
        !error.response.data.mssg
      ) {
        dispatch({
          type: CREATE_SESSION,
          session: error.response.data,
        });
        history.push('/signup');
      } else {
        const mssg = handleErrorMessage(error, dispatch);
        message(dispatch, mssg);
      }
    });
};

export const signup = async (dispatch, values, type, history, shopifyData) => {
  loading(dispatch, true);
  await post(type === 1 ? urlSignupCompany : urlSignupUser, {
    device: { ...device_metadata },
    ...values,
  })
    .then(({ data }) => {
      dispatch({
        type: CREATE_SESSION,
        session: data,
      });
      loading(dispatch, false);
      history.push(shopifyData ? shopifyData : '/app');
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      loading(dispatch, false);
      message(dispatch, mssg);
    });
};

export const signupPersonalData = async (dispatch, values) => {
  dispatch({
    type: PERSONAL_DATA,
    personalData: values,
  });
};

export const recoverPassword = async (dispatch, values) => {
  loading(dispatch, true);
  let status = null;
  await post(urlRecoverPassword, { ...values })
    .then(() => {
      loading(dispatch, false);
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      loading(dispatch, false);
      message(dispatch, mssg);
    });
  return { status };
};

export const updatePassword = async (dispatch, values) => {
  loading(dispatch, true);
  let status = null;
  await patch(urlUpdatePassword, { ...values })
    .then(() => {
      loading(dispatch, false);
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      loading(dispatch, false);
      message(dispatch, mssg);
    });
  return { status };
};

export const closeSession = (dispatch) => {
  localStorage.clear();
  dispatch({
    type: CLOSE_SESSION,
    session: null,
  });
  dispatch({
    type: CHECKIN,
    checkin: null,
  });
};


