import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { I18nContext } from 'translations';
import FormPersonalData from 'components/Forms/Signup/FormPersonalData';
import FormCompanyData from 'components/Forms/Signup/FormCompanyData';
import styles from './styleTabs';

function TabPanel({ children }) {
  return (
    <Typography component="div">
      {children}
    </Typography>
  );
}

function TabsSignup({ submit, onSubmitPersonalData, classes }) {
  const [value, setValue] = useState(0);
  const { translate } = useContext(I18nContext);

  const handleChange = (event, newValue) => value === 1 && setValue(newValue);
  const onNext = () => setValue(1);

  return (
    <>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabsSignup}
        onChange={handleChange}
        centered
      >
        <Tab label={<span><RecentActorsIcon className={classes.icon} />{translate('personalInformation')}</span>} />
        <Tab label={<span><LocationCityIcon className={classes.icon} />{translate('companyData')}</span>} />
      </Tabs>
      <SwipeableViews
        index={value}
        className={classes.containerInputs}
      >
        <TabPanel value={value} index={0}>
          <FormPersonalData
            submit={onSubmitPersonalData}
            next={onNext}
          />
        </TabPanel>
        
        <TabPanel value={value} index={1}>
          <FormCompanyData
            submit={submit}
          />
        </TabPanel>
      </SwipeableViews>

    </>
  );
}

TabsSignup.propTypes = {
  submit: PropTypes.func.isRequired,
  onSubmitPersonalData: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TabsSignup);
