import React, { useContext } from 'react';
import { Typography, Button } from '@material-ui/core';
import { I18nContext } from 'translations';

export default function LinkAccount({ text, link, textLink }) {
  const { translate } = useContext(I18nContext);

  return (
    <div>
      <Typography variant="body1" component="span">
        {translate(text)}
      </Typography>
      <Button
        color="primary"
        href={link}
        size="small"
        style={{ textDecoration: 'underline', margin: '5px' }}
      >
        <b> {translate(textLink)}</b>
      </Button>
    </div>         
  );
}

