import React, { useState, useContext } from 'react';
import { Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import { I18nContext } from 'translations';

const TrackingRating = ({ qualified, sendRate, rate }) => {
  const { translate } = useContext(I18nContext);
  const [rating, setRating] = useState(rate && rate); 

  const onChangeRating = (event, newValue) => setRating(newValue);

  return (
    <>
      <Typography variant="h5" color="textPrimary">
        {qualified ? translate('tracking.thankYouForRatingOurService') : translate('tracking.howDidYouFindOurService')}
      </Typography>
      <br /><br />

      <Rating
        name="simple-controlled"
        size="large"
        value={rating}
        onChange={(event, newValue) => onChangeRating(event, newValue)}
        disabled={qualified && true}
      />
      <br /> <br />
      <Button variant="contained" color="primary" className="btn-rounded" disabled={qualified && true} onClick={() => sendRate(rating)}>
        {qualified ? `${translate('piboxTeam')} 😉` : translate('send')}
      </Button>
      <br />
    </>
  )
}

TrackingRating.propTypes = {
  qualified: PropTypes.any
};

export default TrackingRating;