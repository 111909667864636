import { createStyles } from '@material-ui/core/styles';

const styles = createStyles((theme) => ({
  content: {
    padding: '8px 30px 2px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 15px 2px'
    }
  },
  listContent: {
    maxHeight: 'calc(100vh - 280px)',
    overflow: 'auto',
    padding: '15px 20px 5px',
    marginBottom: 10
  },
  contentButtonSave: {
    marginTop: 10,
    paddingBottom: 0,
    textAlign: 'center'
  }
}));

export default styles;