import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Container, Typography, Button, Snackbar } from '@material-ui/core';
import { message as closeMessage } from 'context/commons/actions';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { getUserList, removeUserCompany, toggleUser } from 'context/settings/users/actions';
import { INITIAL_PAGE, PER_PAGE } from 'utils/constants';
import { modalMessage } from 'utils';
import UsersTable from 'components/Tables/Settings/Users';
import ModalUsers from 'components/Modals/Settings/Users';
import UsersTableFilters from 'components/Tables/Settings/Users/UsersTableFilters';
import Loading from 'components/Loading';

export default function Users() {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [userSearch, setUserSearch] = useState('');
  const [per_page] = useState(PER_PAGE);
  const [
    {
      users: { listUsers, loadingUsers },
      commons: { message },
      checkin: { company },
    },
    dispatch,
  ] = useStateValue();
  const { translate } = useContext(I18nContext);

  const editUser = (user) => {
    setOpen(true);
    setUser(user);
  };

  const messageOk = (mssgText) => {
    modalMessage(
      translate('congratulations'),
      translate(mssgText),
      'success',
      translate('accept'),
      true
    ).then(() => {
      setOpen(false);
      getUsers(INITIAL_PAGE);
    });
  };

  const removeUser = async (user) => {
    const { _id } = user;
    modalMessage(
      '',
      translate('messageConfirmDeleteUser'),
      'warning',
      'Continue',
      true,
      true,
      translate('cancel')
    ).then(async (response) => {
      if (response.isConfirmed) {
        const callback = await removeUserCompany(dispatch, { user_id: _id });
        callback && callback.status && messageOk('app.settings.users.userDeletedSuccessfully');
      }
    });
  };

  const getUsers = useCallback(
    async (page) => {
      const { _id } = company;
      getUserList(dispatch, _id, { page, per_page, user_search: userSearch });
    },
    [dispatch, per_page, company, userSearch]
  );

  useEffect(() => {
    company && getUsers(INITIAL_PAGE);
  }, [getUsers, company, userSearch]);

  const submitFilters = ({ name, email }) => {
    email ? setUserSearch(email) : setUserSearch(name);
  };

  const toggleActiveUser = async (user, type) => {
    const { _id } = user;
    modalMessage(
      '',
      type === 0 ? translate('app.settings.disableUserMessageConfirmation') : translate('app.settings.enableUserMessageConfirmation'),
      'warning',
      'Continue',
      true,
      true,
      translate('cancel')
    ).then(async (response) => {
      if (response.isConfirmed) {
        const callback = await toggleUser(dispatch, { user_id: _id });
        callback && callback.status && messageOk(type === 0 ? translate('app.settings.userDisabledNoRequestServicesMessage') : translate('app.setting.userActivated'));
      }
    });
  };

  return (
    <>
      <Typography variant="h5" color="primary">
        {translate('app.settings.users')}
      </Typography>
      <div className="text-center">
        <ModalUsers
          open={open}
          setOpen={setOpen}
          companyId={company && company._id}
          getUsers={getUsers}
          selectedUser={user && user}
          setUser={setUser}
        />
        <Container maxWidth="lg" className="padding0-xs">
          <div className="text-right">
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={() => setOpen(true)}
            >
              {translate('app.settings.users.createUser')}
            </Button>
          </div>
          <br />
          {loadingUsers && <Loading />}

          {listUsers && (
            <>
              <div className="text-left">
                <UsersTableFilters submit={submitFilters} />
              </div>
              <br />
              <UsersTable
                data={listUsers}
                editUser={editUser}
                removeUser={removeUser}
                getUsers={getUsers}
                recordsPerPage={per_page}
                toggleActiveUser={toggleActiveUser}
              />
            </>
          )}
        </Container>
      </div>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </>
  );
}
