import React, { useContext, useState } from 'react';
import {
  Button,
  InputLabel,
  TextField,
  Grid,
  Typography,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { I18nContext } from 'translations';
import schema from './schema';

export default function FormConfigurationWooCommerce({
  listWooCommerceStores,
  submit,
  storeName,
}) {
  const { translate } = useContext(I18nContext);
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const findStore = () =>
    listWooCommerceStores?.find(
      (listWooCommerceStore) => listWooCommerceStore.store_name === storeName
    );

  const [selectedStore] = useState(findStore());
  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      {listWooCommerceStores?.length > 0 && selectedStore ? (
        <>
          <Grid
            container
            style={{ marginBottom: 10 }}
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} style={{ marginBottom: 5 }}>
              <InputLabel>
                {translate('app.woocommerce.settingWooCommerce.store_name')}
              </InputLabel>
            </Grid>
            <Grid item sm={12}>
              <TextField
                type="text"
                name="store_name"
                variant="outlined"
                label={translate(
                  'app.woocommerce.settingWooCommerce.store_name'
                )}
                inputRef={register}
                size="small"
                fullWidth
                defaultValue={selectedStore.store_name}
                error={!!errors.store_name}
                helperText={
                  errors.store_name && translate(errors.store_name.message)
                }
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 5 }}>
              <InputLabel>
                {translate('app.integration.settingStore.form.syncEvery')}
              </InputLabel>
            </Grid>
            <Grid item sm={12}>
              <TextField
                type="text"
                name="order_synchronization_days"
                variant="outlined"
                label={translate('app.integration.settingStore.form.days')}
                inputRef={register}
                size="small"
                fullWidth
                defaultValue={selectedStore.order_synchronization_days || 15}
                error={!!errors.order_synchronization_days}
                helperText={
                  errors.order_synchronization_days &&
                  translate(errors.order_synchronization_days.message)
                }
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 5 }}>
              <InputLabel>
                {translate(
                  'app.woocommerce.settingWooCommerce.principal_address'
                )}
              </InputLabel>
            </Grid>
            <Grid item sm={12}>
              <TextField
                type="text"
                name="principal_address"
                variant="outlined"
                label={translate(
                  'app.woocommerce.settingWooCommerce.principal_address'
                )}
                inputRef={register}
                size="small"
                fullWidth
                defaultValue={selectedStore.main_address}
                error={!!errors.principal_address}
                helperText={
                  errors.principal_address &&
                  translate(errors.principal_address.message)
                }
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
          {translate('app.integration.settingStore.form.doNotHaveStores')}
        </Typography>
      )}
      <div className="text-center">
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={listWooCommerceStores?.length <= 0}
        >
          {translate('save')}
        </Button>
      </div>
    </form>
  );
}
