import React, { useContext } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { I18nContext } from 'translations';

export default function AutocompleteListControlled({
	data,
	label,
	setValue,
  inputValue,
  setInputValue
}) {
	const { translate } = useContext(I18nContext);
	
	return (
		<Autocomplete
			noOptionsText={translate('noMatches')}
			size="small" 
			options={data}
			getOptionLabel={(option) => translate(option.title)}
			onChange={(event, newValue) => setValue(newValue ? newValue.id : '')}
			inputValue={inputValue}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			renderInput={(params) => <TextField variant="outlined" {...params} label={translate(label)} />}
		/>
	)
}