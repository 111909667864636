import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { I18nContext } from 'translations';
import { bookingStatusTextClass, bookingStatusCircleClass } from 'utils';
import { BOOKING_STATUSES } from 'utils/constants';
import styles from './HeaderStatusStyles';

function HeaderStatus({ classes, id, status, onClickBack }) {
  const { translate } = useContext(I18nContext);
  return (
    <Typography variant="subtitle1">
      <ArrowBackIcon className={classes.icon} onClick={onClickBack} />
      <span className={classes.identifier}>
        {id}
      </span>
      <br className="block-xs" style={{ display: 'none' }} />
      <i className={bookingStatusCircleClass(status)} />
      <span className={bookingStatusTextClass(status)} style={{ verticalAlign: 'middle' }}>
        {translate(BOOKING_STATUSES[status])}
      </span>
    </Typography>
  )
}

export default withStyles(styles)(HeaderStatus);