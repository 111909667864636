import { LIST_PROMO_CODES, LIST_PROMO_CODES_LOADING } from './constants';

const promoCodesReducer = (state, action) => {
  switch (action.type) {
    case LIST_PROMO_CODES:
      return {
        ...state,
        list: action.list
      }
    case LIST_PROMO_CODES_LOADING:
      return {
        ...state,
        loadingPromoCode: action.loadingPromoCode
      }
    default:
      return state
  }
}

export default promoCodesReducer;