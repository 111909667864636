import { object, string } from 'yup';

const schema = object().shape({
  main_address: string()
    .required('requiredField'),
  main_city: string()
    .required('requiredField'),
});

export default schema;
