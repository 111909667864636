import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { I18nContext } from 'translations';

const FormButton = ({
  expanded,
  setExpanded,
  fields,
  address,
  origin,
  addStops,
  showPackage,
  setShowPackage,
  isValid,
  step,
  setStep,
  setCustomer,
  paymentMethod,
  handleSubmit,
  submit,
  moneyBalance,
  setCounterDelivery,
  serviceByHours,
  scheduledAt,
  vehicleTypes
}) => {
  const { translate } = useContext(I18nContext);
  const index = expanded.toString().match("stops(.*)") ? expanded.toString().match("stops(.*)")[1] : 0;
  const validPayment = paymentMethod !== "";
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    serviceByHours && setShowButton(true);
  }, [serviceByHours])

  return (
    <>
      {expanded === 'origin' ? (
        <Button
          type="button"
          size="large"
          color="primary"
          variant="contained"
          fullWidth
          style={{ borderRadius: '2px' }}
          onClick={() => {
            setCounterDelivery(moneyBalance);
            if (fields.length === 0) {
              return addStops()
            } else {
              return setExpanded('stops0')
            }
          }}
          disabled={(origin && origin.lat ? false : true) || (vehicleTypes === '' ? true : false) || !isValid}
        >
          {translate('app.express.next')}
        </Button>
      ) :  expanded !== 'origin' && expanded !== 'close' ? (
        <>
          {!showPackage ? (
            <Button
              type="button"
              size="large"
              color="primary"
              variant="contained"
              fullWidth
              style={{ borderRadius: '2px' }}
              disabled={(fields.length > 0 && address && address[index] && address[index].lat && address[index].name ? false : true)}
              onClick={e => {
                e.preventDefault();
                setShowPackage(true);
                const section = document.querySelector( `#indications${index}` );
                section && setTimeout(() => { section.focus()}, 50);
              }}
            >
              {translate('app.express.next')}
            </Button>
          ) : (
            <Button
              type="button"
              size="large"
              color="primary"
              variant="contained"
              fullWidth
              style={{ borderRadius: '2px' }}
              disabled={!isValid}
              onClick={e => {
                e.preventDefault();
                setExpanded('close');
                setShowPackage(false);
                setCustomer(false);
              }}
            >
              {translate('app.express.next')}
            </Button>
          )}
        </>
      ) : origin && origin.lat && fields.length > 0 && (
        <>
          {step === 0 ? (
            <Button
              type="button"
              size="large"
              color="primary"
              variant="contained"
              fullWidth
              style={{ borderRadius: '2px' }}
              disabled={!isValid}
              onClick={async e => {
                e.preventDefault();
                setStep(1);
                await handleSubmit((values) => submit(values, 1))()
              }}
            >
              {translate('app.express.goPay')}
            </Button>
          ) : (step === 1 && serviceByHours && scheduledAt && showButton) ? (
            <Button
              type="button"
              size="large"
              color="primary"
              variant="contained"
              fullWidth
              style={{ borderRadius: '2px' }}
              disabled={!isValid}
              onClick={async e => {
                e.preventDefault();
                await handleSubmit((values) => submit(values, 1))()
                setShowButton(false)
              }}
            >
              {translate('recalculate')}
            </Button>
          ) : (
              <Button
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                fullWidth
                style={{ borderRadius: '2px' }}
                disabled={!isValid || !validPayment}
              >
                {translate('app.express.confirmService')}
              </Button>
          )}
        </>
      )}
    </>
  )
}

export default FormButton;