import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { I18nContext } from 'translations';
import CardEstimatedData from './CardEstimatedData';
import { formatCostTemporal, convertToKm, convertToMin } from 'utils';

export default function EstimatedData({
  finalCost,
  estimatedCost,
  companyFinalCost,
  companyEstimatedCost,
  estimatedTraveledTime,
  estimatedTraveledDistance
}) {
  const { translate } = useContext(I18nContext);
  const cost = companyFinalCost || companyEstimatedCost ?
    companyFinalCost ? formatCostTemporal(companyFinalCost) : formatCostTemporal(companyEstimatedCost)
    : finalCost ? formatCostTemporal(finalCost) : formatCostTemporal(estimatedCost)

  return (
    <Grid container alignItems="center" style={{ padding: '20px 10px' }} spacing={2}>
      <Grid item md={4} sm={4} xs={12} className="padding0-xs2 ">
        <CardEstimatedData
          label={translate('app.booking.estimatedValue')}
          text={cost ? cost : ' - '}
        />
      </Grid>
      <Grid item md={4} sm={4} xs={12} className="padding0-xs2">
        <CardEstimatedData
          label={translate('app.booking.distance')}
          text={estimatedTraveledDistance ? convertToKm(estimatedTraveledDistance) : '-'}
        />
      </Grid>
      <Grid item md={4} sm={4} xs={12} className="padding0-xs2">
        <CardEstimatedData
          label={translate('app.booking.estimatedTime')}
          text={estimatedTraveledTime ? convertToMin(estimatedTraveledTime) : '-'}
        />
      </Grid>
    </Grid>
  )
}