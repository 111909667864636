import React, { useContext } from 'react';
import { Grid, Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { I18nContext } from 'translations';

export default function ModalEvidences({ handleModal, open, evidences }) {
  const { translate } = useContext(I18nContext)

  const imagesPicked = [
    evidences?.picked,
    evidences?.optional_picked
  ].filter((value) => value != null)

  const imagesDelivered = [
    evidences?.delivered,
    evidences?.optional_delivered
  ].filter((value) => value != null)

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleModal}
        maxWidth="md"
        fullWidth
      >
        <Typography className="text-center" variant="h5" color="primary" style={{ padding: 20 }}>
          {translate('evidence')}
        </Typography>
        <DialogContent>
          <Grid container className="text-center">
            <Grid item md={6}>
              <Typography className="text-center" variant="subtitle1">
                {translate('evidence.evidenceCollection')}
              </Typography>
              <br />
              <PhotoProvider>
                {imagesPicked.map((item, index) => (
                  <PhotoView key={`picked-${index}`} src={item}>
                    <img
                      src={item}
                      alt="Picked"
                      width="140"
                      height="160"
                      style={{ marginRight: 15, objectFit: 'contain', cursor: 'pointer' }}
                    />
                  </PhotoView>
                ))}
              </PhotoProvider>
            </Grid>
            <Grid item md={6}>
              <Typography className="text-center" variant="subtitle1">
                {translate('evidence.evidenceDelivery')}
              </Typography>
              <br />
              <PhotoProvider>
                {imagesDelivered.map((item, index) => (
                  <PhotoView key={`delivered-${index}`} src={item}>
                    <img
                      src={item}
                      alt="Delivered"
                      width="140"
                      height="160"
                      style={{ marginRight: 15, objectFit: 'contain', cursor: 'pointer' }}
                    />
                  </PhotoView>
                ))}
              </PhotoProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModal} color="primary">
            {translate('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}