import React, { useContext, useEffect, useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Button,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import CachedIcon from '@material-ui/icons/Cached';
import { useStateValue } from 'context/store';
import { getCostCenter } from 'context/express/actions';
import { I18nContext } from 'translations';
import { BALANCE_CORPORATIVE_PROMOCODE_TYPES, BALANCE_STATUS } from 'utils/constants';
import styles from '../../styleTableFilters';
import { schema } from './schema';

const defaultValues = {
  assigned_passenger: '',
  creator_passenger: '',
  type_cd: '',
  cost_center: '',
  created_at: null,
  ini_date: null,
  end_date: null
};

const BalancesTableFilters = ({ classes, onFilters, filters }) => {
  const { translate } = useContext(I18nContext);
  const [costCenter, setCostCenter] = useState([]);
  const [{ checkin: { company }} ] = useStateValue();

  const { handleSubmit, control, reset, errors } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema)
  });

  const listCostCenter = useCallback(async () => {
    const callback = company && (await getCostCenter(company._id));
    callback && callback.costCenter && setCostCenter(callback.costCenter);
  }, [company]);

  useEffect(() => {
    listCostCenter();
  }, [listCostCenter]);

  const onSubmit = (values) => {
    const params = {
      ...filters,
      ...values,
      created_at: values.created_at && format(values.created_at, 'yyyy-MM-dd'),
      ini_date: values.ini_date && format(values.ini_date, 'yyyy-MM-dd'),
      end_date: values.end_date && format(values.end_date, 'yyyy-MM-dd'),
      type_cd: values.type_cd ? parseInt(values.type_cd) : ''
    };
    Object.keys(params).forEach((key) => {
      if (params[key] === '' || params[key] == null) {
        delete params[key];
      }
    });
    onFilters(params);
  };

  const clearFilters = () => {
    reset(defaultValues);
    onFilters("");
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      noValidate
      className="relative"
    >
      <Grid container spacing={1}>
        <Grid item md={3} sm={4} xs={6} className={classes.filter}>
          <Controller
            name="assigned_passenger"
            control={control}
            defaultValue=""
            render={({ onChange, value }) => (
              <TextField
                type="email"
                variant="outlined"
                label={translate('promoCode.filter.assignedTo')}
                fullWidth
                size="small"
                onChange={onChange}
                value={value}
                error={!!errors.assigned_passenger}
                helperText={errors.assigned_passenger && translate(errors.assigned_passenger.message)}
              />
            )}
          />
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Controller
              name="created_at"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <KeyboardDatePicker
                  className={classes.filter}
                  size="small"
                  inputVariant="outlined"
                  label={translate('promoCode.filter.creationDate')}
                  format="dd/MM/yyyy"
                  cancelLabel={translate('cancel')}
                  okLabel={translate('accept')}
                  maxDate={new Date()}
                  invalidDateMessage={translate('invalidFormat')}
                  fullWidth
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Controller
              name="ini_date"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <KeyboardDatePicker
                  className={classes.filter}
                  size="small"
                  inputVariant="outlined"
                  label={translate('promoCode.filter.validSince')}
                  format="dd/MM/yyyy"
                  cancelLabel={translate('cancel')}
                  okLabel={translate('accept')}
                  invalidDateMessage={translate('invalidFormat')}
                  fullWidth
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Controller
              name="end_date"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <KeyboardDatePicker
                  className={classes.filter}
                  size="small"
                  inputVariant="outlined"
                  label={translate('promoCode.filter.validUntil')}
                  format="dd/MM/yyyy"
                  cancelLabel={translate('cancel')}
                  okLabel={translate('accept')}
                  invalidDateMessage={translate('invalidFormat')}
                  fullWidth
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        
        <Grid item md={3} sm={4} xs={6} className={classes.filter}>
          <Controller
            name="creator_passenger"
            control={control}
            defaultValue=""
            render={({ onChange, value }) => (
              <TextField
                type="text"
                variant="outlined"
                label={translate('promoCode.filter.applicant')}
                fullWidth
                size="small"
                onChange={onChange}
                value={value}
                error={!!errors.creator_passenger}
                helperText={errors.creator_passenger && translate(errors.creator_passenger.message)}
              />
            )}
          />
        </Grid>

        <Grid item md={3} sm={4} xs={6}>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.filter}
            fullWidth
          >
            <InputLabel>{translate('balances.table.service_type')}</InputLabel>
            <Controller
              control={control}
              name="type_cd"
              label={translate('balances.table.service_type')}
              variant="outlined"
              defaultValue=""
              as={
                <Select>
                  <MenuItem value="">
                    {translate('app.bookings.filter.choose')}
                  </MenuItem>
                  {Object.keys(BALANCE_CORPORATIVE_PROMOCODE_TYPES).map((key) => (
                    <MenuItem key={key} value={key}>
                      {translate(BALANCE_CORPORATIVE_PROMOCODE_TYPES[key])}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Grid>
        
        <Grid item md={3} sm={4} xs={6}>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.filter}
            fullWidth
          >
            <InputLabel>
              {translate('promoCode.filter.costCenter')}
            </InputLabel>
            <Controller
              control={control}
              name="cost_center"
              label={translate('promoCode.filter.costCenter')}
              variant="outlined"
              defaultValue=""
              as={
                <Select>
                  <MenuItem value="">
                    {translate('app.bookings.filter.choose')}
                  </MenuItem>
                  {costCenter.map((center) => (
                    <MenuItem key={center._id} value={center._id}>
                      {center.name}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Grid>

        <Grid item md={3} sm={4} xs={6}>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.filter}
            fullWidth
          >
            <InputLabel>{translate('balances.table.status')}</InputLabel>
            <Controller
              control={control}
              name="status_cd"
              label={translate('balances.table.status')}
              variant="outlined"
              defaultValue=""
              as={
                <Select>
                  <MenuItem value="">
                    {translate('app.bookings.filter.choose')}
                  </MenuItem>
                  {Object.keys(BALANCE_STATUS).map((key) => (
                    <MenuItem key={key} value={key}>
                      {translate(BALANCE_STATUS[key])}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Grid>

        <Grid item md={6} xs={12} className="text-center-sm">
          <Button
            variant="contained"
            color="primary"
            size="small"
            type="submit"
            style={{ width: 120, marginRight: 10 }}
          >
            {translate('app.bookings.filter.search')}
          </Button>
          <Button
            color="primary"
            size="small"
            type="button"
            style={{ width: 100 }}
            onClick={clearFilters}
          >
            {translate('clear')}
          </Button>
        </Grid>
      </Grid>
      <Button
        color="primary"
        size="small"
        type="submit"
        style={{ position: 'absolute', right: 0 }}
      >
        <CachedIcon fontSize="small" />
      </Button>
      
    </form>
  );
};

export default withStyles(styles)(BalancesTableFilters);