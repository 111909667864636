import { LIST_SERVICES } from './constants';

const servicesOperationsReducer = (state, action) => {
  switch (action.type) {
    case LIST_SERVICES: 
      return {
        ...state,
        list: action.list
      }
    default:
      return state
  }
}

export default servicesOperationsReducer;
