import React, { useContext, useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { useForm, Controller } from 'react-hook-form';
import esLocale from 'date-fns/locale/es';
import DateFnsUtils from '@date-io/date-fns';
import { useStateValue } from 'context/store';
import { listTypePqr } from 'context/claims/actions';
import { PQR_STATUSES } from 'utils/constants';
import { formatDate, cleanObject } from 'utils';
import { I18nContext } from 'translations';
import styles from '../../styleTableFilters';

const defaultValues = {
  ini_date: null,
  end_date: null,
  booking_id: '',
  status_cd: '',
  type_of_request_cd: ''
};

const ClaimsTableFilters = ({ classes, onFilters }) => {
  const { translate } = useContext(I18nContext);
  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const [{ claims: { typePqr } }, dispatch] = useStateValue(); 

  const getTypePQR = useCallback(
    async () => {
      await listTypePqr(dispatch);
    }, [dispatch]
  );

  useEffect(() => {
    getTypePQR()
  },[getTypePQR])


  const onSubmit = (values) => {
    let params = {
      ...values,
      ini_date: formatDate(values?.ini_date),
      end_date: formatDate(values?.end_date),
    };
    params = cleanObject(params)
    onFilters(params);
  };

  const clearFilters = () => {
    reset(defaultValues);
    onFilters('');
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate className="relative">
      <Grid container spacing={2} alignItems="center">
        <Grid item md={3} sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Controller
              name="ini_date"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <KeyboardDatePicker
                  className={classes.filterStyle}
                  inputVariant="outlined"
                  label={translate('claims.table.filter.dateFrom')}
                  format="dd/MM/yyyy"
                  cancelLabel={translate('cancel')}
                  okLabel={translate('accept')}
                  invalidDateMessage={translate('invalidFormat')}
                  fullWidth
                  value={value}
                  onChange={onChange}
                  size="small"
                />
              )}
            />
          </MuiPickersUtilsProvider> 
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Controller
              name="end_date"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <KeyboardDatePicker
                  className={classes.filterStyle}
                  inputVariant="outlined"
                  label={translate('claims.table.filter.dateUntil')}
                  format="dd/MM/yyyy"
                  cancelLabel={translate('cancel')}
                  okLabel={translate('accept')}
                  invalidDateMessage={translate('invalidFormat')}
                  fullWidth
                  value={value}
                  onChange={onChange}
                  size="small"
                />
              )}
            />
          </MuiPickersUtilsProvider> 
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <FormControl
            variant="outlined"
            className={classes.filterStyle}
            fullWidth
            size="small"
          >
            <InputLabel>{translate('claims.table.filter.claimStatus')}</InputLabel>
            <Controller
              control={control}
              name="status_cd"
              label={translate('claims.table.filter.claimStatus')}
              variant="outlined"
              as={
                <Select>
                  <MenuItem value="">{translate('claims.table.filter.claimStatus')}</MenuItem>
                  {Object.keys(PQR_STATUSES).map(key => (
                    <MenuItem key={key} value={key}>{translate(PQR_STATUSES[key])}</MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <FormControl
            variant="outlined"
            className={classes.filterStyle}
            fullWidth
            size="small"
          >
            <InputLabel>Tipo de solicitud</InputLabel>
            <Controller
              control={control}
              name="type_of_request_cd"
              label="Tipo de solicitud"
              variant="outlined"
              as={
                <Select>
                  <MenuItem value="">Tipo de solicitud</MenuItem>
                  {typePqr?.map((type) => (
                    <MenuItem value={type.cd} key={`type-pqr-${type.cd}`}>
                      {type.text}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <Controller
            name="booking_id"
            control={control}
            variant="outlined"
            defaultValue=""
            render={({ onChange, value }) => (
              <TextField
                size="small"
                className={classes.filterStyle}
                variant="outlined"
                label={translate('claims.table.filter.bookingId')}
                fullWidth
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>

        <Grid item md={6} xs={8} className="text-center-sm">
          <Button
            variant="outlined"
            color="primary"
            type="button"
            style={{ width: 120, marginRight: 10 }}
            onClick={clearFilters}
            className="btn-rounded-style"
          >
            {translate('clear')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ width: 120 }}
            className="btn-rounded-style"
          >
            {translate('search')}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default withStyles(styles)(ClaimsTableFilters);