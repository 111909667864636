import { object, string } from 'yup';

const schema = object().shape({
  vtex_app_key: string()
    .matches(/^\S*$/, 'canNotHaveSpacesMessage')
    .required('requiredField'),
  vtex_app_token: string()
    .matches(/^\S*$/, 'canNotHaveSpacesMessage')
    .required('requiredField'),
  account_name: string()
    .matches(/^\S*$/, 'canNotHaveSpacesMessage')
    .required('requiredField'),
  environment: string()
    .matches(/^\S*$/, 'canNotHaveSpacesMessage')
    .required('requiredField'),
  address: string()
    .required('selectAnOptionFromTheList'),
});

export default schema;
