import React, { useContext, useEffect, useState } from 'react';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Grid,
  Divider
} from '@material-ui/core';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { format } from 'date-fns';
import { I18nContext } from 'translations';
import { assingPromoCode } from 'context/promocode/actions';
import { useStateValue } from 'context/store';
import { modalMessage, modalMessageHtml, formatCostCurrency } from 'utils';
import { INITIAL_PAGE } from 'utils/constants';
import Loading from 'components/Loading';


function ModalAssignPromoCode ({
  open,
  setOpen,
  codeInfo,
  setCodeInfo,
  getListPromoCode,
  setIsCheckAll
}) {
  const { translate } = useContext(I18nContext);
  const [{ checkin: { company } }, dispatch ] = useStateValue();
  const [loading, setLoading] = useState(false);

  const { handleSubmit, register, control, errors, reset } = useForm({
    defaultValues: {
      allocations: [{}]
    }
  });

  const { fields } = useFieldArray({
    control,
    name: "allocations"
  });

  const messageOk = () => {
    setLoading(false);
    modalMessage('', translate('promoCode.actions.assignedOk'), 'success', translate('continue'), true).then(async() => {
      setOpen(false);
      getListPromoCode(INITIAL_PAGE);
      setCodeInfo([]);
      setIsCheckAll(false);
    })
  }

  const messageUnasignables = (unasignables) => {
    setLoading(false);
      let unasignablesContent = unasignables.map((item) => { 
      return `<li class='showCode'>
        ${translate('promoCode.actions.unasignables.code')}: <b class='showCode-code'>${item.code} </b>
        <br /> ${translate('promoCode.actions.unasignables.email')}: <b> ${item.email}</b>
        <br /> ${translate('promoCode.actions.unasignables.balance')}: <b> ${item.amount}</b>
        <br /> ${translate('promoCode.actions.unasignables.validSince')}: <b> ${format(new Date(item.ini_date),'dd/MM/yyyy, hh:mm a')}</b>
        <br /> ${translate('promoCode.actions.unasignables.validUntil')}: <b> ${format(new Date(item.end_date),'dd/MM/yyyy, hh:mm a')}</b>
      </li>`;
  }).join('');

  modalMessageHtml('', `<h4 class='showCode-unasignables'>
    ${translate('promoCode.actions.unasignables.unasignablesText')}</h4>
    <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;">
      <span class="swal2-x-mark">
      <span class="swal2-x-mark-line-left"></span>
      <span class="swal2-x-mark-line-right"></span>
      </span>
    </div><br />
   ${translate('promoCode.actions.tryAgain')} <br /><br /><div class='text-left container-unasignables'>
    ${unasignablesContent}</div>`, 'error', translate('close'), false, '', false, 750)
    .then(() => {
      setOpen(false)
      setCodeInfo([])
      setIsCheckAll(false);
    })
  }

  const submit = async (values) => {
    setLoading(true);
    const callback = company && await assingPromoCode(dispatch, company._id, values);
    callback?.unasignables?.length > 0 ? messageUnasignables(callback?.unasignables) : callback.status ? messageOk() : setLoading(false)
  }

  useEffect(() => {
    codeInfo.length > 0 && reset({allocations: [...codeInfo]})
  },[codeInfo]) // eslint-disable-line

  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
      className="modal-assign-promo-code"
    >
      <DialogTitle className="text-center" disableTypography style={{ paddingTop: 30 }}>
        <Typography variant="h5" color="primary">
          {translate('promoCode.form.assign.assignCode')}
        </Typography>
      </DialogTitle>
      <DialogContent className="content-modal">
        {loading && <Loading />}
        <form
          onSubmit={handleSubmit(submit)}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} className="content-modal-form">
              <div className="bg-grey-block">
                <Grid container spacing={2}>  
                  <Grid item xs={5}>
                    <Typography variant="body1">
                      <b>{translate('promoCode.form.assign.details')}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body1">
                      <b>{translate('promoCode.form.assign.beneficiary')}</b>
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            {fields.map((item, index) => (
              <Grid container className="content-form-assign-code" key={item.id}>
                <Grid item sm={5} xs={12} style={{ marginBottom: 15 }}>
                  <Typography variant="body1">
                    {translate('promoCode.form.assign.code')}: <b>{codeInfo[index]?.code}</b>
                  </Typography>
                  <Typography variant="body1">
                    {translate('promoCode.form.assign.balance')}: <b>{codeInfo[index] && formatCostCurrency(codeInfo[index].amount)}</b>
                  </Typography>
                  <Typography variant="body1">
                    {translate('promoCode.form.assign.validSince')}: <b>{codeInfo[index]?.ini_date && format(new Date(codeInfo[index].ini_date),'dd/MM/yyyy, hh:mm a')}</b>
                  </Typography>
                  <Typography variant="body1">
                    {translate('promoCode.form.assign.validUntil')}: <b>{codeInfo[index]?.end_date && format(new Date(codeInfo[index].end_date), 'dd/MM/yyyy, hh:mm a')}</b>
                  </Typography>
                </Grid>
                <Grid item sm={7} xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item md={12} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            type="hidden"
                            defaultValue={codeInfo[index]?._id}
                            fullWidth
                            name={`allocations[${index}].code_id`}
                            required
                            inputRef={register()}
                            style={{ visibility: 'hidden' }}
                          />
                          <TextField
                            label={translate('promoCode.form.assign.email')}
                            size="small"
                            type="text"
                            defaultValue=""
                            variant="outlined"
                            fullWidth
                            name={`allocations[${index}].assigned_to.email`}
                            required
                            inputRef={register({
                              required: translate('requiredField'),
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: `${translate('invalidEmailAddress')}`,
                              }
                            })}
                            error={
                              errors.allocations &&
                              errors.allocations[index] &&
                              !!errors.allocations[index].assigned_to.email
                            }
                            helperText={
                              errors.allocations &&
                              errors.allocations[index] &&
                              errors.allocations[index].email &&
                              errors.allocations[index].email.assigned_to.message
                            }
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Grid container spacing={1}>
                            <Grid item md={4} sm={4} xs={4} className="phone-input-standard-border text-left">
                              <Controller
                                control={control}
                                name={`allocations[${index}].assigned_to.country_code`}
                                defaultValue="57"
                                variant="outlined"
                                size="small"
                                as={
                                  <PhoneInput
                                    country="co"
                                    defaultCountry="co"
                                    regions={['america']}
                                    preferredCountries={['co', 'mx', 'br', 'pe', 'py']}
                                    inputProps={{ disabled: true }}
                                  />
                                }
                              />
                            </Grid>
                            <Grid item md={8} sm={8} xs={8}>
                              <TextField
                                variant="outlined"
                                type="tel"
                                defaultValue=""
                                fullWidth
                                size="small"
                                label={translate('promoCode.form.assign.phone')}
                                required
                                inputProps={{ maxLength: "10" }}
                                name={`allocations[${index}].assigned_to.phone_number`}
                                inputRef={register({
                                  required: translate('requiredField'),
                                  pattern: {
                                    value: /^[\d ]*$/,
                                    message: `${translate('onlyNumbers')}`,
                                  },
                                })}
                                error={
                                  errors.allocations &&
                                  errors.allocations[index] &&
                                  !!errors.allocations[index].assigned_to.phone_number
                                }
                                helperText={
                                  errors.allocations &&
                                  errors.allocations[index] &&
                                  errors.allocations[index].assigned_to.phone_number &&
                                  errors.allocations[index].assigned_to.phone_number.message
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Divider className="content-modal-form-divider"/>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12} style={{ marginTop: fields.length > 1 ? 10 : 30 }}>
              <div className="bg-grey-block" style={{ marginBottom: 20 }}>
                <Typography variant="body1">
                  <b>{translate('promoCode.form.assign.messageToSend')}</b>
                </Typography>
              </div>
              <div className="content-form-assign-code">
                <TextField
                  name="notification_message"
                  size="small"
                  type="text"
                  defaultValue=""
                  variant="outlined"
                  fullWidth
                  required
                  inputRef={register}
                  multiline
                  rows={4}
                />
              </div>
            </Grid>
          </Grid>
          <DialogActions style={{ padding: '10px 10px 20px' }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
            >
              {translate('promoCode.form.assign.assignCode')}
            </Button>
            <Button
              onClick={() => {
                setOpen(false)
                setCodeInfo([])
                setIsCheckAll(false)
              }}
            >
              {translate('close')}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default ModalAssignPromoCode;