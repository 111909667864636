import React, { useContext, useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Snackbar,
} from '@material-ui/core';
import { INITIAL_PAGE, PER_PAGE } from 'utils/constants';
import { getListGroups } from 'context/settings/groups/actions';
import { withStyles } from '@material-ui/core/styles';
import { useStateValue } from 'context/store';
import Loading from 'components/Loading';
import { message as closeMessage } from 'context/commons/actions';
import { I18nContext } from 'translations';
import GroupChooseTable from 'components/Tables/Settings/Groups/GroupChooseTable';
import styles from '../GroupsStyle';

function ModalListGroups ({
  open,
  setOpen,
  setChosenGroup,
  classes
}) {
  const { translate } = useContext(I18nContext);
  const [per_page] = useState(PER_PAGE);
  const [{ commons: { message }, checkin: { company }, groups: { listGroups, loadingGroups } }, dispatch] = useStateValue();

  const getGroups = useCallback(async (page) => {
    const { _id } = company;
    getListGroups(dispatch, _id, { page, per_page })
  }, [dispatch, per_page, company]);

  useEffect(() => {
    getGroups(INITIAL_PAGE);
  }, [getGroups]);

  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="text-center" disableTypography>
        <Typography variant="h5" color="primary">
          {translate('app.settings.users.form.groupsList')}
        </Typography>
        <Typography variant="subtitle2">
          {translate('app.settings.users.form.labelAssignGroup')}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.content}>
        {loadingGroups && <Loading />}
        {listGroups && (
          <GroupChooseTable
            data={listGroups}
            getGroups={getGroups}
            recordsPerPage={per_page}
            setChosenGroup={setChosenGroup}
            setOpen={setOpen}
          />
        )}
        
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>
          {translate('close')}
        </Button>
      </DialogActions>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </Dialog>
  )
}

export default withStyles(styles)(ModalListGroups);