import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  Link,
  IconButton
} from '@material-ui/core';
import { format } from 'date-fns';
import { I18nContext } from 'translations';
import { PREPACKAGES_STATUSES } from 'utils/constants';
import { prepackagesStatusClass } from 'utils';
import EmptyData from '../EmptyData';
import styles from '../style';
import { cancelPrepackage } from 'context/prepackages/actions';
import { useStateValue } from 'context/store';
import ModalPrepackages from 'components/Modals/ModalPrepackages';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const PrepackageTable = ({
  classes,
  data,
  recordsPerPage,
  getPrepackages,
  user
}) => {
  const [, dispatch] = useStateValue();
  const [currentPage, setCurrentPage] = useState(data.page);
  const { translate } = useContext(I18nContext);
  const [prepackageUpdate, setPrepackageUpdate] = useState(null);
  
  const handleCancelClick = async (prePackageId) => {
    await cancelPrepackage(dispatch, prePackageId, user);
  };

  const handleUpdate = (id) => {
    setPrepackageUpdate(id);
  }
  const handleClose = () => {
    setPrepackageUpdate(null)
  }

  useEffect(() => {
    setCurrentPage(data.page);
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    getPrepackages(newPage, user);
  };

  if (!data || (data && data.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />;
  }

  return (
    <>
      <TableContainer>
        <Table className={classes.tableStyle} size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{translate('prepakages.table.status')}</TableCell>
              <TableCell>{translate('prepackages.table.date')}</TableCell>
              <TableCell>{translate('prepackages.table.originAddress')}</TableCell>
              <TableCell>{translate('prepakages.table.destinationAddress')}</TableCell>
              <TableCell>{translate('prepakages.table.orderNumber')}</TableCell>
              <TableCell>{translate('prepakages.table.id')}</TableCell>
              <TableCell>{translate('prepakages.table.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data.data || []).map((pkg, index) => (
              <TableRow
                hover
                key={pkg.id}
              >
                <TableCell>
                  <span className={prepackagesStatusClass(pkg.status_cd)}>
                    {translate(PREPACKAGES_STATUSES[pkg.status_cd])}
                  </span>
                </TableCell>
                <TableCell>
                  {pkg.choosed_at && (
                    <span className={classes.date}>
                      {format(
                        new Date(pkg.choosed_at),
                        'dd/MM/yyyy, hh:mm a'
                      )}
                    </span>
                  )}
                </TableCell>
                <TableCell>
                  {pkg.booking_id ?
                    <Link
                      color="primary"
                      href={`/bookings/${pkg.booking_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {pkg.origin_address}
                    </Link>
                  : pkg.origin_address
                  }
                </TableCell>
                <TableCell>
                  {pkg.end_address}
                </TableCell>
                <TableCell>
                  {pkg.reference}
                </TableCell>
                <TableCell>
                  {pkg.id}
                </TableCell>
                <TableCell>
                  { pkg.status_cd === 0 && (
                    <>
                      <IconButton color="primary" onClick={() => handleUpdate(pkg.id)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton color="secondary" onClick={() => handleCancelClick(pkg.id)}>
                        <DeleteIcon/>
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.total ? data.total : data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
      {prepackageUpdate && 
        <ModalPrepackages
          handleClose={handleClose}
          open={!!prepackageUpdate}
          id={prepackageUpdate}
        />
      }
    </>
  );
};

function areEqual(prevProps, nextProps) {
  return prevProps.data === nextProps.data;
}

export default withStyles(styles)(React.memo(PrepackageTable, areEqual));
