import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const PasswordInput = ({
  label,
  inputRef,
  defaultValue,
  error,
  errortext,
  name,
  size = 'small'
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      name={name}
      variant="outlined"
      label={label}
      inputRef={inputRef}
      size={size}
      fullWidth
      defaultValue={defaultValue}
      error={error}
      helperText={errortext}
      autoComplete="new-password"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              size={size}
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordInput;
