import React, { useContext } from 'react';
import {
  Grid,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  Snackbar,
  CircularProgress
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { createBankAccount, editBankAccount } from 'context/payments/actions';
import { modalMessage } from 'utils';
import { I18nContext } from 'translations';
import schema from './schema';

export default function ModalAccounts ({
  open,
  setOpen,
  bankList,
  accountTypeList,
  documentTypeList,
  getAccounts,
  bankAccountSelected
}) {
  const { translate } = useContext(I18nContext);
  const { handleSubmit, register, errors, control } = useForm({
    resolver: yupResolver(schema)
  });
  const [{ commons: { loading, message }}, dispatch] = useStateValue();

  const messageOk = () => {
    modalMessage(translate('congratulations'), translate('app.payments.bankAccount.accountAddedSuccessfully'), 'success', translate('accept'), true).then(() => {
      getAccounts();
      setOpen(false);
    })
  }

  const submit = async values => {
    if (bankAccountSelected) {
      const callback = await editBankAccount(dispatch, bankAccountSelected._id, { bank_account: { ...values } });
      callback.status && messageOk();
    } else {
      const callback = await createBankAccount(dispatch, { bank_account: {...values} });
      callback.status && messageOk();
    }
  }

  const bankSelected = () => bankList.findIndex(id => id.code === bankAccountSelected.bank._id);

  const bankAccountTypeSelected = () => accountTypeList.findIndex(id => id.code === bankAccountSelected.bank_account_type._id);

  const documentTypeListSelected = () => documentTypeList.findIndex(id => id.code === bankAccountSelected.bank_fiscal_number_type._id);

  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <div className="text-center">
        <img src="/images/payments/creditCardScanning.gif"
          alt="accounts"
          style={{ width: '145px', marginTop: '15px' }}
        />
      </div>
      <DialogTitle className="text-center" disableTypography style={{ padding: '0 20px' }}>
        <Typography variant="h5" color="primary">
          {bankAccountSelected ? 'Editar cuenta' : translate('app.payments.bankAccounts.addAccount')}
        </Typography>
      </DialogTitle>

      <DialogContent className="text-center" style={{ margin: '8px 13px 2px' }}>

        <form onSubmit={handleSubmit(submit)} noValidate>
          <FormControl fullWidth className="text-left" variant="outlined" size="small" style={{ marginBottom: '12px' }}>
            <InputLabel>{translate('app.payments.bankAccounts.bank')} * </InputLabel>
            <Controller
              control={control}       
              name="bank_id"
              defaultValue={bankAccountSelected ? bankList[bankSelected()].code : ""}
              label={`${translate('app.payments.bankAccounts.bank')} *`}
              error={!!errors.bank_id}
              as={
                <Select >
                  {bankList.map(bank => ( 
                    <MenuItem value={bank.code} key={bank.code}>
                      {bank.name}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
            {errors.bank_id && (
              <p className="error">
                {translate(errors.bank_id.message)}
              </p>
            )}
          </FormControl>
          <Grid container spacing={2} style={{ marginBottom: '5px' }}>
            <Grid item md={6} sm={6} xs={12}>
              <FormControl fullWidth className="text-left" variant="outlined" size="small">
                <InputLabel>{translate('app.payments.bankAccounts.accountType')} *</InputLabel>
                <Controller
                  control={control}
                  name="bank_account_type_id"
                  defaultValue={bankAccountSelected ? accountTypeList[bankAccountTypeSelected()].code : ''}
                  label={`${translate('app.payments.bankAccounts.accountType')} *`}
                  error={!!errors.bank_account_type_id}
                  as={
                    <Select>
                      {accountTypeList.map(accountType => ( 
                        <MenuItem value={accountType.code} key={accountType.code}>
                          {accountType.name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
                {errors.bank_account_type_id && (
                  <p className="error">
                    {translate(errors.bank_account_type_id.message)}
                  </p>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                name="number"
                label={translate('app.payments.bankAccounts.accountNumber')}
                variant="outlined"
                size="small"
                type="text"
                defaultValue={bankAccountSelected ? bankAccountSelected.number : ""}
                fullWidth
                required
                inputRef={register}
                error={!!errors.number}
                helperText={errors.number && translate(errors.number.message)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: '5px' }}>
            <Grid item md={6} sm={6} xs={12}>
              <FormControl fullWidth className="text-left" variant="outlined" size="small">
                <InputLabel>{translate('app.payments.bankAccounts.documentType')} *</InputLabel>
                <Controller
                  control={control}       
                  name="bank_fiscal_number_type_id"
                  defaultValue={bankAccountSelected ? documentTypeList[documentTypeListSelected()].code : ""}
                  label={`${translate('app.payments.bankAccounts.documentType')} *`}
                  error={!!errors.bank_fiscal_number_type_id}
                  as={
                    <Select>
                      {documentTypeList.map(documentType => ( 
                        <MenuItem value={documentType.code} key={documentType.code}>
                          {documentType.name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
                {errors.bank_fiscal_number_type_id && (
                  <p className="error">
                    {translate(errors.bank_fiscal_number_type_id.message)}
                  </p>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                name="fiscal_number"
                label={translate('app.payments.bankAccounts.documentNumber')}
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                required
                defaultValue={bankAccountSelected ? bankAccountSelected.fiscal_number : ""}
                inputRef={register}
                error={!!errors.fiscal_number}
                helperText={errors.fiscal_number && translate(errors.fiscal_number.message)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginBottom: '5px' }}>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                name="name"
                label={translate('app.payments.bankAccounts.name')}
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                required
                inputRef={register}
                defaultValue={bankAccountSelected ? bankAccountSelected.name : ""}
                error={!!errors.name}
                helperText={errors.name && translate(errors.name.message)}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                name="last_name"
                label={translate('app.payments.bankAccounts.surnames')}
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                required
                inputRef={register}
                defaultValue={bankAccountSelected ? bankAccountSelected.last_name : ""}
                error={!!errors.last_name}
                helperText={errors.last_name && translate(errors.last_name.message)}
              />
            </Grid>
            <Grid item md={12} className="text-center" style={{ marginTop: 10 }}>
              <Button
                variant="contained"
                color="primary"
                style={{ width: '200px' }}
                type="submit"
                startIcon={loading ? <CircularProgress size={18} /> : null}
                disabled={loading}
              >
                {translate('app.payments.bankAccounts.save')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} disabled={loading}>
          {translate('close')}
        </Button>
      </DialogActions>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </Dialog>
  )
}