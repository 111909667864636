import { LIST_CLAIMS, LIST_TYPE_PQR, SHOW_PQR } from './constants';

const claimsReducer = (state, action) => {
  switch (action.type) {
    case LIST_CLAIMS: {
      return {
        ...state,
        list: action.list
      }
    }
    case LIST_TYPE_PQR: {
      return {
        ...state,
        typePqr: action.typePqr
      }
    }
    case SHOW_PQR: {
      return {
        ...state,
        pqr: action.pqr
      }
    }
    default:
      return state
  }
}

export default claimsReducer;
