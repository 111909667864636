import React from 'react';
import { Typography, Grid } from '@material-ui/core';

export default function TextBooking ({ label, text, space, icon, type = 0 }) {
  return(
    <>
      {type === 0 ? (
        <Typography variant="body1">
          <b>{label} </b>
          {space && <br />}
          {text}
        </Typography>
      ) : (
        <Grid container>
          <Grid item md={6} xs={6}>
            <Typography variant="body1">
              <b>{label} </b>
            </Typography>
          </Grid>
          <Grid item md={6} xs={6} className="text-right">
            <Typography variant="body1">
              <span style={{ verticalAlign: 'middle' }}>{text}</span>
              {icon}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  )
}