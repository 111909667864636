import { LIST_FAVORITE_ADDRESSES, LIST_FAVORITE_ADDRESSES_LOADING } from './constants';

const addressesReducer = (state, action) => {
  switch (action.type) {
    case LIST_FAVORITE_ADDRESSES:
      return {
        ...state,
        listAddresses: action.listAddresses
      }
    case LIST_FAVORITE_ADDRESSES_LOADING: {
      return {
        ...state,
        loadingAddresses: action.loadingAddresses
      }
    }
    default:
      return state
  }
}

export default addressesReducer;
