import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { I18nContext } from 'translations';

const FormButtonRent = ({
  fields,
  expanded,
  setExpanded,
  addStops,
  origin,
  isValid,
  step,
  setCustomer,
  paymentMethod,
  handleSubmit,
  submit,
  loadingPromoCode
}) => {
  const { translate } = useContext(I18nContext);
  const validPayment = paymentMethod !== "";

  return (
    <>
      {expanded === 'origin' ? (
        <Button
          type="button"
          size="large"
          color="primary"
          variant="contained"
          fullWidth
          style={{ borderRadius: '2px' }}
          onClick={() => {
            if (fields.length === 0) {
              return addStops()
            } else {
              return setExpanded('stops0')
            }
          }}
          disabled={origin && origin.lat ? false : true}
        >
          {translate('app.express.next')}
        </Button>
      ) :  expanded === 'stops0' && expanded !== 'close' ? (
        <>
          <Button
            type="button"
            size="large"
            color="primary"
            variant="contained"
            fullWidth
            style={{ borderRadius: '2px' }}
            disabled={!isValid}
            onClick={async e => {
              e.preventDefault();
              setCustomer(false);
              setExpanded('paymentRent');
              await handleSubmit((values) => submit(values, 1))()
            }}
          >
            {translate('app.express.next')}
          </Button>
        </>
      ) : expanded === 'paymentRent' && origin.lat && expanded !== 'close' && (
        <Button
          type="submit"
          size="large"
          color="primary"
          variant="contained"
          fullWidth
          style={{ borderRadius: '2px' }}
          disabled={!isValid || !validPayment || loadingPromoCode}
        >
          {translate('app.express.confirmService')}
        </Button>
      )}
    </>
  )
}

export default FormButtonRent;