import React, { useContext, useEffect, useState }from 'react';
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import EditIcon from '@material-ui/icons/Edit';
import AutocompleteInput from 'components/CustomInputs/AutocompleteInput';
import { I18nContext } from 'translations';
import { COUNTRY_CODE } from 'utils/constants';
import { withStyles } from '@material-ui/core/styles';
import { SIZE_CD } from 'utils/constants';
import styles from './FormStyle';
import Money from 'utils/money';

function PackagesGroupingForm ({
  register,
  address,
  origin,
  submit,
  city,
  handleAddress,
  handleSuggest,
  cities,
  handleSubmit,
  classes,
  errors,
  isValid,
  setExpanded,
  expanded,
  isModal,
  prepackage
}) {
  const { langCode } = useContext(I18nContext);
  const [checkedDelivery, setCheckedDelivery] = useState(prepackage?.counter_delivery || false);
  const { translate } = useContext(I18nContext);
  
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    expanded === 'resetCity' && setCheckedDelivery(false);
  }, [expanded])

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(submit)}>
      <div
        style={{ overflowY: 'auto', height: `calc(100vh - ${isModal?'250px':'200px'})`, padding: '10px 20px 10px 10px' }}
      >
        <Accordion
          expanded={expanded === 'origin' || expanded === 'resetCity'}
          onChange={handleChange('origin')}
          className={classes.accordionContainer}
        >
          <AccordionSummary
            expandIcon={<EditIcon color="primary" fontSize="small" />}
          >
            <Typography color="primary" variant="subtitle2">
              1. {translate('prepackages.form.addresses')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            
            
            <AutocompleteInput
              title={translate('prepackages.form.originAddress')}
              name="origin"
              address={origin && origin}
              cityId={city ? city._id : cities[0]._id}
              pos={{ lat: city ? city.location?.lat : cities[0].lat, lon: city ? city.location?.lon : cities[0].lon }}
              focus
              handleChange={(info) => handleSuggest(info, 0, 0)}
            />

            <TextField
              type="text"
              name="origin_secondary_addres"
              variant="outlined"
              label={translate('prepackages.form.complementaryAddress')}
              fullWidth
              defaultValue={prepackage?.origin_secondary_address}
              inputRef={register}
              size="small"
              className="marginTop10"
            />

            <div style={{ marginBottom: 15, marginTop: 25 }}>
              <Typography color="primary" variant="subtitle2" className="marginBottom10">
                {translate('prepackages.form.whereDeliverThePackage')}
              </Typography>
              <div style={{ padding: '10px 0 2px' }}>
                <AutocompleteInput
                  title={translate('prepakages.form.destinationAddress')}
                  name="address"
                  address={address ? address[0] : ''}
                  cityId={city ? city._id : cities[0]._id}
                  pos={{ lat: city ? city.location?.lat : cities[0].lat, lon: city ? city.location?.lon : cities[0].lon }}
                  focus={false}
                  handleChange={(info) => handleAddress(info, 0, 0)}
                />
                <TextField
                  name="end_secondary_address"
                  label={translate('prepackages.form.complementaryAddress')}
                  defaultValue={prepackage?.end_secondary_address}
                  inputRef={register}
                  fullWidth
                  variant="outlined"
                  size="small"
                  className="marginBottom10 marginTop10"
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        
        {expanded === 'resetCity' ? null : (
          <Accordion
            expanded={expanded === 'package'}
            onChange={handleChange('package')}
            className={classes.accordionContainer}
            style={{ display: expanded === 'package' ? 'block' : 'none' }}
          >
            <AccordionSummary disabled={true}>
              <Typography color="primary" variant="subtitle2">
                2. {translate('prepackages.form.addPackageInformation')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: 'column' }}>
              <TextField
                name="indications"
                label={translate('prepackages.form.WhatTheCourierDo')}
                defaultValue={prepackage?.indications}
                inputRef={register}
                fullWidth
                variant="outlined"
                size="small"
                multiline
                rows={3}
                className="marginBottom10"
                required
                error={errors && !!errors.indications}
                helperText={errors && errors.indications && translate(errors.indications.message)}
              />
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel>
                      {translate('prepackages.form.size')}
                    </InputLabel>
                    <Select
                      native
                      defaultValue={prepackage?.size_cd || 0}
                      inputRef={register}
                      label={translate('prepackages.form.size')}
                      name="size_cd"
                    >
                      {Object.values(SIZE_CD).map((size, index) => (
                        <option key={index.toString()} value={index}>
                          {translate(size)}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <TextField
                    name="declared_value"
                    label={translate('prepackages.form.declaredValue')}
                    defaultValue={prepackage?.declared_value && new Money(prepackage?.declared_value).flatFormat() }
                    inputRef={register}
                    fullWidth
                    variant="outlined"
                    required
                    size="small"
                    error={errors && !!errors.declared_value}
                    helperText={errors && errors.declared_value && translate(errors.declared_value.message)}
                  />
                </Grid>
              </Grid>
              <TextField
                name="reference"
                label={translate('prepackages.form.orderNumber')}
                defaultValue={prepackage?.reference}
                inputRef={register}
                fullWidth
                required
                variant="outlined"
                size="small"
                className="marginBottom10 marginTop10"
                error={errors && !!errors.reference}
                helperText={errors && errors.reference && translate(errors.reference.message)}
              />
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon fontSize="small" />}
                      checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                      name="counter_delivery"
                      inputRef={register}
                      defaultChecked={prepackage?.counter_delivery || false}
                      onChange={(e) => setCheckedDelivery(e.target.checked)}
                    />
                  }
                  label={translate('prepackages.form.driverHaveToCollectMoney')}
                />
              </FormControl>
              {checkedDelivery && (
                <TextField
                  name="collected_value"
                  label={translate('prepackages.form.valueToCollect')}
                  defaultValue={prepackage?.collected_value && new Money(prepackage?.collected_value).flatFormat()}
                  inputRef={register}
                  fullWidth
                  variant="outlined"
                  size="small"
                  className="marginTop10"
                  error={errors && !!errors.collected_value}
                  helperText={errors && errors.collected_value && translate(errors.collected_value.message)}
                />
              )}

              <div style={{ marginBottom: 15, marginTop: 25 }}>     
                <Typography color="primary" variant="subtitle2" className="marginBottom10">
                  {translate('prepackages.form.addPersonWhoReceives')}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <TextField
                      name="customer.name"
                      label={translate('prepackages.form.customer.name')}
                      defaultValue={prepackage?.customer_info?.name}
                      inputRef={register}
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      name="customer.country_code"
                      inputRef={register}
                      type="hidden"
                      defaultValue={prepackage?.customer_info?.country_code  || COUNTRY_CODE[langCode][3]}
                    />
                    <TextField
                      name="customer.phone"
                      label={translate('prepackages.form.customer.phone')}
                      defaultValue={prepackage?.customer_info?.phone}
                      inputRef={register}
                      inputProps={{ maxLength: '11' }}
                      fullWidth
                      variant="outlined"
                      size="small"
                      error={errors && !!errors?.customer?.phone}
                      helperText={errors && errors.customer?.phone && translate(errors.customer.phone.message)}
                    />
                  </Grid>
                </Grid>
                <TextField
                  name="customer.email"
                  label={translate('prepackages.form.customer.email')}
                  defaultValue={prepackage?.customer_info?.email}
                  inputRef={register}
                  fullWidth
                  variant="outlined"
                  size="small"
                  className="marginTop10"
                  error={errors && !!errors.customer?.email}
                  helperText={errors && errors.customer?.email && translate(errors.customer.email.message)}
                />
              </div>

            </AccordionDetails>
          </Accordion>
        )}
      </div>
      {expanded === 'origin' || expanded === 'resetCity' ?
        <Button
          type="button"
          color="primary"
          variant="contained"
          size="large"
          fullWidth
          disabled={!origin?.lat || !address[0]?.lat}
          onClick={e => {
            e.preventDefault();
            setExpanded('package')
          }}
        > 
          {translate('next')}
        </Button>
      : <Button
          type="submit"
          color="primary"
          variant="contained"
          size="large"
          fullWidth
          disabled={!isValid}
        > 
          {isModal? translate('send') : translate('prepackages.form.create')}
        </Button>
      }
    </form>
  )
}

export default withStyles(styles)(PackagesGroupingForm);