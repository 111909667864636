import { LIST_COMMODATE_LOAN, FETCH_DATA, GET_COMMODATE, GET_BORROWER_LIST } from './constants';

const commodateLoanReducer = (state, action) => {
  switch (action.type) {
    case LIST_COMMODATE_LOAN: {
      return {
        ...state,
        list: action.list
      }
    }
    case FETCH_DATA: {
      return {
        ...state,
        commodate: action.commodate
      }
    }
    case GET_BORROWER_LIST: {
      const info = action.borrowerList ? action.borrowerList.length > 0 && action.borrowerList.map(borrower => {
        return {
          id: borrower._id,
          title: borrower.name
        }
      }) : []
      return {
        ...state,
        borrowerList: info?.length > 0 ? info : []
      }
    }
    case GET_COMMODATE: {
      return {
        ...state,
        fetchCommodate: action.fetchCommodate ? {
          _id: action.fetchCommodate._id,
          init_date: action.fetchCommodate.created_at,
          return_date: action.fetchCommodate.return_date,
          driver_phone_number: action.fetchCommodate.borrower?.phone || ' - ',
          driver: action.fetchCommodate.borrower?.name,
          commodates_attributes: action.fetchCommodate.commodates.map(item => {
            return {
              _id: item._id,
              category_cd: item.category.code,
              name: item.category.name,
              description: item.description,
              receivable: item.receivable,
              incidents: item.incidents,
              status: item.status
            }
          })
        } : null
      }
    }
    default:
      return state
  }
}

export default commodateLoanReducer;
