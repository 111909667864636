import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export const PaginationAccordion = ({ handleChangePage, page, totalPages, perPage, type, totalP, total }) => (
  <div>
    <Typography variant="body2" component="span">
      {`${page === 1 ? page : ((page - 1) * perPage) + 1} - ${total < page *perPage ? total : page * perPage} of ${total}`}
    </Typography>
    {type !== 'inc' && (
      <IconButton
        component="span"
        onClick={() => handleChangePage('dec')}
        disabled={page === 1  && true}
      >
        <ChevronLeftIcon />
      </IconButton>
    )}
    <IconButton
      component="span"
      onClick={() => handleChangePage('inc')}
      disabled={((totalPages === page  && true) || (totalP === page && true))}
    >
      <ChevronRightIcon />
    </IconButton>
  </div>
);