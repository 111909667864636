import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Radio,
  Select,
  MenuItem,
  Checkbox,
  FormLabel,
  RadioGroup,
  InputLabel,
  FormControl,
  FormControlLabel,
  TextField,
  Button,
  Typography,
  FormHelperText
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,  
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import { HOURS_DEDICATED } from 'utils/constants';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { I18nContext } from 'translations';
import ModalServiceDriver from 'components/Modals/ModalServiceDriver';

const PaymentMethodFields = ({
  Controller,
  control,
  register,
  typeServices,
  scheduledAt,
  company,
  selectedPrincipalBooking,
  listReservations,
  bookingReservationId,
  balance_wallet
}) => {
  const { translate } = useContext(I18nContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    scheduledAt && control.setValue('hours_dedicated', '')
  }, [scheduledAt]) // eslint-disable-line
  
  return (
    <Grid container justify="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth size="small">
          <InputLabel htmlFor="size_cd">
            {translate('app.express.selectAPaymentMethod')}
          </InputLabel>
          <Controller
            control={control}
            name="payment_method"
            label= {translate('app.express.selectAPaymentMethod')}
            variant="outlined"
            defaultValue="1"
            as={
              <Select>
                <MenuItem value="">
                  <em>{translate('app.express.none')}</em>
                </MenuItem>
                <MenuItem key={1} value={1}>
                  {translate('app.express.cash')}
                </MenuItem>
              </Select>
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon fontSize="small" />}
                checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                name="use_wallet_balance"
                inputRef={register}
                color="secondary"
                defaultChecked={true}
              />
            }
            label={translate('app.express.useWallet')}
          />
          <FormHelperText
            style={{ marginLeft: '1rem', color:'#828080' }}
          >
            {translate('app.express.useWallet.description')}
          </FormHelperText>
        </FormControl>
      </Grid>
      {listReservations && listReservations.length > 0 &&
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => setOpen(true)}
          >
            {translate('app.express.driverHours')}
          </Button>
          {bookingReservationId && <div><small>{`${translate('app.bookingReserved.table.driver')}: ${bookingReservationId}`}</small></div>}
        </Grid>
      }
      <Grid item xs={12}>
        <FormControl>
          <FormLabel component="legend" style={{ margin: '10px 0' }}><b>{translate('app.express.typeOfService')}</b></FormLabel>
          <Controller
            rules={{ required: true }}
            control={control}
            name="type_services"
            defaultValue="express"
            as={
              <RadioGroup row>
                <FormControlLabel value="express" control={<Radio size="small" />} label={translate('app.typeOfService.express')} />
                <FormControlLabel value="schedule" control={<Radio size="small" />} label={translate('app.typeOfService.scheduled')} />
              </RadioGroup>
            }
          />
        </FormControl>
      </Grid>
      {typeServices === 'schedule' && (
        <Grid container direction="row" alignItems="flex-start" spacing={2}>
          <Grid item md={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <Controller
                name="scheduled_at"
                control={control}
                defaultValue={null}
                render={({ onChange, value }) => (
                  <KeyboardDateTimePicker
                    size="small"
                    inputVariant="outlined"
                    label={translate('app.express.schedule')}
                    format="dd/MM/yyyy hh:mm a"
                    cancelLabel={translate('cancel')}
                    okLabel={translate('accept')}
                    minDate={new Date()}
                    invalidDateMessage={translate('invalidFormat')}
                    value={value}
                    onChange={onChange}
                    fullWidth
                  />
                )}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {company && !bookingReservationId && (
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>
                  {translate('app.express.howManyHours')}
                </InputLabel>
                <Controller
                  control={control}
                  name="hours_dedicated"
                  label={translate('app.express.howManyHours')}
                  variant="outlined"
                  defaultValue=""
                  disabled={!scheduledAt}
                  as={
                    <Select>
                      <MenuItem value={-1}>
                        <em>{translate('app.express.none')}</em>
                      </MenuItem>
                      {HOURS_DEDICATED.map(hours => (
                        <MenuItem key={hours} value={hours}>
                          {hours}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
                <small>{translate('optionalField')} - {translate('app.express.mustHaveADate')}</small>
              </FormControl>
            </Grid>
          )}
        </Grid>
      )}
      {listReservations && listReservations.length > 0 &&
        <ModalServiceDriver
          open={open}
          setOpen={setOpen}
          selectedPrincipalBooking={selectedPrincipalBooking}
          listReservations={listReservations}
          bookingReservationId={bookingReservationId}
        />
      }
      <Grid container direction="row" alignItems="flex-start" spacing={2}>
        <Grid item md={6}>
          <FormLabel component="legend" style={{ margin: '10px 0' }}><b>{translate('app.express.howManyVehicles')}</b></FormLabel>
          <TextField
            type="text"
            name="multiplier"
            variant="outlined"
            label={translate('app.express.numberOfVehicles')}
            fullWidth
            inputRef={register({
              pattern: {
                value: /^[0-9]+$/,
                message: translate('onlyNumbers'),
              },
              min: 2,
              max: 50
            })}
            size="small"
            disabled={control.getValues('hours_dedicated') > 0 && true}
          />
          <Typography
            color="textSecondary"
            variant="caption"
            className="line-height-xs"
          >
            {translate('app.express.mustBeANumberBetweenMessage')}
          </Typography>
        </Grid>
      </Grid>
   
    </Grid>
  )
}

export default PaymentMethodFields;