import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField, InputAdornment, Grid, Button
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import PhoneInput from 'react-phone-input-2';
import EmailIcon from '@material-ui/icons/Email';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import { I18nContext } from 'translations';
import schema from './schema';
import stylesFormPersonalData from './styleFoms';

function FormPersonalData({ submit, classes, next }) {
  const { translate } = useContext(I18nContext);
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, control, errors, watch } = useForm({
    resolver: yupResolver(schema)
  });

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classes.form}>
      <TextField
        autoFocus
        name="passenger.name"
        error={errors.passenger && !!errors.passenger.name}
        label={translate('fullName')}
        helperText={errors.passenger && errors.passenger.name && translate(errors.passenger.name.message)}
        type="text"
        inputRef={register}
        fullWidth
        className={classes.marginBottomField}
        required
        InputProps={{
          endAdornment: <InputAdornment position="end"><PersonIcon className={classes.icon}/></InputAdornment>,
        }}
      />

      <TextField
        name="passenger.email"
        error={errors.passenger && !!errors.passenger.email}
        label={translate('email')}
        helperText={errors.passenger && errors.passenger.email && translate(errors.passenger.email.message)}
        type="email"
        inputRef={register}
        fullWidth
        className={classes.marginBottomField}
        required
        InputProps={{
          endAdornment: <InputAdornment position="end"><EmailIcon className={classes.icon}/></InputAdornment>,
        }}
      />
      <Grid container spacing={2}>
        <Grid item md={4} sm={4} xs={4} className="phone-input-standard text-left">
          <Controller
            control={control}
            name="passenger.country_code"
            defaultValue="57"
            as={
              <PhoneInput
                country="co"
                defaultCountry="co"
                regions={['america']}
                preferredCountries={['co', 'mx', 'gt', 'py']}
                inputProps={{ disabled: true }}
              />
            }
          />
        </Grid>
        <Grid item md={8} sm={8} xs={8}>
          <TextField
            name="passenger.phone"
            error={errors.passenger && !!errors.passenger.phone}
            label={translate('phone')}
            helperText={errors.passenger && errors.passenger.phone && translate(errors.passenger.phone.message)}
            type="tel"
            inputRef={register}
            fullWidth
            className={classes.marginBottomField}
            required
            inputProps={{ maxLength: "10" }}
            InputProps={{
              endAdornment: <InputAdornment position="end"><PhoneIcon className={classes.icon}/></InputAdornment>,
            }}
          />
        </Grid>
      </Grid>

      <TextField
        name="passenger.password"
        error={errors.passenger && !!errors.passenger.password}
        label={translate('password')}
        helperText={errors.passenger && errors.passenger.password && translate(errors.passenger.password.message)}
        type={showPassword ? "text" : "password"}
        inputRef={register}
        autoComplete="no-password"
        className={classes.marginBottomField}
        fullWidth
        required
        InputProps={{
          endAdornment: <InputAdornment position="end" onClick={() => setShowPassword(!showPassword)}>{showPassword ? <VisibilityOffIcon className={classes.iconHover} /> : <VisibilityIcon className={classes.iconHover} />}</InputAdornment>,
        }}
      />

      <br /><br />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        onClick={Object.keys(errors).length === 0 && watch().passenger && watch().passenger.name && watch().passenger.email ? next : () => {}}
        className={classes.button}
      >
        {translate('next')}
      </Button>

    </form>
  );
}

FormPersonalData.propTypes = {
  submit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  next: PropTypes.func.isRequired
};

export default withStyles(stylesFormPersonalData)(FormPersonalData);
