import React, { useEffect, useState, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  Button,
  TableRow,
  Checkbox,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination
} from '@material-ui/core';
import { I18nContext } from 'translations';
import { formatCost } from 'utils';
import ModalAssignBalances from 'components/Modals/Balances';
import ModalWithdrawalBalance from 'components/Modals/Balances/ModalWithdrawalBalance';
import EmptyData from '../EmptyData';
import styles from '../style';

const AssingBalancesTable = ({
  open,
  data,
  classes,
  setOpen,
  codeInfo,
  setCodeInfo,
  passengerInfo,
  removeBalance,
  recordsPerPage,
  getListBalances,
  getlistPassengers,
  setPassengerInfo
}) => {
  const [ currentPage, setCurrentPage ] = useState(data.page);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const { translate } = useContext(I18nContext);
  const [ openModalWithdrawal, setOpenModalWithdrawal ] = useState(false);
  
  useEffect(() => {
    setCurrentPage(data.page);
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    getListBalances(newPage);
  };

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    const info = data?.data.map(all => {
      return {
        ...all,
        checked: true
      }
    })
    setCodeInfo(info)
    if (isCheckAll) {
      setCodeInfo([]);
    }
  };

  if (!data || (data && data.data && data.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />;
  }

  return (
    <>
      <TableContainer>
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  onChange={handleSelectAll}
                  checked={isCheckAll}
                  color="primary"
                />
              </TableCell>
              <TableCell>{translate('balances.table.users')}</TableCell>
              <TableCell>{translate('balances.table.role')}</TableCell>
              <TableCell>{translate('balances.table.service_type')}</TableCell>
              <TableCell>{translate('balances.table.total_amount')}</TableCell>
              <TableCell>{translate('balances.table.total_available_amount')}</TableCell>
              <TableCell>{translate('balances.table.total_consumed_amount')}</TableCell>
              <TableCell>{translate('balances.table.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data.data || []).map((passenger, index) => (
              <TableRow
                hover
                className={codeInfo?.filter((promo) => promo._id === passenger._id)?.length > 0 ? 'active-table-code' : 'disabled-table-code'}
                key={passenger._id}
              >
                <TableCell>
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCodeInfo([
                          ...codeInfo,
                          {
                            ...passenger,
                            checked: e.target.checked
                          }
                        ]);
                      } else {
                        setCodeInfo(
                          codeInfo.filter((promo) => promo._id !== passenger._id),
                        );
                      }
                    }}
                    value={codeInfo}
                    type="checkbox"
                    checked={codeInfo.filter((promo) => promo._id === passenger._id).length > 0 && true}
                  />
                </TableCell>
                <TableCell>
                  {passenger.name}
                  <br/>
                  { passenger.email}
                </TableCell>
                <TableCell>
                  {passenger.role}
                </TableCell>
                <TableCell>
                  {passenger.services}
                </TableCell>
                <TableCell>
                  {formatCost(passenger.total_balance)} 
                </TableCell>
                <TableCell>
                  {formatCost(passenger.available_balance)}
                </TableCell>
                <TableCell>
                  {formatCost(passenger.consumed_balance)}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    disableElevation
                    size="small"
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      setPassengerInfo(passenger.id);
                      setOpen(true);
                      setCodeInfo(codeInfo.filter((promo) => promo._id === passenger._id));
                      
                    }}
                    className="active-yellow-btn"
                  >
                    {translate('balances.table.actions.button.assing')}
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    disableElevation
                    style={{ marginRight: 10 }}
                    className="active-red-btn"
                    onClick={() => {
                      setPassengerInfo(passenger.id);
                      setOpenModalWithdrawal(true);
                      setCodeInfo(codeInfo.filter((promo) => promo._id === passenger._id));
                      
                    }}
                    disabled={passenger && passenger.available_balance && passenger.available_balance['subunits'] <= 0}
                  >
                    {translate('balances.table.actions.button.withdraw')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.total ? data.total : data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
      {open &&
        <ModalAssignBalances
          open={open}
          setOpen={setOpen}
          codeInfo={codeInfo}
          setCodeInfo={setCodeInfo}
          passengerInfo={passengerInfo}
          setPassengerInfo={setPassengerInfo}
          getlistPassengers={getlistPassengers}
          getListBalances={getListBalances}
          setIsCheckAll={setIsCheckAll}
        />
      }

      {openModalWithdrawal &&
        <ModalWithdrawalBalance
          open={openModalWithdrawal}
          setOpen={setOpenModalWithdrawal}
          codeInfo={codeInfo}
          setCodeInfo={setCodeInfo}
          passengerInfo={passengerInfo}
          setPassengerInfo={setPassengerInfo}
          getlistPassengers={getlistPassengers}
          getListBalances={getListBalances}
          setIsCheckAll={setIsCheckAll}
        />
      }
      
    </>
  );
};

export default withStyles(styles)(AssingBalancesTable);
