import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  FormControl
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { I18nContext } from 'translations';
import { BILL_PAYMENT_STATUSES } from 'utils/constants';
import styles from '../../styleTableFilters';

const defaultValues = {
  status: '',
};

const PipayTableFilters = ({ classes, onFilters }) => {
  const { translate } = useContext(I18nContext);
  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const onSubmit = (values) => {
    onFilters(values);
  };

  const clearFilters = () => {
    reset(defaultValues);
    onFilters("");
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate className="relative">
      <Grid container spacing={1} alignItems="center">
        <Grid item md={1} sm={12} xs={12}>
          <Typography variant="subtitle1" style={{ color: 'black' }}>
            {translate('bills.table.filter.filterBy')}:
          </Typography>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <FormControl
            variant="outlined"
            className={classes.filterStyle}
            fullWidth
          >
            <InputLabel>
              {translate('bills.table.filter.status')}
            </InputLabel>
            <Controller
              control={control}
              name="status"
              label={translate('bills.table.filter.status')}
              variant="outlined"
              defaultValue=""
              as={
                <Select>
                  <MenuItem value="">
                    {translate('app.bookings.filter.choose')}
                  </MenuItem>
                  {Object.entries(BILL_PAYMENT_STATUSES).map((status) => {
                    return (
                      <MenuItem key={status[0]} value={status[0]}>
                        {translate(status[1])}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
            />
          </FormControl> 
        </Grid>

        <Grid item md={6} sm={6} xs={12} className="text-center-sm">
          <Button
            variant="outlined"
            color="primary"
            size="small"
            type="button"
            style={{ width: 100, marginRight: 10 }}
            onClick={clearFilters}
            className="btn-rounded-style"
          >
            {translate('clear')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            type="submit"
            style={{ width: 120 }}
            className="btn-rounded-style"
          >
            {translate('app.bookings.filter.search')}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default withStyles(styles)(PipayTableFilters);