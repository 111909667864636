import React from 'react';
import { useContext } from 'react';
import { I18nContext } from 'translations';
import { FieldsContainer } from './style';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { COUNTRIES_IDS_BY_NAME, INDUSTRY_TYPE_CD } from 'utils/constants';
import { useStateValue } from 'context/store';
import { formatCost } from 'utils';
import NumberFormat from 'react-number-format';

const AboutShipping = ({ control, errors }) => {
  const { translate } = useContext(I18nContext);

  const industryTypes = INDUSTRY_TYPE_CD.map(({ _id, name }) => ({
    value: _id,
    label: translate(name),
  }));

  const [
    {
      checkin: { checkin },
      companyRegister: { maxDeclaredValueOptions },
    },
  ] = useStateValue();

  const maxDeclaredValue = maxDeclaredValueOptions?.map((option, index) => ({
    value: index,
    label: `${translate('until')} ${formatCost(option)}`,
  }));

  const renderTenativeMaxDeclaredValueByCountry = (onChange, value) => {
    if (checkin?.company?.geo_fence_id === COUNTRIES_IDS_BY_NAME.colombia._id) {
      return (
        <FormControl
          variant="outlined"
          fullWidth
          size="small"
          error={errors.tentative_max_declared_value}
        >
          <InputLabel error={errors.tentative_max_declared_value}>
            {translate('app.setting.companyRegister.insurance_value')}
          </InputLabel>
          <Select
            fullWidth
            variant="outlined"
            size="small"
            label={translate('app.setting.companyRegister.insurance_value')}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            error={Boolean(errors?.tentative_max_declared_value)}
          >
            <MenuItem value="" style={{ minHeight: '3rem' }} />
            {maxDeclaredValue?.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
          {errors.tentative_max_declared_value && (
            <FormHelperText>
              {translate(errors?.tentative_max_declared_value?.message)}
            </FormHelperText>
          )}
        </FormControl>
      );
    } else {
      return (
        <NumberFormat
          thousandSeparator
          customInput={TextField}
          variant="outlined"
          size="small"
          fullWidth
          label={translate('app.setting.companyRegister.insurance_value')}
          value={value}
          onValueChange={(values) => {
            const { floatValue } = values;
            onChange(floatValue);
          }}
          prefix={'$'}
          allowNegative={false}
          error={!!errors?.tentative_max_declared_value}
          helperText={translate(errors?.tentative_max_declared_value?.message)}
        />
      );
    }
  };

  return (
    <FieldsContainer item xs={12} md={9}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            {translate('app.setting.companyRegister.aboutYourShippings')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="tentative_max_declared_value"
            render={({ onChange, value }) =>
              renderTenativeMaxDeclaredValueByCountry(onChange, value)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="industry_type_cd"
            render={({ onChange, value }) => (
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                error={errors?.industry_type}
              >
                <InputLabel error={errors?.industry_type}>
                  {translate('app.setting.companyRegister.industryType')}
                </InputLabel>
                <Select
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={translate('app.setting.companyRegister.industryType')}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  error={Boolean(errors?.industry_type)}
                >
                  <MenuItem value="" style={{ minHeight: '3rem' }} />
                  {industryTypes?.map((industry) => (
                    <MenuItem key={industry.value} value={industry.value}>
                      {industry.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors?.industry_type_cd && (
                  <FormHelperText>
                    {translate(errors?.industry_type_cd?.message)}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </FieldsContainer>
  );
};

export default AboutShipping;
