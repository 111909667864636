import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Snackbar } from '@material-ui/core';
import { useStateValue } from 'context/store';
import Loading from 'components/Loading';
import UnarchiveOutlinedIcon from '@material-ui/icons/UnarchiveOutlined';
import Title from 'components/Titles/TitleWithDowload';
import { message as closeMessage } from 'context/commons/actions';
import {
  listPrepackages
} from 'context/prepackages/actions';
import { I18nContext } from 'translations';
import { INITIAL_PAGE, PER_PAGE } from 'utils/constants';
import PrepackageTable from 'components/Tables/Prepackage';
import PrepackagesTableGroupsFilters from 'components/Tables/Prepackage/Filters/PrepackagesTableGroupsFilters';
import PrepackagesTableFilters from 'components/Tables/Prepackage/Filters/PrepackagesTableFilters';

export default function ListPrepackages () {
  const { translate } = useContext(I18nContext);
  const [per_page] = useState(PER_PAGE);
  const [filters, setFilters] = useState({
    active_prepackages: false,
  });
  const [user_id, setUser_id] = useState(null);
  const [{
    prepackages: { list, loadingPrepackages },
    checkin: { checkin },
    commons: { message } },
    dispatch
  ] = useStateValue();

  const getPrepackages = useCallback(
    async (page, user) => {
      await listPrepackages(dispatch, { page, per_page, ...filters }, user);
    }, [dispatch, per_page, filters]
  );

  useEffect(() => {
    getPrepackages(INITIAL_PAGE, null);
  }, [getPrepackages]); // eslint-disable-line

  const myPrepackages =  () => {
    checkin && setUser_id(checkin.profile._id);
      getPrepackages(INITIAL_PAGE, checkin.profile._id);
  }

  const onFilters = (params) => setFilters(params);

  return (
    <div className="padding-page-full-height" style={{ backgroundColor: '#fafafa' }}>
      {loadingPrepackages && <Loading />}
      <Title
        title="Lista de Prepaquetes"
        icon={<UnarchiveOutlinedIcon className="align-center" style={{ marginRight: 5 }} />}
      />

      <div style={{ marginTop: 20 }}>
        <PrepackagesTableFilters
          onFilters={onFilters}
          filters={filters}
        />
      </div>
      
      <div className="marginFiltersTable">
        <PrepackagesTableGroupsFilters
          user_id={user_id}
          setUser_id={setUser_id}
          myPrepackages={myPrepackages}
          getPrepackages={getPrepackages}
        />
      </div>

      {list && (
        <PrepackageTable
          data={list}
          getPrepackages={getPrepackages}
          recordsPerPage={per_page}
          user={user_id}
        />
      )}

      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
      
    </div>
  );
};


