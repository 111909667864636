import axios from 'axios';

/** Petición GET estandar con axios */
export const get = async (urlRequest, params) => {
  const default_token = localStorage.getItem('session_token');
  const lang = localStorage.getItem('lang') || 'es';
  const sendParams = Object.assign({}, params);
  const response = await axios({
    headers: {
      'X-PI-TOKEN': default_token || '',
      lang,
    },
    url: urlRequest,
    method: 'GET',
    params: sendParams,
  });
  return response;
};

/** Petición POST estandar con axios */
export const post = async (urlRequest, dataRequest) => {
  const default_token = localStorage.getItem('session_token');
  const lang = localStorage.getItem('lang') || 'es';
  const response = await axios({
    headers: {
      'X-PI-TOKEN': default_token || '',
      lang,
    },
    url: urlRequest,
    method: 'POST',
    data: dataRequest,
  });
  return response;
};

/** Petición PATCH estandar con axios */
export const patch = async (urlRequest, dataRequest, token='') => {
  const default_token = localStorage.getItem('session_token');
  const lang = localStorage.getItem('lang') || 'es';
  const response = await axios({
    headers: {
      'X-PI-TOKEN': token ? token : default_token,
      lang,
    },
    url: urlRequest,
    method: 'PATCH',
    data: dataRequest,
  });
  return response;
};

/** Petición PUT estandar con axios */
export const put = async (urlRequest, dataRequest) => {
  const default_token = localStorage.getItem('session_token');
  const lang = localStorage.getItem('lang') || 'es';
  const response = await axios({
    headers: {
      'X-PI-TOKEN': default_token || '',
      lang,
    },
    url: urlRequest,
    method: 'PUT',
    data: dataRequest,
  });
  return response;
};

/** Petición DELETE estandar con axios */
export const deleteD = async (urlRequest, dataRequest) => {
  const default_token = localStorage.getItem('session_token');
  const lang = localStorage.getItem('lang') || 'es';
  const response = await axios({
    headers: {
      'X-PI-TOKEN': default_token || '',
      lang,
    },
    url: urlRequest,
    method: 'DELETE',
    data: dataRequest,
  });
  return response;
};

/** Petición DELETE estandar con axios */
export const deleteP = async (urlRequest, params) => {
  const default_token = localStorage.getItem('session_token');
  const lang = localStorage.getItem('lang') || 'es';
  const sendParams = Object.assign({}, params);
  const response = await axios({
    headers: {
      'X-PI-TOKEN': default_token || '',
      lang,
    },
    url: urlRequest,
    method: 'DELETE',
    params: sendParams,
  });
  return response;
};

/** Gets and forces download of a xls file from server */
export const getExcelFile = async (urlRequest, params) => {
  const default_token = localStorage.getItem('session_token');
  const lang = localStorage.getItem('lang') || 'es';
  const response = await axios({
    url: `${urlRequest}.xlsx`,
    method: 'GET',
    params: {
      ...params
    },
    responseType: 'blob',
    headers: {
      'X-PI-TOKEN': default_token,
      lang
    }
  }).then((httpResponse) => {
    const url = window.URL.createObjectURL(
      new Blob([httpResponse.data])
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Reporte.xlsx');
    document.body.appendChild(link);
    link.click();
  });
  return response;
}


/** Petición GET estandar con axios */
export const getTest = async (urlRequest, params) => {
  const lang = localStorage.getItem('lang') || 'es';
  const sendParams = Object.assign({}, params);
  const response = await axios({
    headers: {
      'x-pibox-e-token': 'ShtSPVS9RgyDpvgTjq0m6_SDm8C9D3kjpp0ef8p2uT0XX0-23wQVWw',
      lang,
    },
    url: urlRequest,
    method: 'GET',
    params: sendParams,
  });
  return response;
};