import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography, Button, withStyles, createStyles, TextField, Snackbar, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DropzoneArea } from 'material-ui-dropzone';
import { message as closeMessage } from 'context/commons/actions';
import { uploadFile } from 'utils/aws';
import { I18nContext } from 'translations';
import { useForm, Controller } from 'react-hook-form';
import { uploadBulkReserved, cleanFailedScheduled } from 'context/express/actions';
import { useStateValue } from 'context/store';
import ModalFailedScheduled from 'components/Modals/ModalHoursDedicated/ModalFailedScheduled';
import Loading from 'components/Loading';
import Title from 'components/Titles';
import { modalMessageHtml } from 'utils';
import { PAYMENT_METHODS_PIBOX } from 'utils/constants';

const styles = createStyles(() => ({
  dropZone: {
    minHeight: '200px'
  }
}))

function HourlyReservations({ classes, history }) {
  const { translate } = useContext(I18nContext);
  const [{ checkin: { configuration }, commons: { message }, bookings: { failedScheduled, successScheduled } }, dispatch ] = useStateValue();
  const [fileUpload, setFileUpload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState(null);
  const [openFailedScheduled, setOpenFailedScheduled] = useState(true);

  const handleModalFailedScheduled = () => {
    setOpenFailedScheduled(!openFailedScheduled);
    openFailedScheduled && cleanFailedScheduled(dispatch);
  }
  const handleFile = async file => setFileUpload(file);

  useEffect(() => {
    configuration && configuration.cities_for_delivery && setCity(configuration.cities_for_delivery[0]);
  }, [configuration, setCity]);

  const { handleSubmit, control } = useForm({
    mode: 'onChange'
  });

  const handleMessageOk = () => {
    modalMessageHtml(
    translate('congratulations'),
    translate('scheduledReservations.msgInfo'),
    'success',
    translate('continue')).then((response) => {
      if (response.isConfirmed) {
        history.push('/app/bookings');
      }
    });
  }

  const submit = async (values) => {
    setLoading(true);
    const date = Date.parse(new Date());
    const response = await uploadFile(fileUpload[0], `booking_bulk_load/${date}`);
    const data = {
      url: response.Location,
      city_id: city?._id,
      ...values
    }
    if (response) {
      const callback = await uploadBulkReserved(dispatch, data);
      setLoading(false);
      callback && callback.status && failedScheduled === null && handleMessageOk();
      failedScheduled === null && setOpenFailedScheduled(true)
    }
  }

  return (
    <div className="padding-page-full-height">
      <Title
        title={translate('scheduledReservations')}
        icon={<LibraryBooksOutlinedIcon className="align-center" style={{ marginRight: 5 }} />}
        subtitle={translate('scheduledReservations.description')}
      />

      {loading && <Loading />}
      <form style={{ margin: '15px 0 20px' }} onSubmit={handleSubmit(submit)} noValidate>
        <Grid container justify="center">
          <Grid item md={8}>
            <Typography variant="h5" color="textPrimary" className="text-center" style={{ margin: '15px 0 10px' }}>
              {translate('app.operations.optimize.loadExcelFile')} - 
              <Button
                color="secondary"
                href="https://s3.amazonaws.com/static.pibox.app/templates/Template_bulk_scheduled.xlsx"
                size="small"
                download
              >
                {translate('app.operations.optimize.downloadFormat')}
              </Button>
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginBottom: '9px' }}>
                <DropzoneArea
                  onChange={handleFile}
                  acceptedFiles={['.xlsx', '.xls', '.csv']}
                  maxFileSize={5000000}
                  filesLimit={1}
                  dropzoneClass={classes.dropZone}
                  dropzoneText={translate('app.bulkLoad.dragFileHereOrClick')}
                  getFileLimitExceedMessage={() => translate('app.bulkLoad.pleaseUploadOnlyOneFile')}
                  getFileAddedMessage={fileName => `${fileName} ${translate('app.bulkLoad.successfullyAdded')}`}
                  getFileRemovedMessage={fileName => `${fileName} ${'app.bulkLoad.removed'}`}
                  getDropRejectMessage={fileName => translate('app.bulkLoad.unsupportedFileType')}
                />
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  id="cities"
                  options={configuration.cities_for_delivery.length > 0 ? configuration.cities_for_delivery : [{ id: 'none', name: translate('app.express.noCities') }]}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
                  value={city ? city : configuration.cities_for_delivery[0]}
                  onChange={(event, newValue) => {
                    event.stopPropagation();
                    setCity(newValue);
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <FormControl
                  variant="outlined"
                  size="small"
                  className={classes.filter}
                  fullWidth
                >
                  <InputLabel>{translate('app.booking.paymentMethod')}</InputLabel>
                  <Controller
                    control={control}
                    name="payment_method"
                    label={translate('app.booking.paymentMethod')}
                    defaultValue={Object.keys(PAYMENT_METHODS_PIBOX)[1]}
                    as={
                      <Select>
                        {Object.keys(PAYMENT_METHODS_PIBOX).map((key) => (
                          <MenuItem value={key} key={`payment-${key}`}>
                            {translate(PAYMENT_METHODS_PIBOX[key])}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className="text-center" style={{ marginTop: 20 }}>
              <Button
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                disabled={!fileUpload[0]}
                >
                {translate('app.operations.optimize.form.confirm')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
      {failedScheduled && failedScheduled.length > 0 &&
        <ModalFailedScheduled
          open={openFailedScheduled}
          handleModalFailedScheduled={handleModalFailedScheduled}
          failedScheduled={failedScheduled}
          successScheduled={successScheduled?.length > 0 && true}
        />
      }
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </div>
  )
}

export default withStyles(styles)(HourlyReservations);