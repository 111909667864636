export const POST_COMPANY_REGISTER_LOADING = 'POST_COMPANY_REGISTER_LOADING';
export const GET_COMPANY_REGISTER_LOADING = 'GET_COMPANY_REGISTER_LOADING';
export const SET_COMPANY_REGISTER = 'SET_COMPANY_REGISTER';
export const MESSAGE = 'MESSAGE';
export const GET_DOCUMENTS_HISTORY = 'GET_DOCUMENTS_HISTORY';
export const GET_DOCUMENTS_HISTORY_TYPE = 'GET_DOCUMENTS_HISTORY_TYPE';
export const TOGGLE_DOCUMENTS_HISTORY_TYPE_LOADING =
  'TOGGLE_DOCUMENTS_HISTORY_TYPE_LOADING';
export const SET_TAX_LIABILITY_OPTIONS = 'SET_TAX_LIABILITY_OPTIONS';
export const SET_MAX_DECLARED_VALUE_OPTIONS = 'SET_MAX_DECLARED_VALUE_OPTIONS';
export const UPDATE_ACCEPTANCE_OF_TERMS_AND_CONDITIONS = 'UPDATE_ACCEPTANCE_OF_TERMS_AND_CONDITIONS';
