import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Paper, Container, Grid, Typography, Snackbar, Hidden } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { signupPersonalData, signup } from 'context/users/actions';
import { I18nContext } from 'translations';
import LayoutOuter from 'components/Layouts/LayoutOuter';
import Loading from 'components/Loading';
import Menu from 'components/Landing/Layouts/Menu';
import LinkAccount from '../components/LinkAccount';
import TabsSignup from './TabsSignup';
import styles from './style';

function SignupCompany({ classes, history }) {
  const { translate } = useContext(I18nContext);
  const [{ commons: { loading, message }, session }, dispatch] = useStateValue();

  const onSubmitPersonalData = async values => await signupPersonalData(dispatch, values);

  const onSubmit = async values => {
    values.passenger = {...session.personalData.passenger, ...values.passenger}
    session.personalData && await signup(dispatch, { ...values}, 1, history);
  }

  return (
    <LayoutOuter bg="#f8f8f8">
      <Menu />
      <Container maxWidth="md" style={{ marginTop: 75 }} className="container-width-lg">
        <Paper elevation={4} variant="outlined" className={classes.wrapper}>
          {loading && <Loading />}
          <Grid container alignItems="center" justify="center" spacing={2}>
            <Grid item sm={4} md={6} xl={7} className="text-center">
              <img
                src="/images/users/pibox-boxes.png"
                alt="signup"
                className={classes.principalImg}
              />
              <Hidden xsDown>
                <LinkAccount
                  text="doYouAlreadyHaveAnAccount"
                  link="/login"
                  textLink="loginHere"
                />
              </Hidden>
            </Grid>
            <Grid item sm={8} md={6} xl={5} className="text-center">
              <Typography variant="h4" color="textPrimary">
                {translate('signup')}
              </Typography>
              <br /><br />
              <TabsSignup
                onSubmitPersonalData={onSubmitPersonalData}
                submit={onSubmit}
              />
            </Grid>
            <Hidden smUp>
              <Grid item xs={12}>
                <LinkAccount
                  text="doYouAlreadyHaveAnAccount"
                  link="/login"
                  textLink="loginHere"
                />
              </Grid>
            </Hidden>
          </Grid>
        </Paper>
      </Container>
      <Snackbar
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </LayoutOuter>
  );
}

SignupCompany.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SignupCompany);
