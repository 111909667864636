import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { I18nContext } from 'translations';

const ButtonFile = (props) => {
  const { translate } = useContext(I18nContext);

  const { color, icon, children, text, isFromModal, error, ...componentProps } =
    props;
  return (
    <Button
      {...componentProps}
      fullWidth
      security="small"
      variant="contained"
      style={{
        marginBottom: '1rem',
        borderColor: error && '#f44336',
        color: error && '#f44336',
        background: color || 'none',
        boxShadow: 'none',
        border: '1px solid',
        borderRadius: isFromModal && '1rem',
        display: 'flex',
        justifyContent: 'space-between',
      }}
      endIcon={icon || <PublishIcon />}
    >
      {children ? children : text ?? translate('uploadNewFile')}
    </Button>
  );
};

export default ButtonFile;
