import React, { useCallback, useContext, useEffect } from 'react';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { FieldsContainer } from './style';
import { I18nContext } from 'translations';
import {
  getBankList,
  getAccountTypeList,
  getDocumentTypeList,
} from 'context/payments/actions';
import {
  COLORS_BY_STATUS_FOR_DOCUMENT_HISTORY,
  COMPANY_REGISTRATION_STATUSES,
  COUNTRY_CODE,
} from 'utils/constants';
import { useStateValue } from 'context/store';
import ButtonUploadFileModal from 'components/Buttons/ButtonUploadFileModal';

const BankAccount = ({
  control,
  errors,
  companyRegister,
  setBankCertification,
}) => {
  const { langCode, translate } = useContext(I18nContext);

  const [
    {
      payments: { bankList, accountTypeList, documentTypeList },
      checkin: { company },
    },
    dispatch,
  ] = useStateValue();

  const getConfigBank = useCallback(async () => {
    const geo_fence_id = company
      ? company.geo_fence_id
      : COUNTRY_CODE[langCode][0];
    getBankList(dispatch, { geo_fence_id });
    getAccountTypeList(dispatch, { geo_fence_id });
    getDocumentTypeList(dispatch, { geo_fence_id });
  }, [dispatch, company, langCode]);

  useEffect(() => {
    getConfigBank();
  }, [getConfigBank]);

  const {approved, pending_update} = COMPANY_REGISTRATION_STATUSES;

  if(accountTypeList?.length === 0 || bankList?.length === 0  || documentTypeList?.length === 0) return null;

  return (
    <FieldsContainer item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            {translate('app.setting.companyRegister.bank_inscription')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="bank_account_type_id"
            render={({ onChange, value }) => (
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                error={!!errors.bank_account_type_id}
              >
                <InputLabel error={!!errors.bank_account_type_id}>
                  {translate(
                    'app.setting.companyRegister.bankAccount.accountType'
                  )}
                </InputLabel>
                <Select
                  fullWidth
                  onChange={onChange}
                  value={value}
                  label={translate(
                    'app.setting.companyRegister.bankAccount.accountType'
                  )}
                  variant="outlined"
                  error={Boolean(errors?.bank_account_type_id)}
                >
                  <MenuItem value="" style={{ minHeight: '3rem' }} />
                  {accountTypeList &&
                    accountTypeList.map((accountType) => (
                      <MenuItem
                        key={accountType.code}
                        value={translate(accountType.code)}
                      >
                        {translate(accountType.name)}
                      </MenuItem>
                    ))}
                </Select>
                {!!errors.bank_account_type_id && (
                  <FormHelperText error>
                    {translate(errors.bank_account_type_id.message)}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="bank_id"
            render={({ onChange, value }) => (
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                error={errors.bank_id}
              >
                <InputLabel error={!!errors.bank_id}>
                  {translate(
                    'app.setting.companyRegister.bankAccount.bankName'
                  )}
                </InputLabel>
                <Select
                  fullWidth
                  onChange={onChange}
                  value={value}
                  label={translate(
                    'app.setting.companyRegister.bankAccount.bankName'
                  )}
                  variant="outlined"
                  error={Boolean(errors?.bank_id)}
                >
                  <MenuItem value="" style={{ minHeight: '3rem' }} />
                  {bankList &&
                    bankList.map((bank) => (
                      <MenuItem key={bank.code} value={bank.code}>
                        {bank.name}
                      </MenuItem>
                    ))}
                </Select>
                {errors.bank_id && (
                  <FormHelperText error>
                    {translate(errors.bank_id.message)}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="account_number"
            render={({ onChange, value }) => (
              <TextField
                fullWidth
                onChange={onChange}
                value={value}
                label={translate(
                  'app.setting.companyRegister.bankAccount.accountNumber'
                )}
                variant="outlined"
                size="small"
                error={Boolean(errors?.account_number)}
                helperText={
                  errors.account_number &&
                  translate(errors.account_number.message)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="bank_fiscal_number_type_id"
            render={({ onChange, value }) => (
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                error={!!errors.bank_fiscal_number_type_id}
              >
                <InputLabel error={errors.bank_fiscal_number_type_id}>
                  {translate(
                    'app.setting.companyRegister.bankAccount.documentType'
                  )}
                </InputLabel>
                <Select
                  fullWidth
                  onChange={onChange}
                  value={value}
                  label={translate(
                    'app.setting.companyRegister.bankAccount.documentType'
                  )}
                  variant="outlined"
                  error={Boolean(errors?.bank_fiscal_number_type_id)}
                >
                  <MenuItem value="" style={{ minHeight: '3rem' }} />
                  {documentTypeList &&
                    documentTypeList.map((documentType) => (
                      <MenuItem
                        key={documentType.code}
                        value={translate(documentType.code)}
                      >
                        {documentType.name}
                      </MenuItem>
                    ))}
                </Select>
                {errors.bank_fiscal_number_type_id && (
                  <FormHelperText error>
                    {translate(errors.bank_fiscal_number_type_id.message)}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="bank_fiscal_number"
            render={({ onChange, value }) => (
              <TextField
                fullWidth
                onChange={onChange}
                value={value}
                label={translate(
                  'app.setting.companyRegister.bankAccount.identificationCardNumber'
                )}
                variant="outlined"
                size="small"
                error={Boolean(errors?.bank_fiscal_number)}
                helperText={
                  errors.bank_fiscal_number &&
                  translate(errors.bank_fiscal_number.message)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="account_name"
            render={({ onChange, value }) => (
              <TextField
                fullWidth
                onChange={onChange}
                value={value}
                label={translate(
                  'app.setting.companyRegister.bankAccount.name'
                )}
                variant="outlined"
                size="small"
                error={Boolean(errors?.account_name)}
                helperText={
                  errors.account_name && translate(errors.account_name.message)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="last_name"
            render={({ onChange, value }) => (
              <TextField
                fullWidth
                onChange={onChange}
                value={value}
                label={translate(
                  'app.setting.companyRegister.bankAccount.lastName'
                )}
                variant="outlined"
                size="small"
                error={Boolean(errors?.last_name)}
                helperText={
                  errors.last_name && translate(errors.last_name.message)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="bank_certification"
            render={({ onChange, value }) => (
              <ButtonUploadFileModal
                type_cd={3}
                buttonColor={
                  COLORS_BY_STATUS_FOR_DOCUMENT_HISTORY[
                    companyRegister?.bank_certification?.status
                  ]
                }
                onChange={(file, url) => {
                  onChange(url);
                  setBankCertification(file);
                }}
                error={!!errors?.bank_certification}
                errorText={
                  errors?.bank_certification &&
                  translate(errors.bank_certification.message)
                }
                value={value}
                buttonText={translate(
                  'app.setting.companyRegister.bankAccount.bankCertification'
                )}
                variant="outlined"
                titleText={translate(
                  'app.setting.companyRegister.bankAccount.bankCertification'
                )}
                helperText={translate(
                  'app.setting.companyRegister.document.helperText'
                )}
                customValidationForHandleUpload={[approved, pending_update].includes(companyRegister.registration_status)}
              />
            )}
          />
        </Grid>
      </Grid>
    </FieldsContainer>
  );
};

export default BankAccount;
