import { string, object } from 'yup';

const schema = object().shape({
  person_type: string().required('requiredField'),
  id_type: string().required('requiredField'),
  person_id: string().required('requiredField'),
  phone_number: string().required('requiredField'),
  company_name: string().required('requiredField'),
  name: string().required('requiredField'),
  email: string().required('requiredField').email('invalidEmailAddress'),
  city_state: string().required('requiredField'),
  city: string().required('requiredField'),
  bank_code: string().required('requiredField'),
  address: string().required('requiredField')
});

export default schema;